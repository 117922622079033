// @flow
import { type JSSThemeVariablesType, type $DeepShape } from './JSSThemeDefault';

export const JSSThemeDefaultSitemap = (themeVars: JSSThemeVariablesType) => {
  const {
    colors: {
      colorTypes: { text1, white },
    },
  } = themeVars;
  return {
    sitemapTitle: {
      color: text1,
      fontWeight: 'bold',
      fontSize: 30,
      marginBottom: 5,
      '@media (max-width: 630px)': {
        fontSize: 28,
      },
    },
    sitemapContainer: {
      padding: '10px 30px 30px',
      maxWidth: 1200,
      '@media (max-width: 630px)': {
        padding: '0px 10px 20px',
      },
    },

    firstListLevel: {
      listStyle: 'none',
      paddingTop: 15,
      height: 'auto',
    },
    firstLinkLevel: {
      color: white,
      fontSize: 20,
      fontWeight: 'bold',
      listStyle: 'none',
      clear: 'left',
      float: 'left',
      paddingBottom: '20px',
      '&:hover': {},
      '@media (max-width: 630px)': {
        fontSize: 20,
      },
    },
    secondListLevelWrapper: {
      float: 'left',
      clear: 'left',
    },
    secondLinkLevel: {
      float: 'left',
      clear: 'left',
      color: white,
      fontSize: 14,
      // width: '50%',
      fontWeight: 'bold',
      listStyle: 'none',
      '&:hover': {},
    },
    secondListLevel: {
      listStyle: 'none',
      paddingTop: 10,
      '@media (max-width: 630px)': {
        width: '100%',
      },
    },
    thirdListLevelWrapper: {
      float: 'left',
      clear: 'left',
      paddingBottom: '20px',
    },
    thirdListLevel: {
      listStyle: 'none',
      paddingTop: 4,
      marginRight: '10px',
      float: 'left',
    },
    thirdLinkLevel: {
      color: ' rgba(255, 255, 255, 0.72)',
      fontSize: 15,
      fontWeight: 'normal',
      listStyle: 'none',
      '&:hover': {
        color: white,
      },
      '&:first-of-type': {
        clear: 'left',
      },
    },
    column: {
      width: '50%',
      '@media (max-width: 450px)': {
        width: '100%',
      },
    },
  };
};

export type JSSThemeDefaultSitemapType = JSSThemeVariablesType => $DeepShape<
  $Call<typeof JSSThemeDefaultSitemap, JSSThemeVariablesType>
>;
