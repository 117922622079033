// @flow
import { DIFFUSION_HANDLED_TOPICS } from '../handled-topics';
import { handleMessages } from './utils';
import { handleScoreboardTopic } from './scoreboard/handle-scoreboard-topic';
import { messageHandlerSettings as message } from './message';
import { quoteHandlerSettings as quote } from './quote';
import { betHandlerSettings as bet } from './bet';
import { eventHandlerSettings as event } from './event';
import { marketHandlerSettings as market } from './market';
import { outcomeHandlerSettings as outcome } from './outcome';
import { clockHandlerSettings as clock } from './clock';
import { sportHandlerSettings as sport } from './sport';
import type { Message, Config } from '../types';

const settings = {
  market,
  outcome,
  event,
  clock,
  message,
  quote,
  bet,
  sport,
};

export const handleTopics = (config: Config) => (message: Message) =>
  message.topic.type === DIFFUSION_HANDLED_TOPICS.SCOREBOARD_TOPIC
    ? handleScoreboardTopic(message)
    : handleMessages(config, message, settings[message.topic.type]);
