// @flow
import type { ReduxAction } from '../../types';

export const FETCH_LIVE_EVENTS: 'diffusion:fetch-live-events' =
  'diffusion:fetch-live-events';

export const diffusionReducer = (
  state: { +liveEventsFetched?: boolean } = {},
  action: ReduxAction
) => {
  switch (action.type) {
    case FETCH_LIVE_EVENTS: {
      return {
        ...state,
        liveEventsFetched: true,
      };
    }
    default:
      return state;
  }
};
