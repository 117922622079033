// @flow
import { type $DeepShape, JSSThemeVariables } from './JSSThemeDefault';

export const JSSThemeDefaultPopularLeagues = (
  themeVars: typeof JSSThemeVariables
) => {
  const {
    margins,
    colors: {
      colorTypes: { background2, white, primary, background5 },
    },
  } = themeVars;
  return {
    PopularLeagues: {
      root: {
        background: background2,
        padding: [margins.lg, 0],
        '@media (pointer: coarse)': {
          background: background5,
        },
      },
      header: {
        color: white,
        fontWeight: 'bold',
        height: 35,
        lineHeight: '35px',
        margin: [0, margins.md],
        '@media (pointer: coarse)': {
          margin: [0, margins.xm],
          color: `${white} !important`,
          fontSize: '14px',
          opacity: 0.5,
          fontWeight: 300,
          textTransform: 'capitalize',
        },
      },
      list: {
        marginTop: margins.xs,
        listStyleType: 'none',
        listStyle: 'none',
        margin: 0,
        padding: 0,
      },
      img: {
        width: '14px',
        height: '14px',
        marginRight: margins.md,
        '@media (pointer: coarse)': {
          filter: `none !important`,
          width: '20px',
          height: '20px',
        },
      },
      item: {},
      link: {
        color: white,
        display: 'flex',
        alignItems: 'center',
        padding: [margins.sm, margins.md],
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'none',
          color: white,
          background: primary,
        },
        '@media (pointer: coarse)': {
          padding: [margins.sm, margins.xm],
          color: `${white} !important`,
        },
      },
    },
  };
};

export type JSSThemeDefaultPopularLeaguesType = (
  typeof JSSThemeVariables
) => $DeepShape<
  $Call<typeof JSSThemeDefaultPopularLeagues, typeof JSSThemeVariables>
>;
