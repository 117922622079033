// @flow
import { LoadableComponentWrapper } from '../../components/components/LoadableComponentWrapper';
import { createKoltronApp } from '../createKoltronApp';
import {
  JSSTheme60700,
  JSSThemeVariables60700,
  getJSSThemePatterns60700,
} from './JSSTheme60700';
import { GeoblockPage60700 } from './GeoblockPage60700';
// $FlowIgnore
import '../../../styles/application-koltron.scss';

createKoltronApp({
  DesktopComponent: LoadableComponentWrapper({
    loader: async () => (await import('./FullDesktop60700')).FullDesktop60700,
  }),
  MobileComponent: LoadableComponentWrapper({
    loader: async () => (await import('./FullMobile60700')).FullMobile60700,
  }),
  JSSTheme: JSSTheme60700,
  JSSThemeVariables: JSSThemeVariables60700,
  JSSThemePatterns: getJSSThemePatterns60700,
  GeoblockPage: GeoblockPage60700,
});
