// @flow
import { type JSSThemeDefaultDropdownMenuType } from '../JSSThemeDefaultDropdownMenu';

export const JSSThemeDropdownMenu60700: JSSThemeDefaultDropdownMenuType = themeVars => {
  const {
    colors: {
      colorTypes: { neutral1, text3, black },
    },
  } = themeVars;
  return {
    dropdownDesktop: {
      item: {
        background: neutral1,
        color: text3,
        '&:hover,&.active': { color: black, background: neutral1 },
      },
    },
  };
};

