import axios, { CancelToken } from 'axios';
import { Observable } from 'rxjs';

export const axiosObservable = config =>
  Observable.create(observer => {
    let source;
    const shouldAssignCancelToken = !config.cancelToken;

    if (shouldAssignCancelToken) {
      source = CancelToken.source();
      // eslint-disable-next-line no-param-reassign
      config = {
        ...config,
        cancelToken: source.token,
      };
    }

    axios(config).then(
      response => {
        observer.next(response);
        observer.complete();
      },
      error => {
        if (shouldAssignCancelToken && axios.isCancel(error)) {
          observer.complete();
        } else {
          observer.error(error);
        }
      }
    );

    return () => {
      if (source) {
        source.cancel();
      }
    };
  });
