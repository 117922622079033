// @flow
import { getEventId, getMarketId, parseJSONSafely } from './utils';
import type { Message } from '../types';

const keys = {
  '1': 'description',
  '2': 'period',
  '3': 'status',
  '4': 'marketTypeId',
  '5': 'order',
  '6': 'ewTerms',
};

const getKey = (message: Message) => getMarketId(message);

const handlers = {
  description: parseJSONSafely,
  period: parseJSONSafely,
  order: Number,
};

const getItlData = (message: Message) => ({
  eventId: getEventId(message),
  key: getKey(message),
});

export const marketHandlerSettings = { keys, getKey, handlers, getItlData };
