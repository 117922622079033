// @flow
import { JSSThemeVariables } from '../JSSThemeDefault';

export const JSSThemeBonuses60700 = (themeVars: typeof JSSThemeVariables) => {
  const {
    margins,
    colors: {
      colorTypes: { white, text4, black },
    },
  } = themeVars;
  return {
    BonusesTabs: {
      tabsItemMobile: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        background: text4,
        borderRight: 'none',
        color: white,
      },
      '&.active': {
        color: white,
      },
      tabsItemDesktop: {
        background: text4,
        color: white,
      },
      bonusContainer: {
        background: 'transparent',
        border: 'none',
        padding: 0,
      },
      tabsContentDesktop: {
        padding: [margins.md, 0],
      },
    },
    Bonuses: {
      description: {
        color: black,
      },
      headingSmall: {
        color: black,
      },
      noBonus: {
        color: black,
      },
      root: {
        border: 'none',
        color: 'black',
        borderRadius: 5,
        background: '#e3e3e3',
      },
      img: {
        padding: [margins.md, margins.md, 0, 0],
      },
    },
  };
};
