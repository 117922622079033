// @flow
import { combineReducers } from 'redux';
import outcomes from './outcomes';
import markets from './markets';
import events from './events';
import clocks from './clocks';
import scoreboards from './scoreboards';
import { favouriteMarkettypes } from './favourite-markettypes';
import { favouriteEventIds } from './favourite-event-ids';
import perform from './perform';
import { type ReduxAction } from '../../../types';

export const liveReducer = combineReducers<_, ReduxAction>({
  outcomes,
  events,
  clocks,
  markets,
  scoreboards,
  favouriteMarkettypes,
  favouriteEventIds,
  perform,
});
