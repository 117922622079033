// @flow
import { type JSSThemeVariablesType, type $DeepShape } from './JSSThemeDefault';

export const JSSThemeDefaultBetgenius = (themeVars: JSSThemeVariablesType) => {
  const {
    margins,
    colors: {
      colorTypes: { background2 },
    },
  } = themeVars;
  return {
    iframeWrapper: {
      display: 'flex',
      margin: [margins.md, margins.md, 0],
      borderRadius: 3,
      padding: 0,
      resize: 'both',
      background: background2,
      overflow: 'hidden',
    },
    liveBetbuilderWrapper: {
      width: 'auto',
      resize: 'none',
      margin: '10px',

      '@media (max-width: 1480px)': {
        maxWidth: 829,
      },

      '@media (max-width: 1024px)': {
        margin: '10px',
      },

      '@media (max-width: 660px)': {
        maxWidth: 500,
      },
    },
    iframe: {
      flexGrow: 1,
      margin: 0,
      padding: 0,
      border: 0,
    },
  };
};

export type JSSThemeDefaultBetgeniusType =
  (JSSThemeVariablesType) => $DeepShape<
    $Call<typeof JSSThemeDefaultBetgenius, JSSThemeVariablesType>
  >;
