// @flow
import { type JSSThemeVariablesType, type $DeepShape } from './JSSThemeDefault';

export const JSSThemeDefaultEventSlider = (
  themeVars: JSSThemeVariablesType
) => {
  const {
    margins,
    borderRadius,
    fontSizes,
    colors: {
      colorTypes: { black, neutral2 },
    },
  } = themeVars;
  return {
    EventSlider: {
      root: {
        display: 'flex',
        overflowX: 'scroll',
        margin: [margins.md, 0],
        padding: [0, margins.md],
      },
    },
    EventSlide: {
      root: {
        width: 300,
        height: 204,
        background: neutral2,
        borderRadius: borderRadius.lg,
        padding: margins.xm,
        margin: [0, margins.xs],
        flex: '1 0 auto',
      },
      container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      },
      titleBlock: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      date: {
        fontSize: fontSizes.xs,
      },
      time: {
        fontSize: fontSizes.md,
      },
      timeLive: {
        fontSize: fontSizes.md,
        display: 'flex',
      },
      liveDetails: {
        fontSize: fontSizes.xs,
      },
      status: {
        fontSize: fontSizes.xs,
      },
      statusContainer: {
        padding: [0, margins.xs],
        border: `1px solid ${black}`,
        boxSizing: 'border-box',
        borderRadius: borderRadius.md,
        height: 17,
      },
      teamsTitle: {
        fontSize: 10,
        display: 'flex',
        justifyContent: 'space-between',
      },
      teamScore: {
        fontWeight: 'bold',
      },
      firstTeam: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      secondTeam: {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
  };
};

export type JSSThemeDefaultEventSliderType = JSSThemeVariablesType => $DeepShape<
  $Call<typeof JSSThemeDefaultEventSlider, JSSThemeVariablesType>
>;
