// @flow
import { zipWith } from 'ramda';
import { reduceDiffusionMessages, diffusionMessageTypes } from '../helpers';
import type { ReduxAction, LiveScoreboardType } from '../../../types';
import { LIVE_DIFFUSION } from '../../actions/live/actions';

const zipValidData = zipWith((update, current) => update || current);

const handlers = {
  [diffusionMessageTypes.delta]: (state, { id, data }) => {
    const scoreboartdToMutate = { ...state[id] };
    state[id] = Object.keys(data).reduce((nextScoreboard, scoreType) => {
      nextScoreboard[scoreType] = zipValidData(
        data[scoreType],
        nextScoreboard[scoreType]
      );
      return nextScoreboard;
    }, scoreboartdToMutate);
    return state;
  },
};

const scoreboards = (
  state: { [id: string]: LiveScoreboardType } = {},
  action: ReduxAction
) => {
  switch (action.type) {
    case LIVE_DIFFUSION: {
      return reduceDiffusionMessages({
        state,
        handlers,
        messages: action.payload,
        topicType: 'scoreboard',
      });
    }
    default:
      return state;
  }
};

export default scoreboards;
