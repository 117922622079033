// @flow
import {
  getSportId,
  getEventId,
  parseJSONSafely,
  getSafetyEventField,
} from './utils';
import type { Message, Config } from '../types';
import type { StreamsType } from '../../types';

const keys = {
  '0': 'id',
  '1': 'teams',
  '2': 'score',
  '3': 'video',
  '4': 'isOpen',
  '5': 'primaryMarketId',
  '6': 'period',
  '7': 'championship',
  '8': 'home',
  '9': 'away',
  '11': 'leagueId',
  '12': 'startTimestamp',
  '13': 'vendorId', // BG event id, is used for live BG scoreboards
  '14': 'streams',
  '15': 'score24Id',
  '16': 'liveBetbuilderId',
};

const getKey = (message: Message) => getEventId(message);

const handlers = {
  teams: parseJSONSafely,
  video: parseJSONSafely,
  period: parseJSONSafely,
  championship: parseJSONSafely,
  home: parseJSONSafely,
  away: parseJSONSafely,
  leagueId: (eventpath: string) => eventpath.split('/')[2],
  streams: parseJSONSafely,
};

const childrenHandlers = {
  streams: (
    streams: ?Array<string>
  ): { streams: StreamsType, isVideoAvailable: boolean } => {
    /**
     * We have different perform stream ids for different origins.
     *
     * Now structure of array of video streams looks like:
     * [“BG_url”,“Inkabet_Perform_ID”,“SIS_url”, “Rojabet_Perform_ID", “Latribet_Perform_ID”]
     */

    const streamIndex = __OSG_CONFIG__.performLiveStreaming.streamIndex;

    if (Array.isArray(streams)) {
      /** Phoenix hasn't added it in their diffusion. So this param is for supporting old version **/
      const isOldDiffusion = streams.length === 3;
      /** Video is available only if provider is enabled in origin's config **/
      const isVideoAvailable =
        (streams[0] && __OSG_CONFIG__.showBgLiveStreaming) ||
        ((isOldDiffusion ? streams[1] : streams?.[streamIndex]) &&
          __OSG_CONFIG__.performLiveStreaming.show) ||
        (streams[2] &&
          (__OSG_CONFIG__.showHorseRacing || __OSG_CONFIG__.showGreyhounds));
      return {
        streams: {
          bg: streams[0] || '',
          perform: (isOldDiffusion ? streams[1] : streams?.[streamIndex]) || '',
          sis: streams[2] || '',
          streams,
        },
        isVideoAvailable: !!isVideoAvailable,
      };
    }

    return {
      streams: { streams },
      isVideoAvailable: false,
    };
  },
  away: (away: string | Object): { away: string } => {
    return {
      away: getSafetyEventField(away, 'away'),
    };
  },
  championship: (championship: string | Object): { championship: string } => {
    return {
      championship: getSafetyEventField(championship, 'championship'),
    };
  },
  home: (home: string | Object): { home: string } => {
    return {
      home: getSafetyEventField(home, 'home'),
    };
  },
  period: (period: string | Object): { period: string } => {
    return {
      period: getSafetyEventField(period, 'period'),
    };
  },
  teams: (teams: string | Object): { teams: string } => {
    return {
      teams: getSafetyEventField(teams, 'teams'),
    };
  },
};

const getItlData = (message: Message, config: Config) => {
  const sportId = getSportId(message);
  return {
    sportId,
    key: getKey(message),
  };
};

export const eventHandlerSettings = {
  keys,
  getKey,
  handlers,
  getItlData,
  childrenHandlers,
};
