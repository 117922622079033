// @flow
if (!__PROD__) {
  window.__OSG_LANGUAGE__ = window.__OSG_RUNTIME_CONFIG__.locale;
}

if (
  !window.__osg_translations_promise &&
  process.env.KOLTRON_RESOURCE !== 'test'
) {
  throw new Error(
    'window.__osg_translations_promise must exist so the translations are available'
  );
}

let ALL_KEYS = {};

export const translationsLoaderPromise = window.__osg_translations_promise.then(
  data => {
    ALL_KEYS = data;
  }
);

// eslint-disable-next-line  no-underscore-dangle
window._t = key => {
  if (Object.prototype.hasOwnProperty.call(ALL_KEYS, key)) {
    return ALL_KEYS[key];
  }

  // eslint-disable-next-line  no-console
  console.warn(`No translation for "${key}"`);
  return key;
};
