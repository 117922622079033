// @flow
import { type $DeepShape, JSSThemeVariables } from './JSSThemeDefault';

export const JSSThemeDefaultDropdownMenu = (
  themeVars: typeof JSSThemeVariables
) => {
  const {
    fontSizes,
    margins,
    colors: {
      colorTypes: { background1, primary, white },
    },
  } = themeVars;
  return {
    dropdownDesktop: {
      imageFlag: {
        width: 20,
        height: 15,
        marginRight: margins.xs,
      },
      selectorWrap: {
        color: 'inherit',
        marginLeft: 15,
      },
      titleWrap: {
        display: 'flex',
        fontSize: fontSizes.sm,
      },
      titleItem: {
        textTransform: 'capitalize',
        fontSize: 12,
        marginLeft: margins.xs,
      },
      list: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        marginTop: margins.md,
        listStyle: 'none',
        '&:before': {
          content: '""',
          position: 'absolute',
          width: 10,
          height: 10,
          right: 10,
          transform: 'rotate(-45deg)',
          transformOrigin: 'top left',
          background: background1,
          zIndex: 0,
        },
      },
      item: {
        padding: [margins.xs, margins.xm],
        display: 'flex',
        background: background1,

        whiteSpace: 'nowrap',
        fontSize: fontSizes.sm,
        zIndex: 1,
        cursor: 'pointer',
        '&:hover,&.active': { background: primary },
        '&:first-child': { paddingTop: '10px' },
        '&:last-child': { paddingBottom: '10px' },
      },
    },

    dropdownMobile: {
      list: {
        listStyle: 'none',
      },
      item: {
        display: 'flex',
        flexWrap: 'nowrap',
        height: 40,
      },
      itemFlag: {
        width: 20,
        height: 15,
        marginRight: margins.xs,
      },
      select: {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'none',
        '-ms-appearance': 'none',
        textAlignLast: 'center',
        appearance: 'none !important',
        border: 'none',
        backgroundColor: 'transparent',
        color: white,
        fontSize: fontSizes.lg,
      },
      chevron: {
        fontSize: fontSizes.sm,
        display: 'inline-block',
      },
      description: {
        display: 'inline-block',
        fontSize: fontSizes.lg,
        marginRight: margins.xs,
      },
      selectorWrapMobile: {
        display: 'flex',
        color: 'inherit',
        marginLeft: 0,
        alignItems: 'center',
      },
      selectorWrapMobileFlags: {
        display: 'none',
        color: 'inherit',
        marginLeft: 0,
        alignItems: 'center',
      },
      titleItemMobile: {
        display: 'flex',
        textTransform: 'capitalize',
        fontSize: 16,
        alignItems: 'center',
      },
      listMobile: {
        padding: [10, 25, 10, 15],
        background: background1,
        listStyle: 'none',
        '&:before': {
          content: '""',
          position: 'absolute',
          width: 10,
          height: 10,
          right: 25,
          transform: 'rotate(-45deg)',
          transformOrigin: 'top left',
          background: background1,
          zIndex: 0,
          bottom: -10,
        },
      },
      itemMobile: {
        display: 'flex',
        flexWrap: 'nowrap',
        height: 30,
      },
    },
  };
};

export type JSSThemeDefaultDropdownMenuType = (
  typeof JSSThemeVariables
) => $DeepShape<
  $Call<typeof JSSThemeDefaultDropdownMenu, typeof JSSThemeVariables>
>;
