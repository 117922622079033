// @flow
import * as React from 'react';
import { path, replace } from 'ramda';
import { Link, matchPath, NavLink, Route } from 'react-router-dom';
import {
  CALENDAR_PATH,
  CAN_GO_BACK,
  EVENT_ID_PARAM,
  LIVE_EVENT_PATH,
  LIVE_PATH,
  LIVE_SPORT_PATH,
  PREMATCH_PATH,
  SCHEDULE_PATH,
  SPORT_ID_PARAM,
} from '../common/constants';
import { type ReduxAction } from './types';
import { routeFactory } from './router-utils/helpers';
import { format, parseISO } from 'date-fns';
import { SEODescriptionOnPages } from './components/SEODescriptionOnPages';
import type { BetslipSelectionSingleType } from './types';

const replaceEventIdParam = replace(`:${EVENT_ID_PARAM}`);
const replaceSportIdParam = replace(`:${SPORT_ID_PARAM}`);

export const makeBemClass =
  (prefix: string) =>
  (element?: string | null, modificator?: string | null | false) => {
    let bemClass = element ? `${prefix}__${element}` : prefix;
    if (modificator) {
      bemClass += ` ${bemClass}--${modificator}`;
    }
    return bemClass;
  };

export const LiveRoute = routeFactory(LIVE_PATH);

export const getLiveEventLink = ({ id }: { +id: string }): string =>
  replaceEventIdParam(id, LIVE_EVENT_PATH);

export const getLiveSportLink = ({ id }: { +id: string }): string =>
  replaceSportIdParam(id, LIVE_SPORT_PATH);

export const getSSOSubmitFirstError: (Object) => ?string = path([
  'response',
  'data',
  'errors',
  '0',
  'message',
]);

export const getSSOSubmitFirstErrorCode: (Object) => ?string = path([
  'response',
  'data',
  'errors',
  '0',
  'code',
]);

export const LiveEventLink = ({
  event,
  ...props
}: {
  event: { +id: string },
}) => (
  <Link
    to={{
      pathname: getLiveEventLink(event),
      state: { [CAN_GO_BACK]: true },
    }}
    {...props}
  />
);

export const LiveSportLink = ({
  sport,
  ...props
}: {
  sport: {
    +id: string,
  },
}) => <Link to={getLiveSportLink(sport)} {...props} />;

export const bem = makeBemClass('osg-layout');

const LinkComponent = ({
  activeClassName,
  className: propsClassName,
  exact = false,
  ...props
}: {
  activeClassName: ?string,
  className: ?string,
  exact?: boolean,
  to: string,
  children: React.Node,
}) => {
  const className = !!propsClassName ? propsClassName : '';
  return activeClassName ? (
    <NavLink
      {...props}
      activeClassName={activeClassName}
      className={className}
      exact={exact}
    />
  ) : (
    <Link {...props} className={className} />
  );
};

export const CalendarLink = ({
  className,
  activeClassName,
}: {
  className?: ?string,
  activeClassName: string,
}) => (
  <LinkComponent
    className={className}
    activeClassName={activeClassName}
    to={CALENDAR_PATH}
    children={_t('coming-events')}
  />
);

class LastVisitedEventWrapper extends React.Component<
  { className: ?string, activeClassName: string },
  {
    lastVisitedEventId: ?string,
  }
> {
  state = {
    lastVisitedEventId: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const match = matchPath(nextProps.location.pathname, LIVE_EVENT_PATH);
    const lastVisitedEventId = match && match.params[EVENT_ID_PARAM];
    if (
      lastVisitedEventId &&
      prevState.lastVisitedEventId !== lastVisitedEventId
    ) {
      return { lastVisitedEventId };
    }

    return null;
  }

  render() {
    const { className, activeClassName } = this.props;
    const { lastVisitedEventId } = this.state;
    return (
      <LinkComponent
        className={className}
        activeClassName={activeClassName}
        exact
        to={
          lastVisitedEventId
            ? getLiveEventLink({ id: lastVisitedEventId })
            : LIVE_PATH
        }
      >
        <span>{_t('event-view')}</span>
      </LinkComponent>
    );
  }
}

export const SheduleLink = ({
  className,
  activeClassName,
}: {
  className?: string,
  activeClassName: string,
}) => {
  return (
    <LinkComponent
      className={className}
      activeClassName={activeClassName}
      exact
      to={SCHEDULE_PATH}
    >
      <span>{_t('schedule-link')}</span>
    </LinkComponent>
  );
};

export const LiveEventViewLink = ({
  className,
  activeClassName,
}: {
  className?: string,
  activeClassName: string,
}) => {
  return <Route>{(prop) => <LastVisitedEventWrapper {...prop} />}</Route>;
};

export const PrematchRoute = routeFactory(PREMATCH_PATH);

export const getPromotionTypeTitle = (type: string) => {
  switch (type) {
    case 'casino':
      return _t('casino');
    // FIXME: Need only for next release. Remove 'sports' somehow
    case 'sports':
    case 'sportsbook':
      return _t('sports');
    default:
      return 'promotionTypeTitle';
  }
};

export const loadScript = (
  src: string
): Promise<{ ...Event, target: HTMLScriptElement }> =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.setAttribute('src', src);
    script.onload = resolve;
    script.onerror = reject;
    document.getElementsByTagName('head')[0].appendChild(script);
  });

export const formatDateYMD = (date: Date | string) =>
  format(typeof date === 'string' ? parseISO(date) : date, 'yyyy-MM-dd');

export const formatDateYMDHMS = (date: Date | string) =>
  format(
    typeof date === 'string' ? parseISO(date) : date,
    "yyyy-MM-dd'T'HH:mm:ss"
  );

export const formatUTCDateYMDHMS = (date: Date): string => {
  const isoDate = date.toISOString();
  return `${isoDate.substr(0, 10)}T${isoDate.substr(11, 8)}`;
};

export const MILLISECOND_TO_HOURS_MULTIPLIER = 60 * 60 * 1000;

// Convert local time to UTC (as our java backend server stores all transaction dates in UTC)
export const getDateForDatePicker = (date: string): string => {
  const unixTimeZero = Date.parse(date);
  const machineTimezoneOffset = getMachineTimezoneOffset();
  const selectedTimezoneOffset = getSelectedTimezoneOffset();

  return formatUTCDateYMDHMS(
    new Date(
      unixTimeZero +
        (machineTimezoneOffset + selectedTimezoneOffset) *
          MILLISECOND_TO_HOURS_MULTIPLIER +
        1000
    )
  );
};

const toggleFavorites = (state: Array<string>, id: string): Array<string> =>
  state.includes(id) ? state.filter((el) => el !== id) : state.concat(id);

export const createToggleFavoritesReducer =
  (
    toggleActionType:
      | 'live:toggle-favourite-event'
      | 'live:toggle-favourite-markettype'
  ) =>
  (state: Array<string> = [], action: ReduxAction) => {
    switch (action.type) {
      case toggleActionType:
        return toggleFavorites(state, action.payload);
      default:
        return state;
    }
  };

export const changeCurrentLocale = (newLocale: string) => {
  const currentLocale = window.__OSG_RUNTIME_CONFIG__.locale;

  if (newLocale === currentLocale) return;

  window.document.cookie = `${window.__OSG_RUNTIME_CONFIG__.langCookieName}=${newLocale}; path=/`;
  window.document.location.reload();
};

export const getSelectedTimezoneOffset = (): number => {
  const timezones = __OSG_CONFIG__.timezones;
  let timezone;

  try {
    const localStorageTimezoneKey = 'OSG_timezone';
    const value = localStorage.getItem(localStorageTimezoneKey);
    if (!value) throw new Error();
    timezone = timezones.find(({ zone }) => zone === value);
  } catch (error) {
    const currentOffset = -new Date().getTimezoneOffset() / 60;
    timezone = timezones.find(({ offset }) => offset === currentOffset);
  }

  return timezone ? timezone.offset : 0;
};

export const getMachineTimezoneOffset = () =>
  -new Date().getTimezoneOffset() / 60;

export const getInfoLink = (key: string) => {
  const locale = window.__OSG_RUNTIME_CONFIG__.locale;
  const infoLink = __OSG_CONFIG__.infoLinks[key];
  return infoLink[locale] || infoLink.default;
};

export const getStringDifference = (s: string, t: string) => {
  let s1 = [...s].sort();
  let t1 = [...t].sort();
  return t1.find((char, i) => char !== s1[i]);
};

export const REGISTRATION_COMPLETE = 'RegistrationComplete';
export const LOGIN_COMPLETE = 'LoginComplete';

export type dataLayerEventNameType =
  | 'LoginIntention'
  | 'LoginComplete'
  | 'LoginSubmitted'
  | 'LoginFailed'
  | 'RegistrationComplete'
  | 'ForgottenPassword'
  | 'ForgottenPasswordSuccess'
  | 'ForgottenPasswordFailed'
  | 'BetShareButtonClick'
  | 'BetShareModalWindowShown'
  | 'BetShareCodeInserted'
  | 'MarketingConsent'
  | 'DepositSuccess'
  | 'DepositAttempt'
  | 'WithdrawalSuccess'
  | 'WithdrawalAttempt'
  | 'BetSuccess'
  | 'BetAttempt'
  | 'BGStreamDisplayed'
  | 'BetslipAcceptChangesCheckbox'
  | 'BetslipAcceptChangesModalButton';

export const dataLayerPush = ({
  eventName,
  id,
  error,
}: {
  eventName: dataLayerEventNameType,
  id?: string | number,
  error?: Object | string,
}) => {
  if (__OSG_CONFIG__.useDataLayer) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: eventName,
      ...(id ? { GUID: id } : {}),
      ...(error ? { error } : {}),
    });
  }
};

export const dataLayerPushCustom = ({
  key,
  value,
}: {
  key: string | number,
  value: any,
}) => {
  if (__OSG_CONFIG__.useDataLayer) {
    window.dataLayer = window.dataLayer || [];
    const lang = window.dataLayer.find((item) => item[key]);
    if (lang) {
      const index = window.dataLayer.indexOf(lang);
      window.dataLayer.splice(index, 1);
    }
    window.dataLayer.push({ [key]: value });
  }
};

export const dataLayerPushObject = ({
  eventName,
  data,
}: {
  eventName: dataLayerEventNameType,
  data: Object,
}) => {
  if (__OSG_CONFIG__.useDataLayer) {
    window.dataLayer = window.dataLayer || [];

    const dataForPush = {
      event: eventName,
      ...data,
    };

    window.dataLayer.push(dataForPush);
  }
};

export const isUrl = (text: string) => /(https?:\/\/[^\s]+)/.test(text);

export const withSEO =
  (Component: React.AbstractComponent<any>): React.AbstractComponent<any> =>
  (props: any) => {
    return (
      <>
        <Component {...props} />
        <SEODescriptionOnPages />
      </>
    );
  };

export const isSupportWebP = () => {
  const canvas = document.createElement('canvas');
  if (!!(canvas.getContext && canvas.getContext('2d'))) {
    return canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }
  // [ ]: ancient browsers don't support  canvas / WebP
  return false;
};

export const getSportIdsFromSingles = (
  singles: BetslipSelectionSingleType[]
) => {
  return [...new Set(singles.map((single) => single.sportId))];
};

export const ACCEPT_ALL_FLAG_KEY = 'accept_all_price_changed';

export const getAcceptAllFlag = () => {
  const storageValue = localStorage.getItem(ACCEPT_ALL_FLAG_KEY) || '{}';
  const acceptAllFlagsObject = JSON.parse(storageValue);
  Object.keys(acceptAllFlagsObject).forEach((id) => {
    acceptAllFlagsObject[id] = JSON.parse(acceptAllFlagsObject[id]) === true;
  });
  return acceptAllFlagsObject;
};
