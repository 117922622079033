// @flow
import { type JSSThemeVariablesType, type $DeepShape } from './JSSThemeDefault';

export const JSSThemeDefaultPromotions = (themeVars: JSSThemeVariablesType) => {
  const {
    fontSizes,
    margins,
    colors: {
      colorTypes: { white, primary },
    },
  } = themeVars;
  return {
    PromotionsComponent: {
      itemsByType: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      itemsByTypeTitle: {
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: fontSizes.xxl,
        marginBottom: margins.lg,
      },
      listTabs: {
        listStyle: 'none',
        display: 'flex',
        flexDirection: 'row',
        fontSize: fontSizes.xxl,
        gap: '1em',
        '@media screen and (max-width: 380px)': {
          fontSize: '16px',
        },
      },
      nav: {
        paddingTop: margins.md,
        paddingBottom: '16.5px',
        paddingLeft: '',
        '@media (pointer: coarse)': {
          paddingLeft: '',
        },
      },
      selectedLink: {
        color: `${primary} !important`,
        textDecoration: 'none',
        fontWeight: 'bold',
        paddingBottom: '4px',
        borderBottom: `3px solid ${primary} !important`,
        '&:hover': {
          opacity: `1 !important`,
        },
      },
      promoLink: {
        textDecoration: 'none',
        color: white,
        transition: 'none',
        '&:hover, &:active': {
          color: white,
          opacity: 0.7,
          paddingBottom: '4px',
          borderBottom: `3px solid ${white}`,
        },
      },
    },
    PromotionsWrapper: {
      promotionsRoot: {
        padding: 0,
      },
      promotionsWrapper: {
        padding: [0, margins.xm],
        marginTop: margins.xs,
      },
      promotionsHeading: {
        fontSize: fontSizes.xxxl,
        fontWeight: 'bold',
        padding: [0, 0, margins.xs, 0],
      },
    },
    PromotionUI: {
      promoItemContainer: {
        textAlign: 'left',
        flexBasis: '100%',
        width: '100%',
        marginBottom: margins.xl - 10,
        '@media screen and (min-width: 1300px)': {
          flexBasis: `calc((100% - ${margins.xm}px) / 2)`,
          '&:nth-child(odd)': {
            marginRight: 'auto',
          },
          '&:last-child': {
            marginRight: 0,
          },
        },
      },
      itemBody: {
        backgroundPosition: 'right center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundColor: white,
        overflow: 'hidden',
        padding: margins.xm,
        display: 'flex',
      },
      itemBodyHeight: {
        height: 150,
      },
      bannerContentWrapper: {
        display: 'flex',
      },

      bannerContentHeader: {
        fontSize: fontSizes.xxxxl,
        padding: [margins.md, 0, 0],
        fontWeight: 'bold',
        color: white,
      },
      bannerContentTitle: {
        fontSize: fontSizes.xxl,
        fontWeight: 'bold',
        color: white,
      },
      itemSecondTitle: {
        fontSize: fontSizes.xl,
        fontWeight: 'bold',
        marginTop: margins.xm,
      },
      itemDescription: {
        fontSize: fontSizes.md,
      },
      buttonSection: {
        marginTop: margins.md,
      },
    },
    SeasonalPromotion: {
      promoImgContainer: {
        position: 'relative',
        overflow: 'hidden',
      },
      img: {
        display: 'block',
        height: 135,
        width: '100%',
        objectFit: 'cover',
        '@media screen and (min-width: 1550px)': {
          height: 'auto',
          width: '100%',
          objectFit: 'unset',
        },
      },
      root: {
        padding: [margins.lg, margins.lg, margins.xl],
        whiteSpace: 'pre-line',
      },
      buttonRoot: {
        background: primary,
        padding: margins.xm,
      },
      promoHeading: {
        padding: [margins.xm, margins.xm, 0],
        '@media (pointer: coarse)': {
          padding: 0,
        },
      },
      promoHeader: {
        fontWeight: 'bold',
        margin: [0, 0, margins.xm],
      },
      termsHeader: {
        fontWeight: 'bold',
      },
    },
  };
};

export type JSSThemeDefaultPromotionsType =
  (JSSThemeVariablesType) => $DeepShape<
    $Call<typeof JSSThemeDefaultPromotions, JSSThemeVariablesType>
  >;
