// @flow
import * as React from 'react';
import { useJSSStyles } from '../jss-hooks';
import { type JSSThemeType } from '../origins/JSSThemeDefault';
const path = (theme: JSSThemeType) => theme.components.Geoblock;

export const GeoblockPageAll = () => {
  const classes = useJSSStyles(path);
  return (
    <div className={classes.rootGeoblock}>
      <div className={classes.textGeoblock}>{_t('site-is-geoblocked')}</div>
    </div>
  );
};
