// @flow

export const LIVE_BETGENIUS_SET_IS_IFRAME: 'live:betgenius-set-is-iframe' =
  'live:betgenius-set-is-iframe';

export const LIVE_BETGENIUS_SET_ERROR: 'live:betgenius-set-error' =
  'live:betgenius-set-error';

export const LIVE_PERFORM_SET_ERROR: 'live:perform-set-error' =
  'live:perform-set-error';

export const LIVE_BETGENIUS_SET_IS_IFRAME_TENNIS_ABP: 'live:betgenius-set-is-iframe:tennis:abp' =
  'live:betgenius-set-is-iframe:tennis:abp';

export const LIVE_BETGENIUS_SET_IS_IFRAME_TENNIS_PHOENIX: 'live:betgenius-set-is-iframe:tennis:phoenix' =
  'live:betgenius-set-is-iframe:tennis:phoenix';
