// @flow
import TopicTypes from './topic-types';

export const createTopic = {
  from(raw: string = '') {
    const parts = raw.split('/') || [];
    const type = TopicTypes.resolve(raw) || TopicTypes.unknown;
    return { parts, type, raw };
  },
};
