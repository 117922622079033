// @flow

export const MYBETS_HANDLE_PENDING_BETS: 'mybets:pending-handle' =
  'mybets:pending-handle';
export const MYBETS_CASHOUT_IS_LOADING: 'mybets:cashout-is-loading' =
  'mybets:cashout-is-loading';
export const MYBETS_CASHOUT_IS_ERROR: 'mybets:cashout-is-error' =
  'mybets:cashout-is-error';
export const MYBETS_CASHOUT_SUCCESS: 'mybets:cashout-success' =
  'mybets:cashout-success';
