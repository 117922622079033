// @flow
import type { ReduxAction, LocationType } from '../../types';
import { PLAYING_CASINO, NOT_PLAYING_CASINO } from '../actions/action-types';

const defaultState = {
  isPlayingCasino: false,
};

const reducer = (
  state: LocationType = defaultState,
  action: ReduxAction
): LocationType => {
  switch (action.type) {
    case PLAYING_CASINO: {
      return {
        isPlayingCasino: true,
      };
    }
    case NOT_PLAYING_CASINO: {
      return defaultState;
    }
    default:
      return state;
  }
};

export default reducer;
