// @flow
import { type JSSThemeDefaultRegistrationType } from '../JSSThemeDefaultRegistration';

export const JSSThemeRegistration60700: JSSThemeDefaultRegistrationType = themeVars => {
  const {
    colors: {
      colorTypes: { background2 },
    },
  } = themeVars;
  return {
    RegistrationPage: {
      registrationPageRoot: {
        background: `${background2} !important`,
      },
    },
    RegistrationFieldsSection: {
      registrationSectionTitle: {
        color: 'black !important',
      },
      registrationFieldsSectionRoot: {
        background: `${background2} !important`,
      },
    },
    RecaptchaView: {
      recaptchaViewRoot: {
        background: `${background2} !important`,
      },
    },
  };
};
