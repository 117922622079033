// @flow
import { type JSSThemeVariablesType, type $DeepShape } from './JSSThemeDefault';
import { applyOnlyMobile, applyOnlyDesktop } from './jss-helpers';

export const JSSThemeDefaultCasino = (themeVars: JSSThemeVariablesType) => {
  const {
    margins,
    fontSizes,
    borderRadius,
    colors: {
      colorTypes: { white, text2, text1, background1 },
      colorCombinations: { text2Primary, whiteText1 },
    },
    buttons,
  } = themeVars;
  return {
    CasinoGameCategories: {
      stickyWrap: {
        top: 0,
        zIndex: 3,
        position: 'sticky',
      },
      gameCategories: {
        display: 'flex',
        listStyle: 'none',
        background: text2,
        height: 50,
        position: 'relative',
      },
      gameCategoriesScroll: {
        overflowX: 'scroll',
        overflowY: 'hidden',
        '-webkit-overflow-scrolling': 'touch',
      },
      menuItem: {
        padding: [0, margins.xm],
        textDecoration: 'none',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'inherit',
        borderRight: `1px solid ${text1}`,
        transition: 'all 0.1s ease',
        alignItems: 'center',
        flexDirection: 'row',
        color: white,
        fontSize: fontSizes.md,
        lineHeight: 1,
        cursor: 'pointer',
        fontWeight: 'normal',
        '&.active, &:hover': {
          ...text2Primary,
        },
        ...applyOnlyDesktop({
          '&:hover': {
            ...text2Primary,
          },
        }),
        ...applyOnlyMobile({
          '&:hover, &:active, &:focus': {
            color: white,
          },
        }),
        '&::before': {
          display: 'block',
          width: 'auto',
          height: 'auto',
          marginRight: 'inherit',

          content: '',
          backgroundSize: 'none',
          backgroundRepeat: 'auto',
          backgroundPosition: 'center',
          ...applyOnlyMobile({
            marginRight: 0,
          }),
        },
        '&#providers': {
          '&::before': {},
        },
        '&#favorites': {
          '&::before': {},
        },
        '&#all-games': {
          '&::before': {},
        },
        '&#jackpot_games': {
          '&::before': {},
        },
        '&#table_games': {
          '&::before': {},
        },
        '&#video_slots': {
          '&::before': {},
        },
        '&#poker': {
          '&::before': {},
        },
        '&#classic_slots': {
          '&::before': {},
        },
        '&#video_poker': {
          '&::before': {},
        },
        '&#other_games': {
          '&::before': {},
        },
      },
      menuTitle: {},
      promosCasino: {
        display: 'none',
        padding: [0, margins.xm],
        textDecoration: 'none',
        textAlign: 'center',
        justifyContent: 'inherit',
        borderRight: `1px solid ${text1}`,
        transition: 'all 0.1s ease',
        alignItems: 'center',
        flexDirection: 'row',
        color: white,
        fontSize: fontSizes.md,
        lineHeight: 1,
        cursor: 'pointer',
        fontWeight: 'normal',
        '&.active, &:hover': {
          ...text2Primary,
        },
        ...applyOnlyMobile({
          '&:hover, &:active, &:focus': {
            color: white,
          },
        }),
        '&::before': {
          display: 'block',
          width: 'auto',
          height: 'auto',
          marginRight: 'inherit',

          content: '',
          backgroundSize: 'none',
          backgroundRepeat: 'auto',
          backgroundPosition: 'center',
          ...applyOnlyMobile({
            marginRight: 0,
          }),
        },
        '&#providers': {
          '&::before': {},
        },
        '&#favorites': {
          '&::before': {},
        },
        '&#all-games': {
          '&::before': {},
        },
        '&#jackpot_games': {
          '&::before': {},
        },
        '&#table_games': {
          '&::before': {},
        },
        '&#video_slots': {
          '&::before': {},
        },
        '&#poker': {
          '&::before': {},
        },
        '&#classic_slots': {
          '&::before': {},
        },
        '&#video_poker': {
          '&::before': {},
        },
        '&#other_games': {
          '&::before': {},
        },
      },
      iconChevron: {
        fontSize: 12,
        transition: 'transform 0.2s ease',
        '&.active': {
          transform: 'rotateZ(90deg) translateX(2px) translateY(-2px)',
          transition: 'transform 0.2s ease',
        },
      },
    },
    CasinoContainer: {
      casinoRoot: {
        '& ~ article': {
          maxWidth: 1372,
          '@media screen and (max-width: 1070px)': {
            width: '100%',
          },
        },
      },
      providersFiltersWrap: {
        padding: [margins.xs, margins.md],
        overflowX: 'scroll',
        position: 'sticky',
        display: 'flex',
        flexWrap: 'nowrap',
        zIndex: 6,
        top: 50,
        background: background1,
        '& input': {
          display: 'none',
        },
      },
      providersFilters: {
        opacity: 0.8,
        marginRight: margins.md,
        padding: [margins.xs, margins.sm],
        border: '1px solid',
        borderRadius: borderRadius.xxl,
        transition: 'all 0.2s ease',
        fontSize: fontSizes.sm,
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        color: 'inherit',
        '&.active': {
          ...whiteText1,
          opacity: 1,
          transition: 'all 0.2s ease',
        },
        ...applyOnlyDesktop({
          '&:hover': {
            ...whiteText1,
            opacity: 1,
            transition: 'all 0.2s ease',
          },
        }),
      },
      searchWrap: {
        background: white,
        position: 'sticky',
        top: 0,
        height: 42,
      },
      casinoTitleWrap: {},
    },
    SearchBox: {
      inputWrap: {
        color: white,
        display: 'flex',
        position: 'static',
      },
      inputSearch: {
        background: 'inherit',
        height: '100%',
        padding: [0, margins.xs],
        border: 'none',
        color: white,
        width: 200,
        '&::placeholder': {
          color: white,
        },
      },
      iconSearch: {
        // position: 'absolute',
        right: 0,
        top: 0,
        padding: margins.md,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      searchResult: {
        position: 'absolute',
        top: 50,
        left: 0,
        width: '100vw',
        height: '100vh',
        background: 'rgba(0,0,0,0.9)',
        overflowY: 'scroll',
      },
      searchResultHeading: {
        textIndent: margins.md,
      },
      beginTyping: {},
    },
    CasinoGameLink: {
      rootReal: {
        ...buttons.primary,
        textAlign: 'center',
        fontSize: fontSizes.md,
        padding: [margins.xs, margins.md],
        borderRadius: borderRadius.md,
      },
      rootFun: {
        ...buttons.secondary,
        textAlign: 'center',
        padding: [margins.xs, margins.md],
        borderRadius: borderRadius.md,
      },
    },
  };
};

export type JSSThemeDefaultCasinoType = (JSSThemeVariablesType) => $DeepShape<
  $Call<typeof JSSThemeDefaultCasino, JSSThemeVariablesType>
>;
