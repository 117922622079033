// import { type JSSThemeDefaultFooterType } from '../JSSThemeDefaultFooter';
// @flow
import { type JSSThemeDefaultFooterAllType } from '../JSSThemeDefaultFooterAll';

export const JSSThemeFooterAll60700: JSSThemeDefaultFooterAllType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { white, black },
    },
  } = themeVars;
  return {
    footerWrap: {
      background: black,
      color: white,
    },
    licenseWrap: {
      display: 'flex',
      alignItems: 'center',
    },
    socialHide: {
      display: 'block',
    },
    socialCont: {
      margin: 0,
    },
    socialWrap: {
      display: 'none',
    },
    newStyle: {
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: 20,
      '@media screen and (max-width: 1300px)': {
        flexWrap: 'wrap',
      },
    },
    logosContainer: {
      display: 'flex',
      alignItems: 'center',
      margin: '10px 20px 10px 0',
    },
    legal: {
      borderTop: '1px solid rgba(255, 255, 255, 0.3)',
    },
    headerMenu: {
      fontWeight: 'bold',
      '@media screen and (max-width: 1370px)': {
        fontWeight: 'bold',
      },
    },
  };
};
