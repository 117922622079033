// @flow
import { mergeDeepRight } from 'ramda';
import { JSSThemeDefaultBetslip } from './JSSThemeDefaultBetslip';
import { jssHelpers, applyOnlyDesktop, getButtonColors } from './jss-helpers';
import { JSSThemeDefaultHeaderMenu } from './JSSThemeDefaultHeaderMenu';
import { JSSThemeDefaultMyBets } from './JSSThemeDefaultMyBets';
import { JSSThemeDefaultSportsMenu } from './JSSThemeDefaultSportsMenu';
import { JSSThemeDefaultBannerItems } from './JSSThemeDefaultBannerItems';
import { JSSThemeDefaultForm } from './JSSThemeDefaultForm';
import { JSSThemeDefaultTooltip } from './JSSThemeDefaultTooltip';
import { JSSThemeDefaultBonuses } from './JSSThemeDefaultBonuses';
import { JSSThemeDefaultPromotions } from './JSSThemeDefaultPromotions';
import { JSSThemeDefaultSitemap } from './JSSThemeDefaultSitemap';
import { JSSThemeDefaultHeader } from './JSSThemeDefaultHeader';
import { JSSThemeDefaultHeaderMobile } from './JSSThemeDefaultHeaderMobile';
import { JSSThemeDefaultAuthForm } from './JSSThemeDefaultAuthForm';
import { JSSThemeDefaultLanguageSwitcher } from './JSSThemeDefaultLanguageSwitcher';
import { JSSThemeDefaultDropdownMenu } from './JSSThemeDefaultDropdownMenu';
import { JSSThemeDefaultSearch } from './JSSThemeDefaultSearch';
import { JSSThemeDefaultEventSlider } from './JSSThemeDefaultEventSlider';
import { JSSThemeDefaultOutcomes } from './JSSThemeDefaultOutcomes';
import { JSSThemeDefaultCoupons } from './JSSThemeDefaultCoupons';
import { JSSThemeDefaultAccountSection } from './JSSThemeDefaultAccountSection';
import { JSSThemeDefaultBalance } from './JSSThemeDefaultBalance';
import { JSSThemeDefaultPopularLeagues } from './JSSThemeDefaultPopularLeagues';
import { JSSThemeDefaultResets } from './JSSThemeDefaultResets';
import { JSSThemeDefaultRegistration } from './JSSThemeDefaultRegistration';

import { JSSThemeDefaultCasino } from './JSSThemeDefaultCasino';
import { JSSThemeDefaultVirtuals } from './JSSThemeDefaultVirtuals';
import { JSSThemeDefaultLayout } from './JSSThemeDefaultLayout';
import { JSSThemeDefaultWidgets } from './JSSThemeDefaultWidgets';
import { JSSThemeDefaultLive } from './JSSThemeDefaultLive';
import { JSSThemeDefaultFooter } from './JSSThemeDefaultFooter';
import { JSSThemeDefaultFooterAll } from './JSSThemeDefaultFooterAll';
import { JSSThemeDefaultFooterAllMobile } from './JSSThemeDefaultFooterAllMobile';
import { JSSThemeDefaultIPools } from './JSSThemeDefaultIPools';
import { JSSThemeDefaultCookies } from './JSSThemeDefaultCookies';
import { JSSThemeDefaultArticleBody } from './JSSThemeDefaultArticleBody';
import { JSSThemeDefaultBetgenius } from './JSSThemeDefaultBetgenius';
import { JSSThemeDefaultAffiliates } from './JSSThemeDefaultAffiliates';
import { JSSThemeDefaultXtremepush } from './JSSThemeDefaultXtremepush';
import { JSSThemeDefaultOryx } from './JSSThemeDefaultOryx';
import { JSSThemeDefaultIPoolsOld } from './JSSThemeDefaultIPoolsOld';
import { JSSThemeDefaultDevTools } from './JSSThemeDefaultDevTools';

// helper to convert scss vars
// var convertSCSSVarsToObject = str =>
//   str
//     .split('\n')
//     .map(e => e.split(/\:\s+/))
//     .map(([key, value]) => ({
//       [key.replace(/-([a-z])/g, g => g[1].toUpperCase())]: value.replace(
//         /( !default)?;$/,
//         ''
//       ).replace(/^\$/, ''),
//     }))
//     .reduce((obj, next) => Object.assign(obj, next), {});

// var convertSCSSVarsToJSON = str =>
//   JSON.stringify(convertSCSSVarsToObject(str), null, 2);

export type $DeepShape<O: Object> = Object &
  $Shape<$ObjMap<O, (<V: Object>(V) => $DeepShape<V>) & (<V>(V) => V)>>;

export const colorTypes = {
  white: '#fff',
  black: '#000000',
  primary: '#ff6427', //brightOrange
  text1: '#494949', //grey700
  text2: '#58584f', //fuscousGrey
  text3: '#1a1a1a', //grey900
  text4: '', //available for a new color
  text5: '', //available for a new color
  background1: '#494949', //grey700
  background2: '#958b85', //colorAlex2
  background3: '#999',
  background4: '#AB1B0F',
  background5: '#AB1B0F',
  background6: '#bab2ae',
  background7: '', //available for a new color

  shadow: 'rgba(0, 0, 0, 0.4)', //black whith opacity for text shadow
  accent: 'seagreen',
  success: '#21a22d', //green
  error: '#b33322', //red
  error2: '#b33322', //red
  error3: '#e24848', //red
  warning: '#ffa500', //orange
  inform: '#ff7945', //lightOrange
  neutral1: '#eee', //grey200
  neutral2: '#e7e7e7', //grey300
  neutral3: '', //available for a new color
  neutral4: '', //available for a new color
  border1: '#333', //grey800
  border2: '#e7e7e7', //grey300
};

export type ColorTypes = typeof colorTypes;

const pick = (v) => v;

const getCombinations = (colors: typeof colorTypes) => ({
  blackWhite: {
    color: colors.black,
    background: colors.white,
  },
  blackPrimary: {
    color: colors.black,
    background: colors.primary,
  },
  whiteBg1: {
    color: colors.white,
    background: colors.background1,
  },
  whiteBg2: {
    color: colors.white,
    background: colors.background2,
  },
  whiteBg3: {
    color: colors.white,
    background: colors.background3,
  },
  whiteBg4: {
    color: colors.white,
    background: colors.background4,
  },
  whitePrimary: {
    color: colors.white,
    background: colors.primary,
  },
  whiteText3: {
    color: colors.white,
    background: colors.text3,
  },
  whiteText1: {
    color: colors.white,
    background: colors.text1,
  },
  whiteNeutral3: {
    color: colors.white,
    background: colors.neutral3,
  },
  primaryWhite: {
    color: colors.primary,
    background: colors.white,
  },
  text1Primary: {
    color: colors.text1,
    background: colors.primary,
  },
  text1Bg1: {
    color: colors.text1,
    background: colors.background1,
  },
  text1Bg2: {
    color: colors.text1,
    background: colors.background2,
  },
  text1Border1: {
    color: colors.text1,
    background: colors.border1,
  },
  text1Bg4: {
    color: colors.text1,
    background: colors.background4,
  },
  text1Bg3: {
    color: colors.text1,
    background: colors.background3,
  },
  text2Primary: {
    color: colors.text2,
    background: colors.primary,
  },
  text2Accent: {
    color: colors.text2,
    background: colors.accent,
  },
  text2Bg2: {
    color: colors.text2,
    background: colors.background2,
  },
  text2Bg1: {
    color: colors.text2,
    background: colors.background1,
  },
  text1Neutral1: {
    color: colors.text1,
    background: colors.neutral1,
  },
  text1Neutral2: {
    color: colors.text1,
    background: colors.neutral2,
  },
  text1White: {
    color: colors.text1,
    background: colors.white,
  },
  blackNeutral1: {
    color: colors.black,
    background: colors.neutral1,
  },
  text2Neutral1: {
    color: colors.text2,
    background: colors.neutral1,
  },
  text2Neutral2: {
    color: colors.text2,
    background: colors.neutral2,
  },
  whiteNeutral2: {
    color: colors.white,
    background: colors.neutral2,
  },
  whiteNeutral1: {
    color: colors.white,
    background: colors.neutral1,
  },
  text3Neutral1: {
    color: colors.text3,
    background: colors.neutral1,
  },
  text3Neutral2: {
    color: colors.text3,
    background: colors.neutral2,
  },
  whiteSuccess: {
    color: colors.white,
    background: colors.success,
  },
  whiteAccent: {
    color: colors.white,
    background: colors.accent,
  },
  neutral1Neutral2: {
    color: colors.neutral1,
    background: colors.neutral2,
  },
  whiteWarning: {
    color: colors.white,
    background: colors.warning,
  },
  whiteInform: {
    color: colors.white,
    background: colors.inform,
  },
  whiteError: {
    color: colors.white,
    background: colors.error,
  },
  bg2Neutral1: {
    color: colors.background2,
    background: colors.neutral1,
  },
  errorWhite: {
    color: colors.error,
    background: colors.white,
  },
  whiteBorder1: {
    color: colors.white,
    background: colors.border1,
  },
  neutral1Bg1: {
    color: colors.neutral1,
    background: colors.background1,
  },
  neutral1Bg2: {
    color: colors.neutral1,
    background: colors.background2,
  },
  warningWhite: {
    color: colors.warning,
    background: colors.white,
  },
  primaryBg2: {
    color: colors.primary,
    background: colors.background2,
  },
  text3Neutral3: {
    color: colors.text3,
    background: colors.neutral3,
  },
  primaryBg1: {
    color: colors.primary,
    background: colors.background1,
  },
  text3White: {
    color: colors.text3,
    background: colors.white,
  },
  text3Border2: {
    color: colors.text3,
    background: colors.border2,
  },
  text3Background4: {
    color: colors.text3,
    background: colors.background4,
  },
  primaryBackground3: {
    color: colors.primary,
    background: colors.background3,
  },
  text1Border2: {
    color: colors.text1,
    background: colors.border2,
  },
  text2Border1: {
    color: colors.text2,
    background: colors.border1,
  },
  text2White: {
    color: colors.text2,
    background: colors.white,
  },
  text4Bg3: {
    color: colors.text4,
    background: colors.background3,
  },
  text2Bg3: {
    color: colors.text2,
    background: colors.background3,
  },
  text3Bg4: {
    color: colors.text3,
    background: colors.background4,
  },
  bg4Border2: {
    color: colors.background4,
    background: colors.border2,
  },
  whiteBorder2: {
    color: colors.white,
    background: colors.border2,
  },
  bg1Primary: {
    background: colors.primary,
    color: colors.background1,
  },
  whiteText2: {
    color: colors.white,
    background: colors.text2,
  },
  text5White: {
    background: colors.white,
    color: colors.text5,
  },
  text3Border1: {
    color: colors.text3,
    background: colors.border1,
  },
  bg1White: {
    color: colors.background1,
    background: colors.white,
  },
  bg1Bg3: {
    color: colors.background1,
    background: colors.background3,
  },
  bg1Bg4: {
    color: colors.background1,
    background: colors.background4,
  },
  bg4Bg1: {
    color: colors.background4,
    background: colors.background1,
  },
  text3Warning: {
    color: colors.text3,
    background: colors.warning,
  },
  text3Primary: {
    color: colors.text3,
    background: colors.primary,
  },
  bg2Primary: {
    color: colors.background2,
    background: colors.primary,
  },
  bg1Neutral1: {
    color: colors.background1,
    background: colors.neutral1,
  },
  neutral3Text2: {
    color: colors.neutral3,
    background: colors.text2,
  },
  Bg2text2: {
    color: colors.background2,
    background: colors.text2,
  },
  text2Error: {
    color: colors.text2,
    background: colors.error,
  },
  text2Error2: {
    color: colors.text2,
    background: colors.error2,
  },
  neutral3White: {
    color: colors.neutral3,
    background: colors.white,
  },
  whiteText4: {
    color: colors.white,
    background: colors.text4,
  },
  text4Bg4: {
    color: colors.text4,
    background: colors.background4,
  },
  text4Bg2: {
    color: colors.text4,
    background: colors.background2,
  },
  blackBg3: {
    color: colors.black,
    background: colors.background3,
  },
  text2Text3: {
    color: colors.text2,
    background: colors.text3,
  },
  blackBg2: {
    color: colors.black,
    background: colors.background2,
  },
  whiteBg5: {
    color: colors.white,
    background: colors.background5,
  },
  accentBg2: {
    color: colors.accent,
    background: colors.background2,
  },
});

export type CombinationsType = $Call<typeof getCombinations, typeof colorTypes>;

export const getColorCombinations = <
  P: (CombinationsType) => $Shape<CombinationsType>
>(
  colors: typeof colorTypes,
  pickGroups?: P
): $Call<P, CombinationsType> => {
  const fn = pickGroups || pick;
  return fn(getCombinations(colors));
};

export const colorCombinations = getColorCombinations(colorTypes, pick);

const colors = {
  colorTypes,
  colorCombinations,
};

const defaultMargins = {
  xs: 5,
  sm: 8,
  md: 10,
  xm: 15,
  lg: 20,
  xl: 40,
};

const defaultFontSizes = {
  xxs: 8,
  xs: 10,
  xsm: 11,
  sm: 12,
  md: 14,
  lg: 16,
  xl: 18,
  xxl: 20,
  xxxl: 30,
  xxxxl: 45,
};

const defaultBorderRadius = {
  sm: 2,
  md: 3,
  lg: 5,
  xl: 10,
  xxl: 15,
};

export const JSSThemeVariables = {
  colors,
  margins: defaultMargins,
  fontSizes: defaultFontSizes,
  borderRadius: defaultBorderRadius,
  fonts: {
    default: {
      name: 'Inter',
      fontFamily: 'Inter, Helvetica Neue, Helvetica, Arial, sans-serif',
      config: [
        {
          path: 'inter/Inter-Regular',
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
        {
          path: 'inter/Inter-Bold',
          fontWeight: 'bold',
          fontStyle: 'normal',
        },
        {
          path: 'inter/Inter-ExtraBold',
          fontWeight: '800',
          fontStyle: 'normal',
        },
      ],
    },
    tiny: {
      name: 'osg-roboto-condensed',
      fontFamily:
        'osg-roboto-condensed, Helvetica Neue, Helvetica, Arial, sans-serif',
      config: [
        {
          path: 'roboto-cond/robotocondensed-regular-webfont',
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
        {
          path: 'roboto-cond/robotocondensed-bold-webfont',
          fontWeight: 'bold',
          fontStyle: 'normal',
        },
      ],
    },
    agora: {
      name: 'osg-agora',
      fontFamily: 'osg-agora, Helvetica Neue, Helvetica, Arial, sans-serif',
      config: [
        {
          path: 'Agora/AgoraSansProRegular',
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
        {
          path: 'Agora/AgoraSansProMedium',
          fontWeight: 'bold',
          fontStyle: 'normal',
        },
      ],
    },
  },
};

const getJSSThemeFromThemeVars = (themeVars: JSSThemeVariablesType) => {
  const buttonSidePadding = themeVars.margins.md * 1.5;
  const {
    colors: {
      colorCombinations: {
        whitePrimary,
        primaryWhite,
        blackNeutral1,
        text1Bg2,
        whiteAccent,
        text2Bg2,
      },
      colorTypes: {
        white,
        border1,
        text2,
        background2,
        inform,
        text1,
        accent,
        neutral2,
        shadow,
        primary,
        background3,
        error,
        success,
      },
    },
    margins,
    fontSizes,
    borderRadius,
    fonts,
  } = themeVars;

  const buttonLinkStyles = {
    fontSize: fontSizes.sm,
    padding: 0,
    textDecoration: 'underline',
    background: 'none',
  };

  return {
    eventSlider: JSSThemeDefaultEventSlider(themeVars),
    outcomes: JSSThemeDefaultOutcomes(themeVars),
    popularLeagues: JSSThemeDefaultPopularLeagues(themeVars),
    Heading: {
      common: {
        margin: 0,
        padding: [margins.xs, margins.xm, '0'],
        fontWeight: '800',
        fontSize: '26px',
        fontFamily: fonts.default,
        '&.headingMobile': {
          background: '',
        },
      },
      light: {
        fontWeight: 'lighter',
      },
      bold: {
        fontWeight: 'bold',
      },
      center: {
        textAlign: 'center',
      },
      right: {
        textAlign: 'right',
      },
      none: {
        paddingLeft: 0,
        paddingRight: 0,
        marginLeft: 0,
        marginRight: 0,
      },
      xm: {
        paddingLeft: margins.xm,
        paddingRight: margins.xm,
        marginLeft: margins.xm,
        marginRight: margins.xm,
      },
    },
    Button: {
      disable: {
        pointerEvents: 'none',
      },
      root: {
        borderRadius: borderRadius.md,
        fontFamily: fonts.default.fontFamily,
        display: 'inline-block',
        transition: '0.3s ease-in',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        fontStyle: 'normal',
        border: 'none',
        fontSize: fontSizes.md,
        padding: [margins.xs, buttonSidePadding],
        lineHeight: 'normal',
        cursor: 'pointer',
        textDecoration: 'none',
        outline: 0,
        width: 'auto',
      },
      default: getButtonColors(text1Bg2),
      primary: getButtonColors(whitePrimary),
      secondary: getButtonColors(text2Bg2),
      accent: getButtonColors(whiteAccent),
      block: {
        display: 'block',
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      large: {
        fontSize: fontSizes.xxxl,
        padding: [0, buttonSidePadding],
        lineHeight: 'normal',
      },
      small: {
        fontSize: fontSizes.sm,
        padding: [0, buttonSidePadding],
        lineHeight: 'normal',
      },
      linkDefault: {
        ...buttonLinkStyles,
        color: text1,
        ...applyOnlyDesktop({
          '&:hover': {
            color: text2,
          },
        }),
      },
      linkPrimary: {
        ...buttonLinkStyles,
        color: text2,
        ...applyOnlyDesktop({
          '&:hover': {
            color: text2,
          },
        }),
      },
      linkSecondary: {
        ...buttonLinkStyles,
        color: text2,
        ...applyOnlyDesktop({
          '&:hover': {
            color: text2,
          },
        }),
      },
      linkAccent: {
        ...buttonLinkStyles,
        color: inform,
        fontWeight: '',
        ...applyOnlyDesktop({
          '&:hover': {
            color: inform,
          },
        }),
      },
    },
    CustomIcon: {
      shadow: {
        filter: `drop-shadow( 0px 1px 3px ${shadow})`,
      },
    },
    IconCircle: {
      root: {
        background: background3,
        width: 10,
        height: 10,
        borderRadius: borderRadius.lg,
        alignSelf: 'center',
        marginRight: margins.xs,
      },
    },
    betslip: JSSThemeDefaultBetslip(themeVars),
    header: JSSThemeDefaultHeader(themeVars),
    cookies: JSSThemeDefaultCookies(themeVars),
    article: JSSThemeDefaultArticleBody(themeVars),
    headerMenu: JSSThemeDefaultHeaderMenu(themeVars),
    mybets: JSSThemeDefaultMyBets(themeVars),
    virtuals: JSSThemeDefaultVirtuals(themeVars),
    live: JSSThemeDefaultLive(themeVars),
    ipools: JSSThemeDefaultIPools(themeVars),
    ipoolsOld: JSSThemeDefaultIPoolsOld(themeVars),
    oryx: JSSThemeDefaultOryx(themeVars),
    LiveCalendar: {
      common: {
        margin: [margins.md * 2, 0],
      },
      h2: {
        margin: 0,
        fontWeight: 'normal',
        fontSize: fontSizes.md, // $live-banner-header-font-size
        height: '35px', // $live-banner-header-height
        lineHeight: '35px', // $live-banner-header-height
        padding: [0, margins.md], // $default-margin
        ...whitePrimary,
        ...jssHelpers.ellipsis,
      },
      dateTitle: {
        margin: 0,
        padding: [margins.xs, margins.md], // [$small-margin $default-margin]
        fontSize: fontSizes.sm, // $live-menu-event-font-size
        color: white, // $live-schedule-date-color
        background: border1, // $live-schedule-date-background-color
      },
      dateEvents: {
        margin: 0,
      },
      dateEvent: {
        margin: 0,
        fontSize: fontSizes.sm,
        padding: [margins.xs, margins.md],
        borderBottom: `1px solid ${border1}`,
        color: white, //$live-schedule-color
        background: text2, // $live-schedule-background-color
        ...jssHelpers.ellipsis,
        '&:hover': {
          cursor: 'pointer',
          color: white, // $live-menu-event-color-hover;
          background: background2, // $live-menu-event-background-color-hover
        },
      },
      link: {
        color: white,
      },
      dateEventNoMarkets: {
        '&:hover': {
          cursor: 'default',
        },
      },
      dateEventIcon: {},
      icon: {
        fontSize: fontSizes.lg,
        marginRight: margins.md,
      },
    },
    NoBetsMessage: {
      root: {
        padding: margins.md,
        fontWeight: 'bold',
        fontSize: fontSizes.md,
        lineHeight: 1.3,
        boxShadow: 'none',
        ...primaryWhite,
      },
    },
    BetslipMyBetsPanelTitle: {
      heading: {
        padding: [0, margins.md],
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...blackNeutral1,
      },
      title: {
        flex: 1,
        width: 'auto',
        textAlign: 'left',
        fontWeight: 'bold',
        lineHeight: `${fontSizes.md * 2}px`,
        fontSize: fontSizes.md,
        ...jssHelpers.ellipsis,
        overflow: 'visible',
      },
    },
    BetslipMyBetsStakePotentialTotalUI: {
      stakePotential: {
        maxWidth: '50%',
        color: text1,
        fontSize: 13,
      },
      potential: {
        maxWidth: '100%',
      },
      stakeTitle: {
        marginTop: margins.xs,
        whiteSpace: 'nowrap',
        ...jssHelpers.ellipsis,
      },
      stakePotentialWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        overflow: 'hidden',
        marginTop: margins.xs,
        borderTop: `1px solid ${white}`,
      },
      money: {
        fontWeight: 'bold',
        marginTop: margins.xs,
      },
    },
    BetslipWithBonusMyBetsStakePotentialTotalUI: {
      stakeInfo: {
        background: 'inherit',
      },
      info: {
        display: 'flex',
        color: text1,
      },
      summaryTitle: {
        maxWidth: '50%',
        flex: 1,
      },
      summaryValue: {
        maxWidth: '50%',
        flex: 1,
        position: 'absolute',
        right: 0,
        marginRight: margins.xs,
      },
      potentialReturnValue: {
        maxWidth: '50%',
        flex: 1,
        position: 'absolute',
        right: 0,
        marginRight: margins.xs,
        fontSize: 20,
        fontWeight: 'bold',
      },
    },
    BetslipMyBetsPanelUI: {
      singleSelectionWrapper: {
        borderTop: `1px solid ${white}`,
        marginTop: margins.xs,
        paddingTop: margins.xs,
      },
      panel: {
        margin: margins.md,
        padding: margins.md,
        background: neutral2,
        borderRadius: borderRadius.md,
      },
    },
    sportsMenu: JSSThemeDefaultSportsMenu(themeVars),
    banners: JSSThemeDefaultBannerItems(themeVars),
    form: JSSThemeDefaultForm(themeVars),
    tooltip: JSSThemeDefaultTooltip(themeVars),
    bonuses: JSSThemeDefaultBonuses(themeVars),
    headerMobile: JSSThemeDefaultHeaderMobile(themeVars),
    authForm: JSSThemeDefaultAuthForm(themeVars),
    languageSwitcher: JSSThemeDefaultLanguageSwitcher(themeVars),
    dropdownMenu: JSSThemeDefaultDropdownMenu(themeVars),
    search: JSSThemeDefaultSearch(themeVars),
    coupons: JSSThemeDefaultCoupons(themeVars),
    accountSection: JSSThemeDefaultAccountSection(themeVars),
    balance: JSSThemeDefaultBalance(themeVars),
    casino: JSSThemeDefaultCasino(themeVars),
    layout: JSSThemeDefaultLayout(themeVars),
    widgets: JSSThemeDefaultWidgets(themeVars),
    betgenius: JSSThemeDefaultBetgenius(themeVars),
    affiliates: JSSThemeDefaultAffiliates(themeVars),
    NoEventsMessage: {
      root: {
        padding: margins.xm,
      },
    },
    promotions: JSSThemeDefaultPromotions(themeVars),
    sitemap: JSSThemeDefaultSitemap(themeVars),
    xtremepush: JSSThemeDefaultXtremepush(themeVars),
    MiniCasinoGame: {
      root: {
        position: 'relative',
        height: 0,
        overflow: 'none',
        paddingBottom: `${(4 / 3) * 100}%`,
        '& > iframe': {
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
        },
      },
    },
    DropDownUI: {
      dropDownWrapper: {
        position: 'relative',
      },
      title: {
        display: 'flex',
        alignItems: 'center',
        transition: 'opacity 0.4s',
        cursor: 'pointer',
        '&:hover': {
          opacity: 0.7,
          transition: 'opacity 0.4s',
        },
      },
      content: {
        position: 'absolute',
        right: 0,
        opacity: 0,
        transform: 'scale(0)',
        transition: 'opacity 0.3s ease',
        '&.opened': {
          opacity: 1,
          transform: 'scale(1)',
          zIndex: 15,
          transition: 'opacity 0.3s ease',
        },
        '@media (pointer: coarse)': {
          bottom: 26,
          right: 'unset',
        },
      },
      carretDownIcon: {
        fontSize: fontSizes.xs,
        marginLeft: margins.xs,
        transition: 'transform 0.3s ease',
        '&.opened': {
          transform: 'rotateZ(90deg)',
          transition: 'transform 0.3s ease',
        },
      },
    },
    SessionTimer: {
      TimerSelectorWrap: {
        color: 'inherit',
        display: 'flex',
        fontSize: fontSizes.sm,
        marginLeft: margins.lg,
      },
      ClockIcon: {
        marginRight: margins.sm,
      },
    },
    MobileSessionTimer: {
      TimerSelectorWrap: {
        color: 'inherit',
      },
      ClockIcon: {
        marginRight: margins.sm,
      },
    },
    PageNotFound: {
      pageNotFoundRoot: {
        height: 'calc(100vh - 75px)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: [margins.md],
        textAlign: 'center',
      },
      title: {
        fontSize: 65,
        margin: 0,
      },
      desciption: {
        fontSize: fontSizes.xxl,
        margin: [10, 0, 60],
      },
      buttonWrap: {
        margin: [margins.xm, 0],
      },
    },
    resets: JSSThemeDefaultResets(themeVars),
    registration: JSSThemeDefaultRegistration(themeVars),
    footer: JSSThemeDefaultFooter(themeVars),
    footerAll: JSSThemeDefaultFooterAll(themeVars),
    footerAllMobile: JSSThemeDefaultFooterAllMobile(themeVars),
    maintenance: {
      container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '80vh',
        color: white,
        background: '#424242',
        textAlign: 'center',
        padding: '0 15px',
      },
      icon: {
        maxWidth: '120px',
        width: '120px',
        height: '120px',
        marginBottom: '32px',
        color: white,
        opacity: '.4',
        overflow: 'visible',
        boxSizing: 'content-box',
        '@media screen and (max-width: 1024px)': {
          width: 80,
          height: 80,
          marginBottom: 15,
        },
      },
      title: {
        fontSize: 28,
        fontWeight: 700,
        marginBottom: 20,
        '@media screen and (max-width: 1024px)': {
          fontSize: 22,
        },
      },
      text: {
        fontSize: 16,
        '@media screen and (max-width: 1024px)': {
          fontSize: 14,
        },
      },
    },
    LiveNow: {
      title: {},
    },
    CookiesBanner: {
      '@keyframes cookies-banner': {
        from: {
          transform: 'translateY(0)',
          opacity: 0,
        },
        to: {
          transform: 'translateY(-120px)',
          opacity: 1,
        },
      },
      '@keyframes cookies-banner-out': {
        from: {
          transform: 'translateY(-120px)',
          opacity: 1,
        },
        to: {
          transform: 'translateY(0)',
          opacity: 0,
        },
      },
      '@keyframes cookies-banner-detail': {
        from: {
          transform: 'translateY(120px)',
          opacity: 0,
        },
        to: {
          transform: 'translateY(0)',
          opacity: 1,
        },
      },
      '@keyframes cookies-banner-detail-out': {
        from: {
          transform: 'translateY(0)',
          opacity: 1,
        },
        to: {
          transform: 'translateY(120px)',
          opacity: 0,
        },
      },
      CookiesBannerMain: {
        position: 'fixed',
        bottom: -120,
        width: 'calc(100% - 40px)',
        minHeight: '80px',
        zIndex: 9999,
        background: white,
        margin: [margins.lg, margins.lg],
        padding: [margins.lg, '17%'],
        borderRadius: 8,
        boxShadow: '0px 5px 20px 3px #00000080',
        display: 'flex',
        flexDirection: 'row',
        gap: '.6rem',
        alignItems: 'center',
        animation: '1s cookies-banner 0s forwards',
        '&.hide': {
          animation: '1s cookies-banner-out 0s forwards',
        },
      },
      CookiesBannerDetail: {
        position: 'fixed',
        bottom: 0,
        width: 'calc(100% - 40px)',
        minHeight: '80px',
        zIndex: 9999,
        background: white,
        margin: [margins.lg, margins.lg],
        padding: [margins.lg, '17%'],
        borderRadius: 8,
        boxShadow: '0px 5px 20px 3px #00000080',
        animation: '1s cookies-banner-detail 0s forwards',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: '#333',
        order: '',
        '&.account-page $HeaderWrap': {
          justifyContent: '',
        },
        '& $CookiesBannerButtonAccept': {
          marginLeft: 0,
        },
        '&.hide': {
          animation: '1s cookies-banner-detail-out 0s forwards',
        },
        '&.account-page': {
          animation: 'none',
          position: 'static',
          margin: 0,
          padding: '1rem',
          width: '100%',
          background: 'none',
          color: text1,
          boxShadow: 'none',
          order: '',
        },
      },
      CookieTab: {
        width: '100%',
        paddingBottom: '',
        gap: '1rem',
        color: 'inherit',
        '& > *': {
          margin: 0,
        },
      },
      CookieCheckbox: {
        margin: '.54rem 0 0',
        '&.account-page': {
          order: '',
        },
      },
      HeaderWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        paddingBottom: '',
        '& > *': {
          margin: 0,
        },
      },
      ButtonShowDescription: {},
      CookieTabWrap: {},
      CookiesBannerHeading: {
        margin: [0, 0, margins.xs],
        fontSize: fontSizes.lg,
        fontWeight: 900,
        color: '#333',
      },
      CookiesBannerText: {
        margin: [0, 0, margins.xs],
        color: '#333',
      },
      CookiesBannerLink: {
        color: primary,
        '&:hover, &:focus': {
          color: primary,
          opacity: 0.8,
        },
      },
      CookiesBannerButtonAccept: {
        marginLeft: 'auto',
        padding: [0, 40],
        background: primary,
        color: '#fff',
        height: 32,
        '&:hover, &:focus': {
          opacity: 0.7,
          background: primary,
          color: '#fff',
        },
      },
      CookiesBannerButtonDecline: {
        marginLeft: 'auto',
        padding: [0, 40],
        background: primary,
        color: '#fff',
        height: 32,
        '&:hover, &:focus': {
          opacity: 0.7,
          background: primary,
          color: '#fff',
        },
      },
    },
    CookiesBannerMobile: {
      '@keyframes cookies-banner-mobile': {
        from: {
          transform: 'translateY(0)',
          opacity: 0,
        },
        to: {
          transform: 'translateY(-250px)',
          opacity: 1,
        },
      },
      '@keyframes cookies-banner-mobile-out': {
        from: {
          transform: 'translateY(-250px)',
          opacity: 1,
        },
        to: {
          transform: 'translateY(0)',
          opacity: 0,
        },
      },
      '@keyframes cookies-banner-detail': {
        from: {
          transform: 'translateY(120px)',
          opacity: 0,
        },
        to: {
          transform: 'translateY(0)',
          opacity: 1,
        },
      },
      '@keyframes cookies-banner-detail-out': {
        from: {
          transform: 'translateY(0)',
          opacity: 1,
        },
        to: {
          transform: 'translateY(120px)',
          opacity: 0,
        },
      },
      CookiesBannerMain: {
        position: 'fixed',
        bottom: -250,
        width: 'calc(100% - 40px)',
        height: 'auto',
        zIndex: 9999,
        background: white,
        margin: margins.lg,
        padding: margins.lg,
        borderRadius: 8,
        boxShadow: '0px 5px 20px 3px #00000080',
        display: 'flex',
        gap: '.6rem',
        flexDirection: 'column',
        transform: 'translateY(250px)',
        animation: '1s cookies-banner-mobile 0s forwards',
        '&.hide': {
          animation: '1s cookies-banner-mobile-out 0s forwards',
        },
      },
      CookiesBannerDetail: {
        position: 'fixed',
        bottom: 0,
        width: 'calc(100% - 40px)',
        height: '90%',
        zIndex: 9999,
        background: white,
        margin: margins.lg,
        padding: '',
        borderRadius: 8,
        boxShadow: '0px 5px 20px 3px #00000080',
        animation: '1s cookies-banner-detail 0s forwards',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        overflow: 'auto',
        color: '#333',
        '& $CookiesBannerButtonAccept': {
          marginLeft: 0,
          width: '',
        },
        '&.account-page $CookiesBannerButtonAccept': {
          marginLeft: '',
          width: '',
        },
        '&.hide': {
          animation: '1s cookies-banner-detail-out 0s forwards',
        },
        '&.account-page': {
          animation: 'none',
          position: 'static',
          margin: 0,
          padding: '1rem',
          width: '100%',
          background: 'none',
          color: text1,
          boxShadow: 'none',
        },
      },
      CookieTab: {
        width: '100%',
        padding: '',
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        gap: '1rem',
        color: 'inherit',
        '& > *': {
          margin: 0,
        },
      },
      CookieTabWrap: {
        width: '100%',
      },
      CookieCheckbox: {
        margin: '.54rem .54rem 0 0',
      },
      HeaderWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        '& > *': {
          margin: 0,
        },
      },
      ButtonShowDescription: {
        width: '100%',
        height: '25px',
        border: 'none',
        backgroundColor: background3,
      },
      CookiesBannerHeading: {
        margin: [0, 0, margins.md],
        fontSize: fontSizes.lg,
        fontWeight: 900,
        color: '#333',
        '&.detail': {
          margin: 0,
        },
      },
      CookiesBannerText: {
        margin: [0, 0, margins.lg],
        color: '#333',
        '&.detail': {
          margin: 0,
          width: '100%',
        },
      },
      CookiesBannerLink: {
        color: primary,
        '&:hover, &:focus': {
          color: primary,
          opacity: 0.8,
        },
      },
      CookiesBannerButtonAccept: {
        background: primary,
        color: '#fff',
        '&:active, &:hover': {
          opacity: 0.7,
          background: primary,
          color: '#fff',
        },
      },
      CookiesBannerButtonDecline: {
        background: primary,
        color: '#fff',
        '&:active, &:hover': {
          opacity: 0.7,
          background: primary,
          color: '#fff',
        },
      },
    },
    Currency: {
      currencyRoot: {},
    },
    BetslipMybetsToggler: {
      root: {},
      badge: {},
      close: {},
    },
    OfflineMessage: {
      popup: {
        zIndex: 99999,
        position: 'fixed',
        top: 15,
        left: 'calc(50% - 135px)',
        width: 270,
        padding: [margins.md, margins.xm],
        background: '#fff',
        borderRadius: borderRadius.xl,
        boxShadow: '0 0 15px #00000045',
        textAlign: 'center',
      },
    },
    Geoblock: {
      logoGeoblock: {
        width: '100%',
        height: '75px',
        background: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      rootGeoblock: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
      },
      textGeoblock: {
        margin: 'auto',
        fontSize: 36,
        fontWeight: 'bold',
        textAlign: 'center',
      },
      imgGeoblock: {
        height: 50,
      },
    },
    OPMResult: {
      OPMResultRoot: {
        color: white,
        background: text2,
        maxWidth: 500,
        padding: margins.lg,
        borderRadius: 10,
        '@media not all and (pointer: coarse)': {
          margin: [margins.lg, margins.xm],
        },
      },
      OPMResultItem: {
        padding: [margins.xs, 0],
      },
      OPMResultTerms: {
        color: primary,
        display: 'block',
        padding: [margins.xs, 0, margins.lg],
        '&:active, &:hover': {
          color: accent,
        },
      },
      OPMResultError: {
        color: white,
        background: error,
        maxWidth: 500,
        padding: margins.lg,
        borderRadius: 10,
      },
      OPMResultSuccess: {
        color: white,
        background: success,
        maxWidth: 500,
        padding: margins.lg,
        borderRadius: 10,
      },
      OPMResultAmount: {
        fontWeight: 'bold',
      },
      OPMbackButton: {
        marginBottom: margins.lg,
      },
    },
    Pagination: {
      root: { fontSize: fontSizes.md, padding: [margins.md, 0] },
      current: {
        fontWeight: 'bold',
      },
      total: {
        opacity: 0.5,
        fontSize: fontSizes.sm,
      },
      arrows: {
        fontSize: fontSizes.xl,
        padding: [0, margins.md],
        cursor: 'pointer',
        '&.disabled': {
          cursor: 'default',
          opacity: 0.5,
        },
      },
    },
    GameLicense: {
      wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '10px 0',
        padding: '10px',
      },
      iframeWrapper: {
        height: '88vh',
      },
      iframeIOS: {
        width: '160vw',
        maxWidth: '160vw',
        '-webkit-transform': 'scale(0.6)',
        '-webkit-transform-origin': '0 0',
      },
    },
    Devtools: JSSThemeDefaultDevTools(),
  };
};

export const getJSSThemePatterns = (themeVars: typeof JSSThemeVariables) => {
  const {
    colors: {
      colorCombinations: { whitePrimary, whiteAccent, text2Bg2 },
      colorTypes: { text1, text2, inform },
    },
    fontSizes,
  } = themeVars;
  const buttonSidePadding = themeVars.margins.md * 1.5;
  const buttonLinkStyles = {
    fontSize: fontSizes.sm,
    padding: 0,
    textDecoration: 'underline',
    background: 'none',
  };
  return {
    buttons: {
      primary: getButtonColors(whitePrimary),
      secondary: getButtonColors(text2Bg2),
      accent: getButtonColors(whiteAccent),
      linkDefault: {
        ...buttonLinkStyles,
        color: text1,
        ...applyOnlyDesktop({
          '&:hover': {
            color: text2,
          },
        }),
      },
      linkPrimary: {
        ...buttonLinkStyles,
        color: text2,
        ...applyOnlyDesktop({
          '&:hover': {
            color: text2,
          },
        }),
      },
      linkSecondary: {
        ...buttonLinkStyles,
        color: text2,
        ...applyOnlyDesktop({
          '&:hover': {
            color: text2,
          },
        }),
      },
      linkAccent: {
        ...buttonLinkStyles,
        color: inform,
        fontWeight: '',
        ...applyOnlyDesktop({
          '&:hover': {
            color: inform,
          },
        }),
      },
      large: {
        fontSize: fontSizes.xxxl,
        padding: [0, buttonSidePadding],
        lineHeight: 'normal',
      },
      small: {
        fontSize: fontSizes.sm,
        padding: [0, buttonSidePadding],
        lineHeight: 'normal',
      },
    },
  };
};
type ThemePatternsType = $Call<
  typeof getJSSThemePatterns,
  typeof JSSThemeVariables
>;

export type JSSThemeVariablesType = {|
  ...$Exact<typeof JSSThemeVariables>,
  ...$Exact<ThemePatternsType>,
|};

type JSSThemeTypeComponents = $Call<
  typeof getJSSThemeFromThemeVars,
  JSSThemeVariablesType
>;

export type JSSThemeType = {|
  components: JSSThemeTypeComponents,
  ...$Exact<JSSThemeVariablesType>,
  ...$Exact<ThemePatternsType>,
|};

export type JSSThemeShapeType =
  (JSSThemeVariablesType) => $DeepShape<JSSThemeTypeComponents>;
export type GetJSSThemePatternsType = (
  typeof JSSThemeVariables
) => $DeepShape<ThemePatternsType>;

export type JSSThemeVariablesShapeType = $DeepShape<JSSThemeVariablesType>;

export const getJSSTheme = <V: Function>(
  varsOverride: JSSThemeVariablesShapeType,
  themeOverride: V,
  patternsOverride: GetJSSThemePatternsType
): JSSThemeType => {
  const themeVars = mergeDeepRight(JSSThemeVariables, varsOverride);
  const patterns = mergeDeepRight(
    getJSSThemePatterns(themeVars),
    patternsOverride(themeVars)
  );
  const vars = { ...themeVars, ...patterns };
  const theme = mergeDeepRight(
    getJSSThemeFromThemeVars(vars),
    themeOverride(vars)
  );
  return { components: theme, ...vars };
};
