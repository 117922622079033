// @flow
import type { $DeepShape, JSSThemeVariablesType } from './JSSThemeDefault';

export const JSSThemeDefaultRegistration = (
  themeVars: JSSThemeVariablesType
) => {
  const {
    buttons: { primary },
  } = themeVars;
  return {
    RegistrationPage: {
      registrationPageRoot: {},
      RegistrationBannerRoot: {
        display: 'flex',
        justifyContent: 'center',
      },
      RegistrationBanner: {
        '@media (pointer: coarse)': {
          width: '100%',
        },
      },
    },
    RegistrationPageButton: {
      root: primary,
    },
    RegistrationFieldsSection: {
      registrationFieldsSectionRoot: {},
      registrationSectionTitle: {},
    },
    RecaptchaView: {
      recaptchaViewRoot: {},
    },
  };
};

export type JSSThemeDefaultRegistrationType = JSSThemeVariablesType => $DeepShape<
  $Call<typeof JSSThemeDefaultRegistration, JSSThemeVariablesType>
>;
