// @flow
import { createTopic } from './topic/create-topic';
import type { Message } from './types';
import TopicStatus from './topic/topic-status';

const types = {
  initial: 'itl',
  fetch: 'fetch',
  delta: 'delta',
  ack: 'ack',
  removal: 'removal',
  unknown: 'unknown',
};

const resolveTopicStatusMessage = (message: Message) => {
  if (message.getStatus() === TopicStatus.removal) return types.removal;
  return types.unknown;
};

const resolveWebClientMessage = (message: Message) => {
  if (message.isInitialTopicLoad()) return types.initial;
  if (message.isFetchMessage()) return types.fetch;
  if (message.isDeltaMessage()) return types.delta;
  if (message.isAckMessage()) return types.ack;
  return types.unknown;
};

function resolve(message: Message) {
  if (!message) return types.unknown;
  if (message.getStatus) return resolveTopicStatusMessage(message);
  return resolveWebClientMessage(message);
}

export const diffusionMessageTypes = {
  resolve,
  ...types,
};

const describe = (message: Message) => {
  const topic = createTopic.from(message.getTopic());
  const type = diffusionMessageTypes.resolve(message);
  const fields = message.getFields ? message.getFields(0) || [] : [];
  return { topic, type, message, fields };
};

export const diffusionMessageDescriptor = { for: describe };
