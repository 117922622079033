// @flow
import { DiffusionClient } from './create-client/diffusion-client';

const { locale, priceLineId, apiDiffusion } = window.__OSG_RUNTIME_CONFIG__;
const [diffusionHost, diffusionPort = 80] = apiDiffusion.split(':');

export const diffusionClient = new DiffusionClient({
  locale,
  priceLineId,
  diffusionHost,
  diffusionPort,
  credentials: null,
});

export const useDiffusionClient = () => diffusionClient;
