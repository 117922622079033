// @flow
import { merge, timer, of, never } from 'rxjs';
import {
  filter,
  switchMap,
  map,
  distinctUntilChanged,
  takeUntil,
  catchError,
  mapTo,
} from 'rxjs/operators';
import { axiosObservable } from './axiosObservable';
import { API_PORTAL } from '../../../common/constants';
import { getIsPlayingCasino } from '../selectors/location';
import { getIsAuthorised, getAccountId, getUserToken } from '../selectors/auth';
import { type ReduxAction, type ReduxState } from '../../types';
import { type ActionsObservable, type StateObservable } from 'redux-observable';
import {
  BALANCE_UPDATE,
  KOLTRON_UNAUTHORIZE_WITH_ERROR,
} from '../actions/action-types';

export const balanceEpic = (
  action$: ActionsObservable<ReduxAction>,
  state$: StateObservable<ReduxState>
) => {
  const auth$ = state$.pipe(map(getIsAuthorised), distinctUntilChanged());

  const authorized$ = auth$.pipe(filter((f) => f));
  const unauthorized$ = auth$.pipe(filter((f) => !f));

  const init$ = getIsAuthorised(state$.value) ? of(null) : never();
  const initAndAuthorise$ = merge(authorized$, init$);

  const {
    casino: requestFrequencyCasino,
    sportsbook: requestFrequencySportsbook,
  } = window.__OSG_RUNTIME_CONFIG__.balanceRequestFrequency;

  return merge(
    initAndAuthorise$.pipe(mapTo(false)),
    action$.ofType(BALANCE_UPDATE).pipe(mapTo(true))
  ).pipe(
    switchMap((shouldWait) => {
      const isPlayingCasino$ = state$.pipe(
        map(getIsPlayingCasino),
        distinctUntilChanged()
      );

      return isPlayingCasino$.pipe(
        switchMap((inCasino) => {
          const timer$ = inCasino
            ? timer(requestFrequencyCasino * 1000)
            : timer(requestFrequencySportsbook * 1000);
          const maybeTimer$ = shouldWait ? timer$ : of(null);

          return maybeTimer$.pipe(
            switchMap(() => {
              const accountId = getAccountId(state$.value);
              const userToken = getUserToken(state$.value);

              if (accountId == null || userToken == null) {
                return never();
              }

              return axiosObservable({
                method: 'get',
                url: `${API_PORTAL}/accounts/${accountId}/wallets`,
              }).pipe(
                map((response) => {
                  const balances = response.data;

                  return {
                    type: BALANCE_UPDATE,
                    payload: {
                      available_casino_balance: balances.casinoPlayableAmount,
                      available_sportsbook_bonus:
                        balances.sportsbookBonusAmount,
                      available_casino_bonus: balances.casinoBonusAmount,
                      available_cash: balances.availableBalance.cash.amount,
                      available_sportsbook_balance:
                        balances.sportsbookPlayableAmount,
                      available_restricted:
                        balances.availableBalance.restricted.amount,
                    },
                  };
                }),
                catchError((error) => {
                  if (error.response && error.response.status === 401) {
                    return of({
                      type: KOLTRON_UNAUTHORIZE_WITH_ERROR,
                    });
                  }
                  return of({
                    type: BALANCE_UPDATE,
                    payload: {
                      error: error.message,
                    },
                  });
                })
              );
            }),
            takeUntil(unauthorized$)
          );
        })
      );
    })
  );
};
