// @flow
import { reduceDiffusionMessages, diffusionMessageTypes } from '../helpers';
import { LIVE_DIFFUSION } from '../../actions/live/actions';
import type { ReduxAction, LiveSport } from '../../../types';

const handleSport = (state, { id, data: { name } }) => {
  // eslint-disable-next-line no-param-reassign
  // $FlowFixMe reduceDiffusionMessages already makes a copy of state
  state[id] = name;
  return state;
};

const handlers = {
  [diffusionMessageTypes.itl]: handleSport,
  [diffusionMessageTypes.delta]: handleSport,
};

const sports = (state: LiveSport = {}, action: ReduxAction) => {
  switch (action.type) {
    case LIVE_DIFFUSION: {
      return reduceDiffusionMessages({
        state,
        topicType: 'sport',
        handlers,
        messages: action.payload,
      });
    }
    default:
      return state;
  }
};

export default sports;
