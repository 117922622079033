// @flow
import type { MyBetsType } from '../../types';
import { prop, groupBy, compose } from 'ramda';

// $FlowFixMe
export const groupByProp = compose(
  groupBy,
  prop
);

const getComarisonValue = val => (typeof val === 'object' ? val.id : val);
export const createSortingComparator = (sorting: Array<string>) => (
  aItem: string | { +id: string },
  bItem: string | { +id: string }
) => {
  const a = getComarisonValue(aItem);
  const b = getComarisonValue(bItem);
  return (
    Number(sorting.indexOf(a) === -1) - Number(sorting.indexOf(b) === -1) ||
    sorting.indexOf(a) - sorting.indexOf(b)
  );
};

export const getNowTime = () => Date.now() / 1000;

export const isCashout = ({ cashout }: MyBetsType) => cashout > 0;

export const sortSportIds = (config: { +sportIds: string }): Array<string> => {
  const sports = __OSG_CONFIG__.coupons.widgetSportsOrder.slice();
  const sort = createSortingComparator(config.sportIds.split(','));
  return sports.sort(sort);
};
