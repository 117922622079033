// @flow
import { type $DeepShape, JSSThemeVariables } from './JSSThemeDefault';

export const JSSThemeDefaultLanguageSwitcher = (
  themeVars: typeof JSSThemeVariables
) => {
  const {
    fontSizes,
    margins,
    colors: {
      colorTypes: { white, background1, primary },
    },
  } = themeVars;
  return {
    LanguageSelectComponentMobile: {
      list: {
        listStyle: 'none',
      },
      item: {
        display: 'flex',
        flexWrap: 'nowrap',
        height: 40,
      },
      itemFlag: {
        width: 20,
        height: 15,
        marginRight: margins.xs,
      },
      select: {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'none',
        '-ms-appearance': 'none',
        textAlignLast: 'center',
        appearance: 'none !important',
        border: 'none',
        backgroundColor: 'transparent',
        color: white,
        fontSize: fontSizes.lg,
      },
      languageChevron: {
        fontSize: fontSizes.sm,
        display: 'inline-block',
      },
      languageDescription: {
        display: 'inline-block',
        fontSize: fontSizes.lg,
        marginRight: margins.xs,
      },
    },
    LanguageSelectComponent: {
      languageSelectWrap: {
        marginLeft: margins.xm,
      },
      languageTitle: {
        fontSize: fontSizes.sm,
      },
      languageSelect: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        marginTop: margins.md,
        listStyle: 'none',
        '&:before': {
          content: '""',
          position: 'absolute',
          width: 10,
          height: 10,
          right: 10,
          transform: 'rotate(-45deg)',
          transformOrigin: 'top left',
          background: background1,
          zIndex: 0,
        },
      },
      languageItem: {
        padding: [margins.md, margins.xm],
        display: 'flex',
        alignItems: 'center',
        background: background1,
        zIndex: 1,
        cursor: 'pointer',
        '&:hover': { background: primary },
      },
      imageFlag: {
        width: 20,
        height: 15,
        marginRight: margins.xs,
      },
    },
  };
};

export type JSSThemeDefaultLanguageSwitcherType = (
  typeof JSSThemeVariables
) => $DeepShape<
  $Call<typeof JSSThemeDefaultLanguageSwitcher, typeof JSSThemeVariables>
>;
