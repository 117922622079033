import React from 'react';
import { type JSSThemeType } from '../origins/JSSThemeDefault';
import { useJSSStyles } from '../jss-hooks';
import { replaceTags } from '../koltron-utils/helpers';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import cx from 'classnames';

const pathArticleBodyDefault = (theme: JSSThemeType) =>
  theme.components.article.articelBody;
const DEFAULT_SEO_TEXT = '**Insert SEO text here...**';

const PostDebouncer = ({ text }) => {
  const classes = useJSSStyles(pathArticleBodyDefault);
  if (text === DEFAULT_SEO_TEXT) {
    return <></>;
  }

  const preparedText = __OSG_CONFIG__.seoBTagChange
    ? replaceTags(['b', 'strong'], text)
    : text;

  return (
    <Markdown className={cx(classes.articleP, 'article-p')} rehypePlugins={[rehypeRaw]}>
      {preparedText}
    </Markdown>
  );
};

export default PostDebouncer;
