// @flow
import { type JSSThemeVariablesType, type $DeepShape } from './JSSThemeDefault';

export const JSSThemeDefaultLayout = (themeVars: JSSThemeVariablesType) => {
  return {
    ThreeColumnLayout: {
      leftColumn: {},
      centerColumn: {},
      rightColumn: {},
    },
  };
};

export type JSSThemeDefaultLayoutType = JSSThemeVariablesType => $DeepShape<
  $Call<typeof JSSThemeDefaultLayout, JSSThemeVariablesType>
>;
