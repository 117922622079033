// @flow
import { type $DeepShape, JSSThemeVariables } from './JSSThemeDefault';

export const JSSThemeDefaultBonuses = (themeVars: typeof JSSThemeVariables) => {
  const {
    fontSizes,
    margins,
    colors: {
      colorCombinations: { whitePrimary, primaryWhite },
      colorTypes: { white, accent, primary },
    },
    borderRadius,
  } = themeVars;
  return {
    BonusesTabs: {
      bonusContainer: {
        maxWidth: 700,
        border: '1px solid #eee',
        padding: margins.md,
        background: '#f1f8eb',
        margin: [0, 0, margins.md],
        '@media (pointer: coarse)': {
          padding: [0, margins.md, margins.md],
        },
      },
      tabsWrapper: {
        display: 'flex',
      },
      root: {
        fontSize: fontSizes.md,
        padding: [margins.md, margins.xm],
        ...primaryWhite,
      },
      tabsItemDesktop: {
        cursor: 'pointer',
        borderRadius: borderRadius.md,
        marginRight: margins.md,
        '&:hover, &.active': whitePrimary,
      },
      tabsItemMobile: {
        textAlign: 'center',
        flex: 1,
        fontSize: fontSizes.sm,
        lineHeight: '1.3',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '&.active': whitePrimary,
        '&:last-child ': {
          borderRight: 'none',
        },
      },
      tabsContentDesktop: {
        padding: margins.md,
      },
    },
    Bonuses: {
      root: {
        maxWidth: 700,
        border: '1px solid #eee',
        padding: [margins.md, margins.md, margins.xm],
        background: '#fff',
        margin: [0, 0, margins.md],
      },
      row: {
        margin: 0,
      },
      rootLeft: {
        display: 'inline-block',
        verticalAlign: 'top',
        width: 200,
        '@media (pointer: coarse)': {
          width: '100%',
        },
      },
      rootRight: {
        display: 'inline-block',
        verticalAlign: 'top',
        width: 'calc(100% - 200px)',
        '@media (pointer: coarse)': {
          width: '100%',
        },
      },
      img: {
        width: '100%',
        paddingRight: margins.md,
        maxWidth: 200,
      },
      dates: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
      },
      titleSmall: {
        fontSize: fontSizes.md,
        margin: [margins.md, 0],
      },
      description: {
        margin: [0, 0, margins.lg],
      },
      headingSmall: {
        fontWeight: 'bold',
        fontSize: fontSizes.xl,
        margin: 0,
        padding: [margins.xm, 0, margins.xs],
      },
      noBonus: {
        margin: 0,
      },
      progressBar: {
        background: '#999',
        padding: margins.xs,
        textAlign: 'center',
        position: 'relative',
        height: 29,
      },
      progressBarSuccess: {
        height: 29,
        background: accent,
        position: 'absolute',
        top: 0,
        left: 0,
      },
      progressValue: {
        color: white,
        fontWeight: 'bold',
        position: 'relative',
      },
      removeButton: {
        margin: 0,
        fontWeight: 'bold',
        border: 'none',
        textDecoration: 'none',
        cursor: 'pointer',
        padding: [margins.sm, margins.xm],
        fontSize: fontSizes.sm,
        color: white,
        background: accent,
        borderColor: 'transparent',
        borderRadius: 5,
        transition: '0.3s ease-in',
        '&:hover': {
          background: primary,
        },
      },
      bonusCancelled: {
        margin: 0,
      },
      PickButtonWrapper: {},
      PickButton: {
        margin: 0,
        fontWeight: 'bold',
        border: 'none',
        textDecoration: 'none',
        cursor: 'pointer',
        padding: [margins.sm, margins.xm],
        fontSize: fontSizes.sm,
        color: white,
        background: accent,
        borderColor: 'transparent',
        borderRadius: 2,
        transition: '0.3s ease-in',
        '&:hover': {
          background: primary,
        },
        '&:disabled': {
          opacity: '.6',
          cursor: 'auto',
          '&:hover': {
            background: accent,
          },
        },
      },
    },
    BonusesDetails: {
      rootDetails: {
        margin: 0,
      },
      listDetails: {
        display: 'flex',
        '@media (pointer: coarse)': {
          flexDirection: 'column',
        },
      },
      itemDetails: {
        listStyle: 'none',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-end',
        width: '100%',
        padding: [margins.md, 0, 0],
      },
      tooltipDetails: {
        marginBottom: margins.xs,
      },
      titleDetails: {
        width: '100%',
        alignSelf: 'flex-start',
        fontWeight: 'normal',
        fontSize: fontSizes.sm,
        lineHeight: '14px',
      },
      bonusValue: {
        fontWeight: 'bold',
        fontSize: fontSizes.lg,
      },
    },
  };
};

export type JSSThemeDefaultBonusesType = (
  typeof JSSThemeVariables
) => $DeepShape<$Call<typeof JSSThemeDefaultBonuses, typeof JSSThemeVariables>>;
