// @flow
import { type $DeepShape, JSSThemeVariables } from './JSSThemeDefault';

export const JSSThemeDefaultCookies = (themeVars: typeof JSSThemeVariables) => {
  const { fontSizes, margins } = themeVars;
  return {
    cookiesPolicy: {
      CookiesPolicyRoot: {
        margin: margins.lg,
      },
      CookiesPolicyHeading: {
        fontSize: fontSizes.xxxl,
        fontWeight: 'bold',
      },
      CookiesPolicyText: {
        fontSize: fontSizes.md,
        whiteSpace: 'pre-wrap',
      },
      CookiesPolicyTable: {
        margin: [0, margins.lg, margins.lg],
        width: 'calc(100% - 40px)',
        display: '',
        overflowX: '',
      },
      CookiesPolicyTableHeading: {
        fontSize: fontSizes.xl,
        padding: [margins.xs, margins.lg, margins.xm],
        margin: 0,
        fontWeight: 'bold',
      },
      CookiesPolicyCell: {},
      CookiesPolicyTableTh: {},
    },
  };
};

export type JSSThemeDefaultCookiesType = (
  typeof JSSThemeVariables
) => $DeepShape<$Call<typeof JSSThemeDefaultCookies, typeof JSSThemeVariables>>;
