// @flow
import { type JSSThemeVariablesType, type $DeepShape } from './JSSThemeDefault';

export const JSSThemeDefaultFooterAll = (themeVars: JSSThemeVariablesType) => {
  const {
    // margins,
    colors: {
      colorTypes: { white, background1 },
    },
  } = themeVars;
  return {
    logoMain: {
      width: 212,
      paddingBottom: 40,
      '@media screen and (max-width: 683px)': {
        display: 'none',
      },
    },
    footerWrap: {
      marginTop: '',
      width: '100%',
      background: background1,
      color: white,
    },
    footerContainer: {
      maxWidth: 1372,
      padding: '60px 40px 80px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: 'auto',
      '@media screen and (max-width: 683px)': {
        padding: '20px 20px 40px',
      },
    },
    social: {
      fontSize: 25,
      color: white,
      '&:hover, &:active, &:focus': {
        opacity: 0.8,
        color: white,
      },
    },
    socialCont: {
      margin: '14px 0',
      paddingRight: 31,
      '&:last-child': {},
    },
    linkCont: {
      textTransform: '',
      paddingBottom: 10,
      '@media screen and (max-width: 1370px)': {
        fontSize: 12,
      },
    },
    socialWrap: {
      display: 'flex',
      paddingBottom: '',
    },
    socialWrapTop: {
      display: 'flex',
    },
    socialHide: {
      display: 'none',
      '@media screen and (max-width: 1009px)': {
        display: '',
        alignItems: '',
        flexDirection: '',
        width: '',
      },
    },
    socialHeader: {
      color: '',
      fontWeight: 'bold',
      fontSize: 'inherit',
      padding: '1em 0 1em 0',
      display: 'block',
      '&:empty': { padding: 0 },
    },
    sponsorsLogoWrap: {
      display: 'flex',
      flexWrap: '',
      width: '',
      paddingLeft: '',
      gridTemplateColumns: '',
      gap: '',
      '@media screen and (min-width: 1440px)': {
        minWidth: '380px',
      },
    },
    sponsorsLogo: {
      height: 50,
      width: '',
      objectFit: '',
    },
    sponsorsLogoCont: {
      paddingRight: 30,
      paddingBottom: '',
    },
    menu: {
      paddingRight: 5,
      '@media screen and (max-width: 683px)': {
        width: '50%',
      },
      '@media screen and (max-width: 1009px)': {
        textAlign: '',
      },
    },
    mainMenu: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      flexWrap: 'wrap',
    },
    menuWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '65%',
      flexWrap: 'wrap',
      margin: '0',
      paddingBottom: '18px',
      '@media screen and (max-width: 1009px)': {
        width: '100%',
        paddingBottom: '',
      },
    },
    headerMenu: {
      fontSize: 18,
      fontWeight: 800,
      color: '',
      padding: '1em 0 1em 0',
      display: 'block',
      '&:empty': { padding: 0 },
      '@media screen and (max-width: 1370px)': {
        fontSize: 16,
        fontWeight: '',
      },
    },
    links: {
      listStyleType: 'none',
      paddingRight: 10,
    },
    link: {
      color: white,
      opacity: 0.8,
      textTransform: '',
      '&:hover, &:active, &:focus': {
        opacity: 1,
        color: white,
      },
    },
    licenseWrap: {
      display: '',
      alignItems: '',
    },
    logoLicenseImgWrap: {
      display: 'inline-block',
      marginRight: 20,
    },
    licenseLink: {
      color: white,
      fontWeight: 'bold',
      paddingRight: '20px',
      textDecoration: 'underline',
      display: '',
      '&:hover, &:active, &:focus': {
        color: white,
        textDecoration: 'underline',
        opacity: 0.8,
      },
      '&:first-child': {
        color: '',
        pointerEvents: '',
      },
      '@media screen and (max-width: 1370px)': {
        fontSize: 12,
      },
      '&:last-child': {},
    },
    logoImg: {
      width: '113px',
      height: '',
      paddingRight: '',
      '@media (max-width: 500px)': {},
    },
    legal: {
      display: 'flex',
      flexWrap: 'wrap',
      paddingTop: '',
      borderTop: '',
      width: '',
      justifyContent: '',
      alignItems: '',
      '@media screen and (max-width: 1004px)': {
        flexWrap: '',
      },
    },
    copyr: {
      width: '70%',
      paddingRight: '10%',
      opacity: 0.7,
      fontFamily: '',
      '@media screen and (max-width: 1370px)': {
        fontSize: 12,
      },
      '@media (max-width: 943px)': {
        width: '100%',
      },
    },
    age: {
      display: '',
      opacity: 0.7,
      '@media screen and (max-width: 1370px)': {
        fontSize: 12,
      },
    },
    line: {
      width: '100%',
      height: 1,
      margin: '30px 0 20px',
      background: 'rgba(255, 255, 255, 0.3)',
      display: '',
      '@media screen and (max-width: 1009px)': {
        margin: '',
      },
    },
    sponsorWrapper: {
      paddingLeft: '',
      display: '',
      flexDirection: '',
      alignItems: '',
    },
    footer: {},
    newStyle: {
      width: '',
      display: 'flex',
      alignItems: '',
      borderBottom: '',
      paddingBottom: '',
      flexWrap: '',
      justifyContent: '',
      gridTemplateColumns: '',
      rowGap: '',
      '@media screen and (max-width: 1300px)': {
        flexWrap: '',
        maxWidth: '',
      },
      '@media screen and (max-width: 768px)': {
        gridTemplateColumns: '',
        rowGap: '',
      },
      '@media screen and (max-width: 440px)': {
        gridTemplateColumns: '',
      },
    },
    logosContainer: {
      display: 'block',
      alignItems: '',
      margin: 10,
      paddingBottom: '',
      textAlign: '',
      '@media screen and (max-width: 440px)': {
        margin: '',
      },
    },
    logoSvg: {
      fill: white,
      width: 70,
      maxHeight: 30,
      opacity: 0.7,
      height: '',
      '@media screen and (max-width: 440px)': {
        maxHeight: 30,
      },
    },
    logoSection: {
      width: '',
      borderBottom: '',
      display: '',
      justifyContent: '',
    },
    responsiblyContainer: {
      display: 'flex',
      borderBottom: '',
      paddingLeft: '',
      paddingBottom: '',
      '@media screen and (max-width: 1300px)': {
        flexDirection: '',
        alignItems: '',
        paddingLeft: 0,
      },
    },
    logoValidator: {
      width: '',
    },
    validatorLink: {
      '&:hover, &:active, &:focus': {
        opacity: 0.8,
      },
    },
    lineHide: {
      display: 'none',
      '@media screen and (max-width: 1009px)': {
        height: 1,
        margin: '20px 0',
        background: 'rgba(255, 255, 255, 0.3)',
        display: '',
        width: '100%',
      },
    },
    appsWrapper: {
      display: 'none',
      alignItems: '',
      gap: '',
      '@media screen and (max-width: 1009px)': {
        justifyContent: 'center',
      },
    },
    googlePlay: {
      display: 'none',
      width: '',
      '&:hover, &:active, &:focus': {
        opacity: 0.8,
      },
      '@media screen and (max-width: 1009px)': {
        margin: '',
      },
    },
    appGallery: {
      display: 'none',
      width: '',
      '&:hover, &:active, &:focus': {
        opacity: 0.8,
      },
    },
    appGalleryImg: {
      width: '',
    },
  };
};

export type JSSThemeDefaultFooterAllType =
  (JSSThemeVariablesType) => $DeepShape<
    $Call<typeof JSSThemeDefaultFooterAll, JSSThemeVariablesType>
  >;
