// @flow
import { type JSSThemeVariablesType, type $DeepShape } from './JSSThemeDefault';

export const JSSThemeDefaultVirtuals = (themeVars: JSSThemeVariablesType) => {
  const {
    margins,
    colors: {
      colorTypes: { primary, neutral1, neutral3, background2 },
    },
  } = themeVars;
  return {
    Virtuals: {
      virtualsComingSoon: {
        textAlign: 'center',
        height: 'calc(100vh - 65px)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
      titleVirtuals: {
        fontWeight: 'bold',
        fontSize: 75,
      },
      rootTabs: {
        display: 'flex',
        color: neutral1,
        background: neutral3,
      },
      tab: {
        cursor: 'pointer',
        lineHeight: `${margins.xl}px`,
        padding: [0, margins.md],
        boxShadow: `0 0 0 ${primary} inset`,
        transition: '.2s ease-in',
        '&:hover': {
          boxShadow: `0px -3px 0px ${primary} inset`,
        },
      },
      activeTab: {
        boxShadow: `0px -3px 0px ${primary} inset`,
      },
    },
    GRVirtuals: {
      iframeWrapper: {
        display: 'flex',
        resize: 'both',
        background: background2,
        overflow: 'hidden',
      },
      iframe: {
        flexGrow: 1,
        margin: 0,
        padding: 0,
        border: 0,
      },
    },
  };
};

export type JSSThemeDefaultVirtualsType = (JSSThemeVariablesType) => $DeepShape<
  $Call<typeof JSSThemeDefaultVirtuals, JSSThemeVariablesType>
>;
