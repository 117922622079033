// @flow
import React from 'react';
import cx from 'classnames';

export const ErrorMessage = ({
  text,
  className,
  contactLink,
}: {
  text: string,
  className?: string,
  contactLink?: {
    link: string,
    text: string,
  },
}) => (
  <div className={cx('osg-error', className)}>
    <i className="fa fa-times-circle-o" />
    <span>{text}</span>
    {contactLink && (
      <a target="_blank" href={contactLink.link}>
        {contactLink.text}
      </a>
    )}
  </div>
);

export const ServerErrorMessage = () => (
  <ErrorMessage text={_t('server-error')} />
);
