const { diffusionRootTopicName } = window.__OSG_RUNTIME_CONFIG__;

export default {
  sport: new RegExp(`^${diffusionRootTopicName}/(Rank)*Sports/\\d+$`),
  event: new RegExp(`^${diffusionRootTopicName}/(Rank)*Sports/\\d+/\\d+$`),
  market: new RegExp(
    `^${diffusionRootTopicName}/(Rank)*Sports/\\d+/\\d+/Markets/[a-zA-Z0-9]+$`
  ),
  outcome: new RegExp(
    `^${diffusionRootTopicName}/(Rank)*Sports/\\d+/\\d+/Markets/[a-zA-Z0-9]+/Selections/\\d/\\d+$`
  ),
  message: new RegExp(
    `^${diffusionRootTopicName}/(Rank)*Sports/\\d+/\\d+/Messages/[a-zA-Z_]{5}/\\d+$`
  ),
  clock: new RegExp(
    `^${diffusionRootTopicName}/(Rank)*Sports/\\d+/\\d+/Clock$`
  ),
  scoreboard: new RegExp(
    `^${diffusionRootTopicName}/(Rank)*Sports/\\d+/\\d+/Scoreboard$`
  ),
  markets: new RegExp(
    `^${diffusionRootTopicName}/(Rank)*Sports/\\d+/\\d+/Markets/[a-zA-Z0-9]+/$`
  ),
  bet: new RegExp(`^${diffusionRootTopicName}/Outcomes/\\d+$`),
  quote: new RegExp(`^${diffusionRootTopicName}/Quotes/.*$`),
};
