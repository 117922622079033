// @flow
import { getEventId, getSportId } from './utils';
import type { Message } from '../types';

const keys = {
  '3': 'minutes',
  '4': 'seconds',
  '5': 'countdown',
  '6': 'status',
};

const getKey = (message: Message) => getEventId(message);

const handlers = {
  countdown: (countdown: string | null) =>
    countdown ? countdown.toLowerCase() === 'true' : '',
};

const getItlData = (message: Message) => ({
  sportId: getSportId(message),
  key: getKey(message),
});

export const clockHandlerSettings = { keys, getKey, handlers, getItlData };
