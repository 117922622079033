// @flow
import {
  KOLTRON_AUTHORIZE,
  KOLTRON_UNAUTHORIZE,
  KOLTRON_UNAUTHORIZE_WITH_ERROR,
  HIDE_UNAUTHORIZE_POPUP,
  UPDATE_TOKEN,
  USER_REGISTER,
  UPDATE_JUST_REGISTERED,
  WAITING_NOTIFICATION,
  REG_WITH_ERRORS,
} from '../actions/action-types';
import { type AuthType, type ReduxAction } from '../../types';

const defaultState = {
  loggedIn: false,
  error: null,
  showUnauthorizePopup: false,
};

export const authReducer = (
  state: AuthType = defaultState,
  action: ReduxAction
): AuthType => {
  switch (action.type) {
    case USER_REGISTER:
    case KOLTRON_AUTHORIZE:
      return {
        loggedIn: true,
        accountId: action.payload.accountId,
        accountNumber: action.payload.accountNumber,
        currency: action.payload.currency,
        locale: action.payload.locale,
        username: action.payload.username,
        token: action.payload.token,
        hasJustRegistered: action.type === USER_REGISTER,
        waitingNotification: false,
      };
    case KOLTRON_UNAUTHORIZE:
    case KOLTRON_UNAUTHORIZE_WITH_ERROR:
      return {
        loggedIn: false,
        error: null,
        showUnauthorizePopup: action.type === KOLTRON_UNAUTHORIZE_WITH_ERROR,
      };
    case HIDE_UNAUTHORIZE_POPUP:
      if (state.loggedIn) {
        return state;
      }
      return {
        ...state,
        showUnauthorizePopup: false,
      };
    case UPDATE_TOKEN:
      if (!state.loggedIn) {
        return state;
      }

      return {
        ...state,
        token: action.payload,
      };
    case UPDATE_JUST_REGISTERED:
      if (!state.loggedIn) {
        return state;
      }
      return {
        ...state,
        hasJustRegistered: action.type === UPDATE_JUST_REGISTERED,
      };
    case WAITING_NOTIFICATION:
      if (!state.loggedIn) {
        return {
          ...state,
          waitingNotification: action.payload.waitingNotification,
        };
      }
      return state;
    case REG_WITH_ERRORS:
      if (!state.loggedIn) {
        return {
          ...state,
          waitingNotification: false,
          regWithErrors: action.payload.regWithErrors,
          errorName: action.payload.errorName,
        };
      }
      return state;
    default:
      return state;
  }
};
