// @flow
import { type JSSThemeVariablesType, type $DeepShape } from './JSSThemeDefault';

export const JSSThemeDefaultHeader = (themeVars: JSSThemeVariablesType) => {
  const {
    margins,
    colors: {
      colorTypes: { background2 },
    },
  } = themeVars;
  return {
    HeaderDesktop: {
      root: {
        display: 'flex',
        height: 75,
        background: background2,
      },
      authContentWrap: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginRight: margins.xl,
        '@media screen and (max-width: 1200px)': {
          marginRight: margins.md,
        },
      },
      extraUserElementsWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: [margins.xs, 0],
      },
      authWrapper: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        justifyContent: 'flex-end',
      },
    },
    LogoBrand: {
      logoWrapper: {
        width: 300,
        padding: margins.md,
        display: 'flex',
        alignItems: 'center',
      },
      logoLink: {
        display: 'block',
        height: 50,
      },
      logo: {
        height: '100%',
        objectFit: 'contain',
        '@media screen and (max-width: 1200px)': {
          width: '100%',
        },
      },
    },
  };
};

export type JSSThemeDefaultHeaderType = (JSSThemeVariablesType) => $DeepShape<
  $Call<typeof JSSThemeDefaultHeader, JSSThemeVariablesType>
>;
