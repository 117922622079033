// @flow
/* For each sport we have diffent requirements
and keep in the store only the scores
that are required to be shown.
Below the seetings for extracting the proper scores for some sports,
by default we show all scores. */
// no need to slice for sports with Quarters
export const getHomeAwaySlice = (sportId: string) => {
  switch (sportId) {
    case '240': // 240 Football
    case '3400': // 3400 Bowls
      return { start: 1 };
    case '227': // 227 Basketball
      return { take: [1, 3] };
    case '22884': // 22884 Snooker
      return { start: 1, end: 2 };
    case '1100': // 1100 Handball
    case '1600': // 1600 Futsal
    case '3500': // 3500 Field Hockey
      return { start: 1, end: 3 };
    case '1500': // 1500 Beach Soccer
    case '2100': // 2100 Ice Hockey
      return { start: 1, end: 4 };
    case '226': // 226 Baseball
      return { start: 1, end: 10 };
    case '1250': // 1250 Beach Volleyball
    case '1900': // 1900 Table Tennis
    case '5000': // 5000 Badminton
      return { start: 2 };
    case '1200': // 1200 Volleyball
      return { start: 2, end: 7 };
    case '239': // 239 Tennis
      return { start: 2, end: 8 };
    case '22886': // 22886 Darts
      return { start: 3, end: 5 };
    default:
      return false;
  }
};

/* Quarters (1st Quarter, 2nd Quarter, etc) scores are sent if different format "1-2;2-3",
so the below sports are required to be handled by another way */
export const getQuartersIndices = (sportId: string) => {
  switch (sportId) {
    case '1': // 1 American Football (2,4)
    case '227': // 227 Basketball (2,4)
    case '1400': // 1400 Waterpolo (2,4)
      return [2, 4]; // indices of quarters scores
    default:
      return false;
  }
};

/* Common scores (like balls, strikes, etc.) are required to show only for sports below */
export const getCommonScoresIndices = (sportId: string) => {
  switch (sportId) {
    case '226': // 226 Baseball
      return [10, 11, 12, 13];
    default:
      return false;
  }
};
