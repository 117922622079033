// @flow
import { KOLTRON_DEV_TOOLS_UPDATE } from '../actions/action-types';
import { type DevToolsType, type ReduxAction } from '../../types';

export const REGISTRATION_PAYLOAD = 'registration';

/** For each devtool it is necessary to create a state for conditional rendering,
 *  so that it is possible to change this state from the sidebar. */
const defaultState = {
  registration: window.__OSG_RUNTIME_CONFIG__.showDevTools,
};

export const devToolsReducer = (
  state: DevToolsType = defaultState,
  action: ReduxAction
): DevToolsType => {
  switch (action.type) {
    case KOLTRON_DEV_TOOLS_UPDATE:
      return {
        ...state,
        registration:
          action.payload === REGISTRATION_PAYLOAD
            ? !state.registration
            : state.registration,
      };
    default:
      return state;
  }
};
