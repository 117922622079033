import { reduceDiffusionMessagesCreator } from './helpers';
import {
  MYBETS_HANDLE_PENDING_BETS,
  MYBETS_CASHOUT_SUCCESS,
  MYBETS_CASHOUT_IS_LOADING,
  MYBETS_CASHOUT_IS_ERROR,
} from '../actions/my-bets';
import { KOLTRON_UNAUTHORIZE } from '../actions/action-types';

const topicType = 'quote';
const quotesDiffusionReducer = reduceDiffusionMessagesCreator({ topicType });

const defaultState = {};

export const mybets = (state = defaultState, action) => {
  switch (action.type) {
    case KOLTRON_UNAUTHORIZE: {
      return defaultState;
    }
    case MYBETS_HANDLE_PENDING_BETS: {
      return quotesDiffusionReducer(state, action);
    }
    case MYBETS_CASHOUT_SUCCESS: {
      const { payload: id } = action;
      return {
        ...state,
        [id]: {
          ...state[id],
          isCashoutSuccess: true,
          isLoading: false,
          error: null,
        },
      };
    }
    case MYBETS_CASHOUT_IS_LOADING: {
      const { payload: id } = action;
      return {
        ...state,
        [id]: {
          ...state[id],
          isLoading: true,
          error: null,
        },
      };
    }
    case MYBETS_CASHOUT_IS_ERROR: {
      const {
        payload: { id, error },
      } = action;
      return {
        ...state,
        [id]: {
          ...state[id],
          isLoading: false,
          error,
        },
      };
    }
    default:
      return state;
  }
};
