// @flow
import * as React from 'react';
import * as Sentry from '@sentry/browser';
import { ErrorMessage } from './ErrorMessage';

export function withErrorBoundary<
  Config: {},
  Component: React.AbstractComponent<Config>
>(
  WrappedComponent: Component
): React.AbstractComponent<
  $Diff<Config, { setError: (Error => void) | void }>
> {
  return class ErrorBoundary extends React.Component<
    $Diff<Config, { setError: (Error => void) | void }>,
    {
      error: ?Error,
    }
  > {
    state = {
      error: null,
    };

    setError = (error: Error) => {
      this.setState({
        error,
      });
    };

    componentDidCatch(error: Error, errorInfo: any) {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line
        console.log(errorInfo);
      }

      if (process.env.NODE_ENV === 'production') {
        Sentry.withScope(scope => {
          scope.setExtra('extra', errorInfo);
          Sentry.captureException(error);
        });
      }

      this.setError(error);
    }

    render() {
      const { error } = this.state;
      if (error) {
        return <ErrorMessage text={error.message} />;
      }

      return <WrappedComponent {...this.props} setError={this.setError} />;
    }
  };
}
