// @flow
import { type JSSThemeVariablesType, type $DeepShape } from './JSSThemeDefault';

export const JSSThemeDefaultFooterAllMobile = (
  themeVars: JSSThemeVariablesType
) => {
  const {
    // margins,
    colors: {
      colorTypes: { white, text5 },
    },
  } = themeVars;
  return {
    logoMain: {
      width: 212,
      paddingBottom: 40,
    },
    footerWrap: {
      width: '100%',
      background: text5,
      color: white,
    },
    footerContainer: {
      maxWidth: 1372,
      padding: '20px 20px 40px',
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
    },
    social: {
      fontSize: 25,
      height: 22,
      color: white,
      '&:hover, &:active, &:focus': {
        opacity: 0.8,
        color: white,
      },
    },
    socialCont: {
      margin: '14px 0',
      paddingRight: 31,
    },
    linkCont: {
      paddingBottom: 10,
      fontSize: 12,
    },
    socialWrap: {
      display: 'flex',
    },
    sponsorsLogoWrap: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    sponsorsLogo: {
      height: 50,
    },
    sponsorsLogoCont: {
      padding: '0 30px 10px 0',
    },
    mainMenu: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      flexWrap: 'wrap',
    },
    menu: {
      padding: '10px 5px 0 0',
      '@media screen and (max-width: 400px)': {
        width: '50%',
      },
    },
    headerMenu: {
      fontSize: 15,
      fontWeight: 800,
    },
    links: {
      listStyleType: 'none',
      paddingRight: 10,
    },
    link: {
      color: white,
      opacity: 0.8,
      '&:hover, &:active, &:focus': {
        opacity: 1,
        color: white,
      },
    },
    sponsorsLogoContainer: {},
    licenseLink: {
      color: white,
      fontSize: 12,
      fontWeight: 'bold',
      paddingRight: 15,
      textDecoration: 'underline',
      '&:hover, &:active, &:focus': {
        color: white,
        textDecoration: 'underline',
        opacity: 0.8,
      },
    },

    legal: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    copyr: {
      fontSize: 12,
      opacity: 0.7,
    },
    age: {
      opacity: 0.7,
      fontSize: 12,
    },
    line: {
      width: '100%',
      height: 1,
      margin: '30px 0 20px',
      background: 'rgba(255, 255, 255, 0.3)',
    },
    footer: {},
  };
};

export type JSSThemeDefaultFooterAllType = JSSThemeVariablesType => $DeepShape<
  $Call<typeof JSSThemeDefaultFooterAllMobile, JSSThemeVariablesType>
>;
