// @flow
import { type $DeepShape, type JSSThemeVariablesType } from './JSSThemeDefault';

export const JSSThemeDefaultDevTools = () => {
  return {
    sideBar: {
      width: 300,
      height: '100%',
      position: 'fixed',
      zIndex: 1000,
      top: 0,
      left: 0,
      padding: 25,
      background: '#cdcdcde8',
      transform: 'translateX(-100%)',
      transition: 'transform .2s ease-in',
      '&.show': {
        transform: 'translateX(0)',
      },
    },
    showHideBtn: {
      content: `'>'`,
      position: 'absolute',
      top: '90%',
      right: '-18px',
      width: 18,
      height: 42,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#ffffff75',
      cursor: 'pointer',
      fontWeight: 'bold',
      fontSize: '20px',
      borderRadius: '0 5px 5px 0',
    },
    toggleElement: {
      display: 'flex',
      gap: '15px',
      alignItems: 'center',
    },
    toggleButton: {
      height: 'fit-content',
      padding: '6px 12px',
      backgroundColor: '#ddd',
      border: 'none',
      cursor: 'pointer',
      fontSize: 16,
      transition: 'background-color 0.3s ease',
      '&.on': {
        backgroundColor: '#4CAF50',
        color: 'white',
      },
      '&.off': {
        backgroundColor: '#f44336',
        color: 'white',
      },
    },
    toggleText: {
      color: 'black',
      fontWeight: 600,
    },
    regButtonWrap: {
      margin: '5px auto',
      width: 'fit-content',
    },
  };
};

export type JSSThemeDefaultDevToolsType = (JSSThemeVariablesType) => $DeepShape<
  $Call<typeof JSSThemeDefaultDevTools, JSSThemeVariablesType>
>;
