import type { ReduxAction, Live, LiveClockType } from '../../../types';
import { reduceDiffusionMessages } from '../helpers';
import { LIVE_DIFFUSION } from '../../actions/live/actions';
import { UPDATE_TIMER } from '../../actions/tick-timer-actions';

const updateClock = clock => {
  if (clock.status !== 'STARTED') return clock;

  const seconds = parseInt(clock.seconds, 10);
  const minutes = parseInt(clock.minutes, 10);

  const newClock = { ...clock };

  if (clock.countdown) {
    if (minutes === 0 && seconds === 0) return clock;

    if (seconds === 0) {
      newClock.seconds = '59';
      newClock.minutes = `${minutes - 1}`;
    } else {
      newClock.seconds = `${seconds - 1}`;
      newClock.minutes = clock.minutes;
    }
  } else if (seconds === 59) {
    newClock.seconds = '0';
    newClock.minutes = `${minutes + 1}`;
  } else {
    newClock.seconds = `${seconds + 1}`;
    newClock.minutes = clock.minutes;
  }

  return newClock;
};

const clocks = (
  state: $PropertyType<Live, 'clocks'> = {},
  action: ReduxAction
) => {
  switch (action.type) {
    case LIVE_DIFFUSION: {
      return reduceDiffusionMessages({
        state,
        messages: action.payload,
        topicType: 'clock',
      });
    }
    case UPDATE_TIMER: {
      const clockIds = Object.keys(state);

      const nextState: { [id: string]: LiveClockType } = { ...state };

      for (const clockId of clockIds) {
        nextState[clockId] = updateClock(nextState[clockId]);
      }

      return (nextState: $PropertyType<Live, 'clocks'>);
    }
    default:
      return state;
  }
};

export default clocks;
