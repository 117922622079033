// @flow
import { type JSSThemeDefaultFooterType } from '../JSSThemeDefaultFooter';

export const JSSThemeFooter60700: JSSThemeDefaultFooterType = themeVars => {
  const {
    margins,
    fontSizes,
    colors: {
      colorTypes: { neutral2, text5, white, primary, error },
    },
  } = themeVars;
  return {
    FooterDesktop: {
      menu: {
        margin: [0, '30px', 0, 0],
        flex: 'none',
      },
      ligasWrap: {
        display: 'flex',
        alignItems: 'center',
      },
      sponsorsWrap: {
        maxWidth: 1140,
        marginLeft: 40,
        paddingTop: 10,
        width: '100%',
      },
      liga: {
        height: 100,
        display: 'flex',
        alignItems: 'flex-end',
      },
      ligaImg: {
        padding: '0 20px 20px 20px',
        height: 100,
        '&:first-child': {
          height: 140,
          paddingLeft: 0,
        },
        '&:nth-child(2)': {
          height: 130,
        },
        '&:last-child': {
          height: 110,
        },
      },

      linkDeposit: {
        width: 'auto',
        border: 'none',
        cursor: 'pointer',
        display: 'inline-block',
        padding: '5px 15px',
        outline: 0,
        fontSize: fontSizes.md,
        transition: '0.3s ease-in',
        textAlign: 'center',
        fontStyle: 'normal',
        fontFamily:
          'Ubuntu, Inter, Helvetica Neue, Helvetica, Arial, sans-serif',
        whiteSpace: 'nowrap',
        lineHeight: 'normal',
        borderRadius: 3,
        textDecoration: 'none',
        background: primary,
        verticalAlign: 'middle',
        color: '#fff',
        '&:hover': {
          color: '#fff',
          background: error,
        },
      },
      footer: {
        background: text5,
        padding: '0 0 40px',
      },
      link: {
        color: '#B6C1C7',
      },
      responsibleWrapper: {
        borderBottom: '1px solid #0D4D91',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        maxWidth: '1140px',
        margin: '0 0 0 50px',
        padding: [margins.lg, 0, margins.md],
      },
      responsibleText: {
        fontSize: fontSizes.md,
        color: '#C4C4C4',
        fontWeight: 'bold',
        marginRight: margins.lg,
      },
      responsibleEighteen: {
        fontSize: fontSizes.md,
        color: '#C4C4C4',
        fontWeight: 'bold',
        marginRight: margins.lg,
      },
      logoWrap: {
        background: '#d3d6db',
        padding: [margins.md, 0],
        width: '100%',
      },
      logo: {
        filter: 'none',
        display: 'inline-block',
        width: '100%',
        verticalAlign: 'middle',
        maxWidth: 1067,
        paddingRight: margins.lg,
      },
      responsibleBlock: {
        display: 'inline-block',
        background: '#C4C4C4',
        padding: [margins.xs, margins.md],
        borderRadius: '5px',
        color: '#817E7E',
        marginRight: margins.lg,
        fontWeight: 'bold',
        maxWidth: 117,
        textAlign: 'center',
        fontSize: 10,
        textShadow:
          '-0 -1px 0 #FFFFFF, 0 -1px 0 #FFFFFF, -0 1px 0 #FFFFFF, 0 1px 0 #FFFFFF, -1px -0 0 #FFFFFF, 1px -0 0 #FFFFFF, -1px 0 0 #FFFFFF, 1px 0 0 #FFFFFF, -1px -1px 0 #FFFFFF, 1px -1px 0 #FFFFFF, -1px 1px 0 #FFFFFF, 1px 1px 0 #FFFFFF, -1px -1px 0 #FFFFFF, 1px -1px 0 #FFFFFF, -1px 1px 0 #FFFFFF, 1px 1px 0 #FFFFFF',
      },
      heading: {
        color: '#CDD9E9',
        fontSize: fontSizes.lg,
        '@media screen and (max-width: 1150px)': {
          fontSize: fontSizes.lg,
        },
      },
      menuWrap: {
        width: 'auto',
        maxWidth: '1140px',
      },
      allRightsText: {
        color: '#95A2AB',
        fontSize: fontSizes.md,
        paddingBottom: 10,
      },
      linkWrap: {
        padding: [0, 0, margins.xs],
        fontSize: fontSizes.md,
        '@media screen and (max-width: 1150px)': {
          fontSize: fontSizes.md,
        },
      },
      socialWrap: {
        display: 'flex',
      },
      AllRightsReserved: {
        fontWeight: 'bold',
        fontSize: fontSizes.md,
        color: '#95A2AB',
        margin: 0,
      },
      allRights: {
        marginLeft: '-148px',
        paddingTop: margins.md,
      },
      social: {
        display: 'flex',
        width: 35,
        height: 35,
        color: white,
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        margin: [0, margins.xm, 0, 0],
        transition: 'opacity 0.3s ease',
        opacity: 0.8,
        fontSize: 42,
        '&:hover': {
          color: white,
          opacity: 1,
        },
      },
    },
    FooterMobile: {
      sponsors: {
        paddingTop: 10,
      },
      ligasWrap: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
      },
      ligaImg: {
        padding: '5px 10px 5px 10px',

        height: 60,
        '&:first-child': {
          paddingLeft: 0,
          height: 80,
        },
        '&:nth-child(2)': {
          height: 70,
        },
        '&:last-child': {
          height: 65,
        },
      },
      menuWrap: {
        flexWrap: 'wrap',
      },
      menu: {
        flex: '1 0 50%',
      },
      footer: {
        background: text5,
      },
      link: {
        color: neutral2,
      },
      heading: {
        color: neutral2,
      },
      allRights: {
        marginTop: margins.md,
        color: neutral2,
      },
    },
  };
};
