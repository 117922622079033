// @flow
import * as Sentry from '@sentry/browser';
import invariant from 'invariant';
import createSentryMiddleware from 'redux-sentry-middleware';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import sportsmenu from './koltron-redux/reducers/sportsmenu';
import { authReducer } from './koltron-redux/reducers/auth';
import balance from './koltron-redux/reducers/balance';
import location from './koltron-redux/reducers/location';
import merlin from './koltron-redux/reducers/merlin';
import { timerReducer } from './koltron-redux/reducers/timerReducer';
import { mybets } from './koltron-redux/reducers/my-bets';
import sportsReducer from './koltron-redux/reducers/live/sports';
import { diffusionReducer } from './koltron-redux/reducers/diffusion';
import { epicEnhancer } from './koltron-redux/epic-enhancer';
import { UPDATE_TIMER } from './koltron-redux/actions/tick-timer-actions';
import { getNowTime } from './koltron-redux/selectors/utils';
import type { ReduxState, OsgRuntimeConfig } from './types';
import { RUNTIME_CONFIG } from '../common/constants';
import {
  oddsFormatReducer,
  oddsFormatReducerWithSelector,
} from './components/components/OddsFormatSelect';
import { liveReducer } from './koltron-redux/reducers/live/index';
import { betslipReducer } from './koltron-redux/reducers/betslip';
import {
  betslipPlaceBetEpic,
  betslipSetInitialSelectionsEpic,
  betslipToggleSelectionEpic,
} from './koltron-redux/epics/betslip';
import { balanceEpic } from './koltron-redux/epics/balance';
import { lastAction } from './koltron-redux/reducers/lastAction';
import { seoReducer } from './koltron-redux/reducers/seo';
import { seoUtilsReducer } from './koltron-redux/reducers/seoUtils';
import { devToolsReducer } from './koltron-redux/reducers/devtools';
import {
  xtremepushAuthEpic,
  xtremepushTimerTokenUpdateEpic,
} from './koltron-redux/epics/xtremepush';
import { regAuthEpic } from './koltron-redux/epics/regAuthActions';
import storage from 'redux-persist/lib/storage';
import { createTransform, persistReducer, persistStore } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import qs from 'qs';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2'; // defaults to localStorage for web

const makeCreateKoltronStore =
  (runTimer?: boolean = true) =>
  (initialState: ReduxState, osgRuntimeConfig: OsgRuntimeConfig) => {
    invariant(
      window.__OSG_RUNTIME_CONFIG__,
      'window.__OSG_RUNTIME_CONFIG__ is required'
    );

    window[RUNTIME_CONFIG] = osgRuntimeConfig;

    const middlewares = [thunk];

    if (process.env.NODE_ENV === 'production') {
      Sentry.init({
        dsn: window.__OSG_RUNTIME_CONFIG__.sentryDsn,
        release: __OSG_VERSION__,
        defaultIntegrations: false,
        environment: process.env.KOLTRON_RESOURCE,
        beforeSend(event, hint) {
          const error = hint?.originalException;

          if (
            error &&
            error.message &&
            (error.message.includes(
              "Failed to read the 'localStorage' property from 'Window'"
            ) ||
              /** Very frequent error that cant be fully fixed since it is caused by GooglePageTranslate */
              error.message.includes(
                "Failed to execute 'removeChild' on 'Node'"
              ))
          ) {
            return null;
          }

          return event;
        },
      });

      middlewares.unshift(
        createSentryMiddleware(Sentry, {
          getUserContext: (state) => state.auth,
        })
      );
    }

    /** Use composeWithDevTools for dev and for testing environments to allow ReduxDevTools Chrome extension  */
    const { isDevelopmentMode, isTestMode } = osgRuntimeConfig;
    const composeEnchancers =
      isDevelopmentMode || isTestMode
        ? composeWithDevTools({
            name: `Redux`,
            realtime: true,
            trace: true,
            traceLimit: 7,
          })
        : compose;

    /**
     * Configure persisting state between tabs and sessions in a browser using localstorage
     * We persist:
     * betslip -- whole state except selectionsInitialized field
     * live -- favouriteMarkettypes, favouriteEventIds fields
     */

    /**
     * If sharing betslip is enabled,
     * we have to check if url contains a selections param
     * if parameter exists, we don't load persisted state for betslip
     */
    const betslipTransform = createTransform(
      /** Transform state on its way to being serialized and persisted */
      (inboundState) => {
        return { ...inboundState };
      },
      /** Transform state being rehydrated */
      (outBoundState) => {
        const selectionsParam = qs.parse(window.location.search, {
          ignoreQueryPrefix: true,
        })?.selections;
        if (
          __OSG_CONFIG__.enableBetsSharing &&
          typeof selectionsParam === 'string' &&
          selectionsParam !== ''
        ) {
          return {};
        }
        return { ...outBoundState };
      },
      /** Define which reducers this transform gets called for. */
      { whitelist: ['betslip'] }
    );

    const persistBaseConfig = {
      key: 'root',
      storage,
      transforms: [betslipTransform],
      stateReconciler: autoMergeLevel2,
      whitelist: ['betslip'],
    };
    const persistLiveConfig = {
      key: 'live',
      storage,
      whitelist: ['favouriteMarkettypes', 'favouriteEventIds'],
    };

    const reducers = combineReducers({
      sports: sportsReducer,
      auth: authReducer,
      balance,
      betslip: betslipReducer,
      timer: timerReducer,
      oddsFormat: __OSG_CONFIG__.showOddsFormatSelector
        ? oddsFormatReducerWithSelector
        : oddsFormatReducer,
      sportsmenu,
      mybets,
      lastAction,
      diffusion: diffusionReducer,
      // $FlowFixMe
      live: persistReducer(persistLiveConfig, liveReducer),
      location,
      merlin,
      seo: seoReducer,
      seoUtils: seoUtilsReducer,
      devTools: devToolsReducer,
    });
    // $FlowFixMe
    const persistedReducer = persistReducer(persistBaseConfig, reducers);

    // $FlowFixMe TODO update lint
    const store = createStore(
      persistedReducer,
      initialState,
      composeEnchancers(
        epicEnhancer(
          {},
          betslipSetInitialSelectionsEpic,
          betslipPlaceBetEpic,
          betslipToggleSelectionEpic,
          balanceEpic,
          xtremepushAuthEpic,
          xtremepushTimerTokenUpdateEpic,
          regAuthEpic
        ),
        applyMiddleware(...middlewares),
        global.devToolsExtension ? global.devToolsExtension() : (f) => f
      )
    );

    if (runTimer) {
      setInterval(() => {
        store.dispatch({ type: UPDATE_TIMER, payload: getNowTime() });
      }, 1000);
    }

    const persistor = persistStore(store);
    return { store, persistor };
  };

export const createKoltronStore = makeCreateKoltronStore();
export const createKoltronStoreForStorybook = makeCreateKoltronStore(false);
