// @flow
import { reduceDiffusionMessages } from '../helpers';
import { LIVE_DIFFUSION } from '../../actions/live/actions';
import type { ReduxAction, LiveMarketType } from '../../../types';

const markets = (
  state: { +[id: string]: LiveMarketType } = {},
  action: ReduxAction
) => {
  switch (action.type) {
    case LIVE_DIFFUSION: {
      return reduceDiffusionMessages({
        state,
        topicType: 'market',
        messages: action.payload,
      });
    }
    default:
      return state;
  }
};

export default markets;
