// @flow
import { getSportId, getEventId, getMessageId } from './utils';
import type { Message } from '../types';

const keys = {
  '0': 'description',
  '1': 'displayTime',
  '2': 'period',
  '3': 'type',
  '4': 'minutes',
  '5': 'seconds',
};

const getKey = (message: Message) => getMessageId(message);

const getItlData = (message: Message) => ({
  sportId: getSportId(message),
  eventId: getEventId(message),
  order: message.topic.parts.slice(-1)[0],
});

export const messageHandlerSettings = { keys, getKey, getItlData };
