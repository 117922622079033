// @flow
export const BETSLIP_SET_SELECTIONS: 'betslip:selections:set' =
  'betslip:selections:set';

export const BETSLIP_SET_INITIAL_SELECTIONS: 'betslip:selections:initial:set' =
  'betslip:selections:initial:set';

export const BETSLIP_SET_RECEIPT: 'betslip:receipt:set' = 'betslip:receipt:set';

export const BETSLIP_SET_ERROR: 'betslip:error:set' = 'betslip:error:set';

export const BETSLIP_ACCEPT_CHANGES: 'betslip:selections:acceptChanges' =
  'betslip:selections:acceptChanges';

export const BETSLIP_TOGGLE_SELECTION: 'betslip:toggle:selection' =
  'betslip:toggle:selection';

export const BETSLIP_CLEAR_SELECTIONS: 'betslip:selections:clear' =
  'betslip:selections:clear';
export const BETSLIP_UPDATE_SELECTION: 'betslip:selection:update' =
  'betslip:selection:update';

export const BETSLIP_RECEIPT_RETAIN_SELECTIONS: 'betslip:receipt:retainSelections' =
  'betslip:receipt:retainSelections';
export const BETSLIP_RECEIPT_CONTINUE: 'betslip:receipt:continue' =
  'betslip:receipt:continue';

export const BETSLIP_COPY_STAKE: 'betslip:stake:copyStake' =
  'betslip:stake:copyStake';

export const BETSLIP_START_PLACE_BET = 'betslip:start:placebet';

export const BETSLIP_START_PLACE_BET_WITH_ACCEPT_CHANGES =
  'betslip:start:placebet:acceptChanges';

export const updateSelection = (payload: { id: string, data: Object }) => ({
  type: BETSLIP_UPDATE_SELECTION,
  payload,
});
