// @flow
const { diffusionRootTopicName } = window.__OSG_RUNTIME_CONFIG__;
export const sports = () => `${diffusionRootTopicName}/Sports/\\d+`;
export const rankedSports = () =>
  `${diffusionRootTopicName}/(Rank)*Sports/\\d+`;
export const events = () => `${sports()}/\\d+`;
export const rankedEventsForSport = (sportId: string) =>
  `${diffusionRootTopicName}/(Rank)*Sports/${sportId}/\\d+`;
export const clocks = () => `${events()}/Clock`;
export const event = (sportId: string, eventId: string) =>
  `${diffusionRootTopicName}/(Rank)*Sports/${sportId}/${eventId}`;
export const clock = (sportId: string, eventId: string) =>
  `${event(sportId, eventId)}/Clock`;
export const markets = (sportId: string, eventId: string) =>
  `${event(sportId, eventId)}/Markets//`;
export const market = (sportId: string, eventId: string, marketId: string) =>
  `${event(sportId, eventId)}/Markets/${marketId}//`;
export const messages = (sportId: string, eventId: string, locale: string) =>
  `${event(sportId, eventId)}/Messages/${locale}/\\d+`;
export const outcomes = (sportId: string, eventId: string) =>
  `${event(sportId, eventId)}/Markets/[a-zA-Z0-9]+/Selections/\\d/\\d+`;
export const scoreboard = (sportId: string, eventId: string) =>
  `${event(sportId, eventId)}/Scoreboard`;
export const bets = () => `${diffusionRootTopicName}/Outcomes/\\d+`;
export const quotes = (accountId: string, locale: string) =>
  `${diffusionRootTopicName}/Quotes/${accountId}/${locale}/`;
