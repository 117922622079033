// @flow
import { type $DeepShape, JSSThemeVariables } from './JSSThemeDefault';

export const JSSThemeDefaultHeaderMobile = (
  themeVars: typeof JSSThemeVariables
) => {
  const {
    colors: {
      colorCombinations: { whiteBg2 },
      colorTypes: { inform },
    },
    margins,
    fontSizes,
  } = themeVars;

  return {
    HeaderMobile: {
      headerContainer: {
        height: 50,
        display: 'flex',
        background: inform,
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      logoWrapper: {
        display: 'block',
        padding: margins.md,
        height: '100%',
      },
      logoImg: {
        height: '100%',
        width: 'auto',
      },
      wrapper: {
        height: '100vh',
        width: '100vw',
        top: 0,
        left: 0,
        position: 'fixed',
        overflow: 'hidden',
        zIndex: 10002,
      },
      backgroundMenu: {
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        zIndex: 99,
        transition: 'opacity 0.4s',
        background: 'rgba(0, 0, 0, 0.6)',
        opacity: 0,
        transform: 'translate(-100vw)',
        '&.active': {
          transform: 'translate(0)',
          opacity: 1,
        },
      },
      iconContainer: {
        padding: margins.xm,
        fontSize: fontSizes.xxl,
        position: 'relative',
        height: 50,
        lineHeight: `${fontSizes.xxl}px`,
      },
      iconClose: {
        position: 'absolute',
        padding: margins.xm,
        right: 0,
        top: 0,
        fontSize: fontSizes.xxl,
        lineHeight: `${fontSizes.xxl}px`,
        opacity: 0.5,
        zIndex: 1,
      },

      menuContainer: {
        ...whiteBg2,
        width: '80vw',
        top: 0,
        height: '100%',
        zIndex: 100,
        overflowY: 'scroll',
        paddingBottom: margins.md,
        position: 'fixed',
        transition: 'transform 0.4s',
        display: 'flex',
        flexDirection: 'column',
        '-webkit-overflow-scrolling': 'touch',
      },
      menuRight: {
        right: 0,
        transform: 'translateX(80vw)',
        justifyContent: 'space-between',
        '&.active': {
          transform: 'translateX(0)',
        },
      },
      menuLeft: {
        left: 0,
        transform: 'translateX(-80vw)',
        '&.active': {
          transform: 'translateX(0)',
        },
      },
      languageSelectWrap: {
        padding: [0, margins.xm],
        display: '',
        alignItems: '',
      },
    },
  };
};

export type JSSThemeDefaultHeaderMobileType = (
  typeof JSSThemeVariables
) => $DeepShape<
  $Call<typeof JSSThemeDefaultHeaderMobile, typeof JSSThemeVariables>
>;
