// @flow
import {
  PREMATCH_COUPON_HEADER_LOADED,
  SEO_BLOCK_UNMOUNT,
} from '../actions/action-types';
import { type SeoUtils, type ReduxAction } from '../../types';

const defaultState = {
  isHeader: false,
};

export const seoUtilsReducer = (
  state: SeoUtils = defaultState,
  action: ReduxAction
): SeoUtils => {
  switch (action.type) {
    case SEO_BLOCK_UNMOUNT:
    case PREMATCH_COUPON_HEADER_LOADED:
      return {
        isHeader: action.payload.isHeader,
      };
    default:
      return state;
  }
};
