// @flow
import { type JSSThemeDefaultMyBetsType } from '../JSSThemeDefaultMyBets';

export const JSSThemeMyBets60700: JSSThemeDefaultMyBetsType = themeVars => {
  const {
    colors: {
      colorTypes: { background2 },
    },
  } = themeVars;

  return {
    MyBets: {
      content: {
        background: background2,
      },
    },
  };
};
