// @flow
import axios from 'axios';

export const checkKoltronVersion = async () => {
  const { data } = await axios.get('/api/koltron/version');
  if (data !== __OSG_VERSION__) {
    window.location.reload();
    return false;
  }
  return true;
};
