// @flow
import { type JSSThemeVariablesType, type $DeepShape } from './JSSThemeDefault';

export const JSSThemeDefaultFooter = (themeVars: JSSThemeVariablesType) => {
  const {
    margins,
    colors: {
      colorTypes: { white, background2, text2 },
    },
  } = themeVars;
  return {
    FooterDesktop: {
      footer: {
        color: background2,
        flex: '0 0 auto',
        background: '#000',
        padding: [margins.xm, margins.xl],
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      ligasWrap: {},
      liga: {},
      ligaImg: {},
      sponsorsWrap: {},
      sponsors: {},
      linkDeposit: {},
      logoWrap: {
        listStyle: 'none',
        padding: [margins.xm, 0, margins.lg],
        textAlign: 'center',
      },
      logosContainer: {
        display: 'inline-block',
      },
      logo: {
        filter: 'invert(100%) brightness(1.7) contrast(100%)',
      },
      logoSvg: {
        maxHeight: 20,
        maxWidth: 100,
        width: '100%',
        height: '100%',
      },
      logoContainer: {
        fill: '#fff',
        margin: margins.md,
      },
      logoWraper: {
        padding: [margins.xm, 0, margins.xm],
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexWrap: 'wrap',
      },
      allRightsText: {},
      AllRightsReserved: {},
      responsibleWrapper: {},
      responsibleText: {},
      responsibleEighteen: {},
      responsibleBlock: {},
      links: {
        listStyle: 'none',
      },
      linkWrap: {
        padding: [0, 0, margins.xs],
      },
      link: {
        color: text2,
        textDecoration: 'none',
        transition: ' all 0.2s',
        cursor: 'pointer',
        wordWrap: 'break-word',
        '&:hover, &:active, &:focus': {
          color: white,
          transition: ' all 0.2s',
        },
      },
      menuWrap: {
        display: 'flex',
        justifyContent: 'center',
        width: 950,
        marginLeft: 60,
        marginBottom: margins.md,
      },
      menu: {
        flex: 1,
      },
      allRights: {
        width: 950,
        marginLeft: 60,
      },
      heading: {},
      social: {},
      socialWrap: {},
    },
    FooterMobile: {
      ligasWrap: {},
      sponsorsWrap: {},
      sponsors: {},
      ligaImg: {},
      footer: {
        color: background2,
        flex: '0 0 auto',
        background: '#000',
        padding: [margins.xs, margins.md, 30],
      },
      links: {
        listStyle: 'none',
      },
      link: {
        color: text2,
        textDecoration: 'none',
        transition: ' all 0.2s',
        cursor: 'pointer',
        wordWrap: 'break-word',
        '&:hover, &:active, &:focus': {
          color: white,
          transition: ' all 0.2s',
        },
      },
      linkWrap: {
        padding: [0, 0, margins.xs],
      },
      menuWrap: {
        display: 'flex',
        justifyContent: 'center',
      },
      menu: {
        flex: 1,
      },
      heading: {},
      allRights: {},
    },
  };
};

export type JSSThemeDefaultFooterType = JSSThemeVariablesType => $DeepShape<
  $Call<typeof JSSThemeDefaultFooter, JSSThemeVariablesType>
>;
