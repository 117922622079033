// @flow
import { type JSSThemeVariablesType, type $DeepShape } from './JSSThemeDefault';

export const JSSThemeDefaultXtremepush = (themeVars: JSSThemeVariablesType) => {
  return {
    xtremepushBadgeWrap: {
      height: 0,
      width: '100%',
      position: 'relative',
      pointerEvents: 'none',
    },
    xtremepushBadge: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      top: 0,
      right: -5,
      height: 20,
      minWidth: 20,
      paddingLeft: 5,
      paddingRight: 5,
      fontSize: 14,
      borderRadius: 10,
      color: '#ea3302',
      backgroundColor: 'white',
      pointerEvents: 'none',
    },
  };
};

export type JSSThemeDefaultXtremepushType = JSSThemeVariablesType => $DeepShape<
  $Call<typeof JSSThemeDefaultXtremepush, JSSThemeVariablesType>
>;
