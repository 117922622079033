// @flow
import { type JSSThemeDefaultLayoutType } from '../JSSThemeDefaultLayout';

export const JSSThemeLayout60700: JSSThemeDefaultLayoutType = themeVars => {
  const {
    colors: {
      colorTypes: { white, black },
    },
  } = themeVars;
  return {
    ThreeColumnLayout: {
      leftColumn: {
        background: `${black} !important`,
        borderRadius: '0 5px 5px 0',
      },
      centerColumn: {
        boxShadow: 'none',
      },
      rightColumn: {
        background: `${white} !important`,
        borderRadius: '5px 0 0 5px',
      },
    },
  };
};
