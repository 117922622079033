// @flow
import { type JSSThemeVariablesType, type $DeepShape } from './JSSThemeDefault';

const trendBefore = {
  content: "''",
  width: '50px',
  height: '20px',
  position: 'absolute',
  zIndex: 999,
};
const trendAfter = {
  content: "''",
  width: 0,
  height: 0,
  position: 'absolute',
  border: '25px solid transparent',
  zIndex: 999,
};

export const JSSThemeDefaultOutcomes = (themeVars: JSSThemeVariablesType) => {
  const {
    margins,
    borderRadius,
    fontSizes,
    colors: {
      colorTypes: { primary, white, text1, neutral1, neutral3, success, error },
    },
  } = themeVars;

  return {
    MobileOutcomes: {
      wideRoot: {
        display: 'flex',
        '& div:not(:first-child)': {
          marginLeft: margins.md,
        },
      },
    },
    MobileOutcome: {
      '@global': {
        '@keyframes price-up': {
          from: { transform: 'scale(1)' },
          '50%': { transform: 'scale(1.2)' },
          '80%': { transform: 'scale(0.9)' },
          to: { transform: 'scale(1)' },
        },
        '@keyframes price-down': {
          from: { transform: 'scale(1)' },
          '50%': { transform: 'scale(1.2)' },
          '80%': { transform: 'scale(0.9)' },
          to: { transform: 'scale(1)' },
        },
        '@keyframes angle-up': {
          from: { transform: 'translateY(0)' },
          to: { transform: 'translateY(-80px)' },
        },
        '@keyframes angle-down': {
          from: { transform: 'translateY(0)' },
          to: { transform: 'translateY(80px)' },
        },
        '@keyframes blinkUp': {
          '0%': { backgroundColor: '#02bd2e' },
          '50%': { backgroundColor: neutral1 },
          '100%': { backgroundColor: '#02bd2e' },
          // '0%': { opacity: 1 },
          // '50%': { opacity: 0.1 },
          // '100%': { opacity: 1 },
        },
        '@keyframes blinkDown': {
          '0%': { backgroundColor: '#ff2424' },
          '50%': { backgroundColor: neutral1 },
          '100%': { backgroundColor: '#ff2424' },
        },
      },
      root: {
        border: 0,
        background: primary,
        borderRadius: borderRadius.md,
        marginLeft: margins.xs,
        width: 45,
        height: 45,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        overflow: 'hidden',
        fontSize: '12px',
        '&.active': {
          background: primary,
          transition: 'all 0.4s',
        },
        '&.suspended': {
          opacity: 0.4,
        },
      },
      rootTrendUp: {
        borderRadius: [0, borderRadius.md, borderRadius.md, borderRadius.md],
        '&::before': {
          content: "''",
          position: 'absolute',
          // bottom: '50%',
          // right: '50%',
          // width: '100%',
          // height: '100%',
          // background: accent,
          //background: `linear-gradient(to right, ${accent}, ${accent} 80%, ${primary})`,
          // linear-gradient(to right, #207c23, #207c23 60%, #fece38)
          //opacity: 0.7,

          //*************  triangle  ********** */
          background: '#0F0',
          top: '-14px',
          left: '-14px',
          border: '2px solid',
          borderColor: neutral1,
          width: 18,
          height: 18,
          transform: 'rotate(45deg)',

          //*********** triangle */
          /*
          bottom: 3,
          left: 6,
          width: 34,
          height: 2,
          background: ' #02bd2e', */

          animation: 'blinkUp 1.5s linear infinite',
          zIndex: 100,
        },
      },
      rootTrendDown: {
        borderRadius: [borderRadius.md, borderRadius.md, 0, borderRadius.md],
        '&::after': {
          content: "''",
          position: 'absolute',
          // top: '50%',
          // left: '50%',
          // width: '100%',
          // height: '100%',
          // background: `linear-gradient(to left, #e16a55, #e16a55 80%, ${primary})`,
          // background: '#e16a55',

          //*************  triangle  ********** */
          background: '#ff2424',
          bottom: '-14px',
          right: '-14px',
          border: '2px solid',
          borderColor: neutral1,
          width: 18,
          height: 18,
          transform: 'rotate(45deg)',

          //************** Line */
          // bottom: 3,
          // left: 6,
          // width: 34,
          // height: 2,
          // background: '#ff2424',

          animation: 'blinkDown 1.5s linear infinite',
          zIndex: 100,
        },
      },
      wideRoot: {
        flex: 1,
        width: '100%',
        border: 0,
        background: primary,
        borderRadius: borderRadius.md,
        padding: [margins.xs, margins.sm],
      },
      price: {
        fontWeight: 'bold',
        textAlign: 'center',
        position: 'absolute',
        zIndex: 3,
      },
      widePrice: {
        fontWeight: 'bold',
        display: 'flex',
      },
      priceValue: {
        display: 'block',
        color: text1,
        '&.small': {
          fontSize: fontSizes.xxs,
        },
        '&.active': {
          color: white,
        },
      },
      priceUp: {
        animation:
          '0.7s cubic-bezier(0.6, 0.04, 0.98, 0.335) 0.35s alternate price-up',
      },
      priceDown: {
        animation:
          '0.7s cubic-bezier(0.6, 0.04, 0.98, 0.335) 0.5s alternate price-down',
      },
      value1x2: {
        opacity: 0.3,
        display: 'block',
        // paddingBottom: margins.xs,
        '&.active': {
          opacity: 0.7,
          color: white,
        },
      },
      wideValue1x2: {
        opacity: 0.3,
        flex: 1,
        '&.active': {
          opacity: 0.7,
          color: white,
        },
      },
      trendUp: {
        '&::before': {
          ...trendBefore,
          background: 'linear-gradient(green, #00000000)',
          bottom: '-30px',
          opacity: 0.7,
        },
        '&::after': {
          ...trendAfter,
          bottom: '-10px',
          borderTop: '10px solid transparent',
          borderBottom: '10px solid green',
          opacity: 0.3,
        },
        '&::before, &::after': {
          animation: '3s ease 0s alternate angle-up',
        },
      },
      trendDown: {
        '&::before': {
          ...trendBefore,
          background: 'linear-gradient(red, #000)',
          top: '-30px',
          opacity: 0.7,
        },
        '&::after': {
          ...trendAfter,
          top: '-10px',
          borderTop: '10px solid #000',
          borderBottom: '10px solid transparent',
          opacity: 0.3,
        },
        '&::before, &::after': {
          animation: '3s ease 0s alternate angle-down',
        },
      },
    },
    Outcomes: {
      outcomes: {
        flex: 1,
        display: 'flex',
        minWidth: 0,
      },
      col33: {
        width: '33.33%',
      },
      col50: {
        width: '50%',
      },
    },
    Outcome: {
      root: {
        transition: '.3s ease-in',
        cursor: 'pointer',
        margin: margins.xs,
        padding: [0, margins.xs],
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        height: 25,
        lineHeight: '25px',
        color: neutral3,
        background: primary,
        borderRadius: borderRadius.md,
        '&.active': {
          color: '#fff',
          background: neutral3,
        },
        '@media not all and (pointer: coarse)': {
          '&:hover': {
            color: '#fff',
            background: neutral3,
          },
        },
        '&.inBanner': {
          fontSize: fontSizes.md,
          lineHeight: '32px',
          height: 32,
          marginTop: 0,
          marginBottom: 0,
          padding: [0, margins.md],
        },
        '&.disabled': {
          cursor: 'default',
          opacity: 0.6,
          '&:hover': {
            color: neutral3,
            background: primary,
          },
        },
      },
      description: {
        flex: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      descriptionName: {
        paddingRight: 3,
      },
      descriptionSpread: {},
      descriptionHandicap: {},
      price: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      fullWidthPrice: {
        width: '100%',
      },
      value1x2: {
        opacity: 0.3,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        flex: 1,
      },
      priceValue: {
        fontWeight: 'bold',
      },
      trend: {
        marginRight: margins.xs,
      },
      trendUp: {
        color: success,
      },
      trendDown: {
        color: error,
      },
    },
  };
};

export type JSSThemeDefaultOutcomesType = JSSThemeVariablesType => $DeepShape<
  $Call<typeof JSSThemeDefaultOutcomes, JSSThemeVariablesType>
>;
