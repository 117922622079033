import { applyMiddleware } from 'redux';
import { createEpicMiddleware, combineEpics } from 'redux-observable';

export const epicEnhancer = (options = {}, ...initEpics) => createStore => (
  reducer,
  preloadedState,
  enhancer
) => {
  const EpicsContainer = new Set();
  const nextEpic = epic => {
    if (!EpicsContainer.has(epic)) {
      EpicsContainer.add(epic);
      epicMiddleware.run(epic);
    }
  };

  const epicMiddleware = createEpicMiddleware(options);
  const middlewareEnhancer = applyMiddleware(epicMiddleware);
  const store = middlewareEnhancer(createStore)(
    reducer,
    preloadedState,
    enhancer
  );

  epicMiddleware.run(combineEpics(...initEpics));

  return {
    ...store,
    nextEpic,
  };
};
