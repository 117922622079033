// @flow
import { type JSSThemeDefaultHeaderType } from '../JSSThemeDefaultHeader';

export const JSSThemeHeader60700: JSSThemeDefaultHeaderType = themeVars => {
  const { margins } = themeVars;
  return {
    HeaderDesktop: {
      root: {
        background: '#000',
        color: '#fff',
      },
      extraUserElementsWrapper: {
        margin: [0, 0, margins.md, 0],
      },
    },
    LogoBrand: {
      logoWrapper: {
        width: 250,
        padding: margins.xm,
      },
    },
  };
};
