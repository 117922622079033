// @flow
import { type $DeepShape, JSSThemeVariables } from './JSSThemeDefault';
import { jssHelpers } from './jss-helpers';

export const JSSThemeDefaultBetslip = (themeVars: typeof JSSThemeVariables) => {
  const {
    colors: {
      colorCombinations: { whiteInform, whiteWarning, errorWhite },
      colorTypes: {
        white,
        neutral1,
        neutral2,
        border2,
        text1,
        text2,
        background4,
        inform,
      },
    },
    margins,
    fontSizes,
    borderRadius,
  } = themeVars;

  const rootStyles = {
    background: white,
    position: 'relative',
    lineHeight: 1.3,
    fontSize: fontSizes.md,
    height: '100%',
  };

  const BASE_MARGIN = margins.md;

  return {
    Betslip: {
      root: rootStyles,
      oneButton: {
        margin: [margins.xs, BASE_MARGIN],
        display: 'flex',
        flexWrap: 'wrap',
      },
      acceptButtonBetslip: {
        width: '100%',
      },
      placeBetButtonBetslip: {
        display: 'flex',
        flexWrap: 'nowrap',
        width: '100%',
      },
      linkButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: [margins.xm, BASE_MARGIN, 0, margins.xm],
        paddingBottom: BASE_MARGIN,
      },
      stakeInfo: {
        background: 'inherit',
      },
      info: {
        background: 'inherit',
        flexWrap: 'wrap',
      },
      wrapTotals: {
        background: 'inherit',
      },
      message: {
        padding: margins.xs,
        margin: BASE_MARGIN,
        borderRadius: borderRadius.md,
        background: neutral2,
        fontSize: fontSizes.sm * 0.9,
      },
      readMoreLink: {
        color: text1,
        textDecoration: 'underline',
      },
    },
    BetslipPanel: {
      headingCollapse: {
        cursor: 'pointer',
      },
      caret: {
        display: 'flex',
        alignItems: 'center',
      },
      tooltipIcon: {
        position: 'relative',
        '&:before, &:after': {
          display: 'block',
          position: 'absolute',
          visibility: 'hidden',
          opacity: 0,
          transition: 'opacity .7s',
          color: '#403f3c',
          backgroundColor: '#fefefe',
        },
        '&:after': {
          display: 'block',
          position: 'absolute',
          visibility: 'hidden',
          opacity: 0,
          transition: 'opacity .7s',
          color: '#403f3c',
          backgroundColor: '#fefefe',
          content: 'attr(data-title)',
          width: 'fit-content',
          fontSize: '0.8em',
          top: '27px',
          left: '-27px',
          padding: '10px',
          borderRadius: '5px',
          boxSizing: 'border-box',
        },
        '&:before': {
          content: '""',
          width: '10px',
          height: '10px',
          left: '7px',
          top: '23px',
          transform: 'translateX(-6px) rotate(45deg)',
        },
        '&:hover:before, &:hover:after': {
          opacity: 1,
          visibility: 'visible',
        },
      },
    },
    BetslipStakeInfo: {
      info: {
        display: 'flex',
        color: text1,
        padding: [margins.xs, margins.xs, margins.xs, BASE_MARGIN],
      },
      summaryTitle: {
        maxWidth: '50%',
        flex: 1,
      },
      summaryValue: {
        maxWidth: '50%',
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    },
    BetslipSelection: {
      common: {
        padding: BASE_MARGIN,
        margin: BASE_MARGIN,
        borderRadius: borderRadius.md,
        background: neutral2,
      },
      notSingle: {},
    },
    SingleSelectionInfo: {
      info: {
        marginBottom: margins.xs,
        fontSize: fontSizes.md,
      },
      eventDescription: {
        color: text1,
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'space-between',
      },
      remove: {
        cursor: 'pointer',
        opacity: 0.8,
        fontSize: fontSizes.sm,
        color: text1,
        marginLeft: margins.xs,
      },
      liveLabel: {
        fontWeight: 'normal',
        fontSize: fontSizes.xs,
        borderRadius: borderRadius.md,
        marginRight: margins.xs,
        padding: [0, margins.xs, 0, margins.xs],
        ...whiteInform,
      },
      marketDescription: {
        marginRight: margins.xs,
        color: text1,
      },
      priceDescription: {
        fontWeight: 'bold',
        color: text1,
      },
      descriptionHasChanged: {
        margin: [0, margins.xs],
        padding: [0, margins.xs],
        ...whiteWarning,
      },
    },
    BetslipTotals: {
      root: {
        display: 'flex',
        borderTop: `1px solid ${border2}`,
      },
      container: {
        padding: [margins.xs, margins.xs, margins.xs, BASE_MARGIN],
        flex: 1,
        color: text1,
        maxWidth: '50%',
      },
      summary: {},
      summaryLabel: {
        paddingBottom: margins.xs,
        ...jssHelpers.ellipsis,
      },
      summaryValue: {
        fontWeight: 'bold',
        fontSize: fontSizes.sm * 2,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
      taxContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
      },
      taxSummary: {
        padding: [0, margins.sm],
        fontSize: fontSizes.sm,
        color: text1,
        flex: 0.5,
      },
      taxLabel: {
        float: 'left',
      },
      taxValue: {
        float: 'right',
      },
    },
    BetslipErrors: {
      list: {
        ...jssHelpers.ulReset,
        marginBottom: margins.xs,
        padding: [margins.xs, margins.sm, 0],
        borderRadius: borderRadius.md,
        ...whiteInform,
        width: '100%',
      },
      listItem: {
        paddingBottom: margins.xs,
        fontSize: fontSizes.sm,
      },
      readMoreLink: {
        '&.active, &:hover': {
          opacity: 0.7,
        },
        textDecoration: 'underline',
      },
    },
    MultipleBody: {
      description: {
        fontWeight: 'bold',
      },
    },
    TextPair: {
      row: {
        display: 'flex',
        marginTop: margins.xs,
        color: text1,
      },
      title: {
        flexGrow: 1,
      },
    },
    SystemType: {
      amount: {
        color: text1,
        fontSize: fontSizes.sm,
      },
    },
    NonSingleBody: {
      selectionInfo: {
        fontWeight: 'bold',
        color: text1,
      },
    },
    BetslipInput: {
      container: {
        display: 'flex',
        background: white,
        padding: [margins.xs, margins.xs, 0],
        borderRadius: [borderRadius.lg, borderRadius.lg, 0, 0],
      },
      label: {
        ...jssHelpers.ellipsis,
        color: text1,
        fontSize: fontSizes.md,
      },
      fieldContainer: {
        borderRadius: [0, 0, borderRadius.lg, borderRadius.lg],
      },
    },
    BetslipInputs: {
      inputs: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: margins.xs,
      },
      stake: {
        flexBasis: '50%',
        overflow: 'hidden',
        '&:nth-child(1)': {
          marginRight: margins.sm,
        },
      },
    },
    SelectionState: {
      root: {
        display: 'inline-block',
        padding: [0, margins.xs * 2, 1],
        fontSize: fontSizes.md,
        borderRadius: borderRadius.md,
        ...errorWhite,
      },
      notSingle: {
        margin: margins.sm,
        background: 'none', // to override root background
      },
      suspended: {},
      closed: {},
    },
    TermsInBetslip: {
      container: {
        padding: [margins.md, 0],
        fontSize: fontSizes.sm,
      },
      links: {
        display: 'flex',
        justifyContent: 'space-between',
        borderTop: `1px solid ${text2}`,
      },
      //TODO replace <Button link/>
      link: {
        color: background4,
        '&:hover': {
          opacity: 0.7,
        },
      },
    },
    Receipt: {
      root: rootStyles,
      titleContainer: {
        textAlign: 'center',
        fontSize: fontSizes.lg,
        padding: [margins.xs, margins.sm],
        margin: BASE_MARGIN,
        color: white,
        borderRadius: borderRadius.md,
        background: inform,
      },
      title: {
        paddingRight: margins.sm,
      },
      controls: {
        background: white,
      },
    },
    SingleSelectionStaticInfo: {
      root: {
        color: text1,
        fontSize: fontSizes.md,
      },
      eventDescription: {
        fontWeight: 'bold',
      },
      marketDescription: {
        display: 'inline-block',
      },
      priceDescription: {
        fontWeight: 'bold',
      },
      separator: {
        margin: [0, margins.xs],
      },
      outcomePrice: {
        fontWeight: 'bold',
      },
    },
    ReceiptSelectionAmounts: {
      root: {
        display: 'flex',
        padding: margins.md,
        margin: BASE_MARGIN,
        borderRadius: borderRadius.md,
        background: neutral1,
      },
      container: {
        flex: 1,
      },
      label: {
        whiteSpace: 'nowrap',
        color: text1,
        fontSize: fontSizes.md,
      },
      value: {
        fontWeight: 'bold',
        color: text1,
        fontSize: fontSizes.md,
      },
    },
    ReceiptNonSingleSelection: {
      systemTitle: {
        fontWeight: 'bold',
        color: text1,
        margin: BASE_MARGIN,
        fontSize: fontSizes.md,
      },
    },
    EachWayButton: {
      root: {
        position: 'relative',
        fontSize: fontSizes.sm,
        whiteSpace: 'nowrap',
        lineHeight: 1.5,
        color: text1,
      },
      input: {
        visibility: 'hidden',
      },
      label: {
        margin: [0, margins.xs],
        cursor: 'pointer',
        '&:after': {
          opacity: 0,
          content: '""',
          position: 'absolute',
          width: '8px',
          height: '4px',
          top: '6px',
          left: '3px',
          border: `2px solid ${text1}`,
          borderTop: 'none',
          borderRight: 'none',
          transform: 'rotate(-45deg)',
        },
        '&:before': {
          content: '""',
          position: 'absolute',
          width: '13px',
          height: '13px',
          top: '3px',
          left: '0px',
          border: `1px solid ${white}`,
          background: white,
        },
      },
      checked: {
        '&:after': {
          opacity: 1,
        },
      },
    },
    MyBetsTab: {
      tab: {
        '&.active, &:hover': {},
      },
    },
    Delta: {
      deltaRoot: {},
      deltaIncreased: {},
      deltaDecreased: {},
    },
    BadgeAmount: {
      badgeAmount: {},
    },
    ShareBets: {
      message: {
        transition: 'transform 0.3s ease',
        opacity: 0,
        willChange: 'opacity',
        '&.shown': {
          opacity: 1,
          color: neutral1,
        },
      },
      shareButton: {
        marginLeft: '8px',
        maxWidth: '37px',
        '&:hover' : {
          backgroundColor:  '',
        },
      },
      shareIcon: {
        marginLeft: '-3px',
        marginTop: '-2px',
        display: 'block',
      },
    },
  };
};

export type JSSThemeDefaultBetslipType = (
  typeof JSSThemeVariables
) => $DeepShape<$Call<typeof JSSThemeDefaultBetslip, typeof JSSThemeVariables>>;
