// @flow
import * as React from 'react';
import { type JSSThemeType } from './origins/JSSThemeDefault';

type JSSContextType = {
  theme: JSSThemeType,
  // eslint-disable-next-line
  jss: any,
  StylesRegistry?: Object,
};

const DefaultStylesRegistry = new Map();
// eslint-disable-next-line
export const JSSThemeContext = React.createContext<JSSContextType>({
  // $FlowFixMe
  theme: null,
  jss: null,
});

const useJSSContext = () => {
  const context = React.useContext(JSSThemeContext);
  return context;
};

const resetsJSSPath = theme => theme.components.resets;
export const useJSSResets = () => {
  return useJSSStyles(resetsJSSPath);
};

export const useJSSStyles = <O: Object>(
  path: JSSThemeType => O
): $ObjMap<O, () => string> => {
  const {
    theme,
    jss,
    StylesRegistry = DefaultStylesRegistry,
  } = useJSSContext();
  const styles = path(theme);

  const registrySheet = StylesRegistry.get(path);

  if (registrySheet) {
    return registrySheet.classes;
  }

  const sheet = jss.createStyleSheet(styles).attach();

  StylesRegistry.set(path, sheet);

  return sheet.classes;
};

export const JSSThemeContextProviderPartner = ({
  children,
  theme,
  jss,
}: {
  children: React.Node,
  theme: JSSThemeType,
  jss: any,
}) => {
  const value = React.useMemo(
    () => ({
      theme,
      jss,
      StylesRegistry: new Map(),
    }),
    [theme, jss]
  );

  React.useEffect(() => {
    return () => {
      value.StylesRegistry.forEach(sheet => sheet.detach());
    };
  }, [value.StylesRegistry]);

  return (
    <JSSThemeContext.Provider value={value}>
      {children}
    </JSSThemeContext.Provider>
  );
};

export const useJssThemeColors = () => {
  const { theme } = useJSSContext();
  return theme.colors.colorTypes;
};
