// @flow
import { HORSERACING_ID, GREYHOUNDS_ID } from '../../common/constants';

export const getSerializedParams = (params: {
  [string]: string | number | boolean | null,
}) => {
  return Object.keys(params).reduce((parts, key) => {
    const serializedParam = `${key}=${encodeURIComponent(String(params[key]))}`;
    return parts ? `${parts}&${serializedParam}` : serializedParam;
  }, '');
};

export const propValidator = (prop: string) => {
  throw new Error(`'${prop}' is required`);
};

export const RANKED_SPORTS = [GREYHOUNDS_ID, HORSERACING_ID];

export const capitalize = (val: string) =>
  val[0].toUpperCase() + val.slice(1).toLowerCase();

export const capitalizeFirstLetter = (val: string) =>
  val[0].toUpperCase() + val.slice(1);

export const convertToI18n = (val: string) =>
  typeof val === 'string'
    ? val
        .toLowerCase()
        .replace(/(-|_|\s)(.)/g, s => s[1].toUpperCase())
        .replace(/\./g, s => '')
    : val;

export const replaceTags = (tags: [string, string], text: string): string => {
  const [currentTag, newTag] = tags;

  return text
    .replace(new RegExp(`<${currentTag}>`, 'gi'), `<${newTag}>`)
    .replace(new RegExp(`</${currentTag}>`, 'gi'), `</${newTag}>`);
}
