// @flow
import type { ReduxState } from '../../types';

const getSeo = (state: ReduxState) => state.seo;

export const getSeoOgTitle = (state: ReduxState) => {
  const seo = getSeo(state);
  return seo && seo.ogTitle ? seo.ogTitle : null;
};

export const getSeoOgDescription = (state: ReduxState) => {
  const seo = getSeo(state);
  return seo && seo.ogDescription ? seo.ogDescription : null;
};

export const getSeoOgImage = (state: ReduxState) => {
  const seo = getSeo(state);
  return seo && seo.ogImage ? seo.ogImage : null;
};

export const getSeoTitle = (state: ReduxState) => {
  const seo = getSeo(state);
  return seo && seo.title ? seo.title : null;
};

export const getSeoDescription = (state: ReduxState) => {
  const seo = getSeo(state);
  return seo && seo.description ? seo.description : null;
};

export const getSeoHeading = (state: ReduxState) => {
  const seo = getSeo(state);
  return seo && seo.h1 ? seo.h1 : null;
};

export const getSeoTextData = (state: ReduxState) => {
  const seo = getSeo(state);
  return seo && seo.textData ? seo.textData : null;
};

export const getSeoPostIDs = (state: ReduxState) => {
  const seo = getSeo(state);
  if (seo && Array.isArray(seo.postIDs)) {
    // $FlowFixMe
    return seo.postIDs.filter((ids) => ids !== '');
  }
  return null;
};

export const getSeoAlias = (state: ReduxState) => {
  const seo = getSeo(state);
  return seo && seo.alias ? seo.alias : null;
};
