// @flow
import {
  map,
  pluck,
  compose,
  values,
  isNil,
  difference,
  keys,
  prop,
  converge,
  lensProp,
  zipObj,
  unnest,
  identity,
  mergeDeepLeft,
  set,
  view,
  head,
  filter,
} from 'ramda';
import type {
  BetslipReceiptType,
  BetslipSelectionType,
  BetslipSelectionSingleType,
  BetslipSelectionMultipleType,
  BetslipSelectionSystemType,
  BetslipType,
  WinBonusType,
  BetslipSelectionMultipleWithBonusType,
  SelectionTypeForDataLayer,
  ReduxState,
} from '../../types';
import { dataLayerPushObject } from '../../utils';

const single: 'single' = 'single';
const multiple: 'multiple' = 'multiple';
const system: 'system' = 'system';
const multipleWithBonus: 'multipleWithBonus' = 'multipleWithBonus';

export const BETSLIP_SELECTION_TYPES = {
  single,
  multiple,
  system,
  multipleWithBonus,
};

const open: 'O' = 'O';
const suspended: 'S' = 'S';
const closed: 'C' = 'C';

export const BETSLIP_OUTCOME_STATES = {
  open,
  suspended,
  closed,
};

const betslip: 'betslip' = 'betslip';
const betPlacement: 'betPlacement' = 'betPlacement';
const receipt: 'receipt' = 'receipt';

export const BETSLIP_STATUSES = {
  betslip,
  betPlacement,
  receipt,
};

const initial: 'itl' = 'itl';
const delta: 'delta' = 'delta';
const removal: 'removal' = 'removal';

const BETSLIP_MESSAGE_TYPES = {
  initial,
  delta,
  removal,
};

const STATE_SELECTION_TYPE_MULTIPLE = 'mul1';
const STATE_SELECTION_TYPE_SYSTEM = 'sys3';

const getDeltaField = (field) => `${field}Delta`;

const getDeltas = (deltaFields) => (selection, diff) =>
  deltaFields.reduce((deltas, field) => {
    if (!diff || diff[field] === undefined) {
      return deltas;
    }

    // eslint-disable-next-line no-param-reassign
    deltas[getDeltaField(field)] = diff[field] - selection[field];
    return deltas;
  }, {});

const fieldsWithDelta = {
  [BETSLIP_SELECTION_TYPES.single]: ['price'],
  [BETSLIP_SELECTION_TYPES.multiple]: ['price'],
  [BETSLIP_SELECTION_TYPES.system]: [],
  [BETSLIP_SELECTION_TYPES.multipleWithBonus]: ['price'],
};

const getDeltasBySelectionType = map(getDeltas, fieldsWithDelta);

const getHasOutcomeDescriptionChanged = (selection, diff) =>
  diff && diff.outcomeDescription ? { hasOutcomeDescriptionChanged: true } : {};

const mergeSelectionDiff = (selection, diff) => ({
  ...selection,
  ...diff,
  ...getDeltasBySelectionType[selection.type](selection, diff),
  ...getHasOutcomeDescriptionChanged(selection, diff),
  isEachWay: diff && isNil(diff.isEachWaySupported) && selection.isEachWay,
});

const defaultDeltas = map(
  (fields) =>
    fields.reduce((deltas, field) => {
      // eslint-disable-next-line no-param-reassign
      deltas[getDeltaField(field)] = 0;
      return deltas;
    }, {}),
  fieldsWithDelta
);

const defaultFields = {
  isEachWay: false,
  stake: 0,
};

const defaultFieldsByType = {
  [BETSLIP_SELECTION_TYPES.single]: { ...defaultFields },
  [BETSLIP_SELECTION_TYPES.multiple]: {
    ...defaultFields,
    outcomeState: BETSLIP_OUTCOME_STATES.open,
  },
  [BETSLIP_SELECTION_TYPES.system]: {
    ...defaultFields,
    outcomeState: BETSLIP_OUTCOME_STATES.open,
  },
  [BETSLIP_SELECTION_TYPES.multipleWithBonus]: {
    ...defaultFields,
    outcomeState: BETSLIP_OUTCOME_STATES.open,
  },
};

const mergeDefaultSelectionFields = (selection) => ({
  ...selection,
  ...defaultDeltas[selection.type],
  ...defaultFieldsByType[selection.type],
});

const resetDeltas = (selection) => ({
  ...selection,
  ...defaultDeltas[selection.type],
});

export const getSelectionTotalStake = (selection: BetslipSelectionType) => {
  let multiplier = 1;

  if (selection.type === 'system') {
    multiplier *= selection.combinations;
  }

  if (selection.isEachWay) {
    multiplier *= 2;
  }

  return multiplier * selection.stake;
};

export const getSelectionCurrentPrice = (
  selection: BetslipReceiptType | BetslipSelectionType
): number => selection[selection.isEachWay ? 'eachWayPrice' : 'price'];

export const getSelectionPotentialReturn = (
  selection: BetslipReceiptType | BetslipSelectionType
): number => getSelectionCurrentPrice(selection) * selection.stake;

export const getSelectionPotentialReturnWithBonus = (
  selection: BetslipSelectionMultipleWithBonusType
): number => {
  const potentialWin =
    getSelectionCurrentPrice(selection) * selection.stake - selection.stake;
  const winBonus = potentialWin * (selection.winBonus / 100);
  return potentialWin + winBonus + selection.stake;
};

export const getSelectionWithBonus = (
  selection: BetslipSelectionMultipleWithBonusType
): number => {
  const potentialWin =
    getSelectionCurrentPrice(selection) * selection.stake - selection.stake;
  return potentialWin * (selection.winBonus / 100);
};

export const getIsSelectionOpen = (selection: BetslipSelectionType) =>
  selection.outcomeState === BETSLIP_OUTCOME_STATES.open;

export const getIsSelectionEachWay = (selection: BetslipSelectionType) =>
  selection.isEachWaySupported;

const resetChangedOutcomeDescriptions = (selection) => ({
  ...selection,
  hasOutcomeDescriptionChanged: false,
});

export const acceptChangesHandler: ({
  [selectionId: string]: BetslipSelectionType,
}) => {
  [selectionId: string]: {
    ...BetslipSelectionType,
    hasOutcomeDescriptionChanged: boolean,
  },
} = compose(
  map(resetDeltas),
  map(resetChangedOutcomeDescriptions),
  filter(getIsSelectionOpen)
);

const selectionTypeEq =
  (type: $Values<typeof BETSLIP_SELECTION_TYPES>) =>
  (selection: BetslipSelectionType) =>
    selection.type === type;

export const getIsSelectionSingle = selectionTypeEq(
  BETSLIP_SELECTION_TYPES.single
);
const getIsSelectionMultiple = selectionTypeEq(
  BETSLIP_SELECTION_TYPES.multiple
);
export const getIsSelectionSystem = selectionTypeEq(
  BETSLIP_SELECTION_TYPES.system
);

export const getIsSelectionMultipleWithBonus = selectionTypeEq(
  BETSLIP_SELECTION_TYPES.multipleWithBonus
);

const DEFAULT_FIELDS_TO_COMPARE = [
  ('price': 'price'),
  ('eachWayPrice': 'eachWayPrice'),
  ('isEachWaySupported': 'isEachWaySupported'),
];

const OUTCOME_STATE: 'outcomeState' = 'outcomeState';

const FIELDS_TO_COMPARE = {
  [BETSLIP_SELECTION_TYPES.single]: [
    ...DEFAULT_FIELDS_TO_COMPARE,
    ('priceId': 'priceId'),
    OUTCOME_STATE,
    ('outcomeDescription': 'outcomeDescription'),
  ],
  [BETSLIP_SELECTION_TYPES.multiple]: [
    ...DEFAULT_FIELDS_TO_COMPARE,
    OUTCOME_STATE,
  ],
  [BETSLIP_SELECTION_TYPES.system]: [
    ...DEFAULT_FIELDS_TO_COMPARE,
    ('combinations': 'combinations'),
    OUTCOME_STATE,
  ],
  [BETSLIP_SELECTION_TYPES.multipleWithBonus]: [
    ...DEFAULT_FIELDS_TO_COMPARE,
    OUTCOME_STATE,
    ('message': 'message'),
    ('winBonus': 'winBonus'),
    ('params': 'params'),
  ],
};

const compareSelectionsByType = map(
  (keysToCompare) => (selectionOld, selectionNew) => {
    const diff = keysToCompare.reduce((result, key) => {
      // $FlowFixMe
      if (selectionOld[key] !== selectionNew[key]) {
        // eslint-disable-next-line no-param-reassign
        result[key] = selectionNew[key];
      }

      return result;
    }, {});

    return Object.keys(diff).length > 0 ? diff : null;
  },
  FIELDS_TO_COMPARE
);

const transactionStatuses = {
  processed: 'PROCESSED',
  rejected: 'REJECTED',
};

const makeSelectionStatusCheck = (status) => (selection: BetslipReceiptType) =>
  selection.transaction && selection.transaction.status === status;

const getSelectionProcessed = makeSelectionStatusCheck(
  transactionStatuses.processed
);
export const getSelectionRejected = makeSelectionStatusCheck(
  transactionStatuses.rejected
);
export const filterProcessedTransaction: (
  Array<BetslipReceiptType>
) => Array<BetslipReceiptType> = (receipts) =>
  receipts.filter(getSelectionProcessed);

export const resetSelection = (selection: BetslipSelectionType) => ({
  ...selection,
  stake: 0,
  isEachWay: false,
});

type BetslipHandledMessages = {
  id: string,
  type: $Values<typeof BETSLIP_MESSAGE_TYPES>,
  data?: BetslipSelectionType,
  resetMultipleDeltas?: true,
};

export const handleBetslipMessages = (
  selections: { +[string]: BetslipSelectionType },
  data: Array<Object>,
  manual: ?boolean
) => {
  const messages = ((data
    .map((sel) => {
      const currentSel = selections[sel.id];
      if (!currentSel) {
        return {
          id: sel.id,
          data: sel,
          type: BETSLIP_MESSAGE_TYPES.initial,
        };
      }

      const diff = compareSelectionsByType[sel.type](currentSel, sel);
      if (diff) {
        return {
          id: sel.id,
          data: diff,
          type: BETSLIP_MESSAGE_TYPES.delta,
        };
      }
      return null;
    })
    .filter((e) => e): any): Array<BetslipHandledMessages>);
  const currentSelectionKeys = keys(selections);
  const idsToRemove = difference(
    currentSelectionKeys,
    data.map(({ id }) => id)
  );

  messages.push(
    ...idsToRemove.map((id) => {
      const obj: BetslipHandledMessages = {
        id,
        type: BETSLIP_MESSAGE_TYPES.removal,
      };

      if (!manual) {
        obj.type = BETSLIP_MESSAGE_TYPES.delta;
        // $FlowFixMe obj.data should incl. BetslipSelectionType
        obj.data = {
          outcomeState: BETSLIP_OUTCOME_STATES.closed,
        };
      }

      return obj;
    })
  );

  const multiple = values(selections).find(
    (e) => e.type === BETSLIP_SELECTION_TYPES.multiple
  );

  if (multiple) {
    const multipleMessage = messages.find((e) => e.id === multiple.id);
    if (
      multipleMessage &&
      manual &&
      multipleMessage.type === BETSLIP_MESSAGE_TYPES.delta
    ) {
      multipleMessage.resetMultipleDeltas = true;
    }
  }

  const multipleWithBonus = values(selections).find(
    (e) => e.type === BETSLIP_SELECTION_TYPES.multipleWithBonus
  );

  if (multipleWithBonus) {
    const multipleWithBonusMessage = messages.find(
      (e) => e.id === multipleWithBonus.id
    );

    if (
      multipleWithBonusMessage &&
      manual &&
      multipleWithBonusMessage.type === BETSLIP_MESSAGE_TYPES.delta
    ) {
      multipleWithBonusMessage.resetMultipleDeltas = true;
    }
  }
  return messages;
};

export const mapSelections = ({
  singles,
  multiple,
  systems,
  winBonus,
}: {
  singles: Array<BetslipSelectionSingleType>,
  multiple: BetslipSelectionMultipleType,
  systems: Array<BetslipSelectionSystemType>,
  winBonus?: {
    message: string,
    value: number,
  },
}) => {
  const mappedSingles = singles.map((single, i) => {
    const nextSingle = {
      ...single,
      type: BETSLIP_SELECTION_TYPES.single,
      order: i,
      id: single.outcomeId,
    };

    delete nextSingle.ref;

    return nextSingle;
  });

  const mappedSystems = systems.map((system, i) => ({
    ...system,
    type: BETSLIP_SELECTION_TYPES.system,
    order: i,
    id: system.ref,
    minWinSelections: system.type,
    outcomeState: BETSLIP_OUTCOME_STATES.open,
  }));

  const selections = [...mappedSingles, ...mappedSystems];

  if (multiple) {
    const mappedMultiple = {
      ...multiple,
      type: BETSLIP_SELECTION_TYPES.multiple,
      id: multiple.ref,
      outcomeState: BETSLIP_OUTCOME_STATES.open,
    };

    if (__OSG_CONFIG__.useBetslipWithBonus && winBonus) {
      mappedMultiple['winBonus'] = winBonus.value;
    }

    selections.push(mappedMultiple);
  }
  return selections;
};

export const betslipWithBonusMapSelections = ({
  singles,
  multiple,
  winBonus,
}: {
  singles: Array<BetslipSelectionSingleType>,
  multiple: BetslipSelectionMultipleType,
  winBonus: WinBonusType,
}) => {
  const mappedSingles = singles.map((single, i) => {
    const nextSingle = {
      ...single,
      type: BETSLIP_SELECTION_TYPES.single,
      order: i,
      id: single.outcomeId,
    };

    delete nextSingle.ref;

    return nextSingle;
  });

  const selections = [...mappedSingles];

  const getWinBonusMessage = (winBonus: WinBonusType) => {
    if (winBonus.text && winBonus.text.message) {
      return winBonus.text.message;
    } else if (winBonus.message) {
      return winBonus.message;
    } else {
      return '';
    }
  };

  if (multiple) {
    const mappedMultiple = {
      ...multiple,
      type: BETSLIP_SELECTION_TYPES.multipleWithBonus,
      id: 'mul1',
      outcomeState: BETSLIP_OUTCOME_STATES.open,
      message: winBonus && getWinBonusMessage(winBonus),
      winBonus: winBonus && winBonus.value,
      params: winBonus && winBonus.text && winBonus.text.params,
    };
    selections.push(mappedMultiple);
  } else if (mappedSingles.length === 1) {
    const currentSingle = mappedSingles[0];
    const mappedMultiple = {
      isEachWaySupported: currentSingle.isEachWaySupported,
      price: currentSingle.price,
      type: BETSLIP_SELECTION_TYPES.multipleWithBonus,
      id: 'mul1',
      outcomeState: BETSLIP_OUTCOME_STATES.open,
      message: winBonus && getWinBonusMessage(winBonus),
      winBonus: winBonus && winBonus.value,
      params: winBonus && winBonus.text && winBonus.text.params,
      eachWayPrice: currentSingle.eachWayPrice,
    };
    selections.push(mappedMultiple);
  }
  return selections;
};

const selectionReducer = (
  selections,
  { id, type, data, resetMultipleDeltas }
) => {
  switch (type) {
    case BETSLIP_MESSAGE_TYPES.initial: {
      // eslint-disable-next-line no-param-reassign
      if (!data) {
        break;
      }
      selections[id] = mergeDefaultSelectionFields(data);
      break;
    }
    case BETSLIP_MESSAGE_TYPES.delta: {
      const selection = selections[id];
      if (selection) {
        // eslint-disable-next-line no-param-reassign
        selections[id] = mergeSelectionDiff(selection, data);
      }

      if (
        (getIsSelectionMultiple(selection) ||
          getIsSelectionMultipleWithBonus(selection)) &&
        resetMultipleDeltas
      ) {
        // eslint-disable-next-line no-param-reassign
        selections[id] = resetDeltas(selections[id]);
      }
      break;
    }
    case BETSLIP_MESSAGE_TYPES.removal: {
      // eslint-disable-next-line no-param-reassign
      delete selections[id];
      break;
    }
    default:
      break;
  }

  return selections;
};

const getNextOutcomeIdsFromSelections = (
  selections,
  prevOutcomeIds
): $PropertyType<BetslipType, 'outcomeIds'> => {
  return prevOutcomeIds.filter((outcomeId) => selections[outcomeId.id]);
};

/**
 * Handle selections payload.
 * Flag initialSelections comes if site was opened
 * in browser by url that has selections parameter. In that case we populate the
 * store with initial selections and outcomeIds and ignore previous state.
 */
export const handleSelectionsPayload = (
  { selections, outcomeIds }: BetslipType,
  payload: Array<Object>,
  manual: ?boolean,
  initialSelections: ?boolean
) => {
  const messages = handleBetslipMessages(selections, payload, manual);
  const selectionsToMutate: { [id: string]: BetslipSelectionType } = {
    ...selections,
  };
  const nextSelections = messages.reduce(selectionReducer, selectionsToMutate);
  const nextOutcomeIds = initialSelections
    ? values(nextSelections)
        // $FlowFixMe
        .filter((selection) => !!selection?.outcomeId)
        .map<Object>((selection) => ({
          // $FlowFixMe
          id: selection?.outcomeId,
          meta: {},
        }))
    : getNextOutcomeIdsFromSelections(nextSelections, outcomeIds);

  return {
    selections: nextSelections,
    outcomeIds: nextOutcomeIds,
  };
};

const pluckOutcomeId = pluck('outcomeId');
const pluckOutcomeIds = pluck('outcomeIds');
const pluckFirstOutcomeId = compose(unnest, map(head), pluckOutcomeIds);
// $FlowFixMe
const convergeZipObj = converge(zipObj);

const normalizeByOutcomeId = convergeZipObj([pluckOutcomeId, identity]);
const normalizeByFirstOutcomeId = convergeZipObj([
  pluckFirstOutcomeId,
  identity,
]);

const getNormalizedOutcomes = compose(normalizeByOutcomeId, prop('outcomes'));
const lensSingles = lensProp('singles');
const getSinglesNormalizedByOutcomeId = compose(
  normalizeByFirstOutcomeId,
  view(lensSingles)
);

const mergeOutcomesInfo = converge(set(lensSingles), [
  converge(compose(values, mergeDeepLeft), [
    getNormalizedOutcomes,
    getSinglesNormalizedByOutcomeId,
  ]),
  identity,
]);

// $FlowFixMe
export const handlePlaceBet = compose(mapSelections, mergeOutcomesInfo);

type ObjectWithSelections = { [key: string]: SelectionTypeForDataLayer };

// $FlowFixMe
export const pushBetsToDataLayer = (state: ReduxState, response) => {
  const stateSelections = { ...state.betslip.selections };
  const stateUserData = { ...state.auth };

  const selections: ObjectWithSelections = {};
  const sportIdsFromSelections = new Set();

  Object.keys(stateSelections).forEach((key) => {
    const selection = stateSelections[key];
    const resultSelection = {
      GUID: stateUserData.accountNumber,
      currency: stateUserData.currency,
      amount: selection.stake,
    };

    if (key === STATE_SELECTION_TYPE_MULTIPLE) {
      selections.multiple = { ...resultSelection };
    } else if (key === STATE_SELECTION_TYPE_SYSTEM) {
      selections.systems = {
        ...resultSelection,
        amount: selection.combinations * selection.stake,
      };
    } else if (!isNaN(Number(key))) {
      selections[key] = {
        ...resultSelection,
        sportID: selection.sportId,
      };

      sportIdsFromSelections.add(selection.sportId);
    }
  });

  /** Add sport IDs to multiple selections */
  if (selections.multiple) {
    const ids = Array.from(sportIdsFromSelections);
    selections.multiple.sportID = ids.length > 1 ? ids : ids[0];
  }

  /** Add sport IDs to system selections */
  if (selections.systems) {
    const ids = Array.from(sportIdsFromSelections);
    selections.systems.sportID = ids.length > 1 ? ids : ids[0];
  }

  /** Add transaction IDs to selections from response */
  if (response) {
    if (Array.isArray(response.singles)) {
      response.singles.forEach((single) => {
        const outcomeId = single.outcomeIds[0];
        selections[outcomeId].transactionID = single.transaction?.id || '';
      });
    }
    if (selections.multiple && response.multiple?.transaction?.id) {
      selections.multiple.transactionID =
        response.multiple.transaction?.id || '';
    }
    if (selections.systems && response.systems.length !== 0) {
      selections.systems.transactionID =
        response.systems[0]?.transaction?.id || '';
    }
  }

  const selectionsWithoutEmptyPrice = Object.values(selections)
    // $FlowFixMe
    .filter((s) => Number(s.amount) !== 0);

  selectionsWithoutEmptyPrice.forEach((betEvent) => {
    dataLayerPushObject({
      eventName: 'BetAttempt',
      data: { ...betEvent },
    });
  });

  if (response) {
    selectionsWithoutEmptyPrice.forEach((betEvent) => {
      dataLayerPushObject({
        eventName: 'BetSuccess',
        data: { ...betEvent },
      });
    });
  }
};
