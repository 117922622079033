// @flow
import React from 'react';

import { useIsAuthorised } from '../../redux-hooks';
import { IdleAutoLogout } from './IdleAutoLogout';

/**
 * Wrapper for switch on / off auto logout
 * @returns UI, React.Component or null
 */

const IdleTimerComponent = () => {
  const enableAutoLogout = __OSG_CONFIG__.idleTimer.enabled;

  const isLoggedIn = useIsAuthorised();

  return <>{isLoggedIn && enableAutoLogout && <IdleAutoLogout />}</>;
};

export default IdleTimerComponent;
