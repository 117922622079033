// @flow
import { lighten, darken, getLuminance } from 'polished';

const faIcons = {
  'caret-right': `'\u{f0da}'`,
  'caret-down': `'\u{f0d7}'`,
  'plus-circle': `'\u{f055}'`,
  'minus-circle': `'\u{f056}'`,
  'question-circle': `'\u{f059}'`,
  user: `'\u{f007}'`,
  bars: `'\u{f0c9}'`,
  'chevron-left': `'\u{f053}'`,
  'envelope-o': `'\u{f003}'`,
  search: `'\u{f002}'`,
  times: `'\u{f00d}'`,
};

const getFaIconStyles = (icon: $Keys<typeof faIcons>) => ({
  '&:before': {
    content: faIcons[icon],
  },
});

export const jssHelpers = {
  ulReset: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  aReset: {
    color: 'unset',
    textDecoration: 'unset',
  },
  ellipsis: {
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  fa: {
    display: 'inline-block',
    font: 'normal normal normal 14px/1 FontAwesome',
    fontSize: 'inherit',
    textRendering: 'auto',
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    margin: '1px 2px',
    '@global': {
      '@font-face': {
        fontFamily: 'FontAwesome',
        src: [
          `url('//maxcdn.bootstrapcdn.com/font-awesome/4.4.0/fonts/fontawesome-webfont.eot?v=4.4.0')`,
          `url('//maxcdn.bootstrapcdn.com/font-awesome/4.4.0/fonts/fontawesome-webfont.eot?#iefix&v=4.4.0') format('embedded-opentype')`,
          `url('//maxcdn.bootstrapcdn.com/font-awesome/4.4.0/fonts/fontawesome-webfont.woff2?v=4.4.0') format('woff2')`,
          `url('//maxcdn.bootstrapcdn.com/font-awesome/4.4.0/fonts/fontawesome-webfont.woff?v=4.4.0') format('woff')`,
          `url('//maxcdn.bootstrapcdn.com/font-awesome/4.4.0/fonts/fontawesome-webfont.ttf?v=4.4.0') format('truetype')`,
          `url('//maxcdn.bootstrapcdn.com/font-awesome/4.4.0/fonts/fontawesome-webfont.svg?v=4.4.0#fontawesomeregular') format('svg')`,
        ].join(',\n'),
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontDisplay: 'swap',
      },
    },
  },
  faIconPlusCircle: getFaIconStyles('plus-circle'),
  faIconMinusCircle: getFaIconStyles('minus-circle'),
  faIconCaretRight: getFaIconStyles('caret-right'),
  faIconCaretDown: getFaIconStyles('caret-down'),
  faIconQuestionCircle: getFaIconStyles('question-circle'),
  faIconMenuBurger: getFaIconStyles('bars'),
  faIconUserAccount: getFaIconStyles('user'),
  faIconChevronLeft: getFaIconStyles('chevron-left'),
  faIconMail: getFaIconStyles('envelope-o'),
  faIconSearch: getFaIconStyles('search'),
  faIconClose: getFaIconStyles('times'),
};

const CDN_URL = 'https://cdn.processingservices.biz';

export const generateJssFontsSrc = (path: string, cdnUrl: string = CDN_URL) =>
  [
    `url(${cdnUrl}/fonts/${path}.eot)`,
    `url(${cdnUrl}/fonts/${path}.eot?#iefix) format('embedded-opentype')`,
    `url(${cdnUrl}/fonts/${path}.woff) format('woff')`,
    `url(${cdnUrl}/fonts/${path}.ttf) format('truetype')`,
    `url(${cdnUrl}/fonts/${path}.svg) format('svg')`,
  ].join(',\n');

export const applyOnlyDesktop = (value: Object) => ({
  '@media not all and (pointer: coarse)': value,
});
export const applyOnlyMobile = (value: Object) => ({
  '@media (pointer: coarse)': value,
});

const getHoverSheme = color => {
  if (getLuminance(color) > 0.66) {
    return { background: darken(0.12, color) };
  } else {
    return { background: lighten(0.1, color) };
  }
};

export const getButtonColors = (colorCombinations: {
  color: string,
  background: string,
}) => ({
  ...colorCombinations,
  ...applyOnlyDesktop({
    '&:hover': {
      ...getHoverSheme(colorCombinations.background),
      color: colorCombinations.color,
    },
  }),
  ...applyOnlyMobile({
    '&:hover, &:active': {
      ...colorCombinations,
    },
  }),
  '&:disabled': {
    cursor: 'not-allowed',
    opacity: 0.6,
    '&:hover, &:active': {
      ...colorCombinations,
    },
  },
});
