// @flow
import {
  type JSSThemeShapeType,
  type JSSThemeVariablesShapeType,
  type ColorTypes,
  getColorCombinations,
  type GetJSSThemePatternsType,
  colorTypes,
  getJSSThemePatterns,
} from '../JSSThemeDefault';
import { JSSThemeHeaderMenu60700 } from './JSSThemeHeaderMenu60700';
import { JSSThemeHeader60700 } from './JSSThemeHeader60700';
import { JSSThemeBetslip60700 } from './JSSThemeBetslip60700';
import { JSSThemeSportsMenu60700 } from './JSSThemeSportsMenu60700';
import { JSSThemeLayout60700 } from './JSSThemeLayout60700';
import { JSSThemeLanguageSwitcher60700 } from './JSSThemeLanguageSwitcher60700';
import { JSSThemeSearch60700 } from './JSSThemeSearch60700';
import { JSSThemeCoupons60700 } from './JSSThemeCoupons60700';
import { JSSThemeBannerItems60700 } from './JSSThemeBannerItems60700';
import { JSSThemeDefaultWidgets60700 } from './JSSThemeWidgets60700';
import { JSSThemeForm60700 } from './JSSThemeForm60700';
import { JSSThemeAuthForm60700 } from './JSSThemeAuthForm60700';
import { JSSThemeRegistration60700 } from './JSSThemeRegistration60700';
import { JSSThemeBalance60700 } from './JSSThemeBalance60700';
import { JSSThemeMyBets60700 } from './JSSThemeMyBets60700';
import { JSSThemeLive60700 } from './JSSThemeLive60700';
import { JSSThemeCasino60700 } from './JSSThemeCasino60700';
import { JSSThemeHeaderMobile60700 } from './JSSThemeHeaderMobile60700';
import { JSSThemeOutcomes60700 } from './JSSThemeOutcomes60700';
import { JSSThemeAccountSection60700 } from './JSSThemeAccountSection60700';
import { JSSThemePopularLeagues60700 } from './JSSThemePopularLeagues60700';
import { JSSThemeFooter60700 } from './JSSThemeFooter60700';
import { JSSThemeCookies60700 } from './JSSThemeCookies60700';
import { JSSThemeBonuses60700 } from './JSSThemeBonuses60700';
import { JSSThemeTooltip60700 } from './JSSThemeTooltip60700';
import { JSSThemeFooterAll60700 } from './JSSThemeFooterAll60700';
import { JSSThemePromotions60700 } from './JSSThemePromotions60700';
import { JSSThemeSitemap60700 } from './JSSThemeSitemap60700';
import { JSSThemeAffiliates60700 } from './JSSThemeAffiliates60700';
import { JSSThemeDropdownMenu60700 } from './JSSThemeDropdownMenu60700';
import { JSSThemeArticleBody60700 } from './JSSThemeArticleBody60700';

export const colorTypes60700: ColorTypes = {
  ...colorTypes,
  text2: '#73747d',
  text3: '#333',
  text4: '#000',
  text5: '#001c35',
  primary: '#CE1126',
  inform: '#f30100',
  error: '#f30100',
  accent: '#006B3F',
  background1: '#6d6d6d',
  background2: '#e2e2e2',
  background4: '#bbb',
  background5: '#989797',
  background6: '#ccc',
  background7: '#313131',
  neutral1: '#e7e7e7',
  neutral2: '#c1cad0',
  neutral3: '#FCD116',
  neutral4: '#7a7a7a',
};

export const colorCombinations60700 = getColorCombinations(colorTypes60700);

export const JSSThemeVariables60700: JSSThemeVariablesShapeType = {
  colors: {
    colorCombinations: colorCombinations60700,
    colorTypes: colorTypes60700,
  },
};

export const JSSTheme60700: JSSThemeShapeType = (themeVars) => {
  const {
    margins,
    fontSizes,
    colors: {
      colorTypes: {
        accent,
        white,
        background2,
        black,
        background5,
        text4,
        primary,
        neutral3,
      },
    },
  } = themeVars;
  return {
    banners: JSSThemeBannerItems60700(themeVars),
    header: JSSThemeHeader60700(themeVars),
    headerMenu: JSSThemeHeaderMenu60700(themeVars),
    betslip: JSSThemeBetslip60700(themeVars),
    sportsMenu: JSSThemeSportsMenu60700(themeVars),
    layout: JSSThemeLayout60700(themeVars),
    languageSwitcher: JSSThemeLanguageSwitcher60700(themeVars),
    search: JSSThemeSearch60700(themeVars),
    coupons: JSSThemeCoupons60700(themeVars),
    widgets: JSSThemeDefaultWidgets60700(themeVars),
    form: JSSThemeForm60700(themeVars),
    authForm: JSSThemeAuthForm60700(themeVars),
    registration: JSSThemeRegistration60700(themeVars),
    balance: JSSThemeBalance60700(themeVars),
    casino: JSSThemeCasino60700(themeVars),
    mybets: JSSThemeMyBets60700(themeVars),
    accountSection: JSSThemeAccountSection60700(themeVars),
    live: JSSThemeLive60700(themeVars),
    cookies: JSSThemeCookies60700(themeVars),
    popularLeagues: JSSThemePopularLeagues60700(themeVars),
    article: JSSThemeArticleBody60700(themeVars),
    Heading: {
      common: {
        paddingLeft: 0,
        fontSize: 30,
        color: black,
        '@media (pointer: coarse)': {
          padding: [margins.xs, margins.xm, 0],
        },
        '&.headingMobile': {
          color: black,
          // padding: [margins.xs, margins.xm, 0],
        },
        none: {
          paddingLeft: 0,
          paddingRight: 0,
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    NoEventsMessage: {
      root: {
        color: `${text4} !important`,
      },
    },
    DropDownUI: {
      title: {
        '&:hover': {
          opacity: 1,
        },
      },
    },
    PageNotFound: {
      pageNotFoundRoot: {
        background: background2,
      },
      title: {
        color: black,
        '@media (pointer: coarse)': {
          fontSize: fontSizes.xxxl,
        },
      },
      desciption: {
        color: black,
        '@media (pointer: coarse)': {
          fontSize: fontSizes.md,
        },
      },
    },
    Button: {
      block: {
        '@media (pointer: coarse)': {
          fontWeight: 'bold',
        },
      },
      large: {
        padding: [margins.md, margins.xl],
      },
      linkDefault: {
        color: black,
        '@media not all and (pointer: coarse)': {
          //applyOnlyDesktop
          '&:hover': {
            color: background5,
          },
        },
        '@media (pointer: coarse)': {
          color: white,
        },
      },
      primary: {
        '&:active, &:focus': {
          color: white,
        },
      },
      secondary: {
        color: black,
        background: neutral3,
        '&:hover': {
          color: white,
          background: primary,
        },
      },
    },
    Geoblock: {
      rootGeoblock: {
        background: white,
      },
      logoGeoblock: {
        background: black,
      },
      textGeoblock: {
        color: black,
      },
    },
    bonuses: JSSThemeBonuses60700(themeVars),
    tooltip: JSSThemeTooltip60700(themeVars),
    footer: JSSThemeFooter60700(themeVars),
    footerAll: JSSThemeFooterAll60700(themeVars),
    headerMobile: JSSThemeHeaderMobile60700(themeVars),
    outcomes: JSSThemeOutcomes60700(themeVars),
    promotions: JSSThemePromotions60700(themeVars),
    sitemap: JSSThemeSitemap60700(themeVars),
    affiliates: JSSThemeAffiliates60700(themeVars),
    dropdownMenu: JSSThemeDropdownMenu60700(themeVars),
    LiveNow: {
      title: {
        background: `transparent !important`,
        borderRadius: '0px !important',
        width: '100% ',
        margin: '0 !important',
        padding: '0 !important',
        color: `${black} !important`,
      },
    },
    BetslipMyBetsPanelTitle: {
      heading: {
        color: text4,
        background: white,
      },
    },
    OfflineMessage: {
      popup: {
        color: black,
      },
    },
    BetslipMyBetsPanelUI: {
      singleSelectionWrapper: {
        marginBottom: '5px',
        padding: 0,
      },
      panel: {
        background: 'white',
        padding: 10,
        margin: '10px 0 0',
        borderRadius: 0,
      },
    },
    NoBetsMessage: {
      root: {
        background: white,
        fontSize: fontSizes.sm,
      },
    },
    BetslipMybetsToggler: {
      root: { background: `${primary} !important` },
      badge: { color: `${primary} !important` },
      close: { background: `${primary} !important` },
    },
    LiveCalendar: {
      common: {
        margin: 0,
      },
      dateEvent: {
        color: black,
        background: white,
        '&:hover': {
          color: black,
          background: background2,
        },
      },
      link: {
        color: black,
      },
    },
    BetslipMyBetsStakePotentialTotalUI: {
      stakePotentialWrapper: {
        borderTop: `1px solid ${accent}`,
        paddingBottom: 3,
      },
    },
  };
};

export const getJSSThemePatterns60700: GetJSSThemePatternsType =
  getJSSThemePatterns;
