// @flow
const EVENT_TOPIC = 'event';
const MARKET_TOPIC = 'market';
const OUTCOME_TOPIC = 'outcome';
const CLOCK_TOPIC = 'clock';
const SCOREBOARD_TOPIC = 'scoreboard';
const SPORT_TOPIC = 'sport';
const MESSAGE_TOPIC = 'message';
const BET_TOPIC = 'bet';
const QUOTE_TOPIC = 'quote';

export const DIFFUSION_HANDLED_TOPICS = {
  EVENT_TOPIC,
  MARKET_TOPIC,
  OUTCOME_TOPIC,
  CLOCK_TOPIC,
  SCOREBOARD_TOPIC,
  SPORT_TOPIC,
  MESSAGE_TOPIC,
  BET_TOPIC,
  QUOTE_TOPIC,
};
