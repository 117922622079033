// @flow
import { compose, find, values } from 'ramda';
import patterns from './patterns';

const types = {
  sport: 'sport',
  clock: 'clock',
  event: 'event',
  message: 'message',
  market: 'market',
  markets: 'markets',
  outcome: 'outcome',
  scoreboard: 'scoreboard',
  bet: 'bet',
  quote: 'quote',
  unknown: 'unknown',
};

const typeFromPattern = (topic: string) => (pattern: string) =>
  patterns[pattern] ? patterns[pattern].test(topic) : false;

function resolve(topic: string) {
  const resolveType = compose(find(typeFromPattern(topic)), values);
  return resolveType(types) || types.unknown;
}

export default { resolve, ...types };
