// @flow

import { COMMA_THOUSANDS_SEPARATOR } from '../constants';

export const DEFAULT_DECIMALS = 2;

export const round = (number: number, length: number = 0) => {
  const powed10 = 10 ** length;
  return Math.round(number * powed10) / powed10;
};

export const defaultRound = (number: number) => round(number, DEFAULT_DECIMALS);

export const formatMoney = (
  amount: number,
  decimals: number = DEFAULT_DECIMALS
) => {
  const currentSeparator = __OSG_CONFIG__.thousandsSeparator;
  const roundedNumber = round(amount, decimals).toFixed(decimals);
  if (currentSeparator === COMMA_THOUSANDS_SEPARATOR) {
    return parseFloat(roundedNumber).toLocaleString('en-US');
  } else {
    return roundedNumber;
  }
};
