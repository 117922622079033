// @flow
import { type $DeepShape, JSSThemeVariables } from './JSSThemeDefault';
import { jssHelpers } from './jss-helpers';

export const JSSThemeDefaultSportsMenu = (
  themeVars: typeof JSSThemeVariables
) => {
  const {
    fontSizes,
    margins,
    colors: {
      colorCombinations: { whiteBg1, whitePrimary, blackWhite },
      colorTypes: { white, background1, text2, primary, shadow },
    },
    borderRadius,
  } = themeVars;
  return {
    SportsMenuDesktop: {
      main: { padding: [margins.lg, 0, 0, 0] },
      header: {
        margin: 0,
        padding: [margins.xs, margins.md],
        color: white,
        fontSize: fontSizes.xxl,
        fontWeight: 'bold',
      },
      sports: jssHelpers.ulReset,
      sport: {
        ...whiteBg1,
        padding: [margins.sm, margins.md],
        lineHeight: 1.2,
        display: 'flex',
        textDecoration: 'none',
        '&:hover, &:focus, &.active': whitePrimary,
      },
      sportTitle: {
        ...jssHelpers.ellipsis,
        flex: 1,
        marginLeft: margins.md,
      },
      regions: jssHelpers.ulReset,
      iconActive: {
        ...jssHelpers.fa,
        ...jssHelpers.faIconCaretDown,
      },
      region: {
        ...whiteBg1,
        display: 'flex',
        border: 'none',
        textDecoration: 'none',
        lineHeight: 1.2,
        padding: [margins.sm, margins.md, margins.sm, 34],
        '&:hover, &:focus, &.active': whitePrimary,
      },
    },
    SportsMenuMobile: {
      region: {
        display: 'block',
        padding: [margins.sm, margins.md],
        textDecoration: 'none',
        fontSize: fontSizes.md,
        ...whiteBg1,
        '&.active': {
          ...whitePrimary,
          '&:hover': whitePrimary,
        },
        '&:hover': whiteBg1,
      },
      regions: {
        ...jssHelpers.ulReset,
        ...whiteBg1,

        overflowX: 'scroll',
        '-webkit-overflow-scrolling': 'touch',
        whiteSpace: 'nowrap',
        '& > li': {
          display: 'inline-block',
        },
      },
    },
    HorizontalSportsListUISmallIcons: {
      list: {
        ...jssHelpers.ulReset,
        display: 'flex',
        overflowX: 'scroll',
        '-webkit-overflow-scrolling': 'touch',
        height: 100,
        paddingTop: margins.md,
      },
      item: {
        flexWrap: 'nowrap',
        height: 20,
        alignSelf: 'flex-start',
        padding: [0, margins.xs],
        cursor: 'pointer',
        color: text2,
        '&:first-child': {
          paddingLeft: 0,
        },
      },
      sportLink: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        height: '100%',
        padding: [margins.xs, margins.md, margins.xs, 0],
        transition: '0.2s ease-in',
        '&.active, &:hover': {
          color: primary,
          transition: '0.2s ease-in',
        },
      },
      arrow: {
        position: 'absolute',
        bottom: 0,
        top: 0,
        padding: '10px 0 0',
        cursor: 'pointer',
        '&:hover': {
          opacity: 1,
        },
      },
      leftArrow: {
        left: 0,
        background:
          'linear-gradient(90deg, rgba(37,37,37,1) 20%, rgba(253,229,0,0) 50%);',
        paddingRight: '60px',
      },
      rightArrow: {
        right: 0,
        background:
          'linear-gradient(270deg, rgba(37,37,37,1) 20%, rgba(253,229,0,0) 50%);',
        paddingLeft: '80px',
      },
    },
    HorizontalSportsListUIBigIcons: {
      list: {
        ...jssHelpers.ulReset,
        display: 'flex',
        overflowX: 'scroll',
        '-webkit-overflow-scrolling': 'touch',
        background: background1,
        padding: [0, margins.xs],
      },
      item: {
        flexWrap: 'nowrap',
      },
      sportLink: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        padding: [margins.xs, 0],
        height: '100%',
        flexDirection: 'column',
        width: 65,
        color: white,
      },
    },
    HorizontalLiveCasinoIcon: {
      iconWrap: {
        height: ' 100%',
        padding: margins.md,
        display: 'flex',
        flexDirection: 'column',
      },
      icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 26,
        position: 'relative',
        color: primary,
        width: 50,
        height: 50,
        borderRadius: ' 100%',
        background: text2,
        marginBottom: margins.xs,
      },
      title: {
        textAlign: 'center',
        maxWidth: '100%',
        lineHeight: `${fontSizes.sm}px`,
        marginBottom: margins.xs,
        fontSize: fontSizes.xs,
        color: text2,
      },
    },
    HorizontalSportsListIconBig: {
      sportsListWrap: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      sportIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: [margins.md, margins.md, margins.xs],
        fontSize: 50,
        position: 'relative',
        // width: '50px',
        margin: '0 auto',
        color: text2,
        '&.active': {
          color: primary,
        },
      },
      specialSportIcon: {
        lineHeight: 1,
        fontSize: fontSizes.xs,
        position: 'absolute',
        left: '4px',
        top: '4px',
      },
      sportName: {
        textAlign: 'center',
        maxWidth: '100%',
        lineHeight: `${fontSizes.sm}px`,
        marginBottom: margins.xs,
        fontSize: fontSizes.xs,
        '&.active': {
          color: primary,
        },
      },
      sportCountContainer: {
        position: 'relative',
      },
      sportCount: {
        ...blackWhite,
        padding: [0, margins.xs],
        borderRadius: borderRadius.md,
        position: 'absolute',
        top: -3,
        right: 0,
        zIndex: 1,
      },
    },
    HorizontalSportsListIconSmall: {
      sportIcon: {
        fontSize: fontSizes.sm,
      },
      sportName: {
        whiteSpace: 'nowrap',
        fontSize: fontSizes.sm,
        marginLeft: margins.xs,
      },
    },
    HorizontalSportsTabs: {
      root: {
        lineHeight: 1, // disable scss styles .osg-coupon line-height: 1.3;
      },
      rootBigIcons: {
        background: background1,
      },
      rootSmallIcons: {
        height: 40,
        overflowY: 'hidden',
        margin: [0, margins.lg],
      },
    },
    SubMenuListUIMobile: {
      heading: {
        fontSize: fontSizes.md,
        padding: [margins.md, margins.xm],
        opacity: 0.5,
      },
      block: {
        display: 'block', //  fix for ios
      },
      icon: {
        marginRight: margins.md,
      },
      link: {
        padding: [margins.xs, margins.xm],
        display: 'flex',
        alignItems: 'center',
        color: white,
        '&:hover,&:focus': {
          color: white,
        },
        '&.active': {
          background: shadow,
        },
      },
      linkDescription: {
        fontSize: fontSizes.xxl,
        ...jssHelpers.ellipsis,
      },
      linkCount: {
        marginLeft: 'auto',
      },
    },
  };
};

export type JSSThemeDefaultSportsMenuType = (
  typeof JSSThemeVariables
) => $DeepShape<
  $Call<typeof JSSThemeDefaultSportsMenu, typeof JSSThemeVariables>
>;
