// @flow
import * as React from 'react';
import cx from 'classnames';
import { useOnClickOutside, useBoolean } from '../hooks';
import { useJSSStyles } from '../jss-hooks';
import { IconChevronRight } from './Icons';

const JSSPath = theme => theme.components.DropDownUI;

export const DropdownUI = ({
  title,
  children,
  pathname,
  dropdown_id,
}: {
  title: React.Node,
  children: React.Node,
  pathname?: string,
  dropdown_id?: string,
}) => {
  const classes = useJSSStyles(JSSPath);
  const refClickOut = React.useRef();
  const { value, setFalse, setToggle } = useBoolean(false);

  const [prevPathname, setPrevPathname] = React.useState(pathname);

  if (prevPathname !== pathname) {
    setPrevPathname(pathname);
    setFalse();
  }

  useOnClickOutside(refClickOut, setFalse);
  return (
    <div className={classes.dropDownWrapper} ref={refClickOut} id={dropdown_id}>
      <div className={classes.title} onClick={setToggle}>
        {title}

        <div className={cx(classes.carretDownIcon, value && 'opened')}>
          <IconChevronRight />
        </div>
      </div>

      <div className={cx(classes.content, value && 'opened')}>{children}</div>
    </div>
  );
};
