// @flow
import type { ReduxAction, BalanceType } from '../../types';
import { KOLTRON_UNAUTHORIZE, BALANCE_UPDATE } from '../actions/action-types';

const defaultState = {
  error: null,
  available_restricted: 0,
  available_casino_bonus: 0,
  available_sportsbook_bonus: 0,
  available_sportsbook_balance: 0,
  available_casino_balance: 0,
  available_cash: 0,
  balanceFetched: false,
};

const reducer = (
  state: BalanceType = defaultState,
  action: ReduxAction
): BalanceType => {
  switch (action.type) {
    case BALANCE_UPDATE: {
      return action.payload
        ? {
            ...state,
            ...action.payload,
            balanceFetched: true,
          }
        : state;
    }
    case KOLTRON_UNAUTHORIZE: {
      return defaultState;
    }
    default:
      return state;
  }
};

export default reducer;
