// @flow
import * as React from 'react';
import type {
  ReduxStore,
  ReduxState,
  LiveEventType,
  LiveMarketType,
} from './types';
import {
  getAuth,
  getIsAuthorised,
  getUsername,
  getUserCurrency,
} from './koltron-redux/selectors/auth';
import { getUserBalanceSelector } from './koltron-redux/selectors/balance';
import { DESKTOP } from '../common/constants';
import { BETSLIP_TOGGLE_SELECTION } from './koltron-redux/actions/betslip';
import { useDiffusionClient } from './diffusion/use-diffusion-client';
import { getCurrentTime } from './koltron-redux/selectors/tick-timer-selectors';
import {
  getSeoDescription,
  getSeoHeading,
  getSeoTextData,
  getSeoTitle,
  getSeoOgTitle,
  getSeoOgImage,
  getSeoOgDescription,
  getSeoPostIDs,
  getSeoAlias,
} from './koltron-redux/selectors/seo';
import { getSeoIsHeader } from './koltron-redux/selectors/seoUtils';
import { getDevTools } from './koltron-redux/selectors/devtools';

// $FlowFixMe
export const ReduxContext = React.createContext<ReduxStore>(null);

export const useReduxStore = () => {
  const store = React.useContext(ReduxContext);
  return store;
};

export const useUserInfo = () => {
  return useReduxState(getAuth);
};

export const useBalance = () => {
  return useReduxState(getUserBalanceSelector);
};

export const useUsername = () => {
  return useReduxState(getUsername);
};

export const useIsAuthorised = () => {
  return useReduxState(getIsAuthorised);
};

export const useIsDesktop = () => {
  return window.__OSG_RUNTIME_CONFIG__.userDevice === DESKTOP;
};

export const useIsMobile = () => {
  return !useIsDesktop();
};

export const useCurrentTime = () => {
  return useReduxState(getCurrentTime);
};

const isNavigatorSupported = typeof window.navigator !== 'undefined';
export const useIsOffline = () => {
  if (!isNavigatorSupported) return false;
  return !window.navigator.onLine;
};

function memoize<AT, RT>(fn: (arg: AT) => RT): (arg: AT) => RT {
  let value: RT;
  let prevArg: AT;

  return (arg: AT) => {
    if (prevArg !== arg) {
      prevArg = arg;
      value = fn(arg);
    }
    return value;
  };
}

export function useReduxState<MS>(selector: (ReduxState) => MS): MS {
  const store = useReduxStore();
  const memoizedMapState = React.useMemo(() => memoize(selector), [selector]);
  const state = store.getState();
  const derivedState = memoizedMapState(state);
  const [, forceUpdate] = React.useReducer((x) => x + 1, 0);
  const lastStateRef = React.useRef(derivedState);
  const memoizedMapStateRef = React.useRef(memoizedMapState);

  React.useEffect(() => {
    lastStateRef.current = derivedState;

    if (
      !__PROD__ &&
      memoizedMapStateRef.current &&
      memoizedMapStateRef.current !== memoizedMapState
    ) {
      // eslint-disable-next-line
      console.warn(
        'Avoid changing mapState (selector) to redux as it is very likely to cause a problem with stale props'
      );
    }

    memoizedMapStateRef.current = memoizedMapState;
  });

  React.useEffect(() => {
    let didUnsubscribe = false;
    const checkForUpdates = () => {
      if (didUnsubscribe) {
        return;
      }
      const newDerivedState =
        ((memoizedMapStateRef.current: any): $NonMaybeType<
          typeof memoizedMapStateRef.current
        >)(store.getState());
      if (newDerivedState !== lastStateRef.current) {
        forceUpdate();
      }
    };
    checkForUpdates();
    const unsubscribe = store.subscribe(checkForUpdates);
    return () => {
      didUnsubscribe = true;
      unsubscribe();
    };
  }, [store]);

  return derivedState;
}

export const useReduxDispatch = () => {
  const store = useReduxStore();
  return store.dispatch;
};

const WidgetNameContext = React.createContext<string>('root');
export const WidgetNameProvider = WidgetNameContext.Provider;

export const useToggleBetslipSelection = () => {
  const dispatch = useReduxDispatch();
  const widgetName = React.useContext(WidgetNameContext);
  const isMobile = useIsMobile();

  const toggleSelection = React.useCallback(
    (outcomeId: string) => {
      const meta = {
        pathname: window.location.pathname,
        date: Date.now(),
        source: widgetName,
        userAgent: window.navigator.userAgent,
        platform: window.navigator.platform,
        isMobile,
      };

      dispatch({
        type: BETSLIP_TOGGLE_SELECTION,
        payload: {
          id: outcomeId,
          meta,
        },
      });
    },
    [dispatch, widgetName, isMobile]
  );

  return toggleSelection;
};

export const usePrimaryMarketSubscribe = (
  event: LiveEventType,
  primaryMarket: LiveMarketType | void
) => {
  const diffusionClient = useDiffusionClient();
  const oldPrimaryMarketId = React.useRef(event.primaryMarketId);

  React.useEffect(() => {
    if (!event) return;
    if (
      (event.primaryMarketId && !primaryMarket) ||
      event.primaryMarketId !== oldPrimaryMarketId.current
    ) {
      diffusionClient.subscribeToPrimaryMarket(event, event.primaryMarketId);
      oldPrimaryMarketId.current = event.primaryMarketId;
    }
  }, [event, primaryMarket, diffusionClient]);
};

export const useSeoTitle = () => {
  return useReduxState(getSeoTitle);
};

export const useSeoDescription = () => {
  return useReduxState(getSeoDescription);
};

export const useSeoHeading = () => {
  return useReduxState(getSeoHeading);
};

export const useSeoTextData = () => {
  return useReduxState(getSeoTextData);
};

export const useSeoOgTitle = () => {
  return useReduxState(getSeoOgTitle);
};

export const useSeoOgImage = () => {
  return useReduxState(getSeoOgImage);
};

export const useSeoOgDescription = () => {
  return useReduxState(getSeoOgDescription);
};

export const useSeoPostIDs = () => {
  return useReduxState(getSeoPostIDs);
};

export const useSeoAlias = () => {
  return useReduxState(getSeoAlias);
};

export const useSeoHeaderLoaded = () => {
  return useReduxState(getSeoIsHeader);
};

export const useGetDevTools = () => {
  return useReduxState(getDevTools);
};

export const useUserCurrency = () => {
  return (
    useReduxState(getUserCurrency) || window.__OSG_RUNTIME_CONFIG__.currencyCode
  );
};
