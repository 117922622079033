// @flow
import { empty, merge, of, timer } from 'rxjs';
import { catchError, filter, map, mapTo, switchMap } from 'rxjs/operators';
import { path } from 'ramda';
import { type ActionsObservable, type StateObservable } from 'redux-observable';
import { formatMoney } from '../../koltron-utils/format-and-round';
import {
  BETSLIP_SET_ERROR,
  BETSLIP_SET_INITIAL_SELECTIONS,
  BETSLIP_SET_RECEIPT,
  BETSLIP_SET_SELECTIONS,
  BETSLIP_START_PLACE_BET,
  BETSLIP_START_PLACE_BET_WITH_ACCEPT_CHANGES,
  BETSLIP_TOGGLE_SELECTION,
} from '../actions/betslip';
import { axiosObservable } from './axiosObservable';
import {
  API_BETSLIP,
  BETSLIP_CONNECTION_ERROR,
} from '../../../common/constants';
import {
  getBetPlacementData,
  getBetPlacementDataWithBonus,
  getBetslipOutcomeIds,
  getBetslipOutcomeIdsEmpty,
} from '../selectors/betslip';
import {
  betslipWithBonusMapSelections,
  handlePlaceBet,
  mapSelections,
  pushBetsToDataLayer,
} from '../selectors/betslip-utils';
import { getAccountId, getUserCurrency, getUserToken } from '../selectors/auth';
import { transformPhrase } from '../../transformPhrase';
import { type ReduxAction, type ReduxState } from '../../types';
import { useIsMobile } from '../../redux-hooks';
import { useIsPhoenix } from '../../hooks';
import { getAcceptAllFlag } from '../../utils';

export const betslipSetInitialSelectionsEpic = (
  action$: ActionsObservable<ReduxAction>
) =>
  action$.ofType(BETSLIP_SET_INITIAL_SELECTIONS).pipe(
    /** Sometimes empty list of selections can come from url parser, so filtering is needed */
    filter((action) => {
      return action.payload.selections && action.payload.selections?.length > 0;
    }),
    /** Making a request to /selections and sending mapped response data to BETSLIP_SET_SELECTIONS reducer */
    switchMap((action) => {
      const { selections: initialSelections } = action.payload;
      if (!Array.isArray(initialSelections) || initialSelections.length === 0)
        return empty();
      const url = `${API_BETSLIP}/betslip/${initialSelections.join(
        ','
      )}/selections`;

      return axiosObservable({
        method: 'get',
        url,
      }).pipe(
        map((response) => {
          if (useIsPhoenix() && response.data.errors.length !== 0) {
            return {
              type: BETSLIP_SET_SELECTIONS,
              payload: {
                selections: __OSG_CONFIG__.useBetslipWithBonus
                  ? betslipWithBonusMapSelections(response.data)
                  : mapSelections(response.data),
                manual: true,
                error: response.data.errors[0].message,
                initial: true,
              },
            };
          } else {
            return {
              type: BETSLIP_SET_SELECTIONS,
              payload: {
                selections: __OSG_CONFIG__.useBetslipWithBonus
                  ? betslipWithBonusMapSelections(response.data)
                  : mapSelections(response.data),
                manual: true,
                initial: true,
              },
            };
          }
        }),
        catchError((error) =>
          of({
            type: BETSLIP_SET_ERROR,
            payload: { errors: [BETSLIP_CONNECTION_ERROR] },
          })
        )
      );
    })
  );

export const betslipToggleSelectionEpic = (
  action$: ActionsObservable<ReduxAction>,
  state$: StateObservable<ReduxState>
) =>
  merge(
    action$.ofType(BETSLIP_TOGGLE_SELECTION),
    // $FlowFixMe
    action$.ofType(BETSLIP_SET_SELECTIONS),
    action$.ofType(BETSLIP_SET_ERROR),
    of({
      type: 'betslip:start:polling',
    })
  ).pipe(
    switchMap((action) =>
      action.type === BETSLIP_SET_SELECTIONS ||
      (action.type === BETSLIP_SET_ERROR &&
        action.payload.errors[0] === BETSLIP_CONNECTION_ERROR)
        ? timer(5000).pipe(mapTo(true))
        : of(true)
    ),
    filter(() => !getBetslipOutcomeIdsEmpty(state$.value)),
    switchMap((manual) => {
      const outcomeIds = getBetslipOutcomeIds(state$.value);
      const lastOutcomeId = outcomeIds.slice(-1).pop();
      const isMobile = useIsMobile();
      const meta = {
        pathname: window.location.pathname,
        date: Date.now(),
        source: 'root',
        userAgent: window.navigator.userAgent,
        platform: window.navigator.platform,
        isMobile,
      };
      return axiosObservable({
        method: 'get',
        url: `${API_BETSLIP}/betslip/${outcomeIds.join(',')}/selections`,
      }).pipe(
        map((response) => {
          if (
            __OSG_CONFIG__.useBetslipWithBonus &&
            !response.data.multiple &&
            response.data.singles.length > 1
          ) {
            return {
              type: BETSLIP_TOGGLE_SELECTION,
              payload: {
                id: lastOutcomeId,
                errors: [_t('bad-bet-placement')],
                meta,
              },
            };
          } else if (
            useIsPhoenix() &&
            response.data.singles.length === 0 &&
            response.data.errors.length !== 0
          ) {
            return {
              type: BETSLIP_TOGGLE_SELECTION,
              payload: {
                id: lastOutcomeId,
                error: response.data.errors[0].message,
                meta,
              },
            };
          } else if (useIsPhoenix() && response.data.errors.length !== 0) {
            return {
              type: BETSLIP_SET_SELECTIONS,
              payload: {
                selections: __OSG_CONFIG__.useBetslipWithBonus
                  ? betslipWithBonusMapSelections(response.data)
                  : mapSelections(response.data),
                manual,
                error: response.data.errors[0].message,
              },
            };
          } else {
            return {
              type: BETSLIP_SET_SELECTIONS,
              payload: {
                selections: __OSG_CONFIG__.useBetslipWithBonus
                  ? betslipWithBonusMapSelections(response.data)
                  : mapSelections(response.data),
                manual,
              },
            };
          }
        }),
        catchError((error) =>
          of({
            type: BETSLIP_SET_ERROR,
            payload: { errors: [BETSLIP_CONNECTION_ERROR] },
          })
        )
      );
    })
  );

export const betslipPlaceBetEpic = (
  action$: ActionsObservable<ReduxAction>,
  state$: StateObservable<ReduxState>
) =>
  merge(
    action$.ofType(BETSLIP_START_PLACE_BET),
    action$.ofType(BETSLIP_START_PLACE_BET_WITH_ACCEPT_CHANGES)
  ).pipe(
    switchMap(() => {
      let data = __OSG_CONFIG__.useBetslipWithBonus
        ? getBetPlacementDataWithBonus(state$.value)
        : getBetPlacementData(state$.value);
      const token = getUserToken(state$.value);
      const isMobile = useIsMobile();

      const accountId = getAccountId(state$.value);

      const acceptAllChangesFlags = getAcceptAllFlag();
      data['acceptChangedPrice'] =
        accountId && accountId in acceptAllChangesFlags
          ? acceptAllChangesFlags[accountId]
          : false;

      return axiosObservable({
        method: 'post',
        data,
        url: `${API_BETSLIP}/betslip/`,
        headers: {
          'X-Osg-Auth-Token': token,
          'X-Osg-Mobile': isMobile,
        },
      }).pipe(
        map((response) => {
          pushBetsToDataLayer(state$.value, response.data);
          return {
            type: BETSLIP_SET_RECEIPT,
            payload: handlePlaceBet(response.data),
            acceptAllChanges: false,
          };
        }),
        catchError((err) => {
          const responseData = path(['response', 'data'], err);
          pushBetsToDataLayer(state$.value);

          const ERROR_CODE_PRICES_ARE_CHANGED = 204;
          const ERROR_CODE_MONEY_LIMIT = 205;
          const ERROR_CODE_MINIMAL_STAKE = 7;
          const ERROR_CODE_MAXIMAL_STAKE = 2;

          let payload;

          if (responseData && Array.isArray(responseData.errors)) {
            const actionErrors: Array<string> = responseData.errors.reduce(
              (errors, error) => {
                let errMesage;

                switch (error.code) {
                  case ERROR_CODE_PRICES_ARE_CHANGED:
                    errMesage = null;
                    break;
                  case ERROR_CODE_MONEY_LIMIT:
                    errMesage = _t('unknown-error');
                    break;
                  case ERROR_CODE_MAXIMAL_STAKE:
                    errMesage = _t('maximal-stake-error');
                    break;
                  case ERROR_CODE_MINIMAL_STAKE:
                    {
                      const left =
                        window.__OSG_RUNTIME_CONFIG__.currencyPosition === 'l';
                      const currency = getUserCurrency(state$.value);

                      const monetize = (amount) => {
                        const arr = [formatMoney(amount), currency];

                        if (left) {
                          arr.reverse();
                        }

                        return arr.join(' ');
                      };

                      errMesage = transformPhrase(_t('minimal-stake-error'), {
                        stake: monetize(error.stake),
                        minimalStake: monetize(error.minimalStake),
                      });
                    }
                    break;
                  default:
                    errMesage = error.message || _t('unknown-error');
                }
                if (errMesage) {
                  errors.push(errMesage);
                }

                return errors;
              },
              []
            );

            payload = {
              errors: actionErrors,
              selections: responseData.selections
                ? mapSelections(responseData.selections)
                : null,
            };
          } else {
            payload = {
              errors: [_t('unknown-error')],
              selections: null,
              acceptAllChanges: false,
            };
          }

          return of({
            type: BETSLIP_SET_ERROR,
            acceptAllChanges: false,
            payload,
          });
        })
      );
    })
  );
