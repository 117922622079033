// @flow
import { parseJSONSafely } from './utils';
import type { Message } from '../types';

const keys = {
  '1': 'id',
  '2': 'status',
  '3': 'priceId',
  '4': 'price',
  '5': 'spread',
  '6': 'description',
  '7': 'prevOdds',
  '8': 'lastRaces',
};

const getKey = (message: Message) => message.fields[0];

const handlers = {
  description: parseJSONSafely,
};

export const betHandlerSettings = { keys, getKey, handlers };
