// @flow
import { type $DeepShape, type JSSThemeVariablesType } from './JSSThemeDefault';

export const JSSThemeDefaultAccountSection = (
  themeVars: JSSThemeVariablesType
) => {
  const {
    fontSizes,
    margins,
    colors: {
      colorTypes: { white, primary, accent, error, text1, black },
    },
  } = themeVars;
  return {
    BetsHistory: {
      noBets: {},
      BetsHistoryRoot: {
        padding: [margins.md, margins.xm],
      },
      BetsHistoryHeading: {
        fontSize: fontSizes.xxl,
        margin: [margins.md, 0, margins.lg],
        fontWeight: 'normal',
      },
      BetsHistoryContainer: {},
      filtersRoot: {
        fontSize: fontSizes.lg,
        margin: [margins.md, 0, margins.lg],
      },
      filtersTitle: {
        fontSize: fontSizes.lg,
      },
      filterSelection: {
        padding: 0,
        marginTop: margins.md,
        position: 'relative',
        display: 'flex',
        flexWrap: 'wrap',
      },
      filterSelectionItem: {
        background: primary,
        color: white,
        border: 'none',
        padding: [margins.md, margins.xm],
        display: 'flex',
        borderRadius: 3,
        fontSize: fontSizes.md,
        textDecoration: 'none',
        margin: [margins.xs, margins.md, 0, 0],
        fontWeight: 'normal',
        transition: '.2s ease-in',
        cursor: 'pointer',
        '&:hover': {
          background: accent,
        },
        '@media (pointer: coarse)': {
          marginRight: margins.xs,
        },
      },
      filterSelectionItemActive: {
        background: accent,
      },
      filterSelectionItemRadio: {
        margin: ['1px', margins.xs, 0, 0],
      },
      iconCalendar: {
        marginRight: margins.sm,
      },
    },
    TogglePage: {
      root: {
        padding: margins.md,
      },
      text: {
        display: 'block',
        padding: [0, 0, margins.md],
      },
    },
    OPMProvider: {
      providerRoot: {
        background: white,
        minHeight: 75,
        margin: [0, 0, margins.md, margins.md],
        padding: margins.md,
        border: '1px solid #f3f3f3',
        boxShadow: '3px 3px 15px #e4e4e4',
        width: 170,
        borderRadius: '2px',
        display: 'flex',
        flexDirection: 'column',
      },
      '@media (max-width: 370px)': {
        providerRoot: {
          margin: [0, 0, margins.sm, margins.sm],
          width: 'calc(50% - 10px)',
        },
      },
      disabledProviderRoot: {
        opacity: 0.3,
      },
      infoIcon: {
        fontSize: fontSizes.lg,
        verticalAlign: 'super',
      },
      logoImg: {
        margin: 'auto',
        maxWidth: 150,
        maxHeight: 70,
        width: '100%',
      },
      data: {
        fontWeight: 'bold',
      },
      dataHeader: {
        margin: [margins.sm, 0],
      },
      dataProcessingTime: {
        margin: 0,
        fontSize: fontSizes.xs,
        lineHeight: '12px',
      },
      limits: {
        flex: 1,
        padding: [0, 0, margins.sm],
        listStyleType: 'none',
        fontSize: fontSizes.xs,
      },
      info: {},
      heading: {},
    },
    OPMForms: {
      formHeader: {
        display: 'flex',
        padding: [margins.md, 0],
      },
      logo: {
        width: '25%',
        marginRight: margins.xl,
      },
      logoImg: {
        maxWidth: '100%',
      },
      aggregatorLogo: {
        width: '25%',
      },
      form: {
        padding: margins.md,
      },
      limits: {
        fontSize: fontSizes.sm,
        listStyleType: 'none',
        flex: 1,
      },
      backButton: {
        marginBottom: margins.lg,
      },
      centerButton: {
        margin: margins.lg,
      },
      backButtonWraper: {},
      submitButton: {
        margin: [margins.lg, 0],
      },
      submitButtonRedirect: {},
      aggregatorForm: {
        marginBottom: margins.lg,
      },
      submitError: {
        marginTop: margins.lg,
      },
      fieldWrapper: {
        maxWidth: '100%',
      },
      description: {
        '& a': {
          fontWeight: 'bold',
        },
      },
      link: {
        color: 'inherit',
        textDecoration: 'underline',
      },
      qrCode: {
        maxWidth: '400px !important',
      },
      OPMIframe: {
        maxWidth: 500,
        width: '100%',
        height: 600,
        background: '#fff',
        display: 'block',
        margin: '0 auto',
        borderRadius: '3px',
      },
    },
    OPMPageComponent: {
      OPMroot: {
        margin: 0,
      },
      OPMPageNotice: {
        padding: 10,
      },
      OPMHeadingWrap: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 10,
        flexWrap: 'wrap',
      },
      root: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      heading: {
        flexBasis: '100%',
        display: 'flex',
        textAlign: 'left',
        margin: [10, 0],
      },
    },
    PendingWithdrawal: {
      heading: {},
      wrapper: {
        margin: 0,
      },
    },
    AccountPageDesktop: {
      accountPageRoot: {
        display: 'flex',
        flex: 1,
      },
      accountPageLeftContent: {
        width: 240,
      },
      accountPageRightContent: {
        flex: 1,
        padding: margins.lg,
      },
    },
    BannerUnderAccountMenu: {
      bannerUnderAccountMenuRoot: {
        marginTop: margins.md,
      },
      bannerUnderAccountMenuImg: {
        display: 'block',
      },
    },
    ResponsibleGaming: {
      depositInputsBlock: {
        paddingTop: 16,
        position: 'relative',
        display: 'flex',
        flexWrap: 'wrap',
      },
      depositInputsForm: {
        width: '100%',
      },
      depositFormHeaderRaw: {
        padding: '0',
        position: 'relative',
        display: 'flex',
        flexWrap: 'wrap',
        paddingLeft: 65,
        '@media (pointer: coarse)': {
          justifyContent: 'center',
          paddingLeft: 70,
        },
      },
      depositFormLeftColumn: {
        minWidth: '60px',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        marginRight: '5px',
      },
      depositFormRow: {
        padding: '0',
        position: 'relative',
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '15px',
        '@media (pointer: coarse)': {
          justifyContent: 'center',
        },
      },
      depositFormColumn: {
        width: '122px',
        minWidth: '100px',
        display: 'block',
        position: 'relative',
        marginRight: 11,
        '@media (pointer: coarse)': {
          width: 'calc(50% - 45px)',
        },
        '&:last-child': {
          marginRight: 0,
        },
      },
      depositFormColumnHeader: {
        width: '122px',
        minWidth: '100px',
        display: 'block',
        position: 'relative',
        marginRight: 11,
        paddingLeft: 10,
        '@media (pointer: coarse)': {
          width: 'calc(50% - 6px)',
        },
        '&:last-child': {
          marginRight: 0,
        },
      },
      changeLimitsButtonDiv: {
        width: 'auto',
        minWidth: '100px',
        maxWidth: '255px',
        padding: '0',
        position: 'relative',
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '32px',
        marginLeft: '65px',
        marginBottom: '56px',
        '@media (pointer: coarse)': {
          marginLeft: '70px',
          marginRight: '5px',
          maxWidth: 'none',
        },
      },
      changeLimitsButton: {
        width: '100% !important',
        minWidth: '120px',
      },
      limitsLabel: {
        fontWeight: 'bold',
      },
      pendingLimitApplyDate: {
        minWidth: '250px',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 10,
        position: 'relative',
        '@media (pointer: coarse)': {
          paddingLeft: 2,
          paddingTop: 6,
          width: '100%',
          minWidth: '202px',
        },
      },
      limitApplyError: {
        minWidth: '250px',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 10,
        position: 'relative',
        '@media (pointer: coarse)': {
          paddingLeft: 18,
          width: 'calc(100% - 78px)',
          minWidth: '202px',
        },
        color: error,
      },
    },
    InfoMessage: {
      messageComponent: {
        textAlign: 'left',
        backgroundColor: '#F8F8F8',
        alignItems: 'center',
        borderRadius: 4,
        display: 'flex',
        color: text1,
        padding: [10, 10, 10, 16],
        margin: 10,
        boxShadow: '0px 4px 20px 3px #00000040',
        justifyContent: 'space-between',
        width: 'auto',
        minWidth: 450,
        maxWidth: 900,
        '@media (pointer: coarse)': {
          maxWidth: '100%',
          minWidth: 0,
          padding: 10,
          flexDirection: 'column',
          alignItems: 'flex-start',
        },
      },
      messageError: {
        border: '2px solid ' + error,
      },
      infoMessage1: {
        whiteSpace: 'normal',
        margin: [0, 40, 0, 0],
        fontSize: 14,
        fontWeight: 'bold',
        '@media (pointer: coarse)': {
          margin: [0, 40, 10, 0],
        },
        color: black,
      },
      infoMessage2: {
        whiteSpace: 'normal',
        margin: [0, 40, 0, 0],
        fontSize: 14,
        fontWeight: 'normal',
        '& > a': {
          color: primary,
        },
        '@media (pointer: coarse)': {
          margin: [0, 40, 20, 0],
        },
        color: black,
      },
      infoMessageProgress: {
        color: black,
        fontWeight: 'bold',
      },
      infoMessageError: {
        color: error,
      },
      matiButton: {
        opacity: 1,
        pointerEvents: 'pointer',
      },
      matiButtonDisabled: {
        opacity: 0.5,
        pointerEvents: 'none',
      },
      personalFormButton: {
        marginLeft: 'auto',
        padding: [0, 40],
        background: primary,
        color: black,
        height: 36,
        transition: 'none',
        '&:hover, &:focus': {
          opacity: 0.7,
          background: primary,
          color: black,
        },
      },
      nameField: {
        '&:not(:last-child):after': {
          content: '", "',
        },
        '&:last-child:after': {
          content: '". "',
        },
      },
    },
    SelfExclusion: {
      deactivateAccountHeader: {
        fontSize: 28,
        margin: [20, 0, 10],
      },
      deactivateAccountDiv: {
        width: 'auto',
        padding: '0',
        position: 'relative',
        display: 'flex',
        flexWrap: 'wrap',
        margin: [30, 0],
        alignItems: 'flex-start',
      },
      deactivateAccountText: {
        position: 'relative',
        marginRight: 14,
        paddingBottom: 10,
      },
      deactivateAccountHint: {
        fontSize: 12,
        maxWidth: 150,
      },
      exclusionButtonWrap: {
        display: 'flex',
      },
      deactivateAccountDropdown: {
        width: 'auto',
        minWidth: '120px',
        maxWidth: '170px',
        margin: [0, 14],
        '@media (pointer: coarse)': {
          width: '100%',
          margin: [0, 14, 0, 0],
        },
      },
      deactivateAccountButton: {
        minWidth: '120px',
        maxWidth: '170px',
        background: primary,
        '&:hover, &:focus': {
          background: accent,
          color: white,
        },
        '@media (pointer: coarse)': {
          width: '100% !important',
        },
      },
    },
  };
};

export type JSSThemeDefaultAccountSectionType =
  (JSSThemeVariablesType) => $DeepShape<
    $Call<typeof JSSThemeDefaultAccountSection, JSSThemeVariablesType>
  >;
