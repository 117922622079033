// @flow
import * as React from 'react';
import { useLocation } from 'react-router';
import cx from 'classnames';

import PostDebouncer from './PostDebouncer';
import { type JSSThemeType } from '../origins/JSSThemeDefault';
import { useJSSStyles } from '../jss-hooks';
import {
  useSeoTextData,
  useSeoHeading,
  useSeoHeaderLoaded,
  useReduxDispatch,
} from '../redux-hooks';
import { SEO_BLOCK_UNMOUNT } from '../koltron-redux/actions/action-types';
// $FlowIgnore
import './SEOAccordion.scss';

const pathArticleBodyDefault = (theme: JSSThemeType) =>
  theme.components.article.articelBody;

const prepareOldDataForMarkdown = (data) => {
  if (typeof data === 'string') return data;

  return data.reduce((acc, item) => {
    const { data } = item;
    if (item.type === 'header') {
      acc += `${'#'.repeat(data.level)} ${data.text}  \n\n`;
    } else {
      if (data.text === '<b>Insert SEO text here...</b>') {
        acc += '**Insert SEO text here...**';
      } else {
        acc += data.text + '  \n\n';
      }
    }
    return acc;
  }, '');
};

export const SEODescriptionOnPages = () => {
  const seoTextData = useSeoTextData();
  const seoH1 = useSeoHeading();
  const isHeaderLoaded = useSeoHeaderLoaded();
  const { pathname } = useLocation();

  const dispatch = useReduxDispatch();

  const [isH1onPage, setIsH1onPage] = React.useState(true);

  const classes = useJSSStyles(pathArticleBodyDefault);

  React.useEffect(() => {
    const isHeader = !!document.querySelector('h1');
    /** Finds <h1> on page if <h1> is inside synchronic component */
    if (!isHeader && !isHeaderLoaded) {
      setIsH1onPage(false);
      /** Subscribed on async components witch has <h1> on page */
    } else if (isHeaderLoaded || isHeader) {
      setIsH1onPage(true);
    }
    return () => {
      dispatch({
        type: SEO_BLOCK_UNMOUNT,
        payload: {
          isHeader: false,
        },
      });
    };
  }, [isHeaderLoaded, pathname, dispatch]);

  return (
    <article
      itemProp="articleBody"
      className={cx(classes.articelWrap, 'seo-text')}
    >
      {seoH1 && !isH1onPage && <h1>{seoH1}</h1>}
      <PostDebouncer text={prepareOldDataForMarkdown(seoTextData || [])} />
    </article>
  );
};
