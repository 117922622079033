// @flow
import { type JSSThemeVariablesType, type $DeepShape } from './JSSThemeDefault';
import { jssHelpers } from './jss-helpers';
import {
  BACKGROUND_IMAGE_LIVE_BANNER_DEFAULT,
  BACKGROUND_IMAGE_LIVE_BANNER_BADMINTON,
  BACKGROUND_IMAGE_LIVE_BANNER_BASEBALL,
  BACKGROUND_IMAGE_LIVE_BANNER_BASKETBALL,
  BACKGROUND_IMAGE_LIVE_BANNER_BEACHSOCCER,
  BACKGROUND_IMAGE_LIVE_BANNER_BEACHVOLLEYBALL,
  BACKGROUND_IMAGE_LIVE_BANNER_BOWLS,
  BACKGROUND_IMAGE_LIVE_BANNER_DARTS,
  BACKGROUND_IMAGE_LIVE_BANNER_FIELDHOCKEY,
  BACKGROUND_IMAGE_LIVE_BANNER_FOOTBALL,
  BACKGROUND_IMAGE_LIVE_BANNER_FORMULA1,
  BACKGROUND_IMAGE_LIVE_BANNER_FUTSAL,
  BACKGROUND_IMAGE_LIVE_BANNER_HANDBALL,
  BACKGROUND_IMAGE_LIVE_BANNER_ICEHOCKEY,
  BACKGROUND_IMAGE_LIVE_BANNER_SNOOKER,
  BACKGROUND_IMAGE_LIVE_BANNER_TABLETENNIS,
  BACKGROUND_IMAGE_LIVE_BANNER_TENNIS,
  BACKGROUND_IMAGE_LIVE_BANNER_VOLLEYBALL,
  BACKGROUND_IMAGE_LIVE_BANNER_ESPORTS,
  BACKGROUND_IMAGE_LIVE_BANNER_AMERICANFOOTBALL,
  BACKGROUND_IMAGE_LIVE_BANNER_UFCMMA,
} from '../../common/constants';

export const JSSThemeDefaultCoupons = (themeVars: JSSThemeVariablesType) => {
  const {
    margins,
    fontSizes,
    borderRadius,
    colors: {
      colorTypes: {
        primary,
        border2,
        black,
        error3,
        background1,
        neutral3,
        background2,
        white,
      },
    },
  } = themeVars;
  return {
    MobileWidgetCouponView: {
      sportHeader: {
        display: 'flex',
        padding: margins.md,
        fontWeight: 'bold',
        '&:first-child': {
          marginTop: margins.md,
        },
      },
      sportLabel: {
        flex: 1,
        fontSize: fontSizes.lg,
      },
      sportName: {
        marginLeft: margins.xs,
      },
      sportLink: {
        fontSize: fontSizes.md,
        '&:hover': {
          color: 'inherit',
        },
      },
      sportLinkIcon: {
        paddingLeft: margins.md,
      },
      sportInnerLink: {
        color: 'inherit',
      },
      sportsContainer: {
        background: 'inherit',
      },
      sportMobileWrap: {
        background: 'inherit',
        paddingBottom: margins.xs,
      },
    },
    SportTabs: {
      root: {
        overflow: 'hidden',
      },
      tabs: {
        display: 'flex',
        padding: [0, margins.md],
      },
      tab: {
        flex: 1,
        background: border2,
        lineHeight: 1.4,
        textAlign: 'center',
        fontWeight: 'bold',
        cursor: 'pointer',
        borderRadius: borderRadius.md,
        marginRight: margins.xs,
        padding: [margins.sm, margins.md],
        overflow: 'hidden',
        fontSize: fontSizes.sm,
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'normal',
        '&:last-child': {
          marginRight: 0,
        },
      },
      activeTab: {
        background: primary,
      },
    },
    AllLeagues: {
      root: {},
      searchBox: {
        padding: margins.md,
        position: 'relative',
      },
      searchInput: {
        width: '100%',
        padding: margins.md,
        borderRadius: borderRadius.lg,
        border: 0,
      },
      filterIcon: {
        position: 'absolute',
        top: '20px',
        right: '20px',
      },
      league: {
        display: 'block',
        padding: margins.md,
        fontWeight: 'bold',
      },
    },
    EventPrimaryMarketOutcomesMobile: {
      root: {
        display: 'flex',
        alignItems: 'center',
        '&.suspended': {
          opacity: 0.4,
        },
      },
      date: {
        fontSize: fontSizes.xs,
        display: 'flex',
      },
      teams: {
        flexBasis: '100%',
        overflow: 'hidden',
        fontSize: fontSizes.md,
      },
      teamItem: {
        ...jssHelpers.ellipsis,
      },
      outcomes: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end',
      },
      eventLink: {
        padding: [0, 0, 0, margins.md],
      },
      eventLinkIcon: {},
    },
    WidgetSportMobile: {
      root: {
        margin: [0, margins.md],
      },
      event: {
        marginBottom: margins.md,
      },
      league: {
        fontWeight: 'bold',
        fontSize: fontSizes.md,
        color: 'inherit',
        display: 'block',
        marginBottom: margins.xs,
      },
    },
    SuspendedBlock: {
      suspendedBlock: {
        width: 145,
        height: 45,
        background: 'rgba(0, 0, 0, 0.2)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: black,
        borderRadius: borderRadius.md,
      },
    },
    CouponHeaderComponent: {
      headerEventsCount: {},
    },
    Market: {
      root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: borderRadius.md,
      },
      date: {
        minWidth: 65,
        padding: [0, margins.md],
        fontSize: fontSizes.xsm,
        '&.active': {
          color: error3,
        },
      },
      time: {
        paddingLeft: margins.md,
      },
    },
    OutcomesCounter: {
      outcomeCount: {
        cursor: 'pointer',
        width: 34,
        height: 26,
        lineHeight: '26px',
        borderRadius: borderRadius.md,
        background: background1,
        color: '#fff',
        fontSize: fontSizes.xs,
        textAlign: 'center',
        marginRight: margins.sm,
        '&:hover': {
          background: neutral3,
        },
      },
    },
    CouponBreadcrumbsComponent: {
      root: {},
      breadcrumbItem: {
        '&.active &:hover': {},
      },
    },
    PrematchEvent: {
      eventDescription: {},
      marketRoot: {
        display: 'flex',
        flexDirection: 'column',
      },
      marketNameHeader: {},
      betbuilderMark: {
        height: 24,
        flexShrink: 0,
        cursor: 'pointer',
        lineHeight: '24px',
        fontSize: fontSizes.sm,
        padding: [0, margins.sm],
        borderRadius: 12,
        borderBottomLeftRadius: borderRadius.sm,
        backgroundColor: primary,
        color: white,
        transition: '.3s ease-in',
        '@media (pointer: coarse)': {
          marginTop: 0,
          transition: '.3s ease-in',
        },

        '&:hover': {
          opacity: .8,
        }
      },
    },
    PrematchFiltersSwitchComponent: {
      listItem: {},
      listItemFake: {},
    },
    WidgetSport: {
      sport: {},
      event: {
        margin: [margins.md, 0, margins.lg, 0],
        borderRadius: borderRadius.sm,
        background: '#fff',
      },
    },
    WidgetCoupon: {
      title: {},
    },
    EventCoupon: {
      eventHeader: {
        backgroundColor: '#000',
        position: 'relative',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_DEFAULT,
        minHeight: 380,
        overflow: 'hidden',
        textAlign: 'center',
        '@media (pointer: coarse)': {
          minHeight: 145,
        },
        '&.header-mobile': {
          padding: margins.xs,
          '&::before': {
            top: '5%',
            left: '2.5%',
            width: '95%',
            height: '90%',
          },
        },
        '&::before': {
          content: '""',
          background: 'rgba(0, 0, 0, 0.3)',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        },
        '&.sport-1': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_AMERICANFOOTBALL,
        },
        '&.sport-5000': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_BADMINTON,
        },
        '&.sport-226': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_BASEBALL,
        },
        '&.sport-227': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_BASKETBALL,
        },
        '&.sport-1500': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_BEACHSOCCER,
        },
        '&.sport-1250': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_BEACHVOLLEYBALL,
        },
        '&.sport-3400': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_BOWLS,
        },
        '&.sport-22886': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_DARTS,
        },
        '&.sport-3500': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_FIELDHOCKEY,
        },
        '&.sport-240': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_FOOTBALL,
        },
        '&.sport-1300': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_FORMULA1,
        },
        '&.sport-1600': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_FUTSAL,
        },
        '&.sport-1100': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_HANDBALL,
        },
        '&.sport-228, &.sport-2100': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_ICEHOCKEY,
        },
        '&.sport-22884': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_SNOOKER,
        },
        '&.sport-1900': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_TABLETENNIS,
        },
        '&.sport-239': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_TENNIS,
        },
        '&.sport-1200': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_VOLLEYBALL,
        },
        '&.sport-6000': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_ESPORTS,
        },
        '&.sport-1201': {
          backgroundImage: BACKGROUND_IMAGE_LIVE_BANNER_UFCMMA,
          '@media (max-width: 1600px)': {
            backgroundPosition: 'top center',
          },
        },
      },
      eventHeaderTitle: {
        margin: 0,
        fontWeight: 'bold',
        color: '#fff',
        fontSize: fontSizes.xxl,
        padding: margins.md,
        paddingTop: margins.xl,
        width: '100%',
        position: 'relative',
        textShadow: '0 0 5px #000',
        '&.headerTitle-mobile': {
          fontSize: fontSizes.lg,
        },
      },
      eventHeaderTime: {
        color: '#fff',
        fontSize: fontSizes.lg,
        textShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
        position: 'relative',
        '&.headerTime-mobile': {
          fontSize: fontSizes.md,
        },
      },
    },
    Betbuilder: {
      color: white,
      toggler: {
        fontSize: fontSizes.lg,
        background: primary,
        color: white,
        padding: margins.md,
        margin: margins.md,
        borderRadius: 3,
        cursor: 'pointer',
        transition: '.3s ease-in',
        '&:hover': {
          background: neutral3,
        },
      },
      showAllChevron: {
        float: 'right',
        margin: 0,
      },
      wrapContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      },
      link: {},
      linkContainer: {},
      exampleContainer: {
        background: background2,
        padding: margins.md,
        fontSize: fontSizes.md,
        margin: [0, margins.md],
        borderRadius: 3,
      },
      liveBetbuilder: {
        padding: '',
      },
      exampleList: {
        '& p': {
          display: 'list-item',
          listStyleType: 'disc',
          listStylePosition: 'inside',
        },
      },
      wrapContent: {
        display: 'flex',
      },
    },
  };
};

export type JSSThemeDefaultCouponsType = (JSSThemeVariablesType) => $DeepShape<
  $Call<typeof JSSThemeDefaultCoupons, JSSThemeVariablesType>
>;
