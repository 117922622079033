// @flow
import React from 'react';

export const CloseIcon = ({ onClick }: { onClick: void => void }) => (
  <button
    onClick={onClick}
    className="osg-btn--close"
    type="button"
    aria-label="close"
  />
);
