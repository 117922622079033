// @flow
import type { ReduxState } from '../../types';
import { getIsAuthorised } from './auth';

const getBalance = (state: ReduxState) => state.balance;

const getMerlinBalance = (state: ReduxState) => state.merlin;

export const getUserBalanceSelector = (state: ReduxState) =>
  getBalance(state).available_cash + getBalance(state).available_restricted;

export const getCasinoBalanceSelector = (state: ReduxState) =>
  getBalance(state).available_casino_bonus;

export const getSportsBalanceSelector = (state: ReduxState) =>
  getBalance(state).available_sportsbook_bonus;

export const getCasinoTotalBalance = (state: ReduxState) =>
  getBalance(state).available_casino_balance;

export const getSportsbookTotalBalance = (state: ReduxState) =>
  getBalance(state).available_sportsbook_balance;

const getHasUserPositiveBalance = (state: ReduxState) =>
  getUserBalanceSelector(state) > 0;

export const getIsAuthorisedAndPositiveBalance = (state: ReduxState) =>
  getIsAuthorised(state) && getHasUserPositiveBalance(state);

export const getIsBalanceFetched = (state: ReduxState) =>
  getBalance(state).balanceFetched;

export const getBalanceError = (state: ReduxState) => getBalance(state).error;

export const getMerlinCasinoBalanceSelector = (state: ReduxState): number =>
  getMerlinBalance(state).bonoLcasino;

export const getMerlinSportsBalanceSelector = (state: ReduxState): number => {
  const { bonoR, bonoL, bonoE } = getMerlinBalance(state);
  return +bonoR + +bonoL + +bonoE;
};
