// @flow
import { type $DeepShape, JSSThemeVariables } from './JSSThemeDefault';

export const JSSThemeDefaultSearch = (themeVars: typeof JSSThemeVariables) => {
  const {
    fontSizes,
    margins,
    colors: {
      colorTypes: { background1, text1, text2, error3 },
    },
    borderRadius,
  } = themeVars;
  return {
    SearchBar: {
      searchContainer: {
        position: 'relative',
        zIndex: '5',
        background: '#fff',
      },
      inputContainer: {
        fontSize: fontSizes.xxl,
        display: 'flex',
        flexGrow: 1,
      },
      sportsbook: {},
      input: {
        width: '100%',
        textOverflow: 'ellipsis',
        fontSize: fontSizes.lg,
        border: 'none',
        position: 'relative',
        padding: [margins.sm, margins.xm],
      },
      iconContainer: {
        fontSize: fontSizes.xxl,
        padding: [margins.sm, margins.xm],
      },
      resultBackground: {
        position: 'absolute',
        background: '#000',
        width: '100vw',
        height: 'calc(100% + 50px)',
        top: -50,
        opacity: 0,
        visibility: 'hidden',
        zIndex: '4',
        transition: '0.4s ease',
        '&.show': {
          visibility: 'visible',
          opacity: 0.6,
          transition: '0.4s ease',
        },
      },
      resultContainer: {
        width: '100vw',
        minHeight: 0,
        maxHeight: 'calc(100vh - 134px)',
        padding: [0, margins.xm],
        overflowX: 'hidden',
        overflowY: 'scroll',
        '-webkit-overflow-scrolling': 'touch',
        background: background1,
      },
    },
    SportsbookSearchSuggest: {
      input: {},
    },
    SportsbookSearchResult: {
      sportsbookSearchResultRoot: {
        position: 'relative',
        fontSize: fontSizes.sm,
        padding: margins.lg,
        '&.mobile': {
          padding: 0,
        },
      },
      title: {
        fontSize: fontSizes.xxl,
        fontWeight: 'bold',
        margin: [margins.md, 0],
      },
      noEventsFound: {
        fontWeight: 'normal',
      },
      event: {
        margin: [margins.md, 0],
        background: '#fff',
        borderRadius: borderRadius.md,
      },
      description: {
        width: '100%',
        position: 'relative',
        lineHeight: '26px',
        padding: margins.xs,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: text1,
        '&:hover': {
          color: text2,
        },
      },
      betbuilderMarker: {
        marginRight: '-5px',
      },
      eventLiveLabel: {
        color: '#fff',
        background: error3,
        display: 'inline-block',
        padding: [0, margins.xs],
        borderRadius: borderRadius.md,
      },
      sportsbookSearchResultOutcomesWrap: {
        display: 'flex',
      },
      marketCountLink: {
        color: 'inherit !important', // override scss tag a color
      },
      marketCount: {
        width: 28,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: [margins.xs, margins.xs, margins.xs, 0],
        borderRadius: borderRadius.md,
        color: '#fff',
        background: background1,
        fontSize: fontSizes.xsm,
      },
    },
  };
};

export type JSSThemeDefaultSearchType = (
  typeof JSSThemeVariables
) => $DeepShape<$Call<typeof JSSThemeDefaultSearch, typeof JSSThemeVariables>>;
