// @flow
import { path } from 'ramda';
import { parseJSONSafely } from './utils';

const keys = {
  '0': 'id',
  '1': 'description',
  '2': 'cashout',
  '3': 'price',
  '5': 'date',
  '6': 'stake',
  '7': 'potential',
  '8': 'isEachWay',
};

const matchSafely = (regExp: string) => (text: string) => {
  if (!text) return text;
  const result = text.match(regExp);
  return result && result.length > 1 ? result[1] : text;
};

const selectionHandlers = {
  outcomeDescription: matchSafely('^(.*?)(?=@|$)'),
  price: (price) => parseFloat(matchSafely('@(.*?)\\[')(price)),
  marketDescription: matchSafely('\\[(.*?)\\]'),
  eventDescription: matchSafely('\\] - (.*)'),
};

const handleSelection = (item: string) => {
  const selections = {};
  Object.keys(selectionHandlers).map(
    (key) => (selections[key] = selectionHandlers[key](item))
  );
  return selections;
};

const childrenHandlers = {
  description: (
    description: string,
    data: Array<string>
  ): null | {
    currency: string,
    amount: string,
    betType: string,
    selections: Array<*>,
  } => {
    if (!description) return null;

    const descriptionSplit = description.split(':');
    const heading = descriptionSplit[0].split(' ');
    const selectionsArr = descriptionSplit[1].split(';');

    let isMultiple = false;
    const isMultipleOrSystem = selectionsArr.length > 1;
    const selections = selectionsArr.map(handleSelection);

    /** Multiple bets always have 'system' betType, so we need figure out where is a real multiple */
    if (isMultipleOrSystem) {
      const stake = parseFloat(data[6]);
      const potential = parseFloat(data[7]);
      let multiplePrice = 1;
      selections.forEach(({ price }) => {
        multiplePrice *= Number(price);
      });
      isMultiple = Math.round(stake * multiplePrice * 100) / 100 === potential;
    }

    /** First element in description is currency, second is amount and all the rest are betType */
    return {
      currency: heading.shift(),
      amount: heading.shift(),
      betType: heading.join(' '),
      selections,
      isMultiple,
      isSystem: isMultipleOrSystem && !isMultiple,
    };
  },
};

const getKey: (Object) => string = path(['topic', 'parts', 4]);

const handlers = {
  price: parseFloat,
  cashout: parseFloat,
  stake: parseFloat,
  potential: parseFloat,
  isEachWay: parseJSONSafely,
};

export const quoteHandlerSettings = {
  keys,
  getKey,
  childrenHandlers,
  handlers,
};
