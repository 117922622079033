import { type JSSThemeDefaultAccountSectionType } from '../JSSThemeDefaultAccountSection';

export const JSSThemeAccountSection60700: JSSThemeDefaultAccountSectionType = themeVars => {
  const {
    colors: {
      colorTypes: { background2, black, background6 },
    },
    margins,
  } = themeVars;
  return {
    OPMProvider: {
      providerRoot: {
        color: '#000',
      },
      logoImg: {
        height: 70,
        objectFit: 'contain',
      },
    },
    OPMPageComponent: {
      heading: {
        color: black,
      },
      OPMroot: {
        margin: [0, margins.xm],
      },
    },
    PendingWithdrawal: {
      heading: {
        color: black,
      },
      wrapper: {
        color: black,
        padding: [0, margins.xm, margins.xm],
      },
    },
    AccountPageDesktop: {
      accountPageLeftContent: {
        background: background2,
        width: 300,
        paddingTop: margins.md,
      },
      accountPageRightContent: {
        background: background6,
        color: black,
      },
    },
    BetsHistory: {
      noBets: {
        color: black,
      },
      BetsHistoryHeading: {
        color: '#000',
      },
      filtersTitle: {
        color: '#000',
      },
    },
    OPMForms: {
      form: {
        background: background2,
        color: '#000',
      },
    },
  };
};
