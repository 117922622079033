// @flow
import {
  getEventId,
  getMarketId,
  getOutcomeId,
  parseJSONSafely,
} from './utils';
import type { Message, Config } from '../types';

const keys = {
  '0': 'id',
  '1': 'isSuspended',
  '2': 'priceLineIds',
  '3': 'priceIds',
  '4': 'priceValues',
  '5': 'spread',
  '6': 'description',
  '7': 'prevOdds',
  '8': 'lastRaces',
  '9': 'nonRunner',
  /** thumbnailId contains id of the file to display in horseRacing event */
  '10': 'thumbnailId',
};

const getKey = (message: Message) => getOutcomeId(message);

const handlers = {
  isSuspended: (status: string) => status === 'suspended',
  priceLineIds: parseJSONSafely,
  priceIds: parseJSONSafely,
  priceValues: parseJSONSafely,
  description: parseJSONSafely,
  prevOdds: parseJSONSafely,
};

const getItlData = (message: Message, config: Config) => ({
  eventId: getEventId(message),
  marketId: getMarketId(message),
  column: message.topic.parts[7],
  row: +message.topic.parts[8],
  key: getKey(message),
  priceLineId: config.priceLineId,
});

export const outcomeHandlerSettings = { keys, getKey, handlers, getItlData };
