// @flow
import { getSportId, getEventId } from '../utils';
import {
  getHomeAwaySlice,
  getQuartersIndices,
  getCommonScoresIndices,
} from './sports-settings';
import type { Message } from '../../types';

const handleHomeAwayScore = (score: string) => {
  // delta sends '' if the scores were not changed
  switch (score) {
    case '':
      return { home: '', away: '' };
    case '@@':
      return { home: 'invalid', away: 'invalid' };
    case '0-0':
      return { home: '-', away: '-' };
    default: {
      const [home, away] = score.split('-');
      return { home, away };
    }
  }
};

const handleMutipleScores = (multipleScores: string) =>
  multipleScores.split(';');

const getHomeAwayScores = (scores: Array<string>, sportId: string) => {
  const homeAwaySlice = getHomeAwaySlice(sportId);
  const quartersIndices = getQuartersIndices(sportId);

  if (!homeAwaySlice && !quartersIndices) return scores;

  let homeAwayScores = [];
  let quartersScores = [];
  if (homeAwaySlice) {
    homeAwayScores = homeAwaySlice.take
      ? scores.filter((s, i) => homeAwaySlice.take.includes(i))
      : scores.slice(homeAwaySlice.start, homeAwaySlice.end || undefined);
  }

  if (quartersIndices) {
    quartersScores = scores
      .filter((s, i) => quartersIndices.includes(i))
      .reduce((validScores, multipleScores) => {
        // multipleScores looks like "1-2;2-3"
        // delta sends '' if the scores were not changed
        if (multipleScores === '') {
          // eslint-disable-next-line no-unused-expressions, no-sequences
          validScores.push('', '');
        } else {
          handleMutipleScores(multipleScores).forEach(score =>
            validScores.push(score)
          );
        }
        return validScores;
      }, []);
  }

  return [...homeAwayScores, ...quartersScores];
};

export const handleScoreboardTopic = (message: Message) => {
  const scores = message.fields;
  const sportId = getSportId(message);
  const home = [];
  const away = [];
  const common = []; // scores like balls, strikes, etc.

  const commonScoresIndices = getCommonScoresIndices(sportId);

  if (commonScoresIndices) {
    commonScoresIndices.forEach(index => {
      common.push(scores[index]);
    });
  }

  getHomeAwayScores(scores, sportId).forEach(homeAwayScore => {
    const { home: homeScore, away: awayScore } = handleHomeAwayScore(
      homeAwayScore
    );
    home.push(homeScore);
    away.push(awayScore);
  });

  const scoreboard = { home, away, common };

  if (home.length === 0 && away.length === 0 && common.length === 0) {
    return false;
  }

  return {
    id: getEventId(message),
    type: message.type,
    topicType: message.topic.type,
    data: scoreboard,
  };
};
