// @flow
import { getNowTime } from '../selectors/utils';
import type { ReduxAction } from '../../types';

export const timerReducer = (
  state: number = getNowTime(),
  action: ReduxAction
  // $FlowFixMe
): number => {
  if (action.type === 'timer:update') {
    return action.payload;
  }

  return state;
};
