// @flow
import { merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { type ReduxAction, type ReduxState } from '../../types';
import { type ActionsObservable, type StateObservable } from 'redux-observable';
import {
  KOLTRON_AUTHORIZE,
  USER_REGISTER,
  KOLTRON_UNAUTHORIZE,
} from '../actions/action-types';
import {
  dataLayerPush,
  REGISTRATION_COMPLETE,
  LOGIN_COMPLETE,
  changeCurrentLocale,
} from '../../utils';
import type { dataLayerEventNameType } from '../../utils';
import {
  LOGIN_TIMESTAMP_STORAGE_LEY,
  OPM_UNLOCKED_STORAGE_KEY,
  SIMPLE_REGISTRATION_TYPE,
} from '../../constants';

/**
 * Set values to localStorage and set event to dataLayer
 * @returns {{type: "regAuthActions:RegistrationComplete"} | {type: "regAuthActions:LoginComplete"}}
 */
const addData = (
  state$: StateObservable<ReduxState>,
  event: dataLayerEventNameType
) => {
  // TODO: is an opportunity to use this into the ReduxState?
  /** Set timestamp for the responsible gaming window,
   * applications/koltron/client/components/timeout-modal/timeoutModal.js
   */
  const loginDatetime = new Date().toISOString();
  localStorage.setItem(LOGIN_TIMESTAMP_STORAGE_LEY, loginDatetime);
  /** Reset the stored variable after each successful login if simpleRegForm is used */
  if (__OSG_CONFIG__.registrationType === SIMPLE_REGISTRATION_TYPE)
    localStorage.setItem(OPM_UNLOCKED_STORAGE_KEY, 'false');

  dataLayerPush({
    eventName: event,
    id: state$.value.auth.accountNumber,
  });

  /**
   * Rebuild client app and reload page.
   * NOTE: In the end of function 'addData' always
   */
  if (__OSG_CONFIG__.specificLocaleForAuthorizedUsers) {
    changeCurrentLocale(__OSG_CONFIG__.specificLocaleForAuthorizedUsers);
  }

  return {
    type: `regAuthActions:${event}`,
  };
};

/**
 * Set values to localStorage and set event to dataLayer
 * @returns {{type: "regAuthActions:koltron:unauthorize"}}
 */
const unauthorize = () => {
  /** Remove timestamp for the responsible gaming window,
   * applications/koltron/client/components/timeout-modal/timeoutModal.js
   */
  /* FIXME:
    1. is an opportunity to use this into the ReduxState?
    2. If user close the casino tab (all tabs) and then return in a few time
    this timer doesn't update
  */
  localStorage.removeItem(LOGIN_TIMESTAMP_STORAGE_LEY);
  /** Remove OPM_UNLOCKED_STORAGE_KEY */
  localStorage.removeItem(OPM_UNLOCKED_STORAGE_KEY);

  return {
    type: `regAuthActions:${KOLTRON_UNAUTHORIZE}`,
  };
};

/**
 * Function creates an epic that triggers on user login, logout and registration
 * @param action$
 * @param state$
 * @returns {rxjs$Observable<T>}
 */
export const regAuthEpic = (
  action$: ActionsObservable<ReduxAction>,
  state$: StateObservable<ReduxState>
) => {
  return merge(
    action$
      .ofType(USER_REGISTER)
      .pipe(map(() => addData(state$, REGISTRATION_COMPLETE))),
    action$
      .ofType(KOLTRON_AUTHORIZE)
      .pipe(map(() => addData(state$, LOGIN_COMPLETE))),
    action$.ofType(KOLTRON_UNAUTHORIZE).pipe(map(() => unauthorize()))
  );
};
