// @flow

export const LIVE_DIFFUSION: 'live:diffusion' = 'live:diffusion';
export const TOGGLE_FAVOURITE_MARKETTYPE: 'live:toggle-favourite-markettype' =
  'live:toggle-favourite-markettype';
export const TOGGLE_FAVOURITE_EVENT: 'live:toggle-favourite-event' =
  'live:toggle-favourite-event';

export const toggleFavoriteMarkettype = (payload: string) => ({
  type: TOGGLE_FAVOURITE_MARKETTYPE,
  payload,
});

export const toggleFavoriteEvent = (payload: string) => ({
  type: TOGGLE_FAVOURITE_EVENT,
  payload,
});
