// @flow
import { type $DeepShape, JSSThemeVariables } from './JSSThemeDefault';
import { jssHelpers } from './jss-helpers';

export const JSSThemeDefaultTooltip = (themeVars: typeof JSSThemeVariables) => {
  const {
    fontSizes,
    margins,
    colors: {
      colorCombinations: { text2White },
      colorTypes: { white },
    },
    borderRadius,
  } = themeVars;
  return {
    Tooltip: {
      wrapper: {
        position: 'relative',
        cursor: 'pointer',
        display: 'inline-block',
      },
      showTip: {
        '&:before': {
          opacity: 1,
          transition: 'opacity 0.3s',
        },
      },
      icon: {
        padding: [0, margins.xs],
        ...jssHelpers.fa,
        ...jssHelpers.faIconQuestionCircle,
      },
      tooltipText: {
        position: 'absolute',
        zIndex: 9999,
        textAlign: 'left',
        padding: [margins.sm, margins.md],
        boxShadow: '3px 3px 20px rgba(0, 0, 0, 0.1)',
        width: 'max-content',
        maxWidth: 177,
        whiteSpace: 'normal',
        ...text2White,
        fontSize: fontSizes.sm,
        borderRadius: borderRadius.md,
        opacity: 0,
        visibility: 'hidden',
        transition: 'all 0.3s',
        '&:before': {
          content: '""',
          position: 'absolute',
          width: 10,
          height: 10,
          transform: 'rotate(-45deg)',
          transformOrigin: 'top left',
          background: white,
        },
      },
      showTool: {
        opacity: 1,
        visibility: 'visible',
        transition: 'all 0.3s',
      },
      top: {
        bottom: 'calc(100% + 5px)',
        '&:before': {
          top: 'calc(100%)',
        },
      },
      bottom: {
        top: 'calc(100% + 5px)',
        '&:before': {
          bottom: 'calc(100% - 10px)',
        },
      },
      left: {
        right: 'calc(50% - 20px)',
        '&:before': {
          left: 'calc(100% - 25px)',
        },
        '@media (pointer: coarse)': {
          right: 'calc(50% - 142px)',
          '&:before': {
            left: 'calc(100% - 149px)',
          },
        },
      },
      right: {
        left: 'calc(50% - 111px)',
        '&:before': {
          right: 'calc(100% - 114px)',
        },
        '@media (pointer: coarse)': {
          left: 'calc(50% - 67px)',
          '&:before': {
            right: 'calc(100% - 70px)',
          },
        },
      },
      center: {
        transform: 'translateX(-50%)',
        '&:before': {
          left: 'calc(50% - 7px)',
        },
      },
      mobile: {
        maxWidth: 200,
      },
    },
  };
};

export type JSSThemeDefaultTooltipType = (
  typeof JSSThemeVariables
) => $DeepShape<$Call<typeof JSSThemeDefaultTooltip, typeof JSSThemeVariables>>;
