// @flow
import * as React from 'react';
import { Route, Link } from 'react-router-dom';
import {
  LIVE_EVENT_PATH,
  LIVE_SPORT_PATH,
  SPORT_ID_PARAM,
  EVENT_ID_PARAM,
  LIVE_PATH,
} from '../../common/constants';

export const routeFactory = (path: string) => {
  const CustomRoute = (
    props: $Diff<React.ElementConfig<typeof Route>, { path: string | void }>
  ) => <Route {...props} path={path} />;
  CustomRoute.displayName = `Route(${path})`;
  return CustomRoute;
};

export const getLiveRedirectPathById = (
  params:
    | {
        sportId: string,
      }
    | {
        eventId: string,
      }
): string => {
  if (typeof params.sportId === 'string') {
    return LIVE_SPORT_PATH.replace(`:${SPORT_ID_PARAM}`, params.sportId);
  }
  if (typeof params.eventId === 'string') {
    return LIVE_EVENT_PATH.replace(`:${EVENT_ID_PARAM}`, params.eventId);
  }

  return LIVE_PATH;
};

export const StyledLink = (props: React.ElementConfig<typeof Link>) => (
  <Link className="osg-button-link" {...props} />
);

export const linkFactory = (path: string) => {
  const CustomStyledLink = (
    props: $Diff<React.ElementConfig<typeof StyledLink>, { to: any }>
  ) => <StyledLink {...props} to={path} />;
  CustomStyledLink.displayName = `CustomStyledLink(${path})`;
  return CustomStyledLink;
};
