// @flow
export const KOLTRON_AUTHORIZE: 'koltron:authorize' = 'koltron:authorize';
export const KOLTRON_UNAUTHORIZE: 'koltron:unauthorize' = 'koltron:unauthorize';
export const KOLTRON_UNAUTHORIZE_WITH_ERROR: 'koltron:unauthorize:with:error' =
  'koltron:unauthorize:with:error';
export const HIDE_UNAUTHORIZE_POPUP: 'koltron:hide:unauthorize:popup' =
  'koltron:hide:unauthorize:popup';

export const BALANCE_UPDATE: 'balance:user:update' = 'balance:user:update';
export const MERLIN_UPDATE: 'merlin:user:update' = 'merlin:user:update';

export const UPDATE_TOKEN: 'koltron:update:token' = 'koltron:update:token';
export const UPDATE_JUST_REGISTERED: 'koltron:update:hasJustRegistered' =
  'koltron:update:hasJustRegistered';
export const USER_REGISTER: 'koltron:registration:completed' =
  'koltron:registration:completed';
export const WAITING_NOTIFICATION: 'koltron:registration:waiting_notification' =
  'koltron:registration:waiting_notification';
export const REG_WITH_ERRORS: 'koltron:registration:reg_with_errors' =
  'koltron:registration:reg_with_errors';

export const PLAYING_CASINO: 'location:casino' = 'location:casino';
export const NOT_PLAYING_CASINO: 'location:not:casino' = 'location:not:casino';

export const KOLTRON_SEO_UPDATE: 'koltron:seo:update' = 'koltron:seo:update';
export const PREMATCH_COUPON_HEADER_LOADED: 'koltron:seo:prematch_coupon_header_loaded' =
  'koltron:seo:prematch_coupon_header_loaded';
export const SEO_BLOCK_UNMOUNT: 'koltron:seo:seo_block_unmount' =
  'koltron:seo:seo_block_unmount';

export const KOLTRON_DEV_TOOLS_UPDATE: 'koltron:devtools:update' =
  'koltron:devtools:update';
