// @flow
import { type JSSThemeDefaultPromotionsType } from '../JSSThemeDefaultPromotions';

export const JSSThemePromotions60700: JSSThemeDefaultPromotionsType = (
  themeVars
) => {
  const {
    margins,
    colors: {
      colorTypes: { black, text3 },
    },
  } = themeVars;
  return {
    PromotionsComponent: {
      itemsByTypeTitle: {
        color: black,
      },
      promoLink: {
        color: text3,
        '&:hover, &:active': {
          color: text3,
          opacity: 0.7,
          borderBottom: `3px solid ${text3}`,
        },
      },
    },
    PromotionUI: {
      itemDescription: {
        '@media (pointer: coarse)': {
          padding: [margins.md, 0],
          color: black,
        },
      },
      itemBody: {
        minHeight: 170,
      },
    },
    PromotionsWrapper: {
      promotionsRoot: {
        padding: 0,
      },
      promotionsWrapper: {
        padding: 0,
        '@media (pointer: coarse)': {
          padding: [0, margins.xm],
        },
      },
    },
  };
};
