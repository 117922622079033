// @flow
import * as React from 'react';
import type { ReduxState } from '../../types';
import { useReduxDispatch, useReduxState } from '../../redux-hooks';
import { DropdownUI } from '../DropdownUI';
import { useJSSStyles } from '../../jss-hooks';
import { IconChevronDown } from '../Icons';

/**
 * TODO Now I have taken JSS styles from Laguage selector. Please replace them om necessary.
 */

const JSSPathMobile = (theme) => theme.components.dropdownMenu.dropdownMobile;
const JSSPathDesktop = (theme) => theme.components.dropdownMenu.dropdownDesktop;

const CHANGE_ODDS_FORMAT = 'odds:сhange:format';
const ODDS_FORMAT_KEY = 'osg-odds-format';

export const getOddsFormat = (state: ReduxState) => state.oddsFormat;

export const OddsFormatSelect = () => {
  const dispatch = useReduxDispatch();
  const oddsFormat = useReduxState(getOddsFormat);

  const classes = useJSSStyles(JSSPathDesktop);

  const oddsFormats =
    __OSG_CONFIG__.oddsFormats.length > 0
      ? __OSG_CONFIG__.oddsFormats
      : ODDS_FORMATS;

  function onClick(format) {
    dispatch({
      type: CHANGE_ODDS_FORMAT,
      payload: format,
    });
    localStorage.setItem(ODDS_FORMAT_KEY, format);
  }

  return (
    <div className={classes.selectorWrap}>
      <DropdownUI
        dropdown_id="oddsChange"
        title={<div className={classes.titleItem}>{oddsFormat}</div>}
      >
        <ul className={classes.selectorWrap}>
          {oddsFormats.map((format) => (
            <li
              value={format}
              key={format}
              onClick={() => onClick(format)}
              className={classes.item}
            >
              <div>
                {
                  // eslint-disable-next-line
                  _t(`${format}`)
                }
              </div>
            </li>
          ))}
        </ul>
      </DropdownUI>
    </div>
  );
};

export const OddsFormatSelectMobile = () => {
  const dispatch = useReduxDispatch();
  const oddsFormat = useReduxState(getOddsFormat);
  const classes = useJSSStyles(JSSPathMobile);

  const oddsFormats =
    __OSG_CONFIG__.oddsFormats.length > 0
      ? __OSG_CONFIG__.oddsFormats
      : ODDS_FORMATS;

  function onChange(e) {
    dispatch({
      type: CHANGE_ODDS_FORMAT,
      payload: e.target.value,
    });
    localStorage.setItem(ODDS_FORMAT_KEY, e.target.value);
  }

  return (
    <>
      <div className={classes.description}>{_t('odds-selector')}:</div>
      <select className={classes.select} onChange={onChange} value={oddsFormat}>
        {oddsFormats.map((format) => (
          <option key={format} value={format}>
            {
              // eslint-disable-next-line
              _t(`${format}`)
            }
          </option>
        ))}
      </select>
      <div className={classes.chevron}>
        <IconChevronDown />
      </div>
    </>
  );
};

export const ODDS_FORMAT_DECIMAL = 'decimal';
export const ODDS_FORMAT_FRACTIONAL = 'fractional';
export const ODDS_FORMAT_AMERICAN = 'american';

export const ODDS_FORMATS = [
  ODDS_FORMAT_DECIMAL,
  ODDS_FORMAT_FRACTIONAL,
  ODDS_FORMAT_AMERICAN,
];

const getDefaultOddsValue = () => {
  const oddsDefault =
    localStorage.getItem(ODDS_FORMAT_KEY) || __OSG_CONFIG__.defaultOddsValue;
  return ODDS_FORMATS.includes(oddsDefault) ? oddsDefault : ODDS_FORMATS[0];
};

export const oddsFormatReducerWithSelector = (
  state: string = getDefaultOddsValue(),
  { type, payload }: { type: string, payload: string }
) =>
  // $FlowFixMe
  type === CHANGE_ODDS_FORMAT ? payload : state;
// $FlowFixMe
export const oddsFormatReducer = (state: 'decimal' = 'decimal') => state;
