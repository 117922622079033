// @flow
import { type JSSThemeVariablesType, type $DeepShape } from './JSSThemeDefault';

export const JSSThemeDefaultAffiliates = (themeVars: JSSThemeVariablesType) => {
  const {
    margins,
    fontSizes,
    colors: {
      colorTypes: { background2, background5, accent, white, warning },
    },
  } = themeVars;
  return {
    root: {
      background: background2,
    },
    text: {
      margin: margins.md,
    },
    affiliatesHeading: {
      display: '',
      color: '#FFF',
      fontWeight: '800 !important',
      fontSize: 72,
      lineHeight: '80px',
      padding: '70px 20px 50px',
      margin: '30px auto 0',
      maxWidth: 1216,
      background: 'transparent',
      '@media (pointer: coarse)': {
        fontSize: 48,
        padding: '50px 15px 30px',
        margin: '0 auto',
        lineHeight: '58px',
      },
    },
    affiliateHeader: {
      headerRoot: {
        background: 'transparent',
      },
      loginBar: {
        maxWidth: 1216,
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: [margins.lg, margins.lg],
        '@media (pointer: coarse)': {
          justifyContent: 'flex-start',
          padding: '40px 0 0 60px',
          flexDirection: 'column',
        },
      },
      languageSwitcher: {
        color: 'rgba(255, 255, 255, 0.8)',
        marginTop: '4px',
      },
      reasonsIcon: {
        fill: '#d4af37',
        marginRight: '25px',
      },
      SignupIcon: {
        marginRight: margins.md,
        fill: white,
        opacity: '0.8',
      },
      loginBarRoot: {
        background: background5,
      },
      SignupRoot: {
        marginRight: 40,
        display: 'flex',
        opacity: '0.8',
        alignItems: 'center',
        '&:last-child': {
          marginRight: 0,
        },
        '&:hover': {
          opacity: '.7',
        },
        '@media (pointer: coarse)': {
          padding: '0 0 30px',
        },
      },
      logo: {
        height: 40,
        width: 'auto',
        marginLeft: margins.lg,
        '@media (pointer: coarse)': {
          marginLeft: margins.xm,
        },
      },
      topBlock: {
        maxWidth: 1216,
        margin: '30px auto 0',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '@media (pointer: coarse)': {
          margin: [margins.lg, 'auto', 0],
        },
      },
      topMenuLink: {
        marginLeft: 40,
        fontSize: fontSizes.lg,
        fontWeight: 'bold',
        '@media (pointer: coarse)': {
          marginBottom: '30px',
          marginLeft: 0,
        },
      },
      topMenuContainer: {
        marginTop: margins.md,
        display: 'flex',
        marginRight: margins.lg,
        '@media (pointer: coarse)': {
          flexDirection: 'column',
          padding: '80px 0 0 60px',
        },
      },
      topMenuItem: {
        '&:hover': {
          color: warning,
        },
      },
      topMenuItemActive: {
        color: warning,
      },
      burger: {
        marginRight: margins.xm,
        position: 'absolute',
        top: 25,
        right: 0,
      },
      cross: {
        position: 'absolute',
        right: '0px',
        top: '22px',
        width: '50px',
        height: '50px',
        '&::before': {
          transform: 'rotate(45deg)',
          position: 'absolute',
          left: 18,
          content: '""',
          height: 29,
          width: 2,
          backgroundColor: '#fff',
        },
        '&::after': {
          transform: 'rotate(-45deg)',
          position: 'absolute',
          left: 18,
          content: '""',
          height: 29,
          width: 2,
          backgroundColor: '#fff',
        },
      },
      burgerOverlay: {
        position: 'fixed',
        background: '#3E3E3E',
        top: 0,
        left: 0,
        width: '100%',
        height: '100vh',
        zIndex: 999,
        overflowY: 'scroll',
      },
      burgerBar: {
        width: 25,
        height: 2,
        background: '#fff',
        marginBottom: '7px',
        display: 'block',
        borderRadius: 2,
        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
    affiliatesTestimonals: {
      testimonalsRoot: {},
      testimonalsWrapper: {},
      testimonalsContent: {},
      testimonalsTextWrapper: {},
      testimonalsItem: {},
      testimonalsText: {},
      testimonalsLink: {},
      testimonalsImg: {},
    },
    affiliatesFooter: {
      footerRoot: {
        background: background5,
      },
      footer: {
        margin: '0 auto',
        maxWidth: 1216,
        padding: ['50px', margins.lg, '50px'],
        fontSize: fontSizes.lg,
        display: 'flex',
        justifyContent: 'space-between',
        color: white,
        opacity: '.6',
        '@media (pointer: coarse)': {
          flexDirection: 'column',
        },
      },
      languageSwitcherMobile: {
        color: 'rgba(255, 255, 255, 0.8)',
        padding: '0 0 20px',
      },
      productsTerms: {
        fontWeight: 'bold',
        textDecoration: 'underline',
        color: 'inherit',
        '&:hover': {
          color: warning,
        },
        '@media (pointer: coarse)': {
          paddingBottom: margins.lg,
        },
      },
      iconSocial: {},
      socialWrap: {},
    },
    affiliatesHome: {
      affiliateRoot: {
        background: background2,
      },
      topBannerRoot: {},
      centerContent: {
        maxWidth: 1216,
        margin: '0 auto',
      },
      topBannerHeader: {
        fontWeight: '800',
        fontSize: 72,
        lineHeight: '80px',
        maxWidth: 500,
        '@media (pointer: coarse)': {
          fontSize: 48,
          lineHeight: '58px',
          maxWidth: 300,
        },
      },
      topBanner: {
        minHeight: 600,
        maxWidth: 1216,
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: [0, margins.lg, '60px'],
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right 0px',
        backgroundSize: 610,
        '@media (pointer: coarse)': {
          padding: ['80px', margins.xm, '133px'],
          minHeight: 'auto',
          backgroundPosition: '200px 125px',
          backgroundSize: 230,
        },
      },
      aflLink: {},
      brandsRoot: {},
      brandItemsRoot: {},
      brandItems: {},
      brandImg: {},
      topBannerButton: {
        padding: '16px 50px',
        borderRadius: 30,
        fontSize: fontSizes.xl,
        marginTop: margins.lg,
        color: '#fff',
        backgroundColor: background2,
        alignSelf: 'flex-start',
        fontWeight: 'bold',
        boxShadow: '0 5px 15px rgba(200,165,52, .5)',
        backgroundSize: '1px 70px',
        background: 'linear-gradient(rgba(254,233,53,1),rgba(200,165,52,1))',
        '-webkit-transition': 'background 1s ease-out',
        '-moz-transition': 'background 1s ease-out',
        '-o-transition': 'background 1s ease-out',
        transition: 'background 1s ease-out',
        textShadow:
          '-0 -2px 0 #000, 0 -2px 0 #000, -0 2px 0 #000, 0 2px 0 #000, -2px -0 0 #000, 2px -0 0 #000, -2px 0 0 #000, 2px 0 0 #000, -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000, -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000',
        '&:hover': {
          backgroundColor: 'rgba(254,233,53,1)',
          backgroundPosition: 50,
          boxShadow: '0 5px 15px rgba(200,165,52, 1)',
        },
        '&:disabled:hover': {
          backgroundColor: background2,
          background:
            'linear-gradient(90deg, rgba(254,233,53,1) 1%, rgba(200,165,52,1) 100%)',
          boxShadow: '0 5px 15px rgba(200,165,52, .5)',
        },
      },
      topBannerDescription: {
        fontSize: fontSizes.xl,
        margin: '25px 0',
        lineHeight: '28px',
        '@media (pointer: coarse)': {
          margin: [margins.xm, 0, margins.md],
          maxWidth: 200,
        },
      },
      stepsRoot: {
        display: 'flex',
        flexDirection: 'row',
        margin: [0, margins.xm],
        '@media (pointer: coarse)': {
          flexDirection: 'column',
        },
      },
      step: {
        background: '#fff',
        width: '33%',
        borderRadius: 30,
        padding: '50px 50px 70px',
        marginRight: '30px',
        marginTop: '-60px',
        marginBottom: '30px',
        boxShadow: '0px 8px 21px 14px rgba(200,165,52, .15)',
        '&:last-child': {
          marginRight: 0,
        },
        '&:first-child': {
          marginTop: '-60px',
        },
        '@media (pointer: coarse)': {
          width: '100%',
          marginRight: '0px',
          marginTop: '0px',
          padding: '35px',
        },
      },
      stepDescription: {
        color: '#041A3D',
        fontSize: fontSizes.lg,
        lineHeight: '24px',
        opacity: '.6',
        padding: [margins.lg, 0, 0],
      },
      stepHeading: {
        color: '#041A3D',
        fontWeight: 800,
        fontSize: fontSizes.xxxl,
        paddingLeft: '20px',
      },
      stepHeadingRoot: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      stepNumber: {
        fontSize: '24px',
        background: accent,
        fontWeight: 800,
        borderRadius: '50px',
        alignSelf: 'flex-start',
        width: '45px',
        height: '45px',
        lineHeight: '45px',
        textAlign: 'center',
        color: white,
      },
      contentRoot: {
        padding: [0, margins.lg],
      },
      centerContentRoot: {
        background: white,
        color: background5,
      },
      contentHeading: {
        color: '#041A3D',
        fontWeight: 800,
        fontSize: 48,
        lineHeight: '58px',
        padding: '120px 0 60px',
        '@media (pointer: coarse)': {
          padding: '50px 0',
        },
      },
      reasonsItemRoot: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: 80,
        flexWrap: 'wrap',
        '@media (pointer: coarse)': {
          flexDirection: 'column',
          paddingBottom: 40,
        },
      },
      reasonsItem: {
        alignSelf: 'flex-start',
        width: '33%',
        padding: '0 50px 40px 0',
        fontSize: fontSizes.lg,
        display: 'flex',
        '@media (pointer: coarse)': {
          width: '100%',
        },
      },
      productsRoot: {
        background: background5,
        color: white,
        padding: [0, margins.lg],
      },
      products: {
        maxWidth: 1216,
        margin: '0 auto',
        display: 'flex',
      },
      productsImg: {
        padding: '120px 70px 30px 0',
        '@media (pointer: coarse)': {
          display: 'none',
        },
      },
      productsHeading: {
        color: white,
        fontWeight: 800,
        fontSize: 48,
        lineHeight: '48px',
        padding: '120px 0 50px',
        margin: 0,
        '@media (pointer: coarse)': {
          padding: '80px 0 50px',
        },
      },
      productsList: {
        color: white,
        opacity: '.6',
        fontSize: fontSizes.lg,
        marginLeft: margins.lg,
      },
      productsListItem: {
        marginBottom: '28px',
      },
      productsButton: {
        padding: '16px 50px',
        borderRadius: 30,
        fontSize: fontSizes.xl,
        margin: [margins.lg, 0, '90px'],
        color: '#fff',
        display: 'inline-block',
        backgroundColor: background2,
        alignSelf: 'flex-start',
        border: 'none',
        fontWeight: 'bold',
        boxShadow: '0 5px 15px rgba(200,165,52, .5)',
        backgroundSize: '1px 70px',
        background: 'linear-gradient(rgba(254,233,53,1),rgba(200,165,52,1))',
        '-webkit-transition': 'background 1s ease-out',
        '-moz-transition': 'background 1s ease-out',
        '-o-transition': 'background 1s ease-out',
        transition: 'background 1s ease-out',
        textShadow:
          '-0 -2px 0 #000, 0 -2px 0 #000, -0 2px 0 #000, 0 2px 0 #000, -2px -0 0 #000, 2px -0 0 #000, -2px 0 0 #000, 2px 0 0 #000, -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000, -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000',
        '&:hover': {
          backgroundColor: 'rgba(254,233,53,1)',
          backgroundPosition: 50,
          boxShadow: '0 5px 15px rgba(200,165,52, 1)',
        },
        '&:disabled:hover': {
          backgroundColor: background2,
          background:
            'linear-gradient(90deg, rgba(254,233,53,1) 1%, rgba(200,165,52,1) 100%)',
          boxShadow: '0 5px 15px rgba(200,165,52, .5)',
        },
      },
    },
    affiliatesSignup: {
      signupRoot: {},
      signupHeader: {
        color: '#FFF',
        fontWeight: 800,
        fontSize: 72,
        lineHeight: '80px',
        padding: '70px 0 50px',
        margin: '30px auto 0',
        maxWidth: 1216,
      },
      signupIframe: {
        maxWidth: 1216,
        margin: '0 auto',
      },
      signupIframeWrapper: {
        background: '#fff',
        color: '#041A3D',
        display: 'flex',
      },
      signupImg: {},
    },
    affiliatesCommission: {
      commissionHeader: {
        color: '#FFF',
        fontWeight: 800,
        fontSize: 72,
        lineHeight: '80px',
        padding: '70px 0 50px',
        margin: '30px auto 0',
        maxWidth: 1216,
      },
      commissionContentWrapper: {
        background: '#fff',
      },
      commissionContent: {
        maxWidth: 1216,
        margin: '0 auto',
        color: '#041A3D',
        padding: ['100px', margins.lg],
      },
      commissionTable: {
        border: '1px solid rgba(4,26,61, .1)',
        color: 'rgba(4,26,61, .6)',
        padding: [0, 0, margins.md],
        borderRadius: 20,
        overflow: 'hidden',
        marginBottom: '50px',
      },
      commissionTableHeading: {
        color: 'rgba(4,26,61, .6)',
        fontWeight: 'bold',
        background: 'rgba(4,26,61, .1)',
        padding: margins.lg,
      },
      commissionTableCell: {
        padding: [margins.lg, margins.lg, margins.md],
      },
      commissionSubHeader: {
        fontWeight: 800,
        fontSize: 36,
        margin: 0,
        maxWidth: 820,
        padding: '50px 0 25px',
      },
      commissionText: {
        fontSize: fontSizes.xl,
        lineHeight: '28px',
        maxWidth: 820,
        color: 'rgba(4,26,61, .6)',
        // padding: [0, 0, '25px'],
        margin: 0,
      },
    },
    affiliatesProducts: {
      productsHeader: {
        color: '#FFF',
        fontWeight: 800,
        fontSize: 72,
        lineHeight: '80px',
        padding: '70px 0 50px',
        margin: '30px auto 0',
        maxWidth: 1216,
      },
      productsContentWrapper: {
        background: '#fff',
        color: '#041A3D',
      },
      productsSignupText: {},
      productsContent: {
        margin: '0 auto',
        maxWidth: 1216,
        padding: ['25px', margins.lg, '50px'],
      },
      productsSubHeader: {
        fontWeight: '800 !important',
        fontSize: 48,
        padding: '75px 0 50px',
        margin: 0,
      },
      productsImgWrapper: {},
      productsImg: {},
      productsImgText: {},
      productsText: {
        opacity: '.6',
        fontSize: fontSizes.xl,
        lineHeight: '28px',
        maxWidth: 820,
        padding: [0, 0, '25px'],
        margin: 0,
      },
      productsButtonBlock: {
        fontSize: fontSizes.lg,
        color: 'rgba(4,26,61, .6)',
      },
      productsButton: {
        padding: '16px 50px',
        borderRadius: 30,
        fontSize: fontSizes.xl,
        margin: ['25px', margins.lg, '90px', 0],
        color: '#fff',
        backgroundColor: background2,
        alignSelf: 'flex-start',
        border: 'none',
        display: 'inline-block',
        fontWeight: 'bold',
        boxShadow: '0 5px 15px rgba(200,165,52, .5)',
        backgroundSize: '1px 70px',
        background: 'linear-gradient(rgba(254,233,53,1),rgba(200,165,52,1))',
        '-webkit-transition': 'background 1s ease-out',
        '-moz-transition': 'background 1s ease-out',
        '-o-transition': 'background 1s ease-out',
        transition: 'background 1s ease-out',
        textShadow:
          '-0 -2px 0 #000, 0 -2px 0 #000, -0 2px 0 #000, 0 2px 0 #000, -2px -0 0 #000, 2px -0 0 #000, -2px 0 0 #000, 2px 0 0 #000, -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000, -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000',
        '&:hover': {
          backgroundColor: 'rgba(254,233,53,1)',
          backgroundPosition: 50,
          boxShadow: '0 5px 15px rgba(200,165,52, 1)',
        },
        '&:disabled:hover': {
          backgroundColor: background2,
          background:
            'linear-gradient(90deg, rgba(254,233,53,1) 1%, rgba(200,165,52,1) 100%)',
          boxShadow: '0 5px 15px rgba(200,165,52, .5)',
        },
        '@media (pointer: coarse)': {
          margin: ['25px', '20px', '20px', 0],
          width: '100%',
          textAlign: 'center',
        },
      },
    },
    affiliatesSitemap: {
      sitemapRoot: {},
      sitemapContentWrapper: {
        background: '#FFF',
      },
      sitemapContent: {
        margin: '0 auto',
        maxWidth: 1216,
        padding: '100px 20px',
        color: '#383838',
      },
      sitemapLoc: {
        display: 'block',
        padding: [margins.md, 0],
      },
    },
    affiliatesContacts: {
      contactsHeader: {
        color: '#FFF',
        fontWeight: 800,
        fontSize: 72,
        lineHeight: '80px',
        padding: '70px 0 50px',
        margin: '30px auto 0',
        maxWidth: 1216,
      },
      bonusLink: {
        display: 'block',
        fontWeight: 'bold',
        textDecoration: 'underline',
        padding: [margins.md, 0],
      },
      bonusList: {},
      bonusListItem: {},
      bonusContent: {},
      bonusTable: {},
      bonusTableHeading: {},
      bonusTableCell: {},
      contactsContentWrapper: {
        background: '#FFF',
      },
      contactsImgWrapper: {},
      contactsImg: {},
      contactsImgText: {},
      contactsText: {
        fontSize: fontSizes.xl,
        lineHeight: '28px',
        maxWidth: 820,
        color: 'rgba(4,26,61, .6)',
        padding: [0, 0, '25px'],
        margin: 0,
      },
      bonusText: {},
      contactsContent: {
        margin: '0 auto',
        maxWidth: 1216,
        padding: '100px 20px',
      },
      contactsMail: {
        color: 'rgba(4,26,61, 1)',
        fontWeight: 'bold',
        display: 'inline-block',
        '&:hover': {
          color: 'rgba(200,165,52, 1)',
        },
      },
    },
    affiliatesFAQ: {
      FAQHeader: {
        color: '#FFF',
        fontWeight: 800,
        fontSize: 72,
        lineHeight: '80px',
        padding: '70px 0 50px',
        margin: '30px auto 0',
        maxWidth: 1216,
      },
      FAQContentWrapper: {
        background: '#FFF',
      },
      FAQContent: {
        margin: '0 auto',
        maxWidth: 1216,
        padding: '50px 20px',
        color: 'rgba(4,26,61, 1)',
      },
      FAQquestion: {
        fontWeight: 800,
        fontSize: 36,
        margin: 0,
        maxWidth: 820,
        padding: '50px 0 15px',
      },
      FAQText: {
        fontSize: fontSizes.lg,
        opacity: '.6',
        maxWidth: 820,
        lineHeight: '26px',
      },
      FAQTextSpan: {
        textDecoration: '',
      },
      contactsMail: {},
    },
  };
};

export type JSSThemeDefaultAffiliatesType =
  (JSSThemeVariablesType) => $DeepShape<
    $Call<typeof JSSThemeDefaultAffiliates, JSSThemeVariablesType>
  >;
