// @flow
import type { ReduxState } from '../../types';

export const getAuth = (state: ReduxState) => state.auth;

export const getIsAuthorised = (state: ReduxState) => getAuth(state).loggedIn;

export const getUserToken = (state: ReduxState) => {
  const auth = getAuth(state);
  return auth.loggedIn ? auth.token : null;
};

export const getUserCurrency = (state: ReduxState) => {
  const auth = getAuth(state);
  return auth.loggedIn ? auth.currency : null;
};

export const getUsername = (state: ReduxState) => {
  const auth = getAuth(state);
  return auth.loggedIn ? auth.username : null;
};

export const getAccountId = (state: ReduxState) => {
  const auth = getAuth(state);
  return auth.loggedIn ? auth.accountId : null;
};

export const getAccountNumber = (state: ReduxState) => {
  const auth = getAuth(state);
  return auth.loggedIn ? auth.accountNumber : null;
};

//TODO add new getter for Mati field

export const getShowUnauthorizePopup = (state: ReduxState) => {
  const auth = getAuth(state);
  return auth.loggedIn ? false : auth.showUnauthorizePopup;
};

export const getHasJustRegistered = (state: ReduxState) => {
  const auth = getAuth(state);
  return auth.loggedIn ? auth.hasJustRegistered : false;
};

export const getRegistrationErrors = (state: ReduxState) => {
  const auth = getAuth(state);
  return !auth.loggedIn
    ? {
        regWithErrors: auth.regWithErrors,
        errorName: auth.errorName,
      }
    : {
        regWithErrors: false,
        errorName: '',
      };
};

export const getWaitingNotificationStatus = (state: ReduxState) => {
  const auth = getAuth(state);
  return !auth.loggedIn && auth.waitingNotification;
};

export const getUserAccountId = getAccountId;

// const checkAuth = <O: $Call<typeof getAuth, ReduxState>, T: $Keys<O>>(
//   key: T
// ) => (state: ReduxState): $ElementType<O, T> | null => {
//   const auth = getAuth(state);
//   return auth.loggedIn ? auth[key] : null;
// };
