const has = (object, key) => Object.prototype.hasOwnProperty.call(object, key);
const replace = String.prototype.replace;
const split = String.prototype.split;
const forEach = (items, fn) => {
  Object.keys(items).forEach(key => {
    const value = items[key];
    fn(value, key);
  });
};

const dollarRegex = /\$/g;
const dollarBillsYall = '$$';
const defaultTokenRegex = /%\{(.*?)\}/g;
const delimiter = '||||';

const russianPluralGroups = n => {
  let lastTwo = n % 100;
  let end = lastTwo % 10;
  if (lastTwo !== 11 && end === 1) {
    return 0;
  }
  if (2 <= end && end <= 4 && !(lastTwo >= 12 && lastTwo <= 14)) {
    return 1;
  }
  return 2;
};

const langToTypeMap = mapping => {
  let ret = {};
  forEach(mapping, function(langs, type) {
    forEach(langs, function(lang) {
      ret[lang] = type;
    });
  });
  return ret;
};

// Mapping from pluralization group plural logic.
const pluralTypes = {
  arabic: n => {
    // http://www.arabeyes.org/Plural_Forms
    if (n < 3) {
      return n;
    }
    let lastTwo = n % 100;
    if (lastTwo >= 3 && lastTwo <= 10) return 3;
    return lastTwo >= 11 ? 4 : 5;
  },
  bosnian_serbian: russianPluralGroups,
  chinese: () => {
    return 0;
  },
  croatian: russianPluralGroups,
  french: n => {
    return n > 1 ? 1 : 0;
  },
  german: n => {
    return n !== 1 ? 1 : 0;
  },
  russian: russianPluralGroups,
  lithuanian: n => {
    if (n % 10 === 1 && n % 100 !== 11) {
      return 0;
    }
    return n % 10 >= 2 && n % 10 <= 9 && (n % 100 < 11 || n % 100 > 19) ? 1 : 2;
  },
  czech: n => {
    if (n === 1) {
      return 0;
    }
    return n >= 2 && n <= 4 ? 1 : 2;
  },
  polish: n => {
    if (n === 1) {
      return 0;
    }
    let end = n % 10;
    return 2 <= end && end <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2;
  },
  icelandic: n => {
    return n % 10 !== 1 || n % 100 === 11 ? 1 : 0;
  },
  slovenian: n => {
    let lastTwo = n % 100;
    if (lastTwo === 1) {
      return 0;
    }
    if (lastTwo === 2) {
      return 1;
    }
    if (lastTwo === 3 || lastTwo === 4) {
      return 2;
    }
    return 3;
  },
};

const pluralTypeToLanguages = {
  arabic: ['ar'],
  bosnian_serbian: ['bs-Latn-BA', 'bs-Cyrl-BA', 'srl-RS', 'sr-RS'],
  chinese: [
    'id',
    'id-ID',
    'ja',
    'ko',
    'ko-KR',
    'lo',
    'ms',
    'th',
    'th-TH',
    'zh',
  ],
  croatian: ['hr', 'hr-HR'],
  german: [
    'fa',
    'da',
    'de',
    'en',
    'es',
    'fi',
    'el',
    'he',
    'hi-IN',
    'hu',
    'hu-HU',
    'it',
    'nl',
    'no',
    'pt',
    'sv',
    'tr',
  ],
  french: ['fr', 'tl', 'pt-br'],
  russian: ['ru', 'ru-RU'],
  lithuanian: ['lt'],
  czech: ['cs', 'cs-CZ', 'sk'],
  polish: ['pl'],
  icelandic: ['is'],
  slovenian: ['sl-SL'],
};

const pluralTypeName = locale => {
  let langToPluralType = langToTypeMap(pluralTypeToLanguages);
  return (
    langToPluralType[locale] ||
    langToPluralType[split.call(locale, /-/, 1)[0]] ||
    langToPluralType.en
  );
};

const pluralTypeIndex = (locale, count) => {
  return pluralTypes[pluralTypeName(locale)](count);
};

export const transformPhrase = (
  phrase,
  substitutions,
  locale = window.__OSG_RUNTIME_CONFIG__.locale,
  tokenRegex
) => {
  if (typeof phrase !== 'string') {
    throw new TypeError(
      'Polyglot.transformPhrase expects argument #1 to be string'
    );
  }

  if (substitutions == null) {
    return phrase;
  }

  let result = phrase;
  let interpolationRegex = tokenRegex || defaultTokenRegex;

  // allow number as a pluralization shortcut
  let options =
    typeof substitutions === 'number'
      ? { smart_count: substitutions }
      : substitutions;

  // Select plural form: based on a phrase text that contains `n`
  // plural forms separated by `delimiter`, a `locale`, and a `substitutions.smart_count`,
  // choose the correct plural form. This is only done if `count` is set.
  if (options.smart_count != null && result) {
    let texts = split.call(result, delimiter);
    result = (
      texts[pluralTypeIndex(locale || 'en', options.smart_count)] || texts[0]
    ).trim();
  }

  // Interpolate: Creates a `RegExp` object for each interpolation placeholder.
  result = replace.call(result, interpolationRegex, (expression, argument) => {
    if (!has(options, argument) || options[argument] == null) {
      return expression;
    }
    // Ensure replacement value is escaped to prevent special $-prefixed regex replace tokens.
    return replace.call(options[argument], dollarRegex, dollarBillsYall);
  });

  return result;
};
