// @flow
import { type JSSThemeVariablesType, type $DeepShape } from './JSSThemeDefault';

export const JSSThemeDefaultBannerItems = (
  themeVars: JSSThemeVariablesType
) => {
  const {
    colors: {
      colorTypes: { white, background2, shadow, text2, border1, black },
    },
    fontSizes,
    margins,
  } = themeVars;
  return {
    BannerContent: {
      bannerContentRoot: {
        color: text2,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      },
      bannerContentFill: {
        padding: margins.sm,
      },
      bannerContentSportsbookCarousel: {
        justifyContent: 'space-between',
      },
      bannerContentCasinoCarousel: {
        padding: margins.lg,
        justifyContent: 'center',
        zIndex: 1,
      },
      bannerContentVirtualsCarousel: {
        padding: margins.lg,
        justifyContent: 'center',
        zIndex: 1,
      },
      bannerContentCasinoLiveCarousel: {
        padding: margins.lg,
        justifyContent: 'center',
        zIndex: 1,
      },
      bannerContentCasinoCarouselMobile: {},
      bannerContentCasinoVirtualsMobile: {},
      bannerContentCasinoLiveCarouselMobile: {},
      bannerContentSportsbookSide: {
        padding: margins.sm,
      },
      bannerContentCasinoSide: {
        padding: margins.sm,
      },
      bannerContentPromo: {
        padding: margins.sm,
      },
      headerSportsbookCarousel: {
        fontSize: '26px',
        lineHeight: '1',
        fontWeight: 'bold',
        color: white,
      },
      headerCasinoCarousel: {
        fontSize: fontSizes.xxxl,
        fontWeight: 'bold',
        color: white,
      },
      headerVirtualsCarousel: {
        fontSize: fontSizes.xxxl,
        fontWeight: 'bold',
        color: white,
      },
      headerCasinoLiveCarousel: {
        fontSize: fontSizes.xxxl,
        fontWeight: 'bold',
        color: white,
      },
      headerSportsbookSide: {
        fontSize: fontSizes.xxxl,
        textShadow: `3px 3px 6px ${shadow}`,
        fontWeight: 'bold',
        color: white,
      },
      headerCasinoSide: {
        fontSize: fontSizes.xxxl,
        textShadow: `3px 3px 6px ${shadow}`,
        fontWeight: 'bold',
        color: white,
      },
      headerPromo: {
        fontSize: fontSizes.xxxl,
        lineHeight: '1',
        textShadow: `3px 3px 6px ${shadow}`,
        paddingBottom: 6,
      },
      headerMobile: {
        fontSize: fontSizes.xxl,
        lineHeight: `${fontSizes.xxl}px`,
      },
      descriptionRoot: {
        whiteSpace: 'pre-wrap',
      },
      descriptionSportsbookCarousel: {
        fontSize: fontSizes.md,
        color: background2,
        padding: [margins.xs, 0, 0],
      },
      descriptionCasinoCarousel: {
        fontSize: fontSizes.xl,
        color: white,
        padding: [margins.sm, 0, 0, 0],
      },
      descriptionVirtualsCarousel: {
        fontSize: fontSizes.xl,
        color: white,
        padding: [margins.sm, 0, 0, 0],
      },
      descriptionCasinoLiveCarousel: {
        fontSize: fontSizes.xl,
        color: white,
        padding: [margins.sm, 0, 0, 0],
      },
      descriptionSportsbookSide: {
        fontSize: fontSizes.xl,
        textShadow: `3px 3px 6px ${shadow}`,
        color: white,
        padding: [margins.xm, 0],
      },
      descriptionCasinoSide: {
        fontSize: fontSizes.xl,
        textShadow: `3px 3px 6px ${shadow}`,
        color: white,
        padding: [margins.xm, 0],
      },
      descriptionPromo: {
        fontSize: fontSizes.xxl,
        fontWeight: 'bold',
        padding: [margins.xm, 0],
      },
      descriptionMobile: {
        fontSize: fontSizes.lg,
        lineHeight: `${fontSizes.lg}px`,
      },
      textWrapperFill: {
        padding: margins.md,
      },
      textWrapperSportsbookCarousel: {},
      textWrapperCasinoCarousel: {},
      textWrapperVirtualsCarousel: {},
      textWrapperCasinoLiveCarousel: {},
      textWrapperSportsbookSide: {},
      textWrapperCasinoSide: {},
      textWrapperPromo: {},
      buttonsWrapperRoot: {
        margin: [margins.md, 0],
      },
      buttonsWrapperSportsbookCarousel: {},
      buttonsWrapperCasinoCarousel: {
        margin: `${margins.xm}px 0 0 0`,
      },
      buttonsWrapperVirtualsCarousel: {
        margin: `${margins.xm}px 0 0 0`,
      },
      buttonsWrapperCasinoLiveCarousel: {
        margin: `${margins.xm}px 0 0 0`,
      },
      buttonsWrapperSportsbookSide: {},
      buttonsWrapperCasinoSide: {},
      buttonsWrapperPromo: {},
    },
    BannerItemSportsbookCarousel: {
      bannerItemSportsbookCarouselRoot: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: margins.md, //del for last?
        overflow: 'hidden',
      },
      heightAuto: {
        height: 'auto',
      },
      heightFix: {
        height: '100%',
      },
      mobileItemSportsbookCarouselRoot: {
        marginRight: 0,
      },
      imgContainer: {
        height: '160px',
        backgroundColor: white,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      },
      imgOnlyContainer: {
        height: 'auto',
      },
      img: {
        width: '100%',
        height: 'auto',
      },
      contentWrapper: {
        width: '100%',
        height: '100%',
        padding: margins.md,
        backgroundColor: border1,
        flex: '1',
        display: 'flex',
      },
      contentWrapperMobile: {},
    },
    BannerItemSportsbookCarouselMobile: {
      contentWrapper: {},
      heightAuto: {
        height: 'auto',
      },
      heightFix: {
        height: '100%',
      },
      imgOnlyContainer: {
        height: 'auto',
      },
      img: {
        width: '100%',
        height: 'auto',
      },
      bannerItemSportsbookCarouselMobileRoot: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        fontWeight: 'bold',
        height: '100%',
      },
      imgContainer: {
        height: '100%',
        minHeight: 80,
        maxHeight: 110,
        overflow: 'hidden',
        backgroundColor: white,
        backgroundSize: 'cover',
        backgroundPosition: 'right top',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        flexDirection: 'column',
        padding: [margins.md, margins.xl],
      },
      header: {
        fontSize: fontSizes.xxxl,
        lineHeight: `${fontSizes.xxxl}px`,
        color: white,
        zIndex: 2,
        textShadow: `0px 0px 10px ${black}`,
      },
      description: {
        fontSize: fontSizes.xl,
        color: white,
        zIndex: 2,
        lineHeight: 1,
        textAlign: 'center',
        whiteSpace: 'pre-wrap',
        textShadow: `0px 0px 10px ${black}`,
      },
    },
    SliderArrows: {
      arrows: {
        position: 'absolute',
        top: 0,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: [0, margins.xm],
        fontSize: fontSizes.xxl,
        lineHeight: `${fontSizes.xxl}px`,
        color: white,
        zIndex: 2,
      },
      nextArrow: {
        right: 0,
      },
      prevArrow: {
        left: 0,
        zIndex: 2,
      },
    },
    CarouselSlidePrematch: {
      outcomesWrapBanner: {},
      carouselSlidePrematchRoot: {
        background: border1,
        padding: margins.md,
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        justifyContent: 'flex-end',
      },
      descriptionWrapper: {
        display: 'flex',
        flex: 1,
        fontSize: fontSizes.md,
      },
      descriptionTimeWrapper: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'right',
        color: background2,
        flexGrow: '2',
        flexShrink: '0',
        marginLeft: margins.md,
      },
      descriptionDate: {
        fontSize: 'inherit',
      },
      descriptionTime: {
        fontSize: 'inherit',
      },
      descriptionTeams: {
        fontSize: 'inherit',
        color: white,
        flexShrink: '2',
      },
      linkWrapper: {
        textAlign: 'right',
        margin: [0, 0, margins.md],
      },
      linkEvent: {
        fontSize: fontSizes.sm,
        textDecoration: 'underline',
      },
      imgContainer: {
        height: 270,
        backgroundColor: white,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      },
      sideBannerPrematchRoot: {
        trasition: 'all 2s',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      },
    },
    SideBanner: {
      sideBannerRoot: {},
      containerWithContent: {
        backgroundSize: 'cover',
        backgroundPosition: 'right center',
        backgroundRepeat: 'no-repeat',
        height: '350px',
        padding: margins.lg,
        display: 'block',
      },
    },
    BannerItemCasinoCarousel: {
      bannerItemCasinoCarouselRoot: {
        display: 'flex',
      },
      heightAuto: {
        height: 'auto',
      },
      heightFix: {
        height: 400,
      },
      img: {
        width: '100%',
        height: 'auto',
      },
      mobile: {
        height: '160px',
        '&:after': {
          content: `''`,
          position: 'absolute',
          width: '100%',
          height: '100%',
          zIndex: ' 0',
          background: `linear-gradient(to right, rgba(0, 0, 0, 0.7) 0%, rgba(10, 31, 50, 0) 20%, rgba(10, 31, 50, 0) 100%)`,
        },
      },
      content: {
        backgroundPosition: 'top right',
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flex: '1',
        position: 'relative',
        zIndex: 10,
      },
      contentWrapper: {
        width: 'auto',
        zIndex: '1',
      },
    },
    BannerItemVirtualsCarousel: {
      bannerItemVirtualsCarouselRoot: {
        display: 'flex',
      },
      heightAuto: {
        height: 'auto',
      },
      heightFix: {
        height: 400,
      },
      img: {
        width: '100%',
        height: 'auto',
      },
      mobile: {
        height: '160px',
        '&:after': {
          content: `''`,
          position: 'absolute',
          width: '100%',
          height: '100%',
          zIndex: ' 0',
          background: `linear-gradient(to right, rgba(0, 0, 0, 0.7) 0%, rgba(10, 31, 50, 0) 20%, rgba(10, 31, 50, 0) 100%)`,
        },
      },
      content: {
        backgroundPosition: 'top right',
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flex: '1',
        position: 'relative',
        zIndex: 10,
      },
      contentWrapper: {
        width: 'auto',
        zIndex: '1',
      },
    },
    BannerItemCasinoLiveCarousel: {
      bannerItemCasinoLiveCarouselRoot: {
        display: 'flex',
      },
      heightAuto: {
        height: 'auto',
      },
      heightFix: {
        height: 400,
      },
      img: {
        width: '100%',
        height: 'auto',
      },
      mobile: {
        height: '160px',
        '&:after': {
          content: `''`,
          position: 'absolute',
          width: '100%',
          height: '100%',
          zIndex: ' 0',
          background: `linear-gradient(to right, rgba(0, 0, 0, 0.7) 0%, rgba(10, 31, 50, 0) 20%, rgba(10, 31, 50, 0) 100%)`,
        },
      },
      content: {
        backgroundPosition: 'top right',
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flex: '1',
        position: 'relative',
        zIndex: 10,
      },
      contentWrapper: {
        width: 'auto',
        zIndex: '1',
      },
    },
  };
};

export type JSSThemeDefaultBannerItemsType =
  (JSSThemeVariablesType) => $DeepShape<
    $Call<typeof JSSThemeDefaultBannerItems, JSSThemeVariablesType>
  >;
