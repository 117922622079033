/* eslint-disable */

/**
 * @author Push Technology Ltd
 * @class This is the DiffusionClient singleton.
 *
 * DiffusionClient class
 */
var DiffusionClient = new (function () {
  this.version = '5.1.5';
  this.buildNumber = '01';
  this.isInvalidFunction = false;
  this.listeners = [];
  this.serviceListeners = [];
  this.credentials = null;
  this.topicListenerRef = 0;
  this.serviceListenerRef = 0;
  this.reconnectAttempts = 0;
  this.isDebugging = false;
  this.serverProtocolVersion = -1;
  this.messageLengthSize = -1;
  this.fragmentMap = {};

  if (navigator.appVersion.match('MSIE') == 'MSIE') {
    /**
     * True if browser is IE
     * @returns {Boolean}
     */
    this.isIE = true;
  } else {
    this.isIE = false;
  }

  if (navigator.appVersion.match('MSIE 9.0') == 'MSIE 9.0') {
    /**
     * True if browser IE9
     * @returns {Boolean}
     */
    this.isIE9 = true;
  } else {
    this.isIE9 = false;
  }

  if (navigator.userAgent.indexOf('Firefox') == -1) {
    /**
     * True if browser Firefox
     * @returns {Boolean}
     */
    this.isFirefox = false;
  } else {
    this.isFirefox = true;
  }

  if (navigator.platform.indexOf('Win') == -1) {
    /**
     * True if platform is windows
     * @returns {Boolean}
     */
    this.isWindows = false;
  } else {
    this.isWindows = true;
  }
  /**
   * Connect to Diffusion using a DiffusionClientConnectionDetails Object, with optional credentials
   * @param {Object} connectionDetails See connection details class for more information {@link DiffusionClientConnectionDetails}
   * @param {DiffusionClientCredentials} credentials See client credentials class for more information {@link DiffusionClientCredentials}
   * @example
   * DiffusionClient.connect({ topic : "Fred", onDataFunction: function(msg){ // do something } });
   *
   * @example
   * DiffusionClient.connect({ topic : "Fred", onDataFunction: function(msg){ // do something } }, { username : "Bert", password : "admin" });
   */
  this.connect = function (connectionDetails, credentials) {
    if (this.isConnected()) {
      DiffusionClient.close();
    }

    if (credentials) {
      this.setCredentials(credentials);
    }

    this.connectionDetails = this.extend(
      new DiffusionClientConnectionDetails(),
      connectionDetails
    );

    if (this.connectionDetails.debug == true) {
      this.setDebugging(true);
    }

    this.trace(navigator.userAgent);
    this.trace(
      'DiffusionClient: Version ' + this.version + ' build ' + this.buildNumber
    );

    if (typeof connectionDetails.onInvalidClientFunction == 'function') {
      this.isInvalidFunction = true;
    }

    setTimeout(function () {
      if (DiffusionClient.diffusionTransport.isConnected == false) {
        if (
          typeof DiffusionClient.connectionDetails.callbackFunction ==
          'function'
        ) {
          DiffusionClient.connectionDetails.callbackFunction(false);
        }
      }
    }, this.connectionDetails.timeoutMS);

    // Lets make sure that the DiffusionContainer exists, if not create it
    var container = document.getElementById('DiffusionContainer');
    if (container == null) {
      container = document.createElement('div');
      container.id = 'DiffusionContainer';
      container.style.width = '0px';
      container.style.height = '0px';
      document.body.appendChild(container);
    }

    this.diffusionTransport = new DiffusionClientTransport();
    this.diffusionTransport.cascade();

    window.onbeforeunload = function () {
      if (
        typeof DiffusionClient.connectionDetails.onBeforeUnloadFunction ==
        'function'
      ) {
        DiffusionClient.connectionDetails.onBeforeUnloadFunction();
      }
      if (DiffusionClient.diffusionTransport != null) {
        if (DiffusionClient.diffusionTransport.isConnected) {
          DiffusionClient.close();
        }
      }
    };

    document.onkeydown = this.checkEscape;
    document.onkeypress = this.checkEscape;
  };

  /**
   * Reconnect to Diffusion using existing DiffusionClientConnectionDetails and Client ID.
   * Utilises existing onCallbackFunction to indicate success or failure, with a second
   * boolean parameter to denote that this is a reconnect result.
   *
   * Example for automatic reconnecting:
   *
   * DiffusionClient.connect({
   * 	onCallbackFunction : function( isConnected, isReconnect) {
   * 		if( !isConnected && isReconnect ) DiffusionClient.reconnect();
   * 		}
   *  },
   *  onLostConnectionFunction : function() {
   *  	DiffusionClient.reconnect();
   *  }
   * });
   *
   */
  this.reconnect = function () {
    if (
      DiffusionClient.diffusionTransport &&
      !DiffusionClient.diffusionTransport.isConnected &&
      DiffusionClient.getClientID()
    ) {
      this.diffusionTransport.reconnect();
    }
  };

  /**
   * Check if the Diffusion Client is currently connected
   * @returns {Boolean} Whether the client is connected or not
   */
  this.isConnected = function () {
    if (DiffusionClient.diffusionTransport) {
      return DiffusionClient.diffusionTransport.isConnected;
    }
    return false;
  };

  /**
   * Check if the Diffusion Client is connected as a result of reconnection
   * @returns {Boolean} Whether the client has been reconnected or not
   */
  this.isReconnected = function () {
    if (DiffusionClient.diffusionTransport) {
      return (
        DiffusionClient.diffusionTransport.isConnected &&
        DiffusionClient.diffusionTransport.isReconnected
      );
    }

    return false;
  };

  /**
   * Set the user credentials
   * @param {credentials} DiffusionClientCredentials The credentials to be supplied
   */
  this.setCredentials = function (credentials) {
    if (credentials != null) {
      this.credentials = this.extend(
        new DiffusionClientCredentials(),
        credentials
      );
    }
  };
  /**
   * Get the credentials for this client
   * @returns {DiffusionClientCredentials}
   */
  this.getCredentials = function () {
    return this.credentials;
  };
  /**
   * Subscribe to a topic. If a subscription is made to an already subscribed topic, this method is a no-op.
   * @param {String} topic or TopicSet pattern
   */
  this.subscribe = function (topic) {
    if (this.isTransportValid()) {
      this.diffusionTransport.subscribe(topic);
    }
  };
  /**
   * Unsubscribe from a topic
   * @param {String} topic or TopicSet pattern
   */
  this.unsubscribe = function (topic) {
    if (this.isTransportValid()) {
      this.diffusionTransport.unsubscribe(topic);
    }
  };
  /**
   * Send TopicMessage.
   * @param {topicMessage} TopicMessage See {@link TopicMessage}
   */
  this.sendTopicMessage = function (topicMessage) {
    if (this.isTransportValid()) {
      var message = topicMessage.getMessage();
      if (
        (message != null && message != '') ||
        topicMessage.userHeaders != null
      ) {
        this.diffusionTransport.sendTopicMessage(topicMessage);
      }
    }
  };
  /**
   * Send a message to the Diffusion Server on a given topic with the specified message
   * @param {String} topic
   * @param {String} message
   */
  this.send = function (topic, message) {
    if (this.isTransportValid()) {
      if (message != null && message != '') {
        this.diffusionTransport.send(topic, message);
      }
    }
  };
  /**
   * Send a credentials message to the Diffusion Server
   * @param {Object} credentials
   */
  this.sendCredentials = function (credentials) {
    this.setCredentials(credentials);
    if (this.isTransportValid()) {
      this.diffusionTransport.sendCredentials(this.credentials);
    }
  };
  /**
   * Send a ping request to the Diffusion server.  The response to this will be a {@link PingMessage}
   */
  this.ping = function () {
    if (this.isTransportValid()) {
      this.diffusionTransport.ping();
    }
  };
  /**
   * Send a message acknowledgement back to the server.  This will be required if autoAck is set to false
   * @param WebClientMessage The WebClientMessage to acknowledge
   */
  this.acknowledge = function (webClientMessage) {
    if (this.isTransportValid()) {
      var ackID = webClientMessage.getAckID();
      if (ackID != null) {
        this.diffusionTransport.sendAckResponse(ackID);
        webClientMessage.setAcknowledged();
      }
    }
  };
  /**
   * Fetch topic state on a given topic. This data will arrive on the onData method and any topic listeners
   * @param {String} topic or TopicSet expression
   * @param {String} correlationID
   */
  this.fetch = function (topic, correlationID) {
    if (this.isTransportValid()) {
      if (correlationID) {
        this.diffusionTransport.fetch(topic, correlationID);
      } else {
        this.diffusionTransport.fetch(topic, null);
      }
    }
  };
  /**
   * Send a command message. The topic message contains the topic name, headers and message body
   * to be sent to the Diffusion server.
   * @param {String} command The command to send
   * @param {int} correlationID
   * @param {topicMessage} TopicMessage See {@link TopicMessage}
   */
  this.command = function (command, correlationID, topicMessage) {
    if (this.isTransportValid()) {
      this.diffusionTransport.command(command, correlationID, topicMessage);
    }
  };
  /**
   * Send a page message. This is a variant of a command message, but does not require
   * a correlation ID.
   * @param {String} Type The page command type (e.g. "O", "R", "N", etc)
   * @param {topicMessage} TopicMessage See {@link TopicMessage}
   */
  this.page = function (type, topicMessage) {
    if (this.isTransportValid()) {
      this.diffusionTransport.command(type, null, topicMessage);
    }
  };

  /**
   * Check if the current transport being used by Diffusion Client is valid
   * @returns {Boolean} Whether the transport is valid
   */
  this.isTransportValid = function () {
    if (!this.diffusionTransport) {
      return false;
    } else {
      return this.diffusionTransport.isValid();
    }
  };
  /**
   * Close the connection
   */
  this.close = function () {
    if (this.diffusionTransport != null) {
      this.diffusionTransport.close();
    }
  };
  /**
   * Add a topic listener
   * @param {String} regex The regex is a String representation of a matches regex pattern on the topic name.  For an exact match, use ^topicname$
   * @param {function} function This is the function to all with a WebClientMessage if the pattern matches
   * @param {Object} context When the function is called above, it will retain the this context of what was passed to this method
   * @returns {int} a reference to the topic loader, this is required if you wish to remove the topic loader
   */
  this.addTopicListener = function (regex, functionPointer, thisContext) {
    var handle = this.topicListenerRef++;
    if (typeof thisContext == 'undefined') {
      thisContext = arguments.callee;
    }
    this.listeners.push(
      new TopicListener(regex, functionPointer, handle, thisContext)
    );
    return handle;
  };

  /**
   * Add a Timed Topic listener
   * @param {String} regex The regex is a String representation of a matches regex pattern on the topic name.  For an exact match, use ^topicname$
   * @param {function} functionPointer This is the function to all with a WebClientMessage if the pattern matches
   * @param {int} time Interval in millisecond to call the @function.
   * @param {boolean} force Call the @function even if any message has been received
   * @param {Object} thisContext When the function is called above, it will retain the this context of what was passed to this method
   * @returns {int} a reference to the topic loader, this is required if you wish to remove the topic loader
   */
  this.addTimedTopicListener = function (
    regex,
    functionPointer,
    time,
    force,
    thisContext
  ) {
    var handle = this.topicListenerRef++;
    if (typeof thisContext == 'undefined') {
      thisContext = arguments.callee;
    }
    this.listeners.push(
      new TimedTopicListener(
        regex,
        functionPointer,
        handle,
        time,
        force,
        thisContext
      )
    );
    return handle;
  };

  /**
   * Add a service listener
   *
   * @param {String} regex The regex is a String representation of a matches regex pattern on the topic name.  For an exact match, use ^topicname$
   * @param {function} function This is the function to call with a WebClientMessage if the pattern matches
   * @param {Object} thisContext When the function is called above, it will retain the this context of what was passed to this method
   * @returns {int} ListenerID A reference to the topic listener, this is required if you wish to remove the topic listener
   */
  this.addServiceListener = function (regex, functionPointer, thisContext) {
    var handle = this.serviceListenerRef++;
    if (typeof thisContext == 'undefined') {
      thisContext = arguments.callee;
    }
    this.serviceListeners.push(
      new TopicListener(regex, functionPointer, handle, thisContext)
    );
    return handle;
  };

  /**
   * Adds a Service topic listener, taking the topic from the supplied message.
   *
   * @param {TopicMessage} TopicMessage The message from which the appropriate Topic is taken.
   * @param {function} function The handler function that will be called by the listener
   * @returns {number} ListenerID A reference to the generated topic listener
   */
  this.createServiceTopicHandler = function (message, handler) {
    return this.addServiceListener('^' + message.getTopic() + '$', handler);
  };

  /**
   * Creates a handler for topic notifications.
   * @param {WebClientMessage} message The ITL for the notification topic
   * @param {TopicNotifyTopicListener} listener The listener object
   * @returns {TopicNotifyTopicHandler}
   * @since 4.6
   * @author Matt Champion
   */
  this.createTopicNotifyTopicHandler = function (message, listener) {
    return new TopicNotifyTopicHandler(message, this, listener);
  };

  /**
   * Create a handler for a paged topic data topic.
   * <P>
   * The handler is created asynchronously. The ready callback of the
   * listener should be used for the code that you run when the handler is
   * created.
   * @param {string} topicName The name of the paged topic
   * @param {PagedTopicListener} listener The PageTopicListener to use
   * @returns {PagedTopicHandler}
   * @since 5.0
   * @author Matt Champion
   */
  this.createPagedTopicHandler = function (topicName, listener) {
    var topicListener = DiffusionClient.addTopicListener(
      '^' + topicName + '$',
      function (message) {
        var handler = new PagedTopicHandler(message, listener);
        handler.pageListener.ready(handler);
        DiffusionClient.removeTopicListener(topicListener);
      }
    );
    DiffusionClient.subscribe(topicName);
  };

  /**
   * Remove the handler and unsubscribe from the paged topic.
   * @param {PagedTopicHandler} handler The handler to remove
   * @since 5.0
   * @author Matt Champion
   */
  this.removePagedTopicHandler = function (handler) {
    DiffusionClient.unsubscribe(handler.topicName);
    DiffusionClient.removeTopicListener(handler.topicListener);
    handler.isOpen = false;
  };

  /**
   * Remove the topic listener
   * @param {int} handle
   */
  this.removeTopicListener = function (handle) {
    var topicListener;
    for (var i = 0; i < this.listeners.length; i++) {
      topicListener = this.listeners[i];
      if (topicListener.getHandle() == handle) {
        this.listeners.splice(i, 1);
        return;
      }
    }
  };

  /**
   * Remove the Timed topic listener
   * @param {int} handle
   */
  this.removeTimedTopicListener = function (handle) {
    var timedTopicListener;
    for (var i = 0; i < this.listeners.length; i++) {
      timedTopicListener = this.listeners[i];
      if (timedTopicListener.getHandle() == handle) {
        timedTopicListener.stop();
        this.listeners.splice(i, 1);
        return;
      }
    }
  };

  /**
   * Remove all topic listeners
   *
   * This function would be required, if the connection was lost and the client was going to used to re-connect
   */
  this.removeAllTopicListeners = function () {
    this.listeners = [];
  };

  /**
   * @ignore
   */
  this.checkEscape = function (e) {
    if (!e) e = event;
    if (e.keyCode == 27) return false;
  };
  /**
   * Extends objA by copying all properties of objB to objA.
   * @param {Object} objA
   * @param {Object} objB
   * @returns {Object} objA with all elements of objB copied into it
   */
  this.extend = function (objA, objB) {
    for (var i in objB) {
      objA[i] = objB[i];
    }
    return objA;
  };
  /**
   * Bind a function to a given context
   * @param {Function} function
   * @param {Object} context
   * @returns {function} The bound function
   */
  this.bind = function (fn, thisObj) {
    return function () {
      var args = Array.prototype.slice.call(arguments, 0);
      return fn.apply(thisObj, args);
    };
  };
  /**
   * Get the current client ID
   * @return {String} the Diffusion ClientID
   */
  this.getClientID = function () {
    return this.diffusionTransport.clientID;
  };
  /**
   * Get the client protocol version
   * @return {number} the client protocol version number
   */
  this.getClientProtocolVersion = function () {
    return 4;
  };
  /**
   * Get the name of the current transport
   * @return {String} the name of the transport used
   */
  this.getTransportName = function () {
    return this.diffusionTransport.transportName;
  };
  /**
   * Get the server protocol version
   * @return {number} the server protocol version number
   */
  this.getServerProtocolVersion = function () {
    return this.serverProtocolVersion;
  };

  /**
   * Set debugging for Diffusion
   * @param {Boolean} value
   */
  this.setDebugging = function (value) {
    if (value == false) {
      // Set the trace function to be empty
      /**
       * @ignore
       */
      this.trace = function (message) {};
      this.isDebugging = false;
    } else {
      this.isDebugging = true;

      if (window.console && window.console.log) {
        /**
         * @ignore
         */
        this.trace = function (message) {
          console.log(DiffusionClient.timestamp() + message);
        };
      } else if (window.opera && opera.postError) {
        /**
         * @ignore
         */
        this.trace = function (message) {
          opera.postError(DiffusionClient.timestamp() + message);
        };
      } else {
        this.isDebugging = false;
        // No logging possible..
        /**
         * @ignore
         */
        this.trace = function (message) {};
      }
    }
  };
  /**
   * Send output to the Debug Console
   * @param {Object} message
   */
  this.trace = function (message) {
    // This is an empty function as it will be populated
    // by DiffusionClient.setDebugging(boolean);
  };
  /**
   * Format current time in timestamp format of HH:mm:ss.SS
   * @returns {String} timestamp
   */
  this.timestamp = function () {
    var date = new Date();
    return (
      date.getHours() +
      ':' +
      date.getMinutes() +
      ':' +
      date.getSeconds() +
      '.' +
      date.getMilliseconds() +
      ' : '
    );
  };
  /**
   * Gets the last time (milliseconds since epoch) there was any interaction between the client and the server
   * @returns {number} Last interaction time in milliseconds since epoch
   */
  this.getLastInteraction = function () {
    return this.diffusionTransport.getLastInteraction();
  };
  /**
   * Detect if flash present, with the minimum version passed as a parameter
   * @param {String} version The minimum version required
   * @returns {Boolean} true if flash player at the version specified is present
   */
  this.hasFlash = function (version) {
    if (window.ActiveXObject) {
      try {
        obj = new ActiveXObject('ShockwaveFlash.ShockwaveFlash');
        ver = obj
          .GetVariable('$version')
          .replace(/([a-z]|[A-Z]|\s)+/, '')
          .split(',');
        if (ver[0] >= version) {
          return true;
        }
      } catch (ignore) {
        return false;
      }
    }
    if (navigator.plugins && navigator.mimeTypes.length) {
      try {
        var x = navigator.plugins['Shockwave Flash'];
        if (x && x.description) {
          ver = x.description
            .replace(/([a-z]|[A-Z]|\s)+/, '')
            .replace(/(\s+r|\s+b[0-9]+)/, '.')
            .split('.');
          if (ver[0] >= version) {
            return true;
          }
        } else {
          return false;
        }
      } catch (ig) {
        return false;
      }
    }
  };
  /**
   * Detect if Silverlight present, this currently looks for version 4.0.5 or above
   * @returns {Boolean} true if a silverlight player is present
   */
  this.hasSilverlight = function () {
    try {
      var plugin = null;
      if (this.isIE) {
        plugin = new ActiveXObject('AgControl.AgControl');
        if (plugin) {
          return plugin.isVersionSupported('4.0.5');
        } else {
          return false;
        }
      }
      if (navigator.plugins['Silverlight Plug-In']) {
        container = document.createElement('div');
        document.body.appendChild(container);
        container.innerHTML =
          '<embed type="application/x-silverlight" src="data:," />';
        plugin = container.childNodes[0];
        var result = plugin.isVersionSupported('4.0.5');
        document.body.removeChild(container);
        return result;
      }
      return false;
    } catch (ignore) {
      return false;
    }
  };
  /**
   * Returns the Web Socket object (if any).
   * @returns {Websocket} the Web Socket object
   */
  this.getWebSocket = function (url) {
    var webSocket = null;

    if ('WebSocket' in window) {
      webSocket = new WebSocket(url);
    } else if ('MozWebSocket' in window) {
      webSocket = new MozWebSocket(url);
    }
    return webSocket;
  };
})();
/**
 * @author Push Technology Ltd
 * @class DiffusionRecord
 *
 * A class representing a Record. This provides an API for putting values into a message.
 */
var DiffusionRecord = function () {
  this.fields = [];
  DiffusionRecord.prototype.addFields.apply(this, arguments);
};

DiffusionRecord.prototype.fieldDelimiter = '\u0002';
DiffusionRecord.prototype.recordDelimiter = '\u0001';

/**
 * Set the field value at a given index
 * @param {Number} index The field index
 * @param {String} field The value to set
 */
DiffusionRecord.prototype.setField = function (index, field) {
  if (this.fields[index]) {
    this.fields[index] = field;
  }
};

/**
 * Add a field at a particular index
 * <p>If the index is greater than current field list, the field will be appended
 *
 * @param {Number} index The index at which to add the field
 * @param {String} field the value to set
 */
DiffusionRecord.prototype.addField = function (index, field) {
  // Optimise for general case of appending fields - push is faster than splice
  if (index >= this.fields.length) {
    this.fields.push(field);
  } else {
    this.fields.splice(index, 0, field);
  }
};

/**
 * Append one or more fields to this record
 * @param {String[]|String...} fields An array of Strings or multiple string arguments
 */
DiffusionRecord.prototype.addFields = function (field) {
  if (field) {
    var fields = typeof field === 'string' ? arguments : field;
    var length = fields.length,
      i = 0;

    for (; i < length; ++i) {
      this.fields.push(fields[i]);
    }
  }
};

/**
 * Removes the field at the specified index
 * @param {Number} index
 */
DiffusionRecord.prototype.removeField = function (index) {
  this.fields.splice(index, 1);
};

/**
 * Gets the field at the specified index
 * @param {Number} index
 * @returns {String} field
 */
DiffusionRecord.prototype.getField = function (index) {
  return this.fields[index];
};

/**
 * Get a copy of all fields in this record as an array
 * @returns {Array} field array
 */
DiffusionRecord.prototype.getFields = function () {
  return this.fields.concat();
};

/**
 * Returns the string representation of this Record (with correct delimiters)
 * @returns {String} the string representation
 */
DiffusionRecord.prototype.toString = function () {
  return this.fields.join(this.fieldDelimiter);
};

/**
 * Returns the number of fields in this Record
 * @returns {Number} number of fields.
 */
DiffusionRecord.prototype.size = function () {
  return this.fields.length;
};
/**
 * @author Push Technology Ltd
 * @class WebClientMessage
 *
 * This is the class that all normal messages from the server will be instantiated with.
 */
var WebClientMessage = function (response, messageCount) {
  this.messageCount = messageCount;
  this.messageType = response.charCodeAt(0);
  this.timeStamp = new Date();

  this.ackID = null;
  this.needsAcknowledge = false;

  this.records = [];
  this.headers = [];

  // Maintain lengths and pointers to allow us to traverse through fields/records easily
  this.fieldslength = 0;

  this.fieldPointer = 0;
  this.recordPointer = 0;
  this.recordFieldPointer = 0;

  this.header = '';
  this.message = '';

  this.payload = response;
  this.parseResponse(response);

  this.recordlength = this.records.length;
  this.headerlength = this.headers.length;

  this.topic = this.headers.shift().slice(1);

  if (this.messageType == 30 || this.messageType == 31) {
    this.ackID = this.headers.shift();
    this.needsAcknowledge = true;
  }
};

WebClientMessage.prototype.displayFormatRecordRegex = new RegExp(
  DiffusionRecord.prototype.recordDelimiter,
  'g'
);
WebClientMessage.prototype.displayFormatFieldRegex = new RegExp(
  DiffusionRecord.prototype.fieldDelimiter,
  'g'
);

/**
 * Get the header for this message
 * @returns {String} the header information of the message
 */
WebClientMessage.prototype.getHeader = function () {
  return this.header;
};

/**
 * Get the body of this message as String
 * @returns {String} the message sent from Diffusion to the client
 */
WebClientMessage.prototype.getBody = function () {
  return this.payload.substr(this.getHeader().length + 1);
};

/**
 * Check if this message is an ITL message
 * @returns {Boolean} true if the message type is a ITL message
 */
WebClientMessage.prototype.isInitialTopicLoad = function () {
  return this.messageType == 20 || this.messageType == 30;
};

/**
 * Check if this message is a fetch message
 * @returns {Boolean}
 */
WebClientMessage.prototype.isFetchMessage = function () {
  return this.messageType == 34;
};

/**
 * Check if this message is a delta message
 * @returns {Boolean} true if the message type is a delta message
 */
WebClientMessage.prototype.isDeltaMessage = function () {
  return this.messageType == 21 || this.messageType == 31;
};

/**
 * Get the topic on which this message was sent
 * @returns {String} the topic of this message
 */
WebClientMessage.prototype.getTopic = function () {
  return this.topic;
};

/**
 * Set the topic for this message
 * @param {String} topic
 */
WebClientMessage.prototype.setTopic = function (topic) {
  this.topic = topic;
};

/**
 * Get the number of records in this message
 * @returns {Number} the number of Diffusion records held in this message
 */
WebClientMessage.prototype.getNumberOfRecords = function () {
  return this.records.length;
};

/**
 * Get the fields contained within the specified record
 * @param {Number} index
 * @returns {Array} fields contained in the record of the given index
 */
WebClientMessage.prototype.getFields = function (index) {
  if (this.records[index]) {
    return this.records[index].getFields();
  }
};

/**
 * Get the record at the specified index
 * @param {Number} index
 * @returns {DiffusionRecord} record at given index
 */
WebClientMessage.prototype.getRecord = function (index) {
  return this.records[index];
};

/**
 * Get the records contained within this message
 * @returns {Array} records contained in the message
 */
WebClientMessage.prototype.getRecords = function () {
  return this.records.concat();
};

/**
 * Checks to see if there is unread data in the message
 * @returns {Boolean}
 */
WebClientMessage.prototype.hasRemaining = function () {
  return this.fieldslength > this.fieldPointer;
};

/**
 * Returns the next available field if it exists
 * @returns {String} next field from record
 */
WebClientMessage.prototype.nextField = function () {
  // Loop to next record with content (if necessary)
  while (
    this.records[this.recordPointer] &&
    this.records[this.recordPointer].size() <= this.recordFieldPointer
  ) {
    this.recordPointer++;
    this.recordFieldPointer = 0;
  }

  if (this.records[this.recordPointer]) {
    this.fieldPointer++;
    return this.records[this.recordPointer].getField(this.recordFieldPointer++);
  }
};

/**
 * Returns the next available record if it exists
 * @returns {DiffusionRecord} next record in message
 */
WebClientMessage.prototype.nextRecord = function () {
  if (this.recordlength > this.recordPointer) {
    this.fieldPointer +=
      this.records[this.recordPointer].size() - this.recordFieldPointer;
    this.recordFieldPointer = 0;
    return this.records[this.recordPointer++];
  }
};
/**
 * Reset all record and field pointers
 */
WebClientMessage.prototype.rewind = function () {
  this.fieldPointer = this.recordPointer = this.recordFieldPointer = 0;
};

/**
 * Get the JSON representation of this message, if sent as such
 * @returns {Object} the message as an object if the message was sent in JSON format
 */
WebClientMessage.prototype.getJSONObject = function () {
  var jsonString = this.records[0].getField(0);

  if (window.JSON && typeof window.JSON.parse === 'function') {
    return JSON.parse(jsonString);
  } else {
    return eval('(' + jsonString + ')');
  }
};

/**
 * Get a date representing when this message was created
 * @returns {Date} the time of when the message was created
 */
WebClientMessage.prototype.getTimestampAsDate = function () {
  return this.timeStamp;
};

/**
 * Get a localised String representation of when this message was created
 * @returns {String} the message creation time in locale date format
 */
WebClientMessage.prototype.localeTimeString = function () {
  return this.timeStamp.toLocaleTimeString();
};

/**
 * Get the sequence number of this message.
 * <p>This is also the same as the number of messages that have been produced by this connection.
 * @returns {Number} message count
 */
WebClientMessage.prototype.getMessageCount = function () {
  return this.messageCount;
};

/**
 * Get user headers
 * @returns {Array} an array of user headers if any were sent with the message or an empty array if none were sent
 */
WebClientMessage.prototype.getUserHeaders = function () {
  return this.headers;
};

/**
 * Get the header at a particular index
 * @param {Number} index
 * @returns {String} the user header at a given index
 */
WebClientMessage.prototype.getUserHeader = function (index) {
  return this.headers[index];
};

/**
 * Get the final part of the topic name
 * @returns {String} the final element of the topic name
 * @example if the topic name is a/b/c this function would return c
 */
WebClientMessage.prototype.getBaseTopic = function () {
  return this.topic.substr(this.topic.lastIndexOf('/') + 1, this.topic.length);
};

/**
 * Check if the message requires an Acknowledgement
 * @returns {Boolean} true if this message needed an Acknowledgement.  There is no need for the user to send the Ack, as the transport will do this
 */
WebClientMessage.prototype.isAckMessage = function () {
  return this.messageType == 30 || this.messageType == 31;
};

/**
 * If this message requires an acknowledgement, get the Ack ID
 * @returns {String} the Ack ID
 */
WebClientMessage.prototype.getAckID = function () {
  return this.ackID;
};

/**
 * This method is used internally when the message has been acknowledged
 */
WebClientMessage.prototype.setAcknowledged = function () {
  this.needsAcknowledge = false;
};

/**
 * Check whether this message has been acknowledged
 * @returns {Boolean} true if the message needs to be acknowledged
 */
WebClientMessage.prototype.needsAcknowledgement = function () {
  return this.needsAcknowledge;
};

/**
 * Get a string representation of the message, with human-readable delimiters
 * @returns {String} the messages with <RD>'s and <FD>'s
 */
WebClientMessage.prototype.displayFormat = function () {
  var display = '';
  if (this.headerslength > 0) {
    display = '[' + this.headers.join('|') + ']';
  }

  display += this.getBody().replace(this.displayFormatRecordRegex, '<RD>');
  display = display.replace(this.displayFormatFieldRegex, '<FD>');

  return display;
};

/**
 * Sets records and headers from response string
 * @param {String} response
 */
WebClientMessage.prototype.parseResponse = function (response) {
  var parts = response.split(DiffusionRecord.prototype.recordDelimiter),
    length = parts.length,
    i = 0;
  for (; i < length; ++i) {
    var fields = parts[i].split(DiffusionRecord.prototype.fieldDelimiter);

    if (i === 0) {
      this.header = parts[i];
      this.headers = fields;
    } else {
      this.fieldslength += fields.length;
      this.records.push(new DiffusionRecord(fields));
    }
  }
};
/**
 * @author Push Technology Ltd
 * @class CommandMessage
 */
var CommandMessage = function (response, messageCount) {
  WebClientMessage.call(this, response, messageCount);

  if (this.isInitialTopicLoad()) {
    this.category = this.headers.shift();
    this.topicType = this.headers.shift();
    this.notificationType = undefined;
  } else {
    this.category = undefined;
    this.topicType = undefined;
    this.notificationType = this.headers.shift();
  }
};

for (var i in WebClientMessage.prototype) {
  CommandMessage.prototype[i] = WebClientMessage.prototype[i];
}

/**
 * Check if this message is an ITL
 * @returns {Boolean}
 */
CommandMessage.prototype.isInitialTopicLoad = function () {
  return this.messageType == 40;
};

/**
 * Check if this message is a Delta message
 * @returns {Boolean}
 */
CommandMessage.prototype.isDeltaMessage = function () {
  return this.messageType == 41;
};

/**
 * Get the category for this command message
 * @returns {String|undefined} category
 */
CommandMessage.prototype.getCategory = function () {
  return this.category;
};

/**
 * Get the type of topic for this command message
 * @returns {String|undefined} type
 */
CommandMessage.prototype.getTopicType = function () {
  return this.topicType;
};

/**
 * Get the type of notification for this message
 * @returns {String} notification type
 */
CommandMessage.prototype.getNotificationType = function () {
  return this.notificationType;
};

CommandMessage.prototype.SERVICE_CATEGORY = 0;
CommandMessage.prototype.PAGED_CATEGORY = 1;
DiffusionClient.internal = DiffusionClient.internal || {};

/**
 * Object for constants
 * NOTE: These values may be repeated where these values cannot be access such
 * as property names in object notation.
 * @private
 * @author Matt Champion
 * @since 5.0
 */
DiffusionClient.internal.constants = {
  delimiters: {
    RD: '\u0001',
    FD: '\u0002',
    MD: '\u0008',
  },
  httpHeaders: {
    METHOD: 'm',
    CLIENT_ID: 'c',
    SEQUENCE: 's',
    POST: 'POST',
  },
  methods: {
    CONNECT: '0',
    POLL: '1',
    SEND: '2',
    DUPLEX_CONNECT: '3',
    BATCH_SEND: '5',
    LOAD: '20',
    DELTA: '21',
    SUBSCRIBE: '22',
    UNSUBSCRIBE: '23',
    PING_SERVER: '24',
    PING_CLIENT: '25',
    CREDENTIALS: '26',
    CRED_REJECTED: '27',
    ABORT: '28',
    CLOSE: '29',
    ACK: '32',
    FETCH: '33',
    TOPIC_STATUS: '35',
    COMMAND: '36',
    COMMAND_LOAD: '40',
    COMMAND_NOTIF: '41',
  },
  connectionTypes: {
    BROWSER_STREAMING: 'BS',
    BROWSER: 'B',
  },
  connectionResponses: {
    SUCCESSFUL: '100',
    INVALID_PROTOCOL: '101',
    INVALID_TPOICS: '103',
    SUCCESSFUL_RECONNECT: '105',
    REJECTED: '111',
    UNDEFINED_ERROR: '127',
  },
};

// Freeze object if possible
if (Object.freeze) {
  function freezeTree(node) {
    if (typeof node === 'object') {
      for (var child in node) {
        freezeTree(node[child]);
      }
      Object.freeze(node);
    }
  }
  freezeTree(DiffusionClient.internal.constants);
}
/**
 * @author Push Technology Ltd
 * @class DiffusionAckProcess
 */
var DiffusionAckProcess = function (topicMessage) {
  DiffusionClient.trace('DiffusionAckProcess ' + topicMessage.getAckID());
  this.topicMessage = topicMessage;
  // Set up the timer event
  var _this = this;
  this.timeout = setTimeout(function () {
    _this.onTimeout(_this);
  }, topicMessage.getAckTimeout());
};
DiffusionAckProcess.prototype.cancel = function () {
  DiffusionClient.trace(
    'DiffusionAckProcess: cancel ' + this.topicMessage.getAckID()
  );
  clearTimeout(this.timeout);
};
DiffusionAckProcess.prototype.onTimeout = function (ackProcess) {
  DiffusionClient.trace(
    'DiffusionAckProcess: onTimeout ' + this.topicMessage.getAckID()
  );
  try {
    DiffusionClient.connectionDetails.onMessageNotAcknowledgedFunction(
      this.topicMessage
    );
  } catch (e) {
    DiffusionClient.trace(
      'DiffusionAckProcess: unable to call onMessageNotAcknowledged ' + e
    );
  }
};
/**
 * @author Push Technology Ltd
 * @class DiffusionClientConnectionDetails
 * This class specifies all of the possible connection detail parameters.
 * This class is used as a default and any connection details passed into the connect method of {@link DiffusionClient} will be extended by this class
 */
var DiffusionClientConnectionDetails = function () {
  /**
   * Enable console logging for the connection&#46;
   *
   * @type Boolean
   * @default false
   */
  this.debug = false;
  /**
   * This is the html path that the Diffusion libraries are stored&#46;
   *
   * @type String
   * @default "/lib/DIFFUSION"
   */
  this.libPath = '/lib/DIFFUSION';

  /**
   * This is the path to the Flash transport library. The value set will be appended to the value of
   * {@link DiffusionClientConnectionDetails.libPath} and used when connecting over the Flash transport&#46;
   *
   * @type String
   * @default "diffusion-flash-5.1.5.swf"
   */
  this.libFlashPath = 'diffusion-flash-5.1.5.swf';

  /**
   * This is the path to the Silverlight transport library. The value set will be appended to the value of
   * {@link DiffusionClientConnectionDetails.libPath} and used when connecting over the Silverlight transport&#46;
   *
   * @type String
   * @default "diffusion-silverlight-5.1.5.xap"
   */
  this.libSilverlightPath = 'diffusion-silverlight-5.1.5.xap';

  /**
   * If a Diffusion deployment is in a application server, a context will need to be specified&#46;
   *
   * @type String
   * @default ""
   */
  this.context = '';

  /**
   * This is the timeout (in ms) for cascading if the transport fails to load&#46;
   *
   * @type Number
   * @default 4000
   */
  this.cascadeTimeout = 4000;

  /**
   * The default Flash host
   * @type string
   */
  this._defaultFlashHost = window.location.hostname;

  /**
   * The host name of which the flash socket will connect to&#46;
   *
   * @type String
   * @default window.location.hostname
   */
  this.flashHost = this._defaultFlashHost;

  /**
   * The default Flash port
   * @type number
   */
  this._defaultFlashPort =
    window.location.port === '' ? 80 : parseInt(window.location.port);

  /**
   * This is the port of which the flash socket will connect to&#46;
   *
   * @type Number
   * @default window.location.port
   */
  this.flashPort = this._defaultFlashPort;

  /**
   * This indicates if it will attempt a secure Flash socket connection. The default value is true if the page was
   * loaded using https and false otherwise.
   */
  this.flashSecure = location.protocol === 'https:' ? true : false;

  /**
   * The default Flash URL
   * @type string
   */
  this._defaultFlashURL = location.protocol + '//' + location.host;

  /**
   * This is the URL to use if HTTP Transport is to be used within the Flash Player
   *
   * @type String
   * @default location.protocol + "//" + location.host
   */
  this.flashURL = this._defaultFlashURL;

  /**
   * This controls the flash transport&#46; By default it is set to socket.
   * This means it will try to create a Flash Socket connection. Values are
   * 'S' for socket, 'C' for HTTP Comet, 'H' for HTTP, any other value will
   * cascade from socket to either HTTP Comet or HTTP. When cascading the
   * protocol of the flashURL property will be used to determine what type of
   * HTTP connection to establish. A protocol of 'http' or 'https' will
   * create a polling connection, a protocol of 'httpc' or 'httpcs' will
   * create an HTTP Comet streaming connection. When the flashTransport is
   * set to HTTP Comet the protocol of flashURL is used only to determine if
   * a secure connection should be established.
   *
   * @type String
   * @default "S"
   */
  this.flashTransport = 'S';

  /**
   * This is the number of millis that the socket connection will try before cascading down to HTTP&#46;
   *
   * @type Number
   * @default 3000
   */
  this.flashTimeout = 3000;

  /**
   * Disable the flash transport&#46;
   *
   * @type Boolean
   * @default false
   */
  this.disableFlash = false;

  /**
   * The default silverlight host
   * @type string
   */
  this._defaultSilverlightHost = window.location.hostname;

  /**
   * The host name of which the silverlight socket will connect to&#46;
   *
   * @type String
   * @default window.location.hostname
   */
  this.silverlightHost = this._defaultSilverlightHost;

  /**
   * The default silverlight port
   * @type number
   */
  this._defaultSilverlightPort = 4503;

  /**
   * This is the port of which the silverlight socket will connect to&#46;
   *
   * @type Number
   * @default 4503
   */
  this.silverlightPort = this._defaultSilverlightPort;

  /*
   * The default silverlight URL
   * @type string
   */
  this._defaultSilverlightURL = location.protocol + '//' + location.host;

  /**
   * This is the URL to use if HTTP Transport is to be used within the Silverlight Player
   *
   * @type String
   * @default location.protocol + "//" + location.host
   */
  this.silverlightURL = this._defaultSilverlightURL;

  /**
   * This controls the silverlight transport, by default it is set to cascade, which means that it will try a socket connection first,
   * if that fails, then it will try the HTTP transport. Valid values are S socket, H HTTP, C cascade.
   *
   * @type String
   * @default "S"
   */
  this.silverlightTransport = 'S';

  /**
   * Disable the silverlight transport&#46;
   *
   * @type Boolean
   * @default false
   */
  this.disableSilverlight = false;

  /**
   * The default XHR URL
   * @type string
   */
  this._defaultXHRURL = location.protocol + '//' + location.host;

  /**
   * This is where it is possible to change where the XmlHttpRequests get served from, if MOD_PROXY not being used and there is a sub domain available&#46;
   *
   * @type String
   * @default location.protocol + "//" + location.host
   */
  this.XHRURL = this._defaultXHRURL;

  /**
   * This is the number of retries the XmlHttpRequest transport will make before it announces that it has lost connection with the Diffusion Server&#46;
   *
   * @type Number
   * @default 3
   */
  this.XHRretryCount = 3;

  /**
   * This is the number of millis to wait to announce that it was not possible to connect to Diffusion&#46;
   *
   * @type Number
   * @default 4000
   */
  this.timeoutMS = 4000;

  /**
   * This is the number of seconds to hold open a XmlHttpRequest / IFrame poll whilst awaiting data&#46;
   *
   * @type Number
   * @default 90
   */
  this.transportTimeout = 90;

  /**
   * Disable the XHR transport&#46;
   *
   * @type Boolean
   * @default false
   */
  this.disableXHR = false;

  /**
   * The default websocket URL
   * @type string
   */
  this._defaultWsURL = 'ws';
  if (location.protocol == 'https:') {
    this._defaultWsURL += 's';
  }
  this._defaultWsURL += '://' + location.host;

  /**
   * This is the Web Socket URL&#46;
   *
   * @type String
   * @default "ws://" + location.host
   */
  this.wsURL = this._defaultWsURL;

  /**
   * Connection timeout for web sockets in millis&#46;
   *
   * @type Number
   * @default 2000
   */
  this.wsTimeout = 2000;

  /**
   * Disable the Web Socket transport&#46;
   *
   * @type Boolean
   * @default false
   */
  this.disableWS = false;

  /**
   * Disable iFrame transport&#46;
   *
   * @type Boolean
   * @default false
   */
  this.disableIframe = false;

  // The FF URL is not intended for overriding as CORS is not possible with iframes
  this._defaultForeverFrameURL = location.protocol + '//' + location.host;

  /**
   * Disable HTTP Forever Frame streaming&#46;
   * @type Boolean
   * @default false
   */
  this.disableForeverFrame = false;

  /**
   * This is the topic to connect to diffusion with&#46;
   *
   * @type String
   * @default null
   */
  this.topic = null;

  /**
   * This flag determines if the Diffusion Client will auto acknowledge messages sent from the server with the Ack / Nak flag set, or if set to false it is down to the
   * client implementation to send the Ack back&#46;
   *
   * @type Boolean
   * @default True
   */
  this.autoAck = true;

  /**
   * This is the function that will be responsible for handling messages from Diffusion.  This function will be called with a argument of WebClientMessage&#46;
   * This function will be called even if there is a topic listener in place for a topic&#46;
   *
   * @example
   * var details = new DiffusionClientConnectionDetails();
   * details.onDataFunction = function(msg) {
   * 	// Do something
   * }
   *
   * @type Function
   * @default null
   */
  this.onDataFunction = function () {};

  /**
   * This function is called when the user closes the browser or navigates away from the page.
   * @type Function
   * @default null
   */
  this.onBeforeUnloadFunction = function () {};

  /**
   * This function is called when Diffusion has connected, or exhausted all transports and can not connect&#46;
   * <p>This function is supplied with two boolean parameters, indicating if the client has connected, and if it is a reconnect.
   *
   * @type Function
   * @default null
   *
   * @example
   * var details = new DiffusionClientConnectionDetails();
   * details.onCallbackFunction = function(isConnected, isReconnect) {
   * 	if (isConnected) {
   * 		// We know we're connected
   *
   * 		if (!isReconnect) {
   * 			// If we're connecting for the first time, init stuff
   * 		}
   * 	}
   * }
   */
  this.onCallbackFunction = function () {};

  /**
   * This function is called when an invalid Diffusion operation is called, for instance if Diffusion&#46;subscribe was called before Diffusion&#46;connect(&#46;&#46;)&#46;
   * <p>This function is supplied with no parameters.
   *
   * @type Function
   * @default null
   */
  this.onInvalidClientFunction = function () {};

  /**
   * This function is called when the DiffusionClient cascades transports&#46;
   * <p>The function is supplied with an argument of the {String} transport name, or NONE if all transport are exhausted.
   * @type Function
   * @default null
   */
  this.onCascadeFunction = function () {};

  /**
   * This function is called with an argument of PingMessage when the ping response has been returned from the server.
   *
   * @type Function
   * @default null
   */
  this.onPingFunction = function () {};

  /**
   * This function is called when the Diffusion Server has terminated the client connected (or the connection has been banned)&#46;
   * <p>This function is supplied with no parameters.
   *
   * @type Function
   * @default null
   */
  this.onAbortFunction = function () {};

  /**
   * This function is called when the DiffusionClient has lost connection with the Diffusion Server&#46;
   * <p>This function will be supplied with no parameters.
   *
   * @type Function
   * @default null
   */
  this.onLostConnectionFunction = function () {};

  /**
   * This function is called when the DiffusionClient connection has been rejected by the Diffusion Server, this is due to incorrect credentials&#46;
   * <p>This function is called with no parameters.
   *
   * @type Function
   * @default null
   */
  this.onConnectionRejectFunction = function () {};

  /**
   * This function is called when a message that has been requested as Acknowledge didn't respond in time&#46;
   * <p>This function will be supplied with a parameter of the topic message that wasn't acknowledged
   *
   * @type Function
   * @default null
   */
  this.onMessageNotAcknowledgedFunction = function () {};

  /**
   * This function is called after a DiffusionClient.sendCredentials and the server rejected the credentials&#46;
   * <p>This function will be supplied with no parameters.
   *
   * @type Function
   * @default null
   */

  this.onServerRejectedCredentialsFunction = function () {};

  /**
   * This function is called if a topic that a client was subscribed to status has changed (topic removal implemented for now)&#46;
   * Status of R returned for removed&#46;
   * <p>This function will be supplied with one argument of a TopicStatusMessage.
   *
   * @type Function
   * @default null
   */
  this.onTopicStatusFunction = function () {};

  /**
   * This is the the host of which the client will connect to unless overridden by a transport specific value&#46;
   * <p>
   * This allows all the transports to be configured. For example:
   * <pre>DiffusionClient.connect({serverHost : "example.org", serverPort : 8070});</pre>
   * <p>
   * Will connect to ws://example.org:8070 using websockets.
   * <p>
   * Will connect to example.org at 8070 using Flash sockets.
   * <p>
   * Will connect to http://example.org:8070 using Flash HTTP.
   * <p>
   * Will connect to example.org at 4503 using Silverlight sockets.
   * <p>
   * Will connect to http://example.org:8070 using Silverlight HTTP.
   * <p>
   * Will connect to http://example.org:8070 using XHR.
   * <p>
   * Silverlight sockets have a limited port range and serverPort will only set this value if it is within this range.
   * <p>
   * <pre>DiffusionClient.connect({serverHost : "example.org", serverPort : 8070, flashPort : 4505, silverlightPort : 4505});</pre>
   * <p>
   * Will connect both the Flash and Silverlight sockets to example.org at 4505.
   * <p>
   * The HTTP transports assume the same protocol as the page is loaded with.
   *
   * @type string
   * @default window.location.hostname
   */
  this.serverHost = window.location.hostname;

  /**
   * This is the port of which the client will connect to unless overridden by a transport specific value&#46;
   * <p>
   * This allows all the transports to be configured. For example:
   * <pre>DiffusionClient.connect({serverHost : "example.org", serverPort : 8070});</pre>
   * <p>
   * Will connect to ws://example.org:8070 using websockets.
   * <p>
   * Will connect to example.org at 8070 using Flash sockets.
   * <p>
   * Will connect to http://example.org:8070 using Flash HTTP.
   * <p>
   * Will connect to example.org at 4503 using Silverlight sockets.
   * <p>
   * Will connect to http://example.org:8070 using Silverlight HTTP.
   * <p>
   * Will connect to http://example.org:8070 using XHR.
   * <p>
   * Silverlight sockets have a limited port range and serverPort will only set this value if it is within this range.
   * <p>
   * <pre>DiffusionClient.connect({serverHost : "example.org", serverPort : 8070, flashPort : 4505, silverlightPort : 4505});</pre>
   * <p>
   * Will connect both the Flash and Silverlight sockets to example.org at 4505.
   * <p>
   * The HTTP transports assume the same protocol as the page is loaded with.
   *
   * @type number
   * @default window.location.port
   */
  this.serverPort =
    window.location.port === '' ? 80 : parseInt(window.location.port);

  /**
   * Should the client require system pings to remain connected?
   * <P>
   * When required if a ping is not received by the time the next one is
   * expected. The lost connection function will be called. This allows
   * browser limitations to be overcome for Forever Frame and IFrame
   * transports where it is not possible to detect the loss or error of an
   * iframe.
   * @type boolean
   * @default false
   */
  this.enableLivenessMonitor = false;
};

// Convert types explicitly instead of relying on coercion
DiffusionClientConnectionDetails.prototype._serverHost = function () {
  if (typeof this.serverHost === 'string') {
    return serverHost;
  } else if (typeof this.serverHost === 'object') {
    if (this.serverHost instanceof String) {
      return serverHost.valueOf();
    } else {
      // Get something like the right value
      return serverHost.toString();
    }
  } else {
    // Get something like the right value
    return serverHost.toString();
  }
};

// Convert types explicitly instead of relying on coercion
DiffusionClientConnectionDetails.prototype._serverPort = function () {
  if (typeof this.serverPort === 'number') {
    return this.serverPort;
  } else if (typeof this.serverPort === 'string') {
    return parseInt(this.serverPort);
  } else if (typeof this.serverPort === 'object') {
    if (this.serverPort instanceof Number) {
      return this.serverPort.getValue();
    } else if (this.serverPort instanceof String) {
      return parseInt(this.serverPort);
    } else {
      // This should fail and probably will later but it will fail in a
      // way consistent with previous versions
      return this.serverPort;
    }
  } else {
    // This should fail and probably will later but it will fail in a
    // way consistent with previous versions
    return this.serverPort;
  }
};

/**
 * Get either the wsURL or URL based on the serverHost and serverPort
 */
DiffusionClientConnectionDetails.prototype._wsURL = function () {
  if (this.wsURL == this._defaultWsURL) {
    if (location.protocol === 'http:') {
      var wsURLString = 'ws';
    } else {
      var wsURLString = 'wss';
    }
    wsURLString = wsURLString + '://' + this.serverHost;
    if (this._serverPort() != 80) {
      wsURLString = wsURLString + ':' + this._serverPort();
    }
    return wsURLString;
  } else {
    return this.wsURL;
  }
};

/*
 * Get either the flashHost or the serverHost
 */
DiffusionClientConnectionDetails.prototype._flashHost = function () {
  if (this.flashHost == this._defaultFlashHost) {
    return this.serverHost;
  } else {
    return this.flashHost;
  }
};

/*
 * Get either the flashPort or the serverPort
 */
DiffusionClientConnectionDetails.prototype._flashPort = function () {
  if (this.flashPort == this._defaultFlashPort) {
    return this._serverPort();
  } else {
    return this.flashPort;
  }
};

/*
 * Get either the flashURL or URL based on the serverHost and serverPort
 */
DiffusionClientConnectionDetails.prototype._flashURL = function () {
  if (this.flashURL == this._defaultFlashURL) {
    if (this._serverPort() == 80) {
      return location.protocol + '//' + this.serverHost;
    } else {
      return (
        location.protocol + '//' + this.serverHost + ':' + this._serverPort()
      );
    }
  } else {
    return this.flashURL;
  }
};

/*
 * Get either the silverlightHost or the serverHost
 */
DiffusionClientConnectionDetails.prototype._silverlightHost = function () {
  if (this.silverlightHost == this._defaultSilverlightHost) {
    return this.serverHost;
  } else {
    return this.silverlightHost;
  }
};

/*
 * Get either the silverlightPort or the serverPort
 */
DiffusionClientConnectionDetails.prototype._silverlightPort = function () {
  if (this.silverlightPort == this._defaultSilverlightPort) {
    if (this._serverPort() >= 4502 && this._serverPort() <= 4534) {
      return this._serverPort();
    }
  }
  return this.silverlightPort;
};

/*
 * Get either the silverlightURL or URL based on the serverHost and serverPort
 */
DiffusionClientConnectionDetails.prototype._silverlightURL = function () {
  if (this.silverlightURL == this._defaultSilverlightURL) {
    if (this._serverPort() == 80) {
      return location.protocol + '//' + this.serverHost;
    } else {
      return (
        location.protocol + '//' + this.serverHost + ':' + this._serverPort()
      );
    }
  } else {
    return this.silverlightURL;
  }
};

/*
 * Get either the XHRURL or URL based on the serverHost and serverPort
 */
DiffusionClientConnectionDetails.prototype._XHRURL = function () {
  if (this.XHRURL == this._defaultXHRURL) {
    if (this._serverPort() == 80) {
      return location.protocol + '//' + this.serverHost;
    } else {
      return (
        location.protocol + '//' + this.serverHost + ':' + this._serverPort()
      );
    }
  } else {
    return this.XHRURL;
  }
};
/**
 * @author Push Technology Ltd
 * @class DiffusionClientCredentials
 * This class specifies all of the user credentials.
 * This class is used as a default and any connection details passed into the connect method of {@link DiffusionClient} will be extended by this class
 */
var DiffusionClientCredentials = function () {
  /**
   * A token that can be referenced by the Diffusion Server as username
   *
   * @type String
   * @default ""
   */
  this.username = '';

  /**
   * A token that can be referenced by the Diffusion Server as password
   *
   * @type String
   * @default ""
   */
  this.password = '';

  /**
   * @ignore
   */
  this.toRecord = function () {
    return this.username + '\u0002' + this.password;
  };
};
/**
 * @author dhudson
 *
 * DiffusionClientTransport class
 */
var DiffusionClientTransport = function () {
  this.clientID;
  this.transportName;

  this.isClosing = false;
  this.isConnected = false;

  this.isReconnected = false;
  this.isReconnecting = false;

  this.messageCount = 0;

  this._cd = DiffusionClient.connectionDetails;
  this._dc = DiffusionClient;

  this.aliasMap = [];
  this.transports = [];
  this.transports.push({
    name: 'WebSocket',
    transport: new DiffusionWSTransport(),
  });
  this.transports.push({
    name: 'ForeverFrame',
    transport: new DiffusionForeverFrameTransport(),
  });
  this.transports.push({
    name: 'Flash',
    transport: new DiffusionFlashTransport(),
  });
  this.transports.push({
    name: 'Silverlight',
    transport: new DiffusionSilverlightTransport(),
  });
  this.transports.push({
    name: 'XmlHttpRequest',
    transport: new DiffusionXHRTransport(),
  });
  this.transports.push({
    name: 'Iframe',
    transport: new DiffusionIframeTransport(),
  });
  this.nextAckSequence = 0;
  this.ackManager = [];
  this.lastInteraction;
  if (this._cd.enableLivenessMonitor) {
    this.pingMonitor = new DiffusionClient.internal.LivenessMonitor(this);
  }
};
DiffusionClientTransport.prototype.cascade = function () {
  // If it's a failed reconnect, then simply trigger callback
  if (this.isReconnecting) {
    this.isReconnecting = this.isReconnected = false;

    if (typeof this._cd.onCallbackFunction == 'function') {
      this._cd.onCallbackFunction(false, true);
    }

    return;
  }

  if (this.transports.length > 0) {
    var trans = this.transports.shift();
    this.transport = trans.transport;
    this.transportName = trans.name;

    if (typeof this._cd.onCascadeFunction == 'function') {
      this._cd.onCascadeFunction(trans.name);
    }

    DiffusionClient.trace(
      'Transport: cascade: about to attempt to connect to ' + trans.name
    );

    this.lastInteraction = new Date().getTime();

    this.transport.connect();
  } else {
    // Exhausted transports
    if (typeof this._cd.onCascadeFunction == 'function') {
      this._cd.onCascadeFunction('None');
    }

    if (typeof this._cd.onCallbackFunction == 'function') {
      this._cd.onCallbackFunction(false);
    }
  }
};

DiffusionClientTransport.prototype.reconnect = function () {
  if (!this.isConnected && !this.isReconnecting) {
    this.isClosing = false;
    this.isReconnecting = true;

    this.transport.connect(true);
  }
};

DiffusionClientTransport.prototype.getLastInteraction = function () {
  return this.lastInteraction;
};

DiffusionClientTransport.prototype.isValid = function () {
  if (this.isConnected == false || this.isClosing == true) {
    if (this._dc.isInvalidFunction) {
      this._cd.onInvalidClientFunction();
    }
    return false;
  }
  // Update the last interaction time
  this.lastInteraction = new Date().getTime();
  return true;
};
DiffusionClientTransport.prototype.connectionRejected = function () {
  if (
    typeof DiffusionClient.connectionDetails.onConnectionRejectFunction ==
    'function'
  ) {
    DiffusionClient.connectionDetails.onConnectionRejectFunction();
  }
};
DiffusionClientTransport.prototype.close = function () {
  this.isConnected = false;
  this.isClosing = true;
  this.isReconnected = false;
  this.isReconnecting = false;

  if (this.transport != null) {
    this.transport.close();
  }
};
DiffusionClientTransport.prototype.sendTopicMessage = function (topicMessage) {
  DiffusionClient.trace('Sending topic message...' + topicMessage.getMessage());
  if (topicMessage.getAckRequired()) {
    // Add this to the AckManager
    var ackProcess = new DiffusionAckProcess(topicMessage);
    this.ackManager[topicMessage.getAckID()] = ackProcess;
  }

  this.transport.sendTopicMessage(topicMessage);
};
DiffusionClientTransport.prototype.send = function (topic, message) {
  DiffusionClient.trace('Sending ...' + message);
  this.transport.send(topic, message);
};
DiffusionClientTransport.prototype.subscribe = function (topic) {
  DiffusionClient.trace('Subscribe ... ' + topic);
  this.transport.subscribe(topic);
};
DiffusionClientTransport.prototype.unsubscribe = function (topic) {
  DiffusionClient.trace('Unsubscribe ... ' + topic);
  this.transport.unsubscribe(topic);
};
DiffusionClientTransport.prototype.sendAckResponse = function (ackID) {
  DiffusionClient.trace('Send ack response ' + ackID);
  this.transport.sendAckResponse(ackID);
};
DiffusionClientTransport.prototype.sendCredentials = function (credentials) {
  DiffusionClient.trace('Send credentials ');
  this.transport.sendCredentials(credentials);
};
DiffusionClientTransport.prototype.fetch = function (topic, correlationID) {
  if (correlationID) {
    DiffusionClient.trace('Fetch ' + topic + ' : ' + correlationID);
    this.transport.fetch(topic, correlationID);
  } else {
    DiffusionClient.trace('Fetch ' + topic);
    this.transport.fetch(topic, null);
  }
};
DiffusionClientTransport.prototype.command = function (
  command,
  correlationID,
  topicMessage
) {
  DiffusionClient.trace('Send command ' + command);
  this.transport.command(command, correlationID, topicMessage);
};
DiffusionClientTransport.prototype.page = function (type, topicMessage) {
  DiffusionClient.trace('Send page command ' + type);
  this.transport.command(type, null, topicMessage);
};
DiffusionClientTransport.prototype.connected = function (clientID) {
  this._dc.trace('Client ID = ' + clientID);

  this.isConnected = true;

  if (this.isReconnecting) {
    this.isReconnecting = false;
    this.isReconnected = this.clientID == clientID;
  }

  this.clientID = clientID;
  if (this.pingMonitor !== undefined && this._cd.enableLivenessMonitor) {
    this.pingMonitor.startup();
  }
  if (typeof this._cd.onCallbackFunction == 'function') {
    this._cd.onCallbackFunction(true, this.isReconnected);
  }

  this.lastInteraction = new Date().getTime();
};
DiffusionClientTransport.prototype.ping = function () {
  if (!this.isClosing && !this.isClosed) {
    this.transport.ping(new Date().getTime(), '0');
  }
};
DiffusionClientTransport.prototype.handleMessages = function (data) {
  this.lastInteraction = new Date().getTime();

  try {
    if (data != '') {
      var messages = data.split('\u0008');
      do {
        var message = messages.shift();

        var rawType = message.charCodeAt(0);

        if ((rawType & 0x40) === 0x40) {
          var fragmentedMessage = new FragmentedMessage(message);
          var assembled = fragmentedMessage.process();
          if (assembled === null) {
            continue;
          }
          message = assembled;
        }
        var messageType = message.charCodeAt(0) & ~0x40;

        switch (messageType) {
          case 28:
            // Abort
            if (typeof this._cd.onAbortFunction == 'function') {
              this._cd.onAbortFunction();
            }
            // Stop polling
            this.isClosing = true;
            return;
          case 24:
            // Its a server ping..
            if (typeof this._cd.onPingFunction == 'function') {
              this._cd.onPingFunction(new PingMessage(message));
            }
            break;
          case 25:
            // Its a client ping, this will only get here on Http Client
            var header = message.split('\u0002')[0];
            var timestamp = header.substr(1, header.length - 2);
            this.transport.sendClientPingResponse(timestamp);
            if (
              this.pingMonitor !== undefined &&
              this._cd.enableLivenessMonitor
            ) {
              this.pingMonitor.onInteraction();
            }
            break;
          case 27:
            // Server rejected Credentials
            if (
              typeof this._cd.onServerRejectedCredentialsFunction == 'function'
            ) {
              this._cd.onServerRejectedCredentialsFunction();
            }
            break;
          case 29:
            // Lost connection
            this.handleLostConnection();
            return;
          case 32:
            // Ack response
            var ackID = parseInt(message.substr(1, message.length - 1));
            this.processAckResponse(ackID);
            break;
          case 35:
            // Topic Status
            var data = message.substr(1, message.length - 2);
            var header = data.split('\u0002');
            var topicData = header[0].split('!');

            //Remove from alias map.
            if (topicData.length > 1) {
              delete this.aliasMap[topicData[1]];
            }

            if (typeof this._cd.onTopicStatusFunction == 'function') {
              var alias = null;
              if (topicData.length > 1) {
                alias = topicData[1];
              }
              var topicStatusMessage = new TopicStatusMessage(
                topicData[0],
                alias,
                header[1]
              );
              this._cd.onTopicStatusFunction(topicStatusMessage);
            }
            break;
          case 40: // Command Topic Load
          case 41: // Command Topic Notification
            var commandMessage = new CommandMessage(
              message,
              this.messageCount++
            );
            this.aliasCheck(commandMessage);
            this.dispatchMessage(commandMessage);
            break;
          case 42: // Fragment cancel
            var data = message.substr(1, message.length - 2);
            DiffusionClient.fragmentMap[data] = undefined;
            break;
          default:
            try {
              var webClientMessage = new WebClientMessage(
                message,
                this.messageCount++
              );
              // If its an Ack message, send the response
              if (webClientMessage.isAckMessage() && this._cd.autoAck) {
                this.transport.sendAckResponse(webClientMessage.getAckID());
                webClientMessage.setAcknowledged();
              }

              this.aliasCheck(webClientMessage);
              this.dispatchMessage(webClientMessage);
            } catch (e) {
              DiffusionClient.trace(
                'DiffusionClient: Error processing data ' + e
              );
            }
            break;
        } // Switch
      } while (messages.length);
    }
  } catch (e) {
    DiffusionClient.trace('DiffusionClient:  Error processing data ' + e);
  }
};
DiffusionClientTransport.prototype.aliasCheck = function (message) {
  if (message.isInitialTopicLoad()) {
    var alias = message.getTopic().split('!');
    if (alias.length == 2) {
      this.aliasMap[alias[1]] = alias[0];
      message.setTopic(alias[0]);
    }
  } else {
    // Delta message
    if (message.getTopic().charCodeAt(0) == 33) {
      // Its an alias
      message.setTopic(this.aliasMap[message.getTopic().substr(1)]);
    }
  }
};
DiffusionClientTransport.prototype.dispatchMessage = function (message) {
  var dispatched = false;
  // First, try to process service listeners.
  if (message instanceof CommandMessage) {
    dispatched = this.dispatchToListeners(
      DiffusionClient.serviceListeners,
      message
    );
  }
  // Next try the standard topic listeners.
  if (!dispatched) {
    dispatched = this.dispatchToListeners(DiffusionClient.listeners, message);
  }
  // If no listener returned true, send to the normal onDataEvent function
  if (!dispatched) {
    this._cd.onDataFunction(message);
  }
};

DiffusionClientTransport.prototype.dispatchToListeners = function (
  listenerList,
  message
) {
  if (listenerList.length > 0) {
    var workingList = listenerList.slice(0).reverse(); // slice() gives us a copy
    var count = workingList.length;
    do {
      --count;
      var topicListener = workingList[count];
      if (message.getTopic().match(topicListener.getRegex())) {
        try {
          if (topicListener.callFunction(message) == true) {
            return true;
          }
        } catch (e) {
          DiffusionClient.trace(
            'Problem with topicListener ' + topicListener.handle + ' : ' + e
          );
        }
      }
    } while (count);
  }
  return false;
};
DiffusionClientTransport.prototype.getNextAckID = function () {
  return this.nextAckSequence++;
};
DiffusionClientTransport.prototype.processAckResponse = function (ackID) {
  var ackProcess = this.ackManager[ackID];
  if (ackProcess != null) {
    ackProcess.cancel();
    delete this.ackManager[ackID];
  }
};

// This should be called whenever the users onLostConnection function should be called
// This allows Diffusion to add behaviour on lost connection
DiffusionClientTransport.prototype.handleLostConnection = function () {
  if (DiffusionClient.diffusionTransport.isClosing != true) {
    // It was not in the middle of a close

    DiffusionClient.diffusionTransport.isClosing = false;
    DiffusionClient.diffusionTransport.isConnected = false;
    DiffusionClient.diffusionTransport.isReconnecting = false;

    if (
      this.pingMonitor !== undefined &&
      this._cd.enableLivenessMonitor === true
    ) {
      // Prevents last received ping causing the lost connection callback
      this.pingMonitor.shutdown();
    }

    if (typeof this._cd.onLostConnectionFunction === 'function') {
      this._cd.onLostConnectionFunction();
    }
  }
};
/**
 * @author dhudson
 *
 * DiffusionFlashTransport class  -- implements DiffusionTransportInterface
 */
var DiffusionFlashTransport = function () {
  this.flashConnection = null;
  this.segments = new Array();
};
DiffusionFlashTransport.prototype.close = function () {
  try {
    if (this.flashConnection != null) {
      this.flashConnection.close();
    }
  } catch (e) {}
};
DiffusionFlashTransport.prototype.sendTopicMessage = function (topicMessage) {
  this.flashConnection.sendTopicMessage(topicMessage.toRecord());
};
DiffusionFlashTransport.prototype.sendCredentials = function (credentials) {
  this.flashConnection.sendCredentials(credentials.toRecord());
};
DiffusionFlashTransport.prototype.send = function (header, message) {
  this.flashConnection.send(header, message);
};

DiffusionFlashTransport.prototype.command = function (
  command,
  correlationID,
  topicMessage
) {
  this.flashConnection.command(command, correlationID, topicMessage.toRecord());
};

DiffusionFlashTransport.prototype.subscribe = function (topic) {
  this.flashConnection.subscribe(topic);
};
DiffusionFlashTransport.prototype.unsubscribe = function (topic) {
  this.flashConnection.unsubscribe(topic);
};
DiffusionFlashTransport.prototype.ping = function (timeStamp, queueSize) {
  this.flashConnection.ping(timeStamp, queueSize);
};
DiffusionFlashTransport.prototype.fetch = function (topic, correlationID) {
  if (correlationID) {
    topic += '\u0003' + correlationID;
  }
  this.flashConnection.fetch(topic);
};
DiffusionFlashTransport.prototype.connect = function (doReconnect) {
  var _cd = DiffusionClient.connectionDetails;

  if (doReconnect && this.flashConnection != null) {
    this.flashConnection.reconnect();
    return;
  }

  if (_cd.disableFlash) {
    DiffusionClient.diffusionTransport.cascade();
    return;
  }

  if (!DiffusionClient.hasFlash(9)) {
    DiffusionClient.diffusionTransport.cascade();
    return;
  }

  DiffusionClient.trace('Flash connect');

  this.clearPlugin();

  var flashUrl =
    DiffusionClient.connectionDetails.context +
    _cd.libPath +
    '/' +
    _cd.libFlashPath +
    '?v=5.1.5_01&host=' +
    _cd._flashHost() +
    '&port=' +
    _cd._flashPort() +
    '&topic=' +
    encodeURIComponent(_cd.topic);
  flashUrl +=
    '&batch=DiffusionClient.diffusionTransport.transport.onFlashBatch&callback=DiffusionClient.diffusionTransport.transport.onFlashConnect&onDataEvent=DiffusionClient.diffusionTransport.handleMessages';
  flashUrl +=
    '&transport=' +
    _cd.flashTransport +
    '&durl=' +
    _cd._flashURL() +
    '&tio=' +
    _cd.flashTimeout;
  if (_cd.flashSecure === true) {
    flashUrl += '&secure=true';
  }

  if (DiffusionClient.credentials != null) {
    flashUrl +=
      '&username=' +
      encodeURIComponent(DiffusionClient.credentials.username) +
      '&password=' +
      encodeURIComponent(DiffusionClient.credentials.password);
  }
  if (DiffusionClient.isDebugging) {
    flashUrl += '&onTrace=DiffusionClient.trace';
  }
  if (DiffusionClient.isIE) {
    flashUrl += '&date=' + new Date();
  }
  var flashHtml =
    '<object width="0" height="0" id="DiffusionClientFlash" type="application/x-shockwave-flash" data="' +
    flashUrl +
    '" >';
  flashHtml += '<param name="allowScriptAccess" value="always" />';
  flashHtml += '<param name="bgcolor" value="#ffffff" />';
  flashHtml += '<param name="movie" value="' + flashUrl + '" />';
  flashHtml += '<param name="scale" value="noscale" />';
  flashHtml += '<param name="salign" value="lt" />';
  flashHtml += '</object>';

  var container = document.getElementById('DiffusionContainer');
  var div = document.createElement('div');
  div.innerHTML = flashHtml;
  container.appendChild(div);

  this.timeoutVar = setTimeout(
    DiffusionClient.bind(this.onTimeout, this),
    _cd.cascadeTimeout
  );
};

DiffusionFlashTransport.prototype.onTimeout = function () {
  DiffusionClient.trace('Flash Timeout Cascade');

  if (!DiffusionClient.diffusionTransport.isReconnecting) {
    this.clearPlugin();
  }

  DiffusionClient.diffusionTransport.cascade();
};
DiffusionFlashTransport.prototype.clearPlugin = function () {
  try {
    var container = document.getElementById('DiffusionContainer');
    var fc = document.getElementById('DiffusionClientFlash');
    if (fc != null) {
      // Remove old node
      var parent = fc.parentNode;
      parent.removeChild(fc);
      container.removeChild(parent);
    }
  } catch (e) {}
};
DiffusionFlashTransport.prototype.onFlashConnect = function (val) {
  clearTimeout(this.timeoutVar);

  if (val == false) {
    DiffusionClient.trace('Flash Connection not successful.');
    DiffusionClient.diffusionTransport.cascade();
    return;
  } else {
    var connectionData = val.split('\u0002');
    DiffusionClient.serverProtocolVersion = connectionData[0];
    DiffusionClient.trace('Flash Connection successful.');
    this.flashConnection = document['DiffusionClientFlash'];
    if (this.flashConnection == null) {
      this.flashConnection = window['DiffusionClientFlash'];
    }
    DiffusionClient.diffusionTransport.connected(connectionData[1]);
  }
};
DiffusionFlashTransport.prototype.onFlashBatch = function (data) {
  if (data.charAt(0) == '\u0003') {
    // Last one in the batch
    this.segments.push(data.substr(1));
    DiffusionClient.diffusionTransport.handleMessages(this.segments.join(''));
    this.segments = new Array();
  } else {
    this.segments.push(data);
    DiffusionClient.trace('Segment ' + this.segments.length);
  }
};
/**
 * @author dhudson
 *
 * DiffusionIframeTransport class  -- implements DiffusionTransportInterface
 */
var DiffusionIframeTransport = function () {
  this.container = document.getElementById('DiffusionContainer');
  this.requests = new Array();
  this.pollFrame = null;
  this.connectFrame = null;
  this.baseURL = DiffusionClient.connectionDetails.context + '/diffusion/';
  this.isSending = false;
  this.seq = 0;
};
DiffusionIframeTransport.prototype.send = function (topic, message) {
  this.post(
    '?m=2&c=' +
      DiffusionClient.getClientID() +
      '&t=' +
      encodeURIComponent(topic) +
      '&d=' +
      encodeURIComponent(message)
  );
};
DiffusionIframeTransport.prototype.sendTopicMessage = function (topicMessage) {
  var url =
    '?m=2&c=' +
    DiffusionClient.getClientID() +
    '&t=' +
    encodeURIComponent(topicMessage.getTopic()) +
    '&d=' +
    encodeURIComponent(topicMessage.getMessage());
  if (topicMessage.getUserHeaders() != null) {
    url +=
      '&u=' + encodeURIComponent(topicMessage.getUserHeaders().join('\u0002'));
  }
  if (topicMessage.getAckRequired()) {
    url += '&a=' + topicMessage.getAckID();
  }
  this.post(url);
};
DiffusionIframeTransport.prototype.subscribe = function (topic) {
  this.post(
    '?m=22&c=' +
      DiffusionClient.getClientID() +
      '&t=' +
      encodeURIComponent(topic)
  );
};
DiffusionIframeTransport.prototype.unsubscribe = function (topic) {
  this.post(
    '?m=23&c=' +
      DiffusionClient.getClientID() +
      '&t=' +
      encodeURIComponent(topic)
  );
};
DiffusionIframeTransport.prototype.ping = function (timeStamp, queueSize) {
  this.post(
    '?m=24&c=' +
      DiffusionClient.getClientID() +
      '&u=' +
      encodeURIComponent(timeStamp + '\u0002' + queueSize)
  );
};
DiffusionIframeTransport.prototype.sendAckResponse = function (ack) {
  this.post('?m=32&c=' + DiffusionClient.getClientID() + '&a=' + ack);
};
DiffusionIframeTransport.prototype.sendCredentials = function (credentials) {
  this.post(
    '?m=26&c=' +
      DiffusionClient.getClientID() +
      '&username=' +
      encodeURIComponent(credentials.username) +
      '&password=' +
      encodeURIComponent(credentials.password)
  );
};
DiffusionIframeTransport.prototype.fetch = function (topic, correlationID) {
  if (correlationID) {
    this.post(
      '?m=33&c=' +
        DiffusionClient.getClientID() +
        '&t=' +
        encodeURIComponent(topic) +
        '&u' +
        correlationID
    );
  } else {
    this.post(
      '?m=33&c=' +
        DiffusionClient.getClientID() +
        '&t=' +
        encodeURIComponent(topic)
    );
  }
};
DiffusionIframeTransport.prototype.command = function (
  command,
  correlationID,
  topicMessage
) {
  var url =
    '?m=36&c=' +
    DiffusionClient.getClientID() +
    '&t=' +
    encodeURIComponent(topicMessage.getTopic()) +
    '&d=' +
    encodeURIComponent(topicMessage.getMessage());

  url += '&u=' + command;
  if (correlationID !== undefined && correlationID !== null) {
    url += encodeURIComponent('\u0002' + correlationID);
  }
  if (topicMessage.getUserHeaders() != null) {
    url += encodeURIComponent(
      '\u0002' + topicMessage.getUserHeaders().join('\u0002')
    );
  }

  if (topicMessage.getAckRequired()) {
    url += '&a=' + topicMessage.getAckID();
  }
  this.post(url);
};
DiffusionIframeTransport.prototype.close = function () {
  if (this.connectFrame != null) {
    var url = this.baseURL + '?m=29&c=' + DiffusionClient.getClientID();
    DiffusionClient.trace('close : ' + url);

    if (DiffusionClient.isIE) {
      this.connectFrame.src = url;
    } else {
      this.connectFrame.contentDocument.location.replace(url);
    }

    if (this.pollFrame) {
      this.container.removeChild(this.pollFrame);
      this.pollFrame = null;
    }

    this.container.removeChild(this.connectFrame);
    this.connectFrame = null;
  }
};
DiffusionIframeTransport.prototype.sendClientPingResponse = function (header) {
  this.post('?m=25&c=' + DiffusionClient.getClientID() + '&u=' + header);
};
DiffusionIframeTransport.prototype.connect = function (doReconnect) {
  this.seq = 0;

  var _cd = DiffusionClient.connectionDetails;

  if (_cd.disableIframe) {
    DiffusionClient.diffusionTransport.cascade();
    return;
  }

  var url =
    this.baseURL +
    '?m=0&ty=B&t=' +
    encodeURIComponent(_cd.topic) +
    '&tt=' +
    _cd.transportTimeout +
    '&v=' +
    DiffusionClient.getClientProtocolVersion();

  if (doReconnect) {
    url += '&c=' + DiffusionClient.getClientID();
  }

  if (DiffusionClient.credentials != null) {
    url +=
      '&username=' +
      encodeURIComponent(DiffusionClient.credentials.username) +
      '&password=' +
      encodeURIComponent(DiffusionClient.credentials.password);
  }

  this.connectFrame = this.createFrame('DIT', url, false);
  setTimeout(function () {
    if (DiffusionClient.diffusionTransport.isConnected == false) {
      DiffusionClient.diffusionTransport.cascade();
    }
  }, 500);
};
DiffusionIframeTransport.prototype.poll = function () {
  if (DiffusionClient.diffusionTransport.isClosing) {
    return;
  }

  var url =
    this.baseURL +
    '?m=1&c=' +
    DiffusionClient.getClientID() +
    '&nc=' +
    new Date().valueOf();
  this.pollFrame = this.createFrame('DITP', url, true);
};
DiffusionIframeTransport.prototype.createFrame = function (id, url, isPolling) {
  try {
    var node = document.getElementById(id);
    if (node) {
      this.container.removeChild(node);
    }
  } catch (e) {}

  var iframe;
  var props = { id: id, name: id, src: url },
    evnts = ['onload', 'onerror', 'onunload'],
    callback = DiffusionClient.diffusionTransport.transport.process;

  try {
    iframe = document.createElement(
      '<iframe id="' +
        props.id +
        '" name="' +
        props.id +
        '" src="' +
        props.src +
        '">'
    );
  } catch (e) {
    iframe = document.createElement('iframe');
    for (var p in props) iframe[p] = props[p];
  }

  iframe.style.width = '0px';
  iframe.style.height = '0px';
  iframe.style.border = 'none';

  if (isPolling) {
    if (iframe.attachEvent) {
      while (evnts.length) iframe.attachEvent(evnts.pop(), callback);
    } else {
      while (evnts.length) iframe[evnts.pop()] = callback;
    }
  }

  this.container.appendChild(iframe);
  return iframe;
};
DiffusionIframeTransport.prototype.post = function (url) {
  this.requests.push(url);
  if (this.isSending == false) {
    this.isSending = true;
    _this = this;
    setTimeout(function () {
      _this.processRequest();
    }, 80);
  }
};
DiffusionIframeTransport.prototype.processRequest = function () {
  if (this.requests.length > 0) {
    var url = this.baseURL + this.requests.shift() + '&s=' + this.seq++;
    if (this.connectFrame != null) {
      if (DiffusionClient.isIE) {
        this.connectFrame.src = url;
      } else if (this.connectFrame.contentDocument) {
        this.connectFrame.contentDocument.location.replace(url);
      }
    }
  }

  if (this.requests.length > 0) {
    _this = this;
    setTimeout(function () {
      _this.processRequest();
    }, 80);
  } else {
    this.isSending = false;
  }
};

DiffusionIframeTransport.prototype.process = function () {
  try {
    var frame = DiffusionClient.diffusionTransport.transport.pollFrame;
    if (frame) {
      var content = frame.contentWindow
        ? frame.contentWindow.document
        : frame.contentDocument
        ? frame.contentDocument
        : frame.document;
      if (
        content !== undefined &&
        content.getElementsByTagName('script').length > 0
      ) {
        return DiffusionClient.diffusionTransport.transport.poll();
      }
    }
  } catch (e) {
    DiffusionClient.trace('Error: Diffusion iFrame Transport: process ' + e);
  }

  DiffusionClient.diffusionTransport.handleLostConnection();
};
/**
 * @author dhudson
 *
 * DiffusionSilverlightTransport class  -- implements DiffusionTransportInterface
 */
var DiffusionSilverlightTransport = function () {
  this.silverlightConnection = null;
  if (window.chrome) {
    // Chrome appears to be slower than other browsers at unloading
    // Silverlight objects. This may need adjusting in the future for
    // other browsers.
    this.silverlightObjectDelay = 1200;
  } else {
    this.silverlightObjectDelay = 200;
  }
};
DiffusionSilverlightTransport.prototype.close = function () {
  if (this.silverlightConnection != null) {
    this.silverlightConnection.close();
  }
};
DiffusionSilverlightTransport.prototype.send = function (header, message) {
  this.silverlightConnection.send(header, message);
};
DiffusionSilverlightTransport.prototype.command = function (
  command,
  correlationID,
  topicMessage
) {
  this.silverlightConnection.command(
    command,
    correlationID,
    topicMessage.toRecord()
  );
};
DiffusionSilverlightTransport.prototype.sendTopicMessage = function (
  topicMessage
) {
  this.silverlightConnection.sendTopicMessage(topicMessage.toRecord());
};
DiffusionSilverlightTransport.prototype.sendCredentials = function (
  credentials
) {
  this.silverlightConnection.sendCredentials(credentials.toRecord());
};
DiffusionSilverlightTransport.prototype.subscribe = function (topic) {
  this.silverlightConnection.subscribe(topic);
};
DiffusionSilverlightTransport.prototype.unsubscribe = function (topic) {
  this.silverlightConnection.unsubscribe(topic);
};
DiffusionSilverlightTransport.prototype.ping = function (timeStamp, queueSize) {
  this.silverlightConnection.ping(timeStamp, queueSize);
};
DiffusionSilverlightTransport.prototype.sendAckResponse = function (ackID) {
  this.silverlightConnection.sendAckResponse(ackID);
};
DiffusionSilverlightTransport.prototype.fetch = function (
  topic,
  correlationID
) {
  if (correlationID) {
    topic += '\u0003' + correlationID;
  }
  this.silverlightConnection.fetch(topic);
};
DiffusionSilverlightTransport.prototype.connect = function (doReconnect) {
  if (doReconnect && this.silverlightConnection != null) {
    this.silverlightConnection.reconnect();
    return;
  }

  var _cd = DiffusionClient.connectionDetails;

  if (_cd.disableSilverlight) {
    DiffusionClient.diffusionTransport.cascade();
    return;
  }

  if (!DiffusionClient.hasSilverlight()) {
    DiffusionClient.diffusionTransport.cascade();
    return;
  }

  DiffusionClient.trace('Silverlight connect');

  if (this.clearPlugin()) {
    var silverlightObjectDelay = this.silverlightObjectDelay;
  } else {
    // Do not delay when no object is being removed
    var silverlightObjectDelay = 0;
  }

  var that = this;
  setTimeout(function () {
    that.createSilverlightObject(doReconnect);
  }, silverlightObjectDelay);
};

DiffusionSilverlightTransport.prototype.createSilverlightObject = function (
  doReconnect
) {
  var _cd = DiffusionClient.connectionDetails;
  var silverlightUrl =
    DiffusionClient.connectionDetails.context +
    _cd.libPath +
    '/' +
    _cd.libSilverlightPath +
    '?v=5.1.5_01';
  var silverlightHtml =
    '<object data="data:application/x-silverlight-2," id="DiffusionClientSilverlight" type="application/x-silverlight-2" width="1" height="1">';
  silverlightHtml += '<param name="source" value="' + silverlightUrl + '" />';
  silverlightHtml +=
    '<param name="onError" value="DiffusionClient.diffusionTransport.transport.onSilverlightError" />';
  var topics;
  if (_cd.topic != null) {
    topics = _cd.topic.split(',').join('|');
  } else {
    topics = '';
  }

  var initParams =
    'host=' +
    _cd._silverlightHost() +
    ',port=' +
    _cd._silverlightPort() +
    ',topic=' +
    topics +
    ',onDataEvent=DiffusionClient.diffusionTransport.handleMessages,';
  initParams +=
    'callback=DiffusionClient.diffusionTransport.transport.onSilverlightConnect,transport=' +
    _cd.silverlightTransport +
    ',durl=' +
    _cd._silverlightURL();

  if (doReconnect) {
    initParams += ',clientid=' + DiffusionClient.getClientID();
  }

  if (DiffusionClient.credentials != null) {
    initParams +=
      ',username=' +
      DiffusionClient.credentials.username +
      ',password=' +
      DiffusionClient.credentials.password;
  }

  if (DiffusionClient.isDebugging) {
    initParams += ',debugging=true';
  }

  silverlightHtml += '<param name="initParams" value="' + initParams + '" />';
  silverlightHtml += '<param name="minRuntimeVersion" value="4.0.50401.0" />';
  silverlightHtml += '<param name="autoUpgrade" value="false" />';
  silverlightHtml += '</object>';

  var container = document.getElementById('DiffusionContainer');

  var div = document.createElement('div');

  div.style.position = 'fixed';
  div.style.left = '0px';
  div.style.top = '0px';
  div.innerHTML = silverlightHtml;

  container.appendChild(div);

  this.timeoutVar = setTimeout(
    DiffusionClient.bind(this.onTimeout, this),
    _cd.cascadeTimeout
  );
};
DiffusionSilverlightTransport.prototype.onTimeout = function () {
  DiffusionClient.trace('Silverlight Timeout Cascade');

  if (!DiffusionClient.diffusionTransport.isReconnecting) {
    this.clearPlugin();
  }

  DiffusionClient.diffusionTransport.cascade();
};
DiffusionSilverlightTransport.prototype.clearPlugin = function () {
  try {
    var container = document.getElementById('DiffusionContainer');
    var fc = document.getElementById('DiffusionClientSilverlight');
    if (fc != null) {
      // Remove old node
      var parent = fc.parentNode;
      parent.removeChild(fc);
      container.removeChild(parent);
      return true;
    }
  } catch (e) {
    Diffusion.trace('Unable to clear previous Silverlight object');
  }
  return false;
};
DiffusionSilverlightTransport.prototype.onSilverlightConnect = function (val) {
  clearTimeout(this.timeoutVar);

  if (val == false) {
    DiffusionClient.trace('Silverlight Connection not successful.');
    DiffusionClient.diffusionTransport.cascade();
  } else {
    DiffusionClient.trace('Silverlight Connection successful.');
    var connectionData = val.split('\u0002');
    DiffusionClient.serverProtocolVersion = connectionData[0];

    var sjsbridge = null;
    sjsbridge = document['DiffusionClientSilverlight'];
    if (sjsbridge == null) {
      sjsbridge = window['DiffusionClientSilverlight'];
    }

    this.silverlightConnection = sjsbridge.content.DiffusionJavaScriptClient;

    DiffusionClient.diffusionTransport.connected(connectionData[1]);
  }
};
DiffusionSilverlightTransport.prototype.onSilverlightError = function (
  sender,
  args
) {
  DiffusionClient.trace('Silverlight Connection not successful. (Error)');
  var appSource = '';

  if (sender != null && sender != 0) {
    appSource = sender.getHost().Source;
  }

  var errorType = args.ErrorType;
  var iErrorCode = args.ErrorCode;

  if (errorType == 'ImageError' || errorType == 'MediaError') {
    return;
  }

  var errMsg = 'Unhandled Error in Silverlight Application ' + appSource + '\n';
  errMsg +=
    'Code: ' +
    iErrorCode +
    ' Category: ' +
    errorType +
    ' Message: ' +
    args.ErrorMessage +
    '\n';
  if (errorType == 'ParserError') {
    errMsg +=
      'File: ' +
      args.xamlFile +
      ' Line: ' +
      args.lineNumber +
      ' Position: ' +
      args.charPosition +
      '\n';
  } else if (errorType == 'RuntimeError') {
    if (args.lineNumber != 0) {
      errMsg +=
        'Line: ' + args.lineNumber + ' Position: ' + args.charPosition + '\n';
    }
    errMsg += 'MethodName: ' + args.methodName + '\n';
  }
  DiffusionClient.trace(errMsg);
};
/**
 * @author dhudson
 *
 * DiffusionWSTransport class -- implements DiffusionTransportInterface
 */
var DiffusionWSTransport = function () {
  this.webSocket;
  this.hasConnected = false;
  this.timeoutVar;
  this.retries = 0;
  this.maxRetry = 3;
};
DiffusionWSTransport.prototype.send = function (topic, message) {
  this.writeBytes('\u0015' + topic + '\u0001' + message);
};
DiffusionWSTransport.prototype.sendTopicMessage = function (topicMessage) {
  var message;
  if (topicMessage.getAckRequired()) {
    message =
      '\u001F' + topicMessage.getTopic() + '\u0002' + topicMessage.getAckID();
  } else {
    message = '\u0015' + topicMessage.getTopic();
  }

  var headers = topicMessage.getUserHeaders();
  if (headers != null && headers.length > 0) {
    message += '\u0002';
    message += headers.join('\u0002');
  }

  message += '\u0001';
  message += topicMessage.getMessage();

  this.writeBytes(message);
};
DiffusionWSTransport.prototype.sendCredentials = function (credentials) {
  this.writeBytes('\u001a' + credentials.toRecord());
};
DiffusionWSTransport.prototype.subscribe = function (topic) {
  this.writeBytes('\u0016' + topic);
};
DiffusionWSTransport.prototype.unsubscribe = function (topic) {
  this.writeBytes('\u0017' + topic);
};
DiffusionWSTransport.prototype.ping = function (timeStamp, queueSize) {
  this.writeBytes('\u0018' + timeStamp + '\u0002' + queueSize);
};
DiffusionWSTransport.prototype.sendAckResponse = function (ack) {
  this.writeBytes('\u0020' + ack);
};
DiffusionWSTransport.prototype.fetch = function (topic, correlationID) {
  if (correlationID != null) {
    this.writeBytes('\u0021' + topic + '\u0002' + correlationID);
  } else {
    this.writeBytes('\u0021' + topic);
  }
};
DiffusionWSTransport.prototype.command = function (
  command,
  correlationID,
  topicMessage
) {
  var message;
  message = '\u0024' + topicMessage.getTopic() + '\u0002' + command;
  if (correlationID !== undefined && correlationID !== null) {
    message += '\u0002' + correlationID;
  }

  var headers = topicMessage.getUserHeaders();
  if (headers != null && headers.length > 0) {
    message += '\u0002';
    message += headers.join('\u0002');
  }
  message += '\u0001';
  if (topicMessage.getMessage()) {
    message += topicMessage.getMessage();
  }

  this.writeBytes(message);
};

DiffusionWSTransport.prototype.close = function () {
  this.writeBytes('\u001D');
  if (this.webSocket != null) {
    this.webSocket.onclose = null;
    this.webSocket.close();
  }
};
DiffusionWSTransport.prototype.sendClientPingResponse = function (header) {
  this.writeBytes('\u0019' + header + '\u0001');
};
DiffusionWSTransport.prototype.connect = function (doReconnect) {
  var _cd = DiffusionClient.connectionDetails;

  if (_cd.disableWS) {
    DiffusionClient.diffusionTransport.cascade();
    return;
  }

  this.hasConnected = false;

  DiffusionClient.trace('WebSocket connect');

  try {
    var topicComponent = '';
    if (_cd.topic !== null) {
      topicComponent = 't=' + encodeURIComponent(_cd.topic) + '&';
    }
    var url =
      _cd._wsURL() +
      _cd.context +
      '/diffusion?' +
      topicComponent +
      'v=' +
      DiffusionClient.getClientProtocolVersion() +
      '&ty=WB';

    if (doReconnect) {
      url += '&c=' + DiffusionClient.getClientID();
    }

    if (
      DiffusionClient.credentials != null &&
      DiffusionClient.credentials.username != null &&
      DiffusionClient.credentials.password != null
    ) {
      url +=
        '&username=' +
        encodeURIComponent(DiffusionClient.credentials.username) +
        '&password=' +
        encodeURIComponent(DiffusionClient.credentials.password);
    }

    DiffusionClient.trace('WebSocket URL: ' + url);

    var webSocket = DiffusionClient.getWebSocket(url);

    if (webSocket == null) {
      DiffusionClient.diffusionTransport.cascade();
      return;
    }

    this.webSocket = webSocket;
    this.webSocket.onopen = DiffusionClient.bind(this.onWSConnect, this);
    this.webSocket.onclose = DiffusionClient.bind(this.onWSClose, this);
    this.webSocket.onmessage = DiffusionClient.bind(this.onWSHandshake, this);

    this.timeoutVar = setTimeout(
      DiffusionClient.bind(this.onTimeout, this),
      _cd.wsTimeout
    );
  } catch (e) {
    DiffusionClient.trace('WebSocket connect exception ' + e);
    clearTimeout(this.timeoutVar);
    // If we get here, there is no web socket..
    DiffusionClient.diffusionTransport.cascade();
    return;
  }
};
DiffusionWSTransport.prototype.writeBytes = function (message) {
  try {
    this.webSocket.send(message);
  } catch (e) {
    DiffusionClient.trace('WebSocket: Unable to send message: ' + message);
  }
};
DiffusionWSTransport.prototype.onTimeout = function () {
  if (!this.hasConnected) {
    DiffusionClient.trace('WebSocket Timeout Cascade');

    this.webSocket.onopen = null;
    this.webSocket.onclose = null;
    this.webSocket.onmessage = null;

    if (this.webSocket != null) {
      this.webSocket.close();
    }

    if (this.retries < this.maxRetry) {
      this.retries++;
      DiffusionClient.trace(
        'Reconnecting number ' + this.retries + ' onTimeout'
      );
      this.connect();
    } else {
      this.retries = 0;
      DiffusionClient.diffusionTransport.cascade();
    }
  }
};
DiffusionWSTransport.prototype.onWSConnect = function (evt) {
  DiffusionClient.trace('onWSConnect');
  clearTimeout(this.timeoutVar);
};
DiffusionWSTransport.prototype.onWSHandshake = function (evt) {
  var data = evt.data.split('\u0002');
  DiffusionClient.serverProtocolVersion = parseInt(data.shift());

  if (data[0] == '100' || data[0] == '105') {
    this.hasConnected = true;
    this.webSocket.onmessage =
      DiffusionClient.diffusionTransport.transport.onWSMessage;
    DiffusionClient.diffusionTransport.connected(data[1]);
  } else {
    if (data[0] == '111') {
      DiffusionClient.diffusionTransport.connectionRejected();
    }
  }
};
DiffusionWSTransport.prototype.onWSMessage = function (evt) {
  DiffusionClient.trace('WebSocket: ' + evt.data);
  DiffusionClient.diffusionTransport.handleMessages(evt.data);
};
DiffusionWSTransport.prototype.onWSClose = function (evt) {
  DiffusionClient.trace('onWSClose ' + this.hasConnected);

  clearTimeout(this.timeoutVar);
  if (!this.hasConnected) {
    // Connection rejected by browser
    DiffusionClient.diffusionTransport.cascade();
    return;
  }
  DiffusionClient.diffusionTransport.handleLostConnection();
};
/**
 * @author dhudson
 *
 * DiffusionXHRTransport class  -- implements DiffusionTransportInterface
 */
var DiffusionXHRTransport = function () {
  this.serverUrl =
    DiffusionClient.connectionDetails._XHRURL() +
    DiffusionClient.connectionDetails.context +
    '/diffusion/';
  this.requests = new Array();
  this.isSending = false;
  this.isNativeXmlHttp = false;
  this.seq = 0;
  this.aborted = false;
};
DiffusionXHRTransport.prototype.sendTopicMessage = function (topicMessage) {
  var headers = {
    m: '2',
    c: DiffusionClient.getClientID(),
    t: encodeURIComponent(topicMessage.getTopic()),
    s: this.seq++,
  };

  if (topicMessage.getUserHeaders() != null) {
    headers.u = encodeURIComponent(
      topicMessage.getUserHeaders().join('\u0002')
    );
  }

  if (topicMessage.getAckRequired()) {
    headers.a = topicMessage.getAckID();
  }

  var requestWrapper = this.createDiffusionRequest(headers);
  requestWrapper.data = topicMessage.getMessage();

  this.processRequest(requestWrapper);
};
DiffusionXHRTransport.prototype.send = function (topic, message) {
  var requestWrapper = this.createDiffusionRequest({
    m: '2',
    c: DiffusionClient.getClientID(),
    t: encodeURIComponent(topic),
    s: this.seq++,
  });
  requestWrapper.data = message;
  this.processRequest(requestWrapper);
};
DiffusionXHRTransport.prototype.XHRSubscription = function (topic, action) {
  this.processRequest(
    this.createDiffusionRequest({
      m: action,
      c: DiffusionClient.getClientID(),
      t: encodeURIComponent(topic),
      s: this.seq++,
    })
  );
};
DiffusionXHRTransport.prototype.subscribe = function (topic) {
  this.XHRSubscription(topic, '22');
};
DiffusionXHRTransport.prototype.unsubscribe = function (topic) {
  this.XHRSubscription(topic, '23');
};
DiffusionXHRTransport.prototype.ping = function (timeStamp, queueSize) {
  this.processRequest(
    this.createDiffusionRequest({
      m: '24',
      c: DiffusionClient.getClientID(),
      u: encodeURIComponent([timeStamp, queueSize].join('\u0002')),
      s: this.seq++,
    })
  );
};
DiffusionXHRTransport.prototype.sendClientPingResponse = function (header) {
  this.processRequest(
    this.createDiffusionRequest({
      m: '25',
      c: DiffusionClient.getClientID(),
      u: header,
      s: this.seq++,
    })
  );
};
DiffusionXHRTransport.prototype.sendAckResponse = function (ack) {
  this.processRequest(
    this.createDiffusionRequest({
      m: '32',
      c: DiffusionClient.getClientID(),
      a: ack,
      s: this.seq++,
    })
  );
};
DiffusionXHRTransport.prototype.fetch = function (topic, correlationID) {
  var headers = {
    m: '33',
    c: DiffusionClient.getClientID(),
    t: encodeURIComponent(topic),
    s: this.seq++,
  };

  if (correlationID) {
    headers.u = correlationID;
  }

  this.processRequest(this.createDiffusionRequest(headers));
};
DiffusionXHRTransport.prototype.command = function (
  command,
  correlationID,
  topicMessage
) {
  var headers = {
    m: '36',
    c: DiffusionClient.getClientID(),
    t: encodeURIComponent(topicMessage.getTopic()),
    s: this.seq++,
  };

  headers.u = command;
  if (correlationID !== undefined && correlationID !== null) {
    headers.u += '\u0002' + correlationID;
  }
  if (topicMessage.getUserHeaders() != null) {
    headers.u += '\u0002';
    headers.u += topicMessage.getUserHeaders().join('\u0002');
  }

  if (headers.u != undefined || headers.u != null) {
    headers.u = encodeURIComponent(headers.u);
  }

  if (topicMessage.getAckRequired()) {
    headers.a = topicMessage.getAckID();
  }

  var requestWrapper = this.createDiffusionRequest(headers);
  requestWrapper.data = topicMessage.getMessage();

  this.processRequest(requestWrapper);
};

DiffusionXHRTransport.prototype.sendCredentials = function (credentials) {
  this.processRequest(
    this.createDiffusionRequest({
      m: '26',
      c: DiffusionClient.getClientID(),
      username: encodeURIComponent(credentials.username),
      password: encodeURIComponent(credentials.password),
      s: this.seq++,
    })
  );
};
DiffusionXHRTransport.prototype.close = function () {
  // Needs to be sync
  var request = this.createXHRTransport();
  request.open('POST', this.serverUrl, false);
  request.setRequestHeader('m', '29');
  request.setRequestHeader('c', DiffusionClient.getClientID());
  try {
    request.send('');
  } catch (e) {}

  if (this.pollRequest) {
    this.aborted = true;
    this.pollRequest.abort();
  }
};
DiffusionXHRTransport.prototype.poll = function () {
  if (DiffusionClient.diffusionTransport.isClosing) {
    return;
  }

  var _this = this;
  var request = this.createDiffusionRequest({
    m: '1',
    c: DiffusionClient.getClientID(),
  }).request;

  request.onreadystatechange = function () {
    if (_this.aborted) {
      // God bless IE9
      return;
    }

    if (request.readyState == 4) {
      if (request.status == 200) {
        DiffusionClient.diffusionTransport.handleMessages(request.responseText);
        _this.poll();
      } else if (DiffusionClient.diffusionTransport.isClosing != true) {
        // Trash any requests awaiting
        this.requests = [];
        DiffusionClient.diffusionTransport.handleLostConnection();
      }
    }
  };

  this.pollRequest = request;
  request.send('');
};
DiffusionXHRTransport.prototype.connect = function (doReconnect) {
  this.seq = 0;

  if (DiffusionClient.connectionDetails.disableXHR == true) {
    DiffusionClient.diffusionTransport.cascade();
    return;
  }
  if (this.detectXmlHttp() == false) {
    DiffusionClient.diffusionTransport.cascade();
    return;
  }

  DiffusionClient.trace('XHR connect');

  var _this = this;
  var headers = {
    m: '0',
    ty: 'B',
    t: encodeURIComponent(DiffusionClient.connectionDetails.topic),
    tt: DiffusionClient.connectionDetails.transportTimeout,
    v: DiffusionClient.getClientProtocolVersion(),
  };

  if (doReconnect) {
    headers.c = DiffusionClient.getClientID();
  }

  var creds = DiffusionClient.getCredentials();

  if (creds != null) {
    headers.username = encodeURIComponent(creds.username);
    headers.password = encodeURIComponent(creds.password);
  }

  var request = this.createDiffusionRequest(headers).request;
  request.onreadystatechange = function () {
    if (request.readyState == 4) {
      if (request.status == 200) {
        var somedata = request.responseText.split('\u0002');
        // First byte is protocol version
        DiffusionClient.serverProtocolVersion = somedata.shift();
        var responseCode = somedata.shift();
        DiffusionClient.messageLengthSize = somedata.shift();

        if (responseCode == '100' || responseCode == '105') {
          DiffusionClient.diffusionTransport.connected(somedata[0]);
          _this.poll();
        } else {
          if (responseCode == '111') {
            DiffusionClient.diffusionTransport.connectionRejected();
          }

          DiffusionClient.diffusionTransport.cascade();
        }
      } else {
        DiffusionClient.diffusionTransport.cascade();
      }
    }
  };
  request.send('');
};
DiffusionXHRTransport.prototype.createXHRTransport = function () {
  if (this.isNativeXmlHttp) {
    return new XMLHttpRequest();
  } else {
    return new ActiveXObject(this.activeXName);
  }
};
DiffusionXHRTransport.prototype.processRequest = function (request) {
  if (request != null) {
    this.requests.push(request);
  }

  if (this.isSending) {
    return;
  }

  if (this.requests.length == 0) {
    return;
  }

  var requestWrapper = this.requests.shift();
  var sendRequest = requestWrapper.request;

  var _this = this;

  sendRequest.onreadystatechange = function () {
    try {
      if (sendRequest.readyState == 4) {
        if (sendRequest.status == 0) {
          DiffusionClient.trace('checkRequest - lost connection');

          DiffusionClient.diffusionTransport.handleLostConnection();
        }
        _this.isSending = false;
        setTimeout(function () {
          _this.processRequest(null);
        }, 0);
      }
    } catch (e) {
      DiffusionClient.trace('error: processRequest ' + e);
    }
  };
  this.isSending = true;
  sendRequest.send(requestWrapper.data);
};
DiffusionXHRTransport.prototype.createDiffusionRequest = function (headers) {
  var request = this.createXHRTransport();
  request.open('POST', this.serverUrl, true);
  for (var header in headers) {
    try {
      request.setRequestHeader(header, headers[header]);
    } catch (e) {
      DiffusionClient.trace(
        "Can't set header " + header + ':' + headers.join(':')
      );
    }
  }

  var requestWrapper = { data: '', request: request };
  return requestWrapper;
};
DiffusionXHRTransport.prototype.detectXmlHttp = function () {
  var xmlhttp = null;
  // Can we get a native request
  try {
    xmlhttp = new XMLHttpRequest();
    DiffusionClient.trace('detectXmlHttp: got native');
    if (xmlhttp != null) {
      this.isNativeXmlHttp = true;
      return true;
    }
  } catch (e) {}

  if (DiffusionClient.isIE) {
    var activeXNames = new Array(
      'MSXML2.XMLHTTP.4.0',
      'MSXML2.XMLHTTP.3.0',
      'MSXML2.XMLHTTP',
      'Microsoft.XMLHTTP'
    );
    for (var i = 0; i < activeXNames.length; ++i) {
      try {
        xmlhttp = new ActiveXObject(activeXNames[i]);
      } catch (e) {}
      if (xmlhttp != null) {
        this.activeXName = activeXNames[i];
        DiffusionClient.trace('detectXmlHttp: ' + this.activeXName);
        return true;
      }
    }
  }
  return false;
};

DiffusionClient.internal = DiffusionClient.internal || {};

/**
 * ForeverFrame - Transport API
 * Creates separate up and downstream channels and calls the relevant methods on them.
 * @private
 * @author Matt Champion
 * @since 5.0
 */
var DiffusionForeverFrameTransport = function () {
  this.constants = DiffusionClient.internal.constants;
  this.transportUrl =
    DiffusionClient.connectionDetails._defaultForeverFrameURL +
    DiffusionClient.connectionDetails.context +
    '/diffusion/';
  this.iframeutils = new DiffusionClient.internal.IframeUtils();
  this.downstream = new DiffusionClient.internal.DownstreamStreamingIframe(
    this
  );
  this.upstream = new DiffusionClient.internal.UpstreamXHR(this);
};

DiffusionForeverFrameTransport.prototype.send = function (topic, message) {
  this.upstream._send(topic, message);
};
DiffusionForeverFrameTransport.prototype.sendTopicMessage = function (
  topicMessage
) {
  this.upstream._sendTopicMessage(topicMessage);
};
DiffusionForeverFrameTransport.prototype.subscribe = function (topic) {
  this.upstream._subscribe(topic);
};
DiffusionForeverFrameTransport.prototype.unsubscribe = function (topic) {
  this.upstream._unsubscribe(topic);
};
DiffusionForeverFrameTransport.prototype.ping = function (
  timeStamp,
  queueSize
) {
  this.upstream._ping(timeStamp, queueSize);
};
DiffusionForeverFrameTransport.prototype.sendAckResponse = function (ack) {
  this.upstream._sendAckResponse(ack);
};
DiffusionForeverFrameTransport.prototype.sendCredentials = function (
  credentials
) {
  this.upstream._sendCredentials(credentials);
};
DiffusionForeverFrameTransport.prototype.fetch = function (
  topic,
  correlationID
) {
  this.upstream._fetch(topic, correlationID);
};
DiffusionForeverFrameTransport.prototype.command = function (
  command,
  correlationID,
  topicMessage
) {
  this.upstream._command(command, correlationID, topicMessage);
};
DiffusionForeverFrameTransport.prototype.close = function () {
  this.upstream._close();
};
DiffusionForeverFrameTransport.prototype.sendClientPingResponse = function (
  header
) {
  this.upstream._sendClientPingResponse(header);
};
DiffusionForeverFrameTransport.prototype.connect = function (doReconnect) {
  var _cd = DiffusionClient.connectionDetails;
  if (_cd.disableForeverFrame) {
    DiffusionClient.diffusionTransport.cascade();
    return;
  }
  this.upstream._connect(
    this.constants.connectionTypes.BROWSER_STREAMING,
    doReconnect
  );
};

/**
 * Utilities for iframes
 * This relies on the container not being disposed of.
 * @private
 * @author Matt Champion
 * @since 5.0
 */
DiffusionClient.internal.IframeUtils = function (transport, createContainer) {
  this.constants = DiffusionClient.internal.constants;
  if (createContainer !== undefined) {
    this.containerContainer = false;
  } else {
    this.createContainer = createContainer;
  }
  this.containerContainer = null;
  this.containerId = 'DiffusionContainer';
  this.container = this.findContainer();
};

/*
 * Get the container
 * May try to create one
 */
DiffusionClient.internal.IframeUtils.prototype.findContainer = function () {
  var container = document.getElementById(this.containerId);
  if (container == null) {
    if (this.createContainer) {
      // Done in the DiffusionClient.connect
      // Responsibility scoping issue
      // TODO: Remove this behaviour from DiffusionClient.connect
      try {
        this.container = document.createElement(
          '<div id="' + this.containerId + '">'
        );
      } catch (e) {
        this.container = document.createElement('div');
        this.container['id'] = this.containerId;
      }
      if (this.containerContainer === null) {
        this.containerContainer = document.body;
        document.body.appendChild(this.container);
      } else {
        this.containerContainer.appendChild(this.container);
      }
    } else {
      throw new Exception('No ' + this.containerId + ' element');
    }
  } else {
    return container;
  }
};

/*
 * Create a new iframe but do not add it to the container
 */
DiffusionClient.internal.IframeUtils.prototype.createFrame = function (
  id,
  url
) {
  try {
    this.removeFrame(id);
  } catch (e) {}

  var iframe;

  try {
    iframe = document.createElement(
      '<iframe id="' + id + '" name="' + id + '" src="' + src + '">'
    );
  } catch (e) {
    iframe = document.createElement('iframe');
    iframe['id'] = id;
    iframe['name'] = id;
    iframe['src'] = url;
  }

  iframe.style.width = '0px';
  iframe.style.height = '0px';
  iframe.style.border = 'none';

  return iframe;
};

/*
 * Remove frame either by element or ID.
 */
DiffusionClient.internal.IframeUtils.prototype.removeFrame = function (frame) {
  if (frame !== undefined && frame !== null) {
    if (frame instanceof Element) {
      if (frame.parentElement !== null) {
        this.container.removeChild(frame);
      }
    } else if (typeof frame === 'string' || frame instanceof String) {
      var frameNode = document.getElementById(frame);
      if (frameNode.parentElement !== null) {
        this.container.removeChild(frameNode);
      }
    }
  }
};

/**
 * Downstream - Server to Client - Forever Frame - Streaming Iframe
 * @private
 * @author Matt Champion
 * @since 5.0
 */
DiffusionClient.internal.DownstreamStreamingIframe = function (transport) {
  this.constants = DiffusionClient.internal.constants;
  this.pollFrame = null;
  this.transport = transport;
  this.polling = false;
  this.ping = false;
  this.pingFrequency = 5000;
  this.closeDelay = 200;
};

DiffusionClient.internal.DownstreamStreamingIframe.prototype.createPollFrame =
  function () {
    var url =
      this.transport.transportUrl + '?m=1&c=' + DiffusionClient.getClientID();
    var iframe = this.transport.iframeutils.createFrame('FFPoll', url);

    var _this = this;
    var callback = function () {
      _this.processPollComplete();
    };

    this.pollFrame = iframe;

    // Attach event handlers for a completed iframe request
    if (iframe.attachEvent) {
      iframe.attachEvent('onload', callback);
      iframe.attachEvent('onerror', callback);
      iframe.attachEvent('onunload', callback);
      iframe.attachEvent('onabort', callback);
    } else {
      iframe.onload = callback;
      iframe.onerror = callback;
      iframe.onunload = callback;
      iframe.onabort = callback;
    }
    this.transport.iframeutils.container.appendChild(iframe);
    this.polling = true;
  };

DiffusionClient.internal.DownstreamStreamingIframe.prototype.startPing =
  function () {
    if (this.polling && this.ping) {
      setInterval(DiffusionClient.ping, this.pingFrequency);
    }
  };

DiffusionClient.internal.DownstreamStreamingIframe.prototype.poll =
  function () {
    if (DiffusionClient.diffusionTransport.isClosing) {
      return;
    }
    // Create a new poll if the client is not closing
    this.createPollFrame();
  };

DiffusionClient.internal.DownstreamStreamingIframe.prototype.processPollComplete =
  function () {
    this.polling = false;
    var frame = this.pollFrame;
    try {
      if (frame) {
        var content = frame.contentWindow.document
          ? frame.contentWindow.document
          : frame.contentDocument
          ? frame.contentDocument
          : frame.document;
        if (
          content !== undefined &&
          content !== null &&
          content.getElementsByTagName('script').length > 0
        ) {
          // If content was received on the last poll try to re-poll
          this.poll();
          return;
        }
      }
    } catch (e) {
      // Frequently a security exception thrown if your iFrame has failed
      DiffusionClient.trace(
        'DiffusionClient: Forever Frame Transport: Poll failed'
      );
    }

    // If no content was received on the last poll or there was an exception creating the new poll
    // Diffusion has gone away
    DiffusionClient.diffusionTransport.handleLostConnection();
  };

DiffusionClient.internal.DownstreamStreamingIframe.prototype.closeDownstream =
  function () {
    if (this.pollFrame !== null) {
      this.transport.iframeutils.removeFrame(this.pollFrame);
    }
  };

// Reduces the byte overhead in iframe transports
window._dhm = function (messages) {
  DiffusionClient.diffusionTransport.handleMessages(messages);
};

/**
 * Upstream - Client to Server - XHR
 * @private
 * @author Matt Champion
 * @since 5.0
 */
DiffusionClient.internal.UpstreamXHR = function (transport) {
  this.constants = DiffusionClient.internal.constants;
  this.transport = transport;
  this.requests = new Array();
  this.isSending = false;
  this.isNativeXmlHttp = false;
  this.isActiveX = false;
  this.activeXName = null;
  this.seq = 0;
  this.detectRequestType();
};
DiffusionClient.internal.UpstreamXHR.prototype._sendTopicMessage = function (
  topicMessage
) {
  var headers = {
    t: encodeURIComponent(topicMessage.getTopic()),
  };

  if (topicMessage.getUserHeaders() != null) {
    headers.u = encodeURIComponent(
      topicMessage.getUserHeaders().join(this.constants.delimiters.FD)
    );
  }

  if (topicMessage.getAckRequired()) {
    headers.a = topicMessage.getAckID();
  }

  var requestWrapper = this._createSequencedDiffusionRequest(
    this.constants.methods.SEND,
    headers
  );
  requestWrapper.data = topicMessage.getMessage();

  this._processRequest(requestWrapper);
};
DiffusionClient.internal.UpstreamXHR.prototype._send = function (
  topic,
  message
) {
  var requestWrapper = this._createSequencedDiffusionRequest(
    this.constants.methods.SEND,
    {
      t: encodeURIComponent(topic),
    }
  );
  requestWrapper.data = message;
  this._processRequest(requestWrapper);
};
DiffusionClient.internal.UpstreamXHR.prototype._XHRSubscription = function (
  topic,
  action
) {
  this._processRequest(
    this._createSequencedDiffusionRequest(action, {
      t: encodeURIComponent(topic),
    })
  );
};
DiffusionClient.internal.UpstreamXHR.prototype._subscribe = function (topic) {
  this._XHRSubscription(topic, this.constants.methods.SUBSCRIBE);
};
DiffusionClient.internal.UpstreamXHR.prototype._unsubscribe = function (topic) {
  this._XHRSubscription(topic, this.constants.methods.UNSUBSCRIBE);
};
DiffusionClient.internal.UpstreamXHR.prototype._ping = function (
  timeStamp,
  queueSize
) {
  this._processRequest(
    this._createSequencedDiffusionRequest(this.constants.methods.PING_SERVER, {
      u: encodeURIComponent(
        [timeStamp, queueSize].join(this.constants.delimiters.FD)
      ),
    })
  );
};
DiffusionClient.internal.UpstreamXHR.prototype._sendClientPingResponse =
  function (header) {
    this._processRequest(
      this._createSequencedDiffusionRequest(
        this.constants.methods.PING_CLIENT,
        {
          u: header,
        }
      )
    );
  };
DiffusionClient.internal.UpstreamXHR.prototype._sendAckResponse = function (
  ack
) {
  this._processRequest(
    this._createSequencedDiffusionRequest(this.constants.methods.ACK, {
      a: ack,
    })
  );
};
DiffusionClient.internal.UpstreamXHR.prototype._fetch = function (
  topic,
  correlationID
) {
  var headers = {
    t: encodeURIComponent(topic),
  };

  if (correlationID) {
    headers.u = correlationID;
  }

  this._processRequest(
    this._createSequencedDiffusionRequest(this.constants.methods.FETCH, headers)
  );
};
DiffusionClient.internal.UpstreamXHR.prototype._command = function (
  command,
  correlationID,
  topicMessage
) {
  var headers = {
    t: encodeURIComponent(topicMessage.getTopic()),
  };

  headers.u = command;
  if (correlationID !== undefined && correlationID !== null) {
    headers.u += this.constants.delimiters.FD + correlationID;
  }
  if (topicMessage.getUserHeaders() != null) {
    headers.u += this.constants.delimiters.FD;
    headers.u += topicMessage
      .getUserHeaders()
      .join(this.constants.delimiters.FD);
  }

  if (headers.u != undefined || headers.u != null) {
    headers.u = encodeURIComponent(headers.u);
  }

  if (topicMessage.getAckRequired()) {
    headers.a = topicMessage.getAckID();
  }

  var requestWrapper = this._createSequencedDiffusionRequest(
    this.constants.methods.COMMAND,
    headers
  );
  requestWrapper.data = topicMessage.getMessage();

  this._processRequest(requestWrapper);
};

DiffusionClient.internal.UpstreamXHR.prototype._sendCredentials = function (
  credentials
) {
  this._processRequest(
    this._createSequencedDiffusionRequest(this.constants.methods.CREDENTIALS, {
      username: encodeURIComponent(credentials.username),
      password: encodeURIComponent(credentials.password),
    })
  );
};
DiffusionClient.internal.UpstreamXHR.prototype._close = function () {
  var request = this._createXHRTransport();
  request.open(
    this.constants.httpHeaders.POST,
    this.transport.transportUrl,
    false
  );
  request.setRequestHeader(
    this.constants.httpHeaders.METHOD,
    this.constants.methods.CLOSE
  );
  request.setRequestHeader(
    this.constants.httpHeaders.CLIENT_ID,
    DiffusionClient.getClientID()
  );
  try {
    request.send('');
  } catch (e) {}
  if (this.transport.downstream.polling) {
    this.transport.downstream.closeDownstream();
  }
};

DiffusionClient.internal.UpstreamXHR.prototype._connect = function (
  clientType,
  doReconnect
) {
  if (!this.isNativeXmlHttp && !this.isActiveX) {
    DiffusionClient.diffusionTransport.cascade();
    return;
  }

  if (clientType === this.constants.connectionTypes.BROWSER_STREAMING) {
    DiffusionClient.trace('Forever Frame connect');
  } else if (clientType === this.constants.connectionTypes.BROWSER) {
    DiffusionClient.trace('XHR connect');
  } else {
    DiffusionClient.trace('Unknown connect');
  }

  this.seq = 0;
  var _this = this;
  var headers = {
    m: this.constants.methods.CONNECT,
    ty: clientType,
    t: encodeURIComponent(DiffusionClient.connectionDetails.topic),
    tt: DiffusionClient.connectionDetails.transportTimeout,
    v: DiffusionClient.getClientProtocolVersion(),
  };

  if (doReconnect) {
    headers.c = DiffusionClient.getClientID();
  }

  var creds = DiffusionClient.getCredentials();

  if (creds != null) {
    headers.username = encodeURIComponent(creds.username);
    headers.password = encodeURIComponent(creds.password);
  }

  var request = this._createDiffusionRequest(headers).request;
  request.onreadystatechange = function () {
    if (request.readyState == 4) {
      if (request.status == 200) {
        var somedata = request.responseText.split(
          _this.constants.delimiters.FD
        );
        // First byte is protocol version
        DiffusionClient.serverProtocolVersion = somedata.shift();
        var responseCode = somedata.shift();
        DiffusionClient.messageLengthSize = somedata.shift();

        if (
          responseCode == _this.constants.connectionResponses.SUCCESSFUL ||
          responseCode ==
            _this.constants.connectionResponses.SUCCESSFUL_RECONNECT
        ) {
          DiffusionClient.diffusionTransport.connected(somedata[0]);
          _this.transport.downstream.poll();
        } else {
          if (responseCode == _this.constants.connectionResponses.REJECTED) {
            DiffusionClient.diffusionTransport.connectionRejected();
          }

          DiffusionClient.diffusionTransport.cascade();
        }
      } else {
        DiffusionClient.diffusionTransport.cascade();
      }
    }
  };
  request.send('');
};
DiffusionClient.internal.UpstreamXHR.prototype._createXHRTransport =
  function () {
    if (this.isNativeXmlHttp) {
      return new XMLHttpRequest();
    } else if (this.isActiveX) {
      // Active XObjects do not appear to have prototypes
      return new ActiveXObject(this.activeXName);
    } else {
      throw new Exception('Unable to create XHR request');
    }
  };

DiffusionClient.internal.UpstreamXHR.prototype._createSequencedDiffusionRequest =
  function (method, headers) {
    var request = this._createXHRTransport();
    request.open(
      this.constants.httpHeaders.POST,
      this.transport.transportUrl,
      true
    );
    // All sequenced requests have method, client and sequence number
    try {
      request.setRequestHeader(this.constants.httpHeaders.METHOD, method);
    } catch (e) {
      DiffusionClient.trace("Can't set header m");
    }
    try {
      request.setRequestHeader(
        this.constants.httpHeaders.CLIENT_ID,
        DiffusionClient.getClientID()
      );
    } catch (e) {
      DiffusionClient.trace("Can't set header c");
    }
    try {
      request.setRequestHeader(this.constants.httpHeaders.SEQUENCE, this.seq++);
    } catch (e) {
      DiffusionClient.trace("Can't set header s");
    }

    for (var header in headers) {
      try {
        request.setRequestHeader(header, headers[header]);
      } catch (e) {
        DiffusionClient.trace(
          "Can't set header " + header + ':' + headers.join(':')
        );
      }
    }

    var requestWrapper = {
      data: '',
      request: request,
    };
    return requestWrapper;
  };

DiffusionClient.internal.UpstreamXHR.prototype._createDiffusionRequest =
  function (headers) {
    var request = this._createXHRTransport();
    request.open(
      this.constants.httpHeaders.POST,
      this.transport.transportUrl,
      true
    );
    for (var header in headers) {
      try {
        request.setRequestHeader(header, headers[header]);
      } catch (e) {
        DiffusionClient.trace(
          "Can't set header " + header + ':' + headers.join(':')
        );
      }
    }

    var requestWrapper = {
      data: '',
      request: request,
    };
    return requestWrapper;
  };

DiffusionClient.internal.UpstreamXHR.prototype._storeRequest = function (
  requestWrapper
) {
  if (requestWrapper !== null) {
    // TODO: Batch stored requests
    this.requests.push(requestWrapper);
  }
};

DiffusionClient.internal.UpstreamXHR.prototype._processRequest = function (
  request
) {
  var requestWrapper;
  if (this.isSending) {
    // Already sending, store request
    this._storeRequest(request);
    return;
  } else {
    if (this.requests.length === 0) {
      if (request === null) {
        return;
      } else {
        // No stored requests
        requestWrapper = request;
      }
    } else {
      // Add request to queue, get next request
      this._storeRequest(request);
      requestWrapper = this.requests.shift();
    }
  }

  var sendRequest = requestWrapper.request;

  var _this = this;

  sendRequest.onreadystatechange = function () {
    try {
      if (sendRequest.readyState == 4) {
        if (sendRequest.status == 0) {
          DiffusionClient.trace('checkRequest - lost connection');

          DiffusionClient.diffusionTransport.handleLostConnection();
        }
        _this.isSending = false;
      }
    } catch (e) {
      DiffusionClient.trace('Error: XHR: _processRequest ' + e);
    }
    setTimeout(function () {
      // Check for stored messages
      // Use separate event to allow this one to complete first
      _this._processRequest(null);
    }, 0);
  };
  this.isSending = true;
  sendRequest.send(requestWrapper.data);
};

DiffusionClient.internal.UpstreamXHR.prototype.detectRequestType = function () {
  var xmlhttp = null;
  if (XMLHttpRequest) {
    this.isNativeXmlHttp = true;
    this.isActiveX = false;
  }

  try {
    xmlhttp = new XMLHttpRequest();
    DiffusionClient.trace('detectXmlHttp: got native');
    if (xmlhttp != null) {
      this.isNativeXmlHttp = true;
      this.isActiveX = false;
      return;
    }
  } catch (e) {}

  if (DiffusionClient.isIE) {
    var activeXNames = new Array(
      'MSXML2.XMLHTTP.4.0',
      'MSXML2.XMLHTTP.3.0',
      'MSXML2.XMLHTTP',
      'Microsoft.XMLHTTP'
    );
    for (var i = 0; i < activeXNames.length; ++i) {
      try {
        xmlhttp = new ActiveXObject(activeXNames[i]);
      } catch (e) {}
      if (xmlhttp != null) {
        this.activeXName = activeXNames[i];
        DiffusionClient.trace('detectXmlHttp: ' + this.activeXName);
        this.isNativeXmlHttp = false;
        this.isActiveX = true;
        return false;
      }
    }
  }
  this.isNativeXmlHttp = false;
  this.isActiveX = false;
};
/**
 * @author Push Technology Ltd
 * @class FragmentedMessage
 */
var FragmentedMessage = function (message) {
  this.payload = message;
  this.messageType = message.charCodeAt(0);
  this.rows = message.split('\u0001');
  this.headers = this.rows[0].split('\u0002');

  var data = this.headers.shift();

  this.topic = data.substr(1, data.length);

  this.baseType = null;
  this.fragmentedType = this.messageType;
  this.baseType = this.messageType & ~0x40;

  this.correlationId = this.headers[0];

  this.totalSize = null;
  var parts = this.headers[1].split('/');
  this.partNumber = parts[0];
  this.totalParts = parts[1];
  if (this.totalParts != null) {
    this.totalSize = this.headers[2];
  }
};

/**
 * Returns the Correlation ID for this message
 * @returns {Number} Correlation ID
 */
FragmentedMessage.prototype.getCorrelationId = function () {
  return this.correlationId;
};

/**
 * Returns the header string as supplied from the server
 * @returns {String} Header string
 */
FragmentedMessage.prototype.getHeader = function () {
  return this.rows[0];
};

/**
 * Returns the message headers as an array
 * @returns {String[]} Headers
 */
FragmentedMessage.prototype.getHeaders = function () {
  return this.headers;
};

/**
 * Get the message body
 * @returns {String} Body
 */
FragmentedMessage.prototype.getBody = function () {
  return this.payload.substr(this.getHeader().length + 1);
};

/**
 * Get the type for this message
 * @returns {Number} Type
 */
FragmentedMessage.prototype.getBaseType = function () {
  return this.baseType;
};

/**
 * Get the part number for this particular fragment
 * @returns {Number} Part number
 */
FragmentedMessage.prototype.getPartNumber = function () {
  return this.partNumber;
};

/**
 * Get the total number of parts for the message
 * @returns {Number} Total parts
 */
FragmentedMessage.prototype.getTotalParts = function () {
  return this.totalParts;
};

/**
 * Attempts to assemble a complete message from the fragments currently received
 * @returns {String} Assembled message
 */
FragmentedMessage.prototype.process = function () {
  if (DiffusionClient.fragmentMap[this.correlationId] === undefined) {
    DiffusionClient.fragmentMap[this.correlationId] = {};
  }
  DiffusionClient.fragmentMap[this.correlationId][this.partNumber] = this;

  var fragments = DiffusionClient.fragmentMap[this.correlationId];
  var first = DiffusionClient.fragmentMap[this.correlationId]['1'];
  if (first === undefined) {
    return null;
  }

  // Quickly count the fragments
  var count = 0;
  for (var key in fragments) {
    count++;
  }
  if (count < first.getTotalParts()) {
    return null;
  }

  var headers = '';
  for (var i = 3; i < first.getHeaders().length; i++) {
    if (i > 3) {
      headers = headers + '\u0002';
    }
    headers = headers + first.getHeaders()[i];
  }
  var body = '';
  for (var f in fragments) {
    body = body + fragments[f].getBody();
  }

  var assembled =
    String.fromCharCode(this.baseType) +
    this.topic +
    '\u0002' +
    headers +
    '\u0001' +
    body;

  return assembled;
};
DiffusionClient.internal = DiffusionClient.internal || {};

/**
 * Monitor for interactions, allows the detection of silent connections
 * @private
 * @author Matt Champion
 * @since 5.0
 */
DiffusionClient.internal.LivenessMonitor = function (transport) {
  this.frequency = 0;
  this.nPings = 0;
  this.lastPing = null;
  this.nextEvent = null;
  this.running = false;
  this.transport = transport;
};

// Update the monitor
// Cancel the existing timeout and create another
DiffusionClient.internal.LivenessMonitor.prototype.onInteraction = function () {
  if (!this.running) {
    return;
  }

  if (this.lastPing === null) {
    this.lastPing = new Date().getTime();
  } else if (this.nPings < 10) {
    var currentTime = new Date().getTime();
    var lastInterval = currentTime - this.lastPing;
    var totalInterval = this.nPings * this.frequency + lastInterval;
    this.nPings++;
    this.frequency = totalInterval / this.nPings;
    this.lastPing = currentTime;
  }

  if (this.nextEvent !== null) {
    this.nextEvent.cancel();
  }
  if (this.nPings > 1) {
    this.nextEvent = new DiffusionClient.internal.LivenessTimeoutEvent(
      this.transport
    );
    var event = this.nextEvent;
    setTimeout(function () {
      event.execute();
    }, this.frequency * 2);
  }
};

// Listen for ping messages
DiffusionClient.internal.LivenessMonitor.prototype.startup = function () {
  this.nextEvent = null;
  this.running = true;
};

// Stop listening for pings and ignore missing pings
DiffusionClient.internal.LivenessMonitor.prototype.shutdown = function () {
  if (this.nextEvent !== null) {
    this.nextEvent.cancel();
    this.nextEvent = null;
  }
  this.running = false;
};

// A cancellable callback
DiffusionClient.internal.LivenessTimeoutEvent = function (transport) {
  this.cancelled = false;
  this.transport = transport;
};

// Cancel the event
DiffusionClient.internal.LivenessTimeoutEvent.prototype.cancel = function () {
  this.cancelled = true;
};

// Execute the lost connection function if not cancelled
DiffusionClient.internal.LivenessTimeoutEvent.prototype.execute = function () {
  if (!this.cancelled) {
    this.transport.handleLostConnection();
  }
};

/**
 * @class The data type of the topic data.
 * @param code The representation of the data type
 * @param description The description of the data type
 * @returns {TopicDataType}
 * @author Matt Champion
 * @since 4.6
 */
var TopicDataType = function (code, description) {
  /**
   * Used to represent the data type
   * @returns {String}
   */
  this.code = code;
  /**
   * The name of the data type
   * @returns {String}
   */
  this.description = description;
};

/**
 * @returns {TopicDataType}
 */
TopicDataType.NONE = new TopicDataType('N', 'None');
/**
 * @returns {TopicDataType}
 */
TopicDataType.SINGLE_VALUE = new TopicDataType('S', 'Single');
/**
 * @returns {TopicDataType}
 */
TopicDataType.RECORD = new TopicDataType('R', 'Record');
/**
 * @returns {TopicDataType}
 */
TopicDataType.PROTOCOL_BUFFER = new TopicDataType(
  'G',
  'Google protocol buffer'
);
/**
 * @returns {TopicDataType}
 */
TopicDataType.CUSTOM = new TopicDataType('U', 'Custom');
/**
 * @returns {TopicDataType}
 */
TopicDataType.SLAVE = new TopicDataType('SD', 'Slave');
/**
 * @returns {TopicDataType}
 */
TopicDataType.SERVICE = new TopicDataType('SV', 'Service');
/**
 * @returns {TopicDataType}
 */
TopicDataType.PAGED_STRING = new TopicDataType('PS', 'Paged string');
/**
 * @returns {TopicDataType}
 */
TopicDataType.PAGED_RECORD = new TopicDataType('PR', 'Paged record');
/**
 * @returns {TopicDataType}
 */
TopicDataType.TOPIC_NOTIFY = new TopicDataType('TN', 'Topic notify');
/**
 * @returns {TopicDataType}
 */
TopicDataType.ROUTING = new TopicDataType('RO', 'Routing');
/**
 * @returns {TopicDataType}
 */
TopicDataType.CHILD_LIST = new TopicDataType('C', 'Child');

/**
 * @private
 */
TopicDataType.MAP = {
  N: TopicDataType.NONE,
  S: TopicDataType.SINGLE_VALUE,
  R: TopicDataType.RECORD,
  G: TopicDataType.PROTOCOL_BUFFER,
  U: TopicDataType.CUSTOM,
  SD: TopicDataType.SLAVE,
  SV: TopicDataType.SERVICE,
  PS: TopicDataType.PAGED_STRING,
  PR: TopicDataType.PAGED_RECORD,
  TN: TopicDataType.TOPIC_NOTIFY,
  RO: TopicDataType.ROUTING,
  C: TopicDataType.CHILD_LIST,
};

/**
 * @class The notification level for topic added notifications.
 * @param {String} code Level code
 * @returns {NotificationLevel}
 * @author Matt Champion
 * @since 4.6
 */
var NotificationLevel = function (code) {
  /**
   * @private
   */
  this.code = code;
};

/**
 * Minimal notification level. Only the topic name and datatype.
 * @returns {NotificationLevel}
 */
NotificationLevel.MINIMAL = new NotificationLevel('0');
/**
 * Properties notification level. The topic name, datatype and properties.
 * @returns {NotificationLevel}
 */
NotificationLevel.PROPERTIES = new NotificationLevel('1');
/**
 * Metadata notification level. The topic name, datatype and metadata.
 * @returns {NotificationLevel}
 */
NotificationLevel.METADATA = new NotificationLevel('2');
/**
 * Full notification level. The topic name, datatype, properties and metadata.
 * @returns {NotificationLevel}
 */
NotificationLevel.FULL = new NotificationLevel('3');
/**
 * No addition notification level. Does not send addition notifications.
 * @returns {NotificationLevel}
 */
NotificationLevel.NONE = new NotificationLevel('4');

/**
 * @class The selection mode when altering the selections.
 * @param {String} code Mode code
 * @author Matt Champion
 * @since 4.6
 * @returns {SelectionMode}
 */
var SelectionMode = function (code) {
  /**
   * @private
   */
  this.code = code;
};

/**
 * The selection mode to add a selection.
 * @returns {SelectionMode}
 */
SelectionMode.ADD = new SelectionMode('A');
/**
 * The selection mode to remove a selection.
 * @returns {SelectionMode}
 */
SelectionMode.REMOVE = new SelectionMode('D');
/**
 * The selection mode to replace the current selections.
 * @returns {SelectionMode}
 */
SelectionMode.REPLACE = new SelectionMode('R');
/**
 * The selection mode to clear all the selections.
 * @returns {SelectionMode}
 */
SelectionMode.CLEAR = new SelectionMode('X');

/**
 * @class The metadata of the topic.
 * @param {String} name Name
 * @param {String} xml XML
 * @returns {TopicMetadata}
 * @author Matt Champion
 * @since 4.6
 */
var TopicMetadata = function (name, xml) {
  /**
   * Name of metadata
   * @returns {String}
   */
  this.name = name;
  /**
   * XML of metadata
   * @returns {String}
   */
  this.xml = xml;
};

/**
 * @private
 */
TopicMetadata.CACHE = new Object();

/**
 * Method to either create or get topic data from the cache. If two arguments
 * are provide it creates a new metadata object and places it in the cache. If
 * one argument is provided it looks up the name in the cache.
 * @param {String} name Name of metadata
 * @param {String} xml (Optional) The XML of the metadata
 * @returns {TopicMetadata}
 * @author Matt Champion
 * @since 4.6
 */
TopicMetadata.getTopicMetadata = function (name, xml) {
  if (xml === undefined) {
    return TopicMetadata.CACHE[name];
  } else {
    var metadata = new TopicMetadata(name, xml);
    TopicMetadata.CACHE[name] = metadata;
    return metadata;
  }
};

/**
 * @class The definition for the topic. Describes the data type, properties
 * and metadata. The properties and metadata may be null when the notification
 * does not describe them.
 * @returns {TopicDefinition}
 * @author Matt Champion
 * @since 4.6
 */
var TopicDefinition = function (dataType, properties, metadata) {
  /**
   * The data type of the topic.
   * @returns {TopicDataType}
   */
  this.dataType = dataType;
  /**
   * The properties of the topic. Each property set is stored as a field.
   * @returns {Object}
   */
  this.properties = properties;
  /**
   * The metadata of the topic.
   * @returns {TopicMetadata}
   */
  this.metadata = metadata;
};

/**
 * @class Basic pattern for notification listeners. The methods need to be
 * implemented
 * @author Matt Champion
 * @since 4.6
 * @returns {TopicNotifyTopicListener}
 */
var TopicNotifyTopicListener = function () {};

/**
 * Callback for added topic notifications.
 * @param {String} topic Topic name
 * @param {TopicDefinition} definition Definition of the topic
 * @author Matt Champion
 * @since 4.6
 */
TopicNotifyTopicListener.prototype.topicAdded = function (topic, definition) {};

/**
 * Callback for removed topic notifications.
 * @param {String} topic Topic name
 * @author Matt Champion
 * @since 4.6
 */
TopicNotifyTopicListener.prototype.topicRemoved = function (topic) {};

/**
 * Callback for updated topic notifications.
 * @param {String} topic Topic name
 * @param {Object} properties Properties of the topic
 * @author Matt Champion
 * @since 4.6
 */
TopicNotifyTopicListener.prototype.topicUpdated = function (
  topic,
  properties
) {};

/**
 * Constructor for handler. Instances of this class should be created using the
 * DiffusionClient object.
 * @param {WebClientMessage} message ITL for notification topic
 * @param {DiffusionClient} client The DiffusionClient
 * @param {TopicNotifyTopicListener} listener The listener object
 * @returns {TopicNotifyTopicHandler}
 * @class The handler for topic notifications. A topic providing topic
 * notifications is subscribed to as normal. The messages received can be
 * parsed by this handler. To create the handler pass in the topic load
 * message from the subscription to the topic. Also required is the client,
 * a notification level and a listener object. The handler can then make
 * selections of topics to listen for notifications to. When a notification
 * is received it is passed to the listener object passed in.
 * @author Matt Champion
 * @since 4.6
 */
var TopicNotifyTopicHandler = function (message, client, listener) {
  this.client = client;
  this.notificationListener = listener;
  this.topicName = message.getTopic();
  this.topicListener = client.addTopicListener(
    this.topicName,
    this.handleTopicMessage,
    this
  );
};

/**
 * Sets the notification level. This sets the level for addition
 * notifications only. Removed and updated notifications will be
 * disabled.
 * @param {NotificationLevel} additions The level of notifications for addition notifications
 * @param {Boolean} removals The level of notifications for removal notifications
 * @param {Boolean} updates The level of notifications for update notifications
 * @since 4.6
 * @author Matt Champion
 */
TopicNotifyTopicHandler.prototype.setNotificationDetails = function (
  additions,
  removals,
  updates
) {
  var message = new TopicMessage(this.topicName, null);
  message.setUserHeaders([additions.code, 'true', removals, updates]);
  this.client.command('L', null, message);
};

/**
 * Updates the topic notifications that are listened for. The
 * selections can be added, removed or cleared.
 * @param {SelectionMode} mode The operation mode applied to the set of selections
 * @param {String} selection The set of selections to use in the modification
 * @since 4.6
 * @author Matt Champion
 */
TopicNotifyTopicHandler.prototype.select = function (mode, selection) {
  var message = new TopicMessage(this.topicName, null);
  message.setUserHeaders([mode.code, selection]);
  this.client.command('S', null, message);
};

/**
 * @private
 */
TopicNotifyTopicHandler.prototype.handleTopicMessage = function (message) {
  var headers = message.getUserHeaders();
  if (headers.length === 1) {
    if (message.notificationType === 'A') {
      this.notificationListener.topicAdded(
        headers[0],
        this.parseAddedNotification(message)
      );
    } else if (message.notificationType === 'D') {
      this.notificationListener.topicRemoved(headers[0]);
    } else if (message.notificationType === 'U') {
      this.notificationListener.topicUpdated(
        headers[0],
        this.parseUpdatedNotification(message)
      );
    }
  }
};

/**
 * @private
 */
TopicNotifyTopicHandler.prototype.parseAddedNotification = function (message) {
  var records = message.getRecords();
  var dataType = null;
  var properties = null;
  var metadata = null;
  if (records.length > 0) {
    dataType = TopicDataType.MAP[records[0].getField(0)];

    if (records.length > 1) {
      var fields = records[1].getFields();
      if (fields.length > 1) {
        properties = new Object();
        for (var i = 0; i < fields.length; i = i + 2) {
          properties[fields[i]] = fields[i + 1];
        }
      }

      if (records.length > 2) {
        if (records[2].getFields().length === 3) {
          if (records[2].getField(0) === 'X') {
            metadata = TopicMetadata.getTopicMetadata(
              records[2].getField(1),
              records[2].getField(2)
            );
          }
        } else if (records[2].getFields().length === 2) {
          if (records[2].getField(0) === 'R') {
            metadata = TopicMetadata.getTopicMetadata(records[2].getField(1));
          }
        }
      }
    }
  }
  return new TopicDefinition(dataType, properties, metadata);
};

/**
 * @private
 */
TopicNotifyTopicHandler.prototype.parseUpdatedNotification = function (
  message
) {
  var records = message.getRecords();
  var properties = new Object();
  var fields = records[0].getFields();
  if (fields.length > 1) {
    properties = new Object();
    for (var i = 0; i < fields.length; i = i + 2) {
      properties[fields[i]] = fields[i + 1];
    }
  }
  return properties;
};
/**
 * @class Describes the status of the current page view.
 * @returns {PageStatus}
 * @author Matt Champion
 * @since 5.0
 */
var PageStatus = function (currentPage, lastPage, numberOfLines) {
  /**
   * The page currently open.
   *
   * @returns {Number}
   */
  this.currentPage = currentPage;
  /**
   * The last page available to be opened.
   *
   * @returns {Number}
   */
  this.lastPage = lastPage;
  /**
   * The total number of lines in the topic.
   *
   * @returns {Number}
   */
  this.numberOfLines = numberOfLines;
  /**
   * Is the status dirty. True if view is out of sync.
   *
   * @returns {Number}
   */
  this.isDirty = false;
};

/**
 * @class A set of lines for a page.
 *        <P>
 *        This is also an Array. Each entry in the array will be either a field
 *        as a String or a record as a Record. The properties this object adds
 *        allow this to be determined.
 * @returns {Lines}
 * @author Matt Champion
 * @since 5.0
 */
var Lines = function () {
  /**
   * True if the array contains strings
   *
   * @returns {Boolean}
   */
  this.isString = false;
  /**
   * True if the array contains records
   *
   * @returns {Boolean}
   */
  this.isRecord = false;
};
Lines.prototype = new Array();

/**
 * @class The skeleton implementation of a listener for paged topics.
 *        <P>
 *        The listener provided when creating the handler will be extended with
 *        this so that you only need to implement the methods you are interested
 *        in.
 * @returns {PagedTopicListener}
 * @author Matt Champion
 * @since 5.0
 */
var PagedTopicListener = function () {};
/**
 * Callback for when the handler has been created.
 * <P>
 * The handler must be created using the topic load of the paged topic. This
 * means it is created asynchronously.
 *
 * @param {PagedTopicHandler}
 *            handler The PagedTopicHandler
 * @since 5.0
 * @author Matt Champion
 */
PagedTopicListener.prototype.ready = function (handler) {};
/**
 * Callback for when a line is added to the current page.
 *
 * @param {PagedTopicHandler}
 *            handler The PagedTopicHandler
 * @param {PageStatus}
 *            status The PageStatus
 * @param {Lines}
 *            lines The lines that have been added
 * @since 5.0
 * @author Matt Champion
 */
PagedTopicListener.prototype.add = function (handler, status, lines) {};
/**
 * Callback for when a page is loaded.
 *
 * @param {PagedTopicHandler}
 *            handler The PagedTopicHandler
 * @param {PageStatus}
 *            status The PageStatus
 * @param {Lines}
 *            lines The lines making up the page
 * @since 5.0
 * @author Matt Champion
 */
PagedTopicListener.prototype.page = function (handler, status, lines) {};
/**
 * Callback for when a the status of the topic is changed but the view of the
 * current page is not changed.
 *
 * @param {PagedTopicHandler}
 *            handler The PagedTopicHandler
 * @param {PageStatus}
 *            status The PageStatus
 * @since 5.0
 * @author Matt Champion
 */
PagedTopicListener.prototype.statusChanged = function (handler, status) {};
/**
 * Callback for when a line on the current page is updated.
 *
 * @param {PagedTopicHandler}
 *            handler The PagedTopicHandler
 * @param {PageStatus}
 *            status The PageStatus
 * @param {Number}
 *            index The index of the updated line on the current page
 * @param {Lines}
 *            line The lines that have been added
 * @since 5.0
 * @author Matt Champion
 */
PagedTopicListener.prototype.update = function (
  handler,
  status,
  index,
  line
) {};

/**
 * @class Paged topic handler.
 *        <P>
 *        This allows the page view to be controlled. It can be used to send
 *        messages to change the view. The responses may cause a
 *        PagedTopicListener callback to be invoked.
 *
 * @param {String}
 *            topicLoad Topic load message
 * @param {PagedTopicListener}
 *            listener The listener for the handler
 * @returns {PagedTopicHandler}
 * @author Matt Champion
 * @since 5.0
 */
var PagedTopicHandler = function (topicLoad, listener) {
  this.topicName = topicLoad.getTopic();
  this.topicPattern = '^' + this.topicName + '$';
  this.pageListener = DiffusionClient.extend(
    new PagedTopicListener(),
    listener
  );
  this.type = topicLoad.topicType;
  this.topicListener = DiffusionClient.addTopicListener(
    this.topicName,
    this.handleMessage,
    this
  );
  /**
   * If a page is currently open
   *
   * @returns {Boolean}
   */
  this.isOpen = false;
  /**
   * The most recent status received by the handler
   *
   * @returns {PageStatus}
   */
  this.currentStatus = null;
};
/**
 * Create a topic message and send the page command
 *
 * @private
 * @since 5.0
 * @author Matt Champion
 */
PagedTopicHandler.prototype.sendPage = function (page) {
  var message = new TopicMessage(this.topicName);
  DiffusionClient.page(page, message);
};
/**
 * Selects a page to open in the current view. Throws a TypeError if page is not
 * and cannot be parsed into a number.
 *
 * @param {Number}
 *            page The page to go to
 * @since 5.0
 * @author Matt Champion
 */
PagedTopicHandler.prototype.page = function (page) {
  if (typeof page === 'number' || page instanceof Number) {
    this.sendPage(page);
  } else if (typeof page === 'string' || page instanceof String) {
    var pageNum = parseInt(page);
    if (isNaN(pageNum)) {
      throw new TypeError('Page must be a number');
    } else {
      this.sendPage(pageNum);
    }
  } else {
    throw new TypeError('Page must be a number');
  }
};
/**
 * Opens a view of the paged topic.
 *
 * @param {Number}
 *            lines The number of lines on each page
 * @param {Number}
 *            page The page to view first
 * @since 5.0
 * @author Matt Champion
 */
PagedTopicHandler.prototype.open = function (lines, page) {
  var message = new TopicMessage(this.topicName);
  message.addUserHeader('' + lines);
  message.addUserHeader('' + page);
  DiffusionClient.page('O', message);
  this.isOpen = true;
};
/**
 * Closes the view of the paged topic. A new view can be opened once the view is
 * closed.
 *
 * @since 5.0
 * @author Matt Champion
 */
PagedTopicHandler.prototype.close = function () {
  this.sendPage('C');
  this.isOpen = false;
};
/**
 * Changes the view to the first page of the topic.
 *
 * @since 5.0
 * @author Matt Champion
 */
PagedTopicHandler.prototype.first = function () {
  this.sendPage('F');
};
/**
 * Changes the view to the last page of the topic.
 *
 * @since 5.0
 * @author Matt Champion
 */
PagedTopicHandler.prototype.last = function () {
  this.sendPage('L');
};
/**
 * Changes the view to the next page of the topic.
 *
 * @since 5.0
 * @author Matt Champion
 */
PagedTopicHandler.prototype.next = function () {
  this.sendPage('N');
};
/**
 * Changes the view to the previous page of the topic.
 *
 * @since 5.0
 * @author Matt Champion
 */
PagedTopicHandler.prototype.prior = function () {
  this.sendPage('P');
};
/**
 * Refreshes the view of the current page of the topic.
 *
 * @since 5.0
 * @author Matt Champion
 */
PagedTopicHandler.prototype.refresh = function () {
  this.sendPage('R');
};
/**
 * Process a message and call the relevant listener method.
 *
 * @private
 * @since 5.0
 * @author Matt Champion
 */
PagedTopicHandler.prototype.handleMessage = function (message) {
  var headers = message.getUserHeaders();
  var currentPage = headers[0];
  var lastPage = headers[1];
  var totalLines = headers[2];
  var status = new PageStatus(currentPage, lastPage, totalLines);
  this.currentStatus = status;
  if (message.notificationType === 'P') {
    var lines = this.createLines(message);
    this.pageListener.page(this, status, lines);
  } else if (message.notificationType === 'S') {
    var dirty = headers[3];
    if (dirty === 'true') {
      status.isDirty = true;
    }
    this.pageListener.statusChanged(this, status);
  } else if (message.notificationType === 'U') {
    var index = parseInt(headers[3]);
    var lines = this.createLines(message);
    this.pageListener.update(this, status, index, lines);
  } else if (message.notificationType === 'A') {
    var lines = this.createLines(message);
    this.pageListener.add(this, status, lines);
  }
};
/**
 * Create a Lines object with the correct type of lines
 *
 * @private
 * @since 5.0
 * @author Matt Champion
 */
PagedTopicHandler.prototype.createLines = function (message) {
  var lines = new Lines();
  if (this.type === 'PS') {
    while (message.hasRemaining()) {
      lines.push(message.nextField());
    }
    lines.isString = true;
  } else {
    while (message.hasRemaining()) {
      lines.push(message.nextRecord());
    }
    lines.isRecord = true;
  }
  return lines;
};
/**
 * @author Push Technology Ltd
 * @class PingMessage
 *
 * This is the response from a DiffusionClient.ping request.  This message contains the latency of the round trip as well as the client queue size on the server
 */
var PingMessage = function (response) {
  var data = response.split('\u0002');
  this.timestamp = data[0].substr(1, data[0].length);
  this.queueSize = data[1].substr(0, data[1].length - 1);
};
/**
 * Get the timestamp sent from the server
 * @returns {String} the timestamp sent from the server. String representation of number of millis since epoch
 */
PingMessage.prototype.getTimestamp = function () {
  return this.timestamp;
};
/**
 * Get the Diffusion Server current queue size
 * @returns {String} the current size of the client queue on the server
 */
PingMessage.prototype.getQueueSize = function () {
  return this.queueSize;
};
/**
 * Get the number of milliseconds that this message has existed for
 * @returns {Number} current time minus the time from the server
 */
PingMessage.prototype.getTimeSinceCreation = function () {
  return new Date().getTime() - new Number(this.timestamp);
};
DiffusionClient.getStackTrace = (function () {
  var mode;
  try {
    0();
  } catch (e) {
    mode = e.stack ? 'Firefox' : window.opera ? 'Opera' : 'Other';
  }

  switch (mode) {
    case 'Firefox':
      return function () {
        try {
          0();
        } catch (e) {
          return e.stack
            .replace(/^.*?\n/, '')
            .replace(/(?:\n@:0)?\s+$/m, '')
            .replace(/^\(/gm, '{anonymous}(')
            .split('\n');
        }
      };

    case 'Opera':
      return function () {
        try {
          0();
        } catch (e) {
          var lines = e.message.split('\n'),
            ANON = '{anonymous}',
            lineRE =
              /Line\s+(\d+).*?in\s+(http\S+)(?:.*?in\s+function\s+(\S+))?/i,
            i,
            j,
            len;

          for (i = 4, j = 0, len = lines.length; i < len; i += 2) {
            if (lineRE.test(lines[i])) {
              lines[j++] =
                (RegExp.$3
                  ? RegExp.$3 + '()@' + RegExp.$2 + RegExp.$1
                  : ANON + RegExp.$2 + ':' + RegExp.$1) +
                ' -- ' +
                lines[i + 1].replace(/^\s+/, '');
            }
          }

          lines.splice(j, lines.length - j);
          return lines;
        }
      };

    default:
      return function () {
        var curr = arguments.callee.caller,
          FUNC = 'function',
          ANON = '{anonymous}',
          fnRE = /function\s*([\w\-$]+)?\s*\(/i,
          stack = [],
          j = 0,
          fn,
          args,
          i;

        while (curr) {
          fn = fnRE.test(curr.toString()) ? RegExp.$1 || ANON : ANON;
          args = stack.slice.call(curr.arguments);
          i = args.length;

          while (i--) {
            switch (typeof args[i]) {
              case 'string':
                args[i] = '"' + args[i].replace(/"/g, '\\"') + '"';
                break;
              case 'function':
                args[i] = FUNC;
                break;
            }
          }

          stack[j++] = fn + '(' + args.join() + ')';
          curr = curr.caller;
        }

        return stack;
      };
  }
})();
/**
 * @author Push Technology Ltd
 * @class TimedTopicListener
 */

var TimedTopicListener = function (
  regex,
  functionPointer,
  handle,
  time,
  force,
  context
) {
  this.regex = new RegExp(regex);
  this.fp = functionPointer;
  this.handle = handle;
  this.time = time;
  this.force = force;
  this.context = context;
  this.time = time;
  this.messagesList = new Array();

  //Start Timer
  this.timer = setInterval(
    (function (self) {
      return function () {
        self.onTimerEvent();
      };
    })(this),
    time
  );
};

TimedTopicListener.prototype.getRegex = function () {
  return this.regex;
};

TimedTopicListener.prototype.getHandle = function () {
  return this.handle;
};

TimedTopicListener.prototype.stop = function () {
  clearInterval(this.timer);
};

TimedTopicListener.prototype.callFunction = function (message) {
  this.messagesList.push(message);
  //It isn't possible to return the "consumed" argument as TopicListener does, because this TimedTopicListener is Asynchronous.
  return false;
};

TimedTopicListener.prototype.onTimerEvent = function () {
  if (this.force || this.messagesList.length > 0) {
    try {
      //Make a copy of the messages
      var messageListCopy = this.messagesList.concat();
      //Clear the messageList
      this.messagesList.length = 0;

      //Call the Function
      this.fp.apply(this.context, [messageListCopy]);
    } catch (e) {
      DiffusionClient.trace(
        'Problem with TimedTopicListener:onTimerEvent: ' + e
      );
    }
  }
};

/**
 * @author Push Technology Ltd
 * @class TimedTopicListener
 */
var TopicListener = function (regex, functionPointer, handle, context) {
  this.regex = new RegExp(regex);
  this.fp = functionPointer;
  this.context = context;
  this.handle = handle;
};

TopicListener.prototype.getHandle = function () {
  return this.handle;
};

TopicListener.prototype.getRegex = function () {
  return this.regex;
};

TopicListener.prototype.callFunction = function (message) {
  try {
    message.rewind();
    if (this.fp.apply(this.context, [message]) == true) {
      return true;
    }
  } catch (e) {
    DiffusionClient.trace(
      'Problem with TopicListener ' + this.handle + ' : ' + e
    );
  }
  return false;
};
/**
 * @author Push Technology Ltd
 * @class TopicMessage
 *
 * This allows the instantiation of full messages on the client, to be populated via the exposed API and then sent to the server.
 */
var TopicMessage = function (topic, message) {
  this.topic = topic;
  this.isCrypted = false;
  this.userHeaders = null;
  this.isAckRequested = false;
  this.ackTimeout = 0;

  this.records = [];

  this.parseMessage(message);
  this.message = message;
};

/**
 * Return the string representation of the message body
 * Alias for asString method
 *
 * @deprecated Use TopicMessage.asString instead
 * @returns {String} the message
 */
TopicMessage.prototype.getMessage = function () {
  return this.asString();
};

/**
 * Set message value from string
 * Alias for put method
 * @deprecated Use TopicMessage.put instead
 * @param {String} message
 */
TopicMessage.prototype.setMessage = function (message) {
  this.put(message);
};

/**
 * Set message value from string
 * @param {String} message
 */
TopicMessage.prototype.put = function (message) {
  this.parseMessage(message);
};

/**
 * Insert one or more fields into the current record.
 * @param {String[]|String...} fields Either an array, or multiple strings
 */
TopicMessage.prototype.putFields = function (field) {
  if (field) {
    if (!this.records.length) {
      this.records.push(new DiffusionRecord());
    }

    var fields = typeof field === 'string' ? arguments : field;
    this.records[this.records.length - 1].addFields(fields);
  }
};

/**
 * Insert one or more fields into a new record.
 *
 * @param {String[]|String...} fields Either an array, or multiple strings
 */
TopicMessage.prototype.putRecord = function (field) {
  if (field) {
    var fields = typeof field === 'string' ? arguments : field;
    this.records.push(new DiffusionRecord(fields));
  }
};

/**
 * Put one or more Records into the message
 * @param {DiffusionRecord[]|DiffusionRecord...} records Either an array, or multiple DiffusionRecords
 */
TopicMessage.prototype.putRecords = function (record) {
  if (record) {
    var records = record instanceof DiffusionRecord ? arguments : record;
    var i = 0,
      length = records.length;

    for (; i < length; ++i) {
      this.records.push(records[i]);
    }
  }
};

/**
 * Returns the records held in the message
 * @return {Array} records
 */
TopicMessage.prototype.asRecords = function () {
  return this.records;
};

/**
 * Returns an array of all fields contained within message
 * @return {Array} fields
 */
TopicMessage.prototype.asFields = function () {
  var fields = [],
    length = this.records.length,
    i = 0;
  for (; i < length; ++i) {
    fields.push.apply(fields, this.records[i].getFields());
  }
  return fields;
};

/**
 * Returns the message body with correct delimiters
 * @return {String} message body
 */
TopicMessage.prototype.asString = function () {
  return this.records.join(DiffusionRecord.prototype.recordDelimiter);
};

/**
 * Returns the message body, formatted to display tags instead of character entities as delimiters
 * @returns {String} the messages with <RD>'s and <FD>'s
 */
TopicMessage.prototype.displayFormat = function () {
  return this.asString()
    .replace(this.displayFormatRecordRegex, '<RD>')
    .replace(this.displayFormatFieldRegex, '<FD>');
};

/**
 * @ignore
 */
TopicMessage.prototype.displayFormatRecordRegex = new RegExp(
  DiffusionRecord.prototype.recordDelimiter,
  'g'
);

/**
 * @ignore
 */
TopicMessage.prototype.displayFormatFieldRegex = new RegExp(
  DiffusionRecord.prototype.fieldDelimiter,
  'g'
);

/**
 * Convert message string into records and fields
 * @param {String} message
 */
TopicMessage.prototype.parseMessage = function (message) {
  if (message) {
    var parts = message.split(DiffusionRecord.prototype.recordDelimiter),
      length = parts.length,
      i = 0;
    for (; i < length; ++i) {
      var fields = parts[i].split(DiffusionRecord.prototype.fieldDelimiter);
      this.records.push(new DiffusionRecord(fields));
    }
  }
};

/**
 * Returns the topic assigned to this TopicMessage
 * @returns {String} topic
 */
TopicMessage.prototype.getTopic = function () {
  return this.topic;
};

/**
 * Set headers by array
 * @param {String[]} headers
 */
TopicMessage.prototype.setUserHeaders = function (headers) {
  this.userHeaders = headers;
};

/**
 * Get the current user headers
 * @returns {Array} the current array of headers, or null if there are no headers
 */
TopicMessage.prototype.getUserHeaders = function () {
  return this.userHeaders;
};

/**
 * Add a user header to the current headers
 * @param {String} header
 */
TopicMessage.prototype.addUserHeader = function (header) {
  if (this.userHeaders == null) {
    this.userHeaders = [];
  }
  this.userHeaders.push(header);
};

/**
 * Set whether this message is sent as encrypted data
 * @param {Boolean} value
 * @deprecated Use setEncrypted()
 */
TopicMessage.prototype.setCrypted = function (value) {
  this.isCrypted = value;
};

/**
 * Set whether this message is sent as encrypted data
 * @param {Boolean} a boolean value indicates if the message should be encrypted before being sent
 */
TopicMessage.prototype.setEncrypted = function (encrypted) {
  this.isCrypted = encrypted;
};

/**
 * Check if this message is set to be encrypted
 * @returns {Boolean} has encrypted encoded been requested
 * @deprecated Use isEncrypted()
 */
TopicMessage.prototype.getCrytped = function () {
  return this.isCrypted;
};

/**
 * Check if this message is set to be encrypted
 * @returns {Boolean} whether this message is set as encrypted
 */
TopicMessage.prototype.isEncrypted = function () {
  return this.isCrypted;
};

/**
 * Check if this message requires an Ack
 * @returns {Boolean} true if an Acknowledgement is requested for this message
 */
TopicMessage.prototype.getAckRequired = function () {
  return this.isAckRequested;
};

/**
 * Set this message to require an Ack
 * @param {int} Set the timeout (ms) for the server to respond
 * @returns {String} the Ack ID
 */
TopicMessage.prototype.setAckRequired = function (timeout) {
  this.isAckRequested = true;
  this.ackTimeout = timeout;
  this.ackID = DiffusionClient.diffusionTransport.getNextAckID();
  return this.ackID;
};

/**
 * Get the ack ID set for this message
 * @returns {String} the Ack ID
 */
TopicMessage.prototype.getAckID = function () {
  return this.ackID;
};

/**
 * Get the ack timeout for this message
 * @returns {int} the ack timeout (ms)
 */
TopicMessage.prototype.getAckTimeout = function () {
  return this.ackTimeout;
};

/**
 * Set the timeout to be used if this message requires an Ack
 * @param {int} set the ack timeout (ms)
 */
TopicMessage.prototype.setAckTimeout = function (timeout) {
  this.ackTimeout = timeout;
};

/**
 * @ignore
 */
TopicMessage.prototype.toRecord = function () {
  var delim = '\u0003';
  var record =
    this.topic +
    delim +
    this.asString() +
    delim +
    this.isCrypted +
    delim +
    this.isAckRequested;

  if (this.isAckRequested) {
    record += delim + this.ackID;
  }

  if (this.userHeaders != null) {
    record += delim + this.userHeaders.join(delim);
  }

  return record;
};
/**
 * @author Push Technology Ltd
 * @class TopicStatusMessage
 *
 * Topic Status Messages are used to deliver notifications on changes to Topics
 */
var TopicStatusMessage = function (topic, alias, status) {
  this.topic = topic;
  this.alias = alias;
  this.status = status;
};
/**
 * Get the topic to which this message belongs.
 * @returns {String} the topic name
 */
TopicStatusMessage.prototype.getTopic = function () {
  return this.topic;
};

/**
 * Get the Topic Alias for this message's topic.
 * @returns {String} the topic alias
 */
TopicStatusMessage.prototype.getAlias = function () {
  return this.alias;
};

/**
 * Get the status of this message's topic.
 * @returns {String} the topic status
 */
TopicStatusMessage.prototype.getStatus = function () {
  return this.status;
};

export const DiffusionClientDefault = DiffusionClient;
