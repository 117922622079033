// @flow
import { values } from 'ramda';
import { type DiffusionData } from '../../types';

export const diffusionMessageTypes = {
  itl: 'itl',
  delta: 'delta',
  removal: 'removal',
};

type HandlersType<S> = {|
  itl?: (state: S, message: DiffusionData) => S,
  delta?: (state: S, message: DiffusionData) => S,
  removal?: (state: S, message: DiffusionData) => S,
|};

/**
 * This is a higher order reducer that returns a function
 * which is a reducer for diffusion messages. Higher order reducer
 * expectes named arguments topicType and handlers. topicType is used
 * to filter out messages from batched payload. handlers is an optional argument
 * and it may have itl, delta or removal keys and function as values that are responsible
 * for handling this particular message type.
 * */
export const reduceDiffusionMessagesCreator = <S: Object>({
  topicType,
  handlers,
}: {
  topicType: string,
  handlers?: HandlersType<S>,
}) => {
  return (state: S, { payload }: { payload: Array<DiffusionData> }): S => {
    return reduceDiffusionMessages({
      topicType,
      handlers,
      state,
      messages: payload,
    });
  };
};

const defaultHandlers = {
  [diffusionMessageTypes.itl]: <S: Object>(
    state: S,
    { id, data }: DiffusionData
  ) => {
    state[id] = data;
    return state;
  },
  [diffusionMessageTypes.delta]: <S: Object>(
    state: S,
    { id, data }: DiffusionData
  ) => {
    if (!state[id]) return state;
    state[id] = {
      ...state[id],
      ...data,
    };
    return state;
  },
  [diffusionMessageTypes.removal]: <S: Object>(
    state: S,
    { id }: DiffusionData
  ) => {
    delete state[id];
    return state;
  },
};

export const reduceDiffusionMessages = <S>({
  topicType,
  state,
  messages,
  handlers = {},
}: {
  topicType: string,
  handlers?: HandlersType<S>,
  state: S,
  messages: Array<DiffusionData>,
}): S => {
  const filteredMessages = messages.filter(e => e.topicType === topicType);
  if (filteredMessages.length === 0) return state;

  const nextState: S = { ...state };

  for (const message of filteredMessages) {
    const { type } = message;
    const handler = handlers[type] || defaultHandlers[type];
    handler(nextState, message);
  }

  return nextState;
};

/** Plz use it for development only * */
/* eslint-disable no-unused-vars */
const checkIntegrity = state => {
  let flag = true;

  const idKeysToStateKeys = {
    outcome: 'outcomes',
    event: 'events',
    market: 'markets',
    clock: 'clocks',
  };

  const checkKeys = item =>
    Object.keys(item)
      .filter(key => key.endsWith('Id'))
      .map(key => key.match(/(\w+)Id$/)[1])
      .filter(key =>
        Object.prototype.hasOwnProperty.call(idKeysToStateKeys, key)
      );

  values(idKeysToStateKeys).forEach(stateKey => {
    values(state[stateKey]).forEach(item => {
      checkKeys(item).forEach(key => {
        const test = state[idKeysToStateKeys[key]][item[`${key}Id`]];
        if (!test) {
          // eslint-disable-next-line no-console
          console.warn(`Could not find ${key} for ${stateKey}`, item);
          flag = false;
        }
      });
    });
  });

  if (flag) {
    // eslint-disable-next-line no-console
    console.info('Integrity check passed');
  } else {
    // eslint-disable-next-line no-console
    console.warn('Integrity check failed');
  }

  return flag;
};
