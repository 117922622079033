// @flow

export const SportsmenuActionTypes = {
  fetch: 'prematch:sportsmenu:fetch-data',
  error: 'prematch:sportsmenu:fetch-is-error',
  stopPolling: 'prematch:sportsmenu:stop:polling',
  startPolling: 'prematch:sportsmenu:start:polling',
};

export const PREMATCH_SPORTS_MENU_FETCH_DATA: 'prematch:sportsmenu:fetch-data' =
  'prematch:sportsmenu:fetch-data';

export const PREMATCH_SPORTS_MENU_FETCH_IS_ERROR: 'prematch:sportsmenu:fetch-is-error' =
  'prematch:sportsmenu:fetch-is-error';

export const PREMATCH_SPORTS_MENU_START_POLLING: 'prematch:sportsmenu:start:polling' =
  'prematch:sportsmenu:start:polling';

export const PREMATCH_SPORTS_MENU_STOP_POLLING: 'prematch:sportsmenu:stop:polling' =
  'prematch:sportsmenu:stop:polling';
