// @flow
import { type JSSThemeVariablesType, type $DeepShape } from './JSSThemeDefault';

const valueUpdated = {
  animation: 'blinker 0.75s linear 3',
  opacity: 0.7,
};

export const JSSThemeDefaultBalance = (themeVars: JSSThemeVariablesType) => {
  const {
    margins,
    fontSizes,
    borderRadius,
    colors: {
      colorCombinations: { whiteBg2 },
      colorTypes: { background2, neutral1, white, background1, primary },
    },
  } = themeVars;
  return {
    BalanceComponent: {
      iconWrapper: {
        fontSize: fontSizes.lg,
        marginRight: margins.md,
      },
      moneyWrapper: {},
      titleContent: {
        color: background2,
        fontSize: fontSizes.sm,
      },
      userMenuWrap: {
        width: 240,
        marginTop: margins.md,
        background: neutral1,
        padding: margins.md,
        borderRadius: borderRadius.md,
        position: 'relative',
        '&:before': {
          content: '""',
          position: 'absolute',
          width: 10,
          top: 1,
          right: 20,
          height: 10,
          transform: 'rotate(-45deg)',
          transformOrigin: 'top left',
          background: neutral1,
        },
      },
      balanceWrap: {
        margin: [margins.xs, 0],
        paddingBottom: margins.md,
      },
      merlinLinkWrapMobile: {
        fontSize: 'large',
        marginBottom: '20px',
      },
      merlinLinkMobile: {
        color: white,
        '&:hover': {
          color: background1,
        },
      },
      merlinLinkWrap: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      merlinLink: {
        color: white,
        textDecoration: 'underline',
        '&:hover': {
          color: background1,
        },
      },
      accountMenuWrap: {
        borderBottom: `1px solid ${background2}`,
        borderTop: `1px solid ${background2}`,
        padding: [margins.md, 0],
      },
      logoutWrap: {
        cursor: 'pointer',
        padding: [margins.md, 0, margins.xs],
        '&:hover': {
          opacity: 0.7,
        },
      },
      mobileUserMenuWrap: {},
      mobileMoneyWrap: {
        margin: [margins.xs, 0, margins.md],
      },
      mobileLogoutWrap: {
        fontSize: fontSizes.xl,
        opacity: 0.6,
        padding: [margins.xs, 0],
      },
      valueUpdated,
    },
    UsernameCompanent: {
      userName: {
        margin: [margins.xs, 0],
      },
      accountNumber: {
        margin: [margins.xs, 0],
      },
    },
    UsernameCompanentMobile: {
      userNameMobile: {
        margin: [margins.xs, 0],
        fontSize: fontSizes.xl,
      },
      accountNumberMobile: {
        margin: [margins.xs, 0],
        fontSize: fontSizes.lg,
        opacity: 0.5,
      },
    },
    Bonus: {
      bonusWrap: {
        display: 'flex',
        alignItems: 'center',
      },
      bonusTitle: {
        marginRight: margins.xs,
      },
      bonusValue: {
        fontWeight: 'bold',
        fontSize: fontSizes.md,
      },
      bonusWrapMobile: {
        display: 'flex',
        alignItems: 'center',
      },
      bonusTitleMobile: {
        fontWeight: 300,
      },
      bonusValueMobile: {
        fontSize: fontSizes.xxl,
        color: primary,
        fontWeight: 'bold',
        marginLeft: margins.md,
      },
      valueUpdated,
    },
    AccountPageMenu: {
      userMenu: {
        listStyle: 'none',
      },
      userMenuItem: {
        fontSize: fontSizes.md,
        display: 'flex',
        alignItems: 'center',
        padding: [margins.xs, 0],
        '&:hover': {
          color: background1,
        },
      },
    },
    AccountPageMenuDesktop: {
      userMenu: {
        listStyle: 'none',
      },
      userMenuItem: {
        fontSize: fontSizes.md,
        display: 'flex',
        alignItems: 'center',
        color: white,
        padding: [margins.xs, margins.lg],
        '&.active, &:hover': {
          ...whiteBg2,
        },
      },
    },
  };
};

export type JSSThemeDefaultBalanceType = JSSThemeVariablesType => $DeepShape<
  $Call<typeof JSSThemeDefaultBalance, JSSThemeVariablesType>
>;
