// @flow
import { type JSSThemeDefaultCouponsType } from '../JSSThemeDefaultCoupons';

export const JSSThemeCoupons60700: JSSThemeDefaultCouponsType = (themeVars) => {
  const {
    borderRadius,
    margins,
    colors: {
      colorTypes: {
        inform,
        white,
        primary,
        background2,
        black,
        accent,
        neutral3,
      },
    },
  } = themeVars;
  return {
    CouponHeaderComponent: {
      headerEventsCount: {
        background: `${inform} !important`,
      },
    },
    Market: {
      root: {
        borderRadius: '0px !important',
        background: `${white} !important`,
        borderTop: 'none !important',
      },
    },
    OutcomesCounter: {
      outcomeCount: {
        background: `${neutral3} !important`,
        color: `${black} !important`,
        height: 25,
        '&:hover': {
          background: `${primary} !important`,
          color: `${white} !important`,
        },
        '@media (pointer: coarse)': {
          height: '35px !important',
          lineHeight: 3.4,
        },
      },
    },
    CouponBreadcrumbsComponent: {
      root: {
        background: `transparent !important`,
        borderBottom: 'none !important',
        padding: [0, 0, 10, 1],
      },
    },
    PrematchEvent: {
      eventDescription: {
        paddingLeft: '0px !important',
      },
      marketRoot: {
        background: `${white} !important`,
        borderTop: 'none !important',
      },
      marketNameHeader: {
        background: `${white} !important`,
      },
    },
    PrematchFiltersSwitchComponent: {
      listItem: {
        background: neutral3,
        color: black,
        borderTop: `2px solid #e2e2e2 !important`,
        borderRight: `2px solid #e2e2e2 !important`,
        borderRadius: '3px !important',
        '&.active, &:hover': {
          background: `${primary} !important`,
          color: `${white} !important`,
        },
      },
      listItemFake: {
        visibility: 'hidden',
      },
    },
    MobileWidgetCouponView: {
      sportHeader: {
        '&:first-child': {
          marginTop: 0,
        },
      },
      sportsContainer: {
        background: 'white',
        borderRadius: [borderRadius.xl, borderRadius.xl, 0, 0],
        overflow: 'hidden',
      },
      sportMobileWrap: {
        background: white,
        borderRadius: [borderRadius.xl, borderRadius.xl, 0, 0],
        color: black,
      },
    },
    SportTabs: {
      tabs: {
        background: background2,
        padding: 0,
      },
      tab: {
        background: background2,
        borderRadius: 0,
        color: black,
        marginRight: 0,
        padding: margins.md,
        '&:hover': {
          color: accent,
        },
      },
      activeTab: {
        background: white,
        color: black,
        borderRadius: [borderRadius.xl, borderRadius.xl, 0, 0],
        '&:focus': {
          color: primary,
        },
        '&:focus, &:hover': {
          color: primary,
        },
      },
    },
    AllLeagues: {
      root: {
        background: white,
      },
      searchInput: {
        border: `1px solid ${black}`,
      },
      filterIcon: {
        fill: black,
      },
    },
    WidgetSport: {
      sport: {
        padding: 0,
      },
      event: {
        borderRadius: borderRadius.md,
        overflow: 'hidden',
      },
    },
    WidgetCoupon: {
      title: {
        background: `transparent !important`,
        color: `${black} !important`,
      },
    },
    Betbuilder: {
      toggler: {
        '&:hover': {
          background: primary,
        },
      },
      exampleContainer: {
        background: white,
        color: '#000',
      },
    },
  };
};
