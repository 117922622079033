// @flow
import type { ReduxAction, MerlinUserDataType } from '../../types';
import { MERLIN_UPDATE } from '../actions/action-types';

// Didnt receive from voxbet possible values of each field, so making this default state almost at random
const defaultState = {
  TF: '',
  VBISID: '',
  allowed: '1',
  bonoE: 0,
  bonoL: '0',
  bonoLcasino: 0,
  bonoR: '0',
  moa: '0',
  msg: '0',
  origin: '',
  parent: '',
  promo: '0',
  quest: '0',
  reload: '0',
  rollover: '0',
  rolloveramount: '0',
  rr_amount: '0.00',
  template: 'ES',
  vrf: '0',
  vrf_mobile: '0',
};

const reducer = (
  state: MerlinUserDataType = defaultState,
  action: ReduxAction
): MerlinUserDataType => {
  switch (action.type) {
    case MERLIN_UPDATE: {
      return action.payload
        ? {
            ...state,
            ...action.payload,
          }
        : state;
    }
    default:
      return state;
  }
};

export default reducer;
