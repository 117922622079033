// @flow
import * as React from 'react';
import type { Location } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

class ScrollToTopOnPathChangeComponent extends React.Component<{
  location: Location,
}> {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return null;
  }
}

export const ScrollToTopOnPathChange = withRouter(
  ScrollToTopOnPathChangeComponent
);
