// @flow
import { reduceDiffusionMessages } from '../helpers';
import type { ReduxAction, LiveEventType } from '../../../types';
import { LIVE_DIFFUSION } from '../../actions/live/actions';
import {
  LIVE_BETGENIUS_SET_IS_IFRAME,
  LIVE_BETGENIUS_SET_IS_IFRAME_TENNIS_ABP,
  LIVE_BETGENIUS_SET_IS_IFRAME_TENNIS_PHOENIX,
} from '../../actions/live/event-actions';

type LiveEventState = { [id: string]: LiveEventType };

const events = (
  state: LiveEventState = {},
  action: ReduxAction
): LiveEventState => {
  switch (action.type) {
    case LIVE_DIFFUSION: {
      const nextState = reduceDiffusionMessages({
        state,
        messages: action.payload,
        topicType: 'event',
      });
      return nextState;
    }
    case LIVE_BETGENIUS_SET_IS_IFRAME: {
      /**
       * We use vendorId in this check to compare our events to the list of provider ones
       * since provider in their API uses either their ids or our ABP ids.
       * On Phoenix we dont know ABP event ids so we cant use them unified for our both backends.
       * That's why was decided to use vendorId (aka betgeniusEventId)
       *
       * Tennis on the other hand works only with ABP ids. Betgenius returns us
       * a list of ABP ids. So for tennis checking works by another algorithm
       */
      if (action.payload.length === 0) return state;
      const nextState: LiveEventState = { ...state };
      const mappedIdsList = Object.keys(nextState).map((eventId) => ({
        eventId: eventId,
        vendorId: nextState[eventId].vendorId,
      }));
      for (const eventIdPayload of action.payload) {
        const mappedIds = mappedIdsList.find(
          (ids) => ids.vendorId === eventIdPayload.toString()
        );
        if (mappedIds && mappedIds.eventId) {
          nextState[mappedIds.eventId] = {
            ...nextState[mappedIds.eventId],
            isBetgeniusIframe: true,
          };
        }
      }
      return nextState;
    }
    case LIVE_BETGENIUS_SET_IS_IFRAME_TENNIS_ABP: {
      /**
       * This algorithm works only for tennis for ABP origins
       * We get a list of ABP ids from betgenius and compare it with a list of
       * our current live events
       */
      if (action.payload.length === 0) return state;
      const nextState: LiveEventState = { ...state };
      for (const eventId of action.payload) {
        if (nextState[eventId]) {
          nextState[eventId] = {
            ...nextState[eventId],
            isBetgeniusIframe: true,
          };
        }
      }
      return nextState;
    }
    case LIVE_BETGENIUS_SET_IS_IFRAME_TENNIS_PHOENIX: {
      /**
       * This algorithm works only for tennis for Phoenix origins
       * We get a list of ABP ids from betgenius and compare it with a list of
       * our current live events filtered by vendoeId field, which contains ABP event id
       */
      if (action.payload.length === 0) return state;
      const nextState: LiveEventState = { ...state };

      const mapedIds: { string: string } = Object.keys(nextState).reduce(
        (acc, phoenixEventId) => {
          const abpEventId = nextState[phoenixEventId]?.vendorId;
          if (abpEventId) {
            acc[abpEventId] = phoenixEventId;
          }
          return acc;
        },
        {}
      );

      for (const abpEventId of action.payload) {
        const phoenixEventId = mapedIds[abpEventId];
        if (phoenixEventId) {
          nextState[phoenixEventId] = {
            ...nextState[phoenixEventId],
            isBetgeniusIframe: true,
          };
        }
      }
      return nextState;
    }
    default:
      return state;
  }
};

export default events;
