// @flow
import { reduceDiffusionMessages, diffusionMessageTypes } from '../helpers';
import { LIVE_DIFFUSION } from '../../actions/live/actions';
import { UPDATE_TIMER } from '../../actions/tick-timer-actions';
import type { ReduxAction, LiveOutcomeType } from '../../../types';

const getPrice = (priceValues, priceLineIds, priceLineId) => {
  if (!priceValues || !priceLineIds) return false;
  const priceIndex = priceLineIds.includes(priceLineId)
    ? priceLineIds.indexOf(priceLineId)
    : priceLineIds.indexOf(2); // priceLineId 2 by default
  return priceValues[priceIndex];
};

const handlers = {
  [diffusionMessageTypes.itl]: (state, { id, data }) => {
    const { priceValues, priceLineId, priceLineIds } = data;
    const nextOutcome = { ...data };
    nextOutcome.price = getPrice(priceValues, priceLineIds, priceLineId);
    state[id] = nextOutcome;
    return state;
  },
  [diffusionMessageTypes.delta]: (state, { id, data }) => {
    const item = state[id];
    if (!item) return state;

    const nextOutcome = {
      ...state[id],
      ...data,
    };

    const { priceValues } = data;
    const priceLineIds = data.priceLineIds || item.priceLineIds;
    const nextPrice =
      getPrice(priceValues, priceLineIds, item.priceLineId) || state[id].price;
    const delta = nextPrice - state[id].price;

    if (delta) {
      nextOutcome.delta = delta;
      nextOutcome.lastDeltaUpdate = Date.now();
    }

    nextOutcome.price = nextPrice;

    state[id] = nextOutcome;

    return state;
  },
};

const outcomes = (
  state: { [id: string]: LiveOutcomeType } = {},
  action: ReduxAction
): { +[id: string]: LiveOutcomeType } => {
  switch (action.type) {
    case LIVE_DIFFUSION: {
      return reduceDiffusionMessages({
        state,
        handlers,
        topicType: 'outcome',
        messages: action.payload,
      });
    }
    case UPDATE_TIMER: {
      const time = action.payload * 1000;
      let changed = false;

      const stateToMutate: { [id: string]: LiveOutcomeType } = { ...state };

      const nextState = Object.keys(state).reduce((acc, key) => {
        const outcome = acc[key];

        if (outcome.lastDeltaUpdate && time - outcome.lastDeltaUpdate > 10000) {
          changed = true;
          acc[key] = {
            ...outcome,
            delta: 0,
            lastDeltaUpdate: null,
          };
        }

        return acc;
      }, stateToMutate);

      return changed ? nextState : state;
    }
    default:
      return state;
  }
};

export default outcomes;
