// @flow

import { JSSThemeVariables } from './JSSThemeDefault';

export const JSSThemeDefaultOryx = (themeVars: typeof JSSThemeVariables) => {
  const {
    colors: {
      colorTypes: { inform, background2 },
    },
  } = themeVars;

  return {
    OryxDesktop: {
      root: {
        width: '35%',
        marginTop: 10,
        position: 'sticky',
        top: '51px',
        height: '100%',
        zIndex: '3',
      },
      iframe: {
        width: '100%',
      },
    },
    OryxMobile: {
      root: {
        margin: 10,
        marginBottom: 10,
        backgroundColor: background2,
        borderRadius: 5,
      },
      rootIsOpen: {
        marginBottom: 5,
      },
      iframe: {
        width: '100%',
        display: 'block',

        padding: '8px 10px',
      },
      iframeIsOpen: {
        display: 'none',
      },
      toggle: {
        display: 'flex',
        flexDirection: 'rox',
        justifyContent: 'space-between',
        alignItems: 'center',

        backgroundColor: inform,
        borderRadius: 5,
        fontSize: '1rem',
        padding: 10,
      },
    },
  };
};
