// @flow
import * as React from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faUser,
  faTimes,
  faSearch,
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faStar,
  faCalendarAlt,
  faEyeSlash,
  faEye,
  faCreditCard,
  faEnvelope,
  faStopwatch,
  faDice,
  faTrophy,
  faAward,
  faClock,
  faPlay,
  faInfoCircle,
  faGamepad,
  faBadgeDollar,
  faFileAlt,
  faCheck,
  faPlus,
  faCaretUp,
  faCaretDown,
  faEllipsisH,
  faExternalLink,
  faScrewdriverWrench,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faInstagram,
  faTiktok,
  faTelegramPlane,
  faFacebookF,
  faTwitter,
  faXTwitter,
  faYoutube,
  faDiscord,
  faBlogger,
  faWhatsapp,
  faGooglePlusG,
} from '@fortawesome/free-brands-svg-icons';
import { useJSSStyles } from '../jss-hooks';

type Props = {|
  pull?: string,
  border?: boolean,
  spin?: boolean,
  pulse?: boolean,
  flip?: string,
  rotation?: number,
  inverse?: boolean,
  fixedWidth?: boolean,
  size?: string,
  className?: string,
|};

const path = (theme) => theme.components.CustomIcon;

const CustomIcon = ({
  icon,
  withShadow,
  ...props
}: {
  icon: Object,
  withShadow?: boolean,
}) => {
  const classes = useJSSStyles(path);
  return (
    <FontAwesomeIcon
      icon={icon}
      className={cx(withShadow && classes.shadow)}
      {...props}
    />
  );
};

export const IconFile = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faFileAlt} />
);

export const IconUser = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faUser} />
);

export const IconBars = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faBars} />
);

export const IconClose = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faTimes} />
);

export const IconSearch = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faSearch} />
);

export const IconChevronDown = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faChevronDown} />
);

export const IconCaretUp = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faCaretUp} />
);

export const IconCaretDown = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faCaretDown} />
);

export const IconEllipses = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faEllipsisH} />
);

export const IconExternalLink = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faExternalLink} />
);

export const IconChevronRight = ({
  withShadow,
  ...props
}: {
  withShadow?: boolean,
}) => {
  return (
    <CustomIcon withShadow={withShadow} {...props} icon={faChevronRight} />
  );
};

export const IconChevronLeft = ({
  withShadow,
  ...props
}: {
  withShadow?: boolean,
}) => {
  return <CustomIcon withShadow={withShadow} {...props} icon={faChevronLeft} />;
};

export const IconStar = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faStar} />
);
export const IconCalendar = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faCalendarAlt} />
);
export const IconEyeSlash = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faEyeSlash} />
);
export const IconEye = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faEye} />
);
export const IconCreditCard = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faCreditCard} />
);
export const IconEnvelope = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faEnvelope} />
);
export const IconStopWatch = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faStopwatch} />
);
export const IconDice = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faDice} />
);
export const IconTrophy = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faTrophy} />
);
export const IconAward = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faAward} />
);
export const IconClock = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faClock} />
);
export const IconPlay = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faPlay} />
);
export const IconInfoCircle = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faInfoCircle} />
);
export const IconGamepad = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faGamepad} />
);
export const IconВadgeDollar = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faBadgeDollar} />
);
export const IconInstagram = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faInstagram} />
);
export const IconTiktok = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faTiktok} />
);

export const IconTelegramPlane = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faTelegramPlane} />
);
export const IconFacebook = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faFacebookF} />
);
export const IconBlogger = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faBlogger} />
);

export const IconWhatsapp = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faWhatsapp} />
);
export const IconYoutube = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faYoutube} />
);
export const IconDiscord = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faDiscord} />
);
export const IconTwitter = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faTwitter} />
);
export const IconTwitterX = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faXTwitter} />
);
export const IconGooglePlus = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faGooglePlusG} />
);

export const IconCheck = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faCheck} />
);
export const IconPlus = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faPlus} />
);

export const IconScrewdriver = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faScrewdriverWrench} />
);

const iconCirclePath = (theme) => theme.components.IconCircle;

export const IconCircle = ({ inline }: { inline?: boolean }) => {
  const classes = useJSSStyles(iconCirclePath);
  return <div className={classes.root} />;
};
