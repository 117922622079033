// @flow
import * as React from 'react';

export const useBodyLockScroll = (lock: boolean) => {
  React.useLayoutEffect(() => {
    if (document.body && lock) {
      document.body.style.overflowY = 'hidden';
      document.body.style.height = '100vh'; //fix no-scrol for safari
      document.body.style.width = '100vw'; //fix no-scrol for safari
      document.body.style.position = 'fixed'; //fix no-scrol for safari
      return () => {
        if (document.body) {
          document.body.style.overflowY = 'initial';
          document.body.style.height = 'initial';
          document.body.style.position = 'initial';
        }
      };
    }
  }, [lock]);
};

export const useInputAutofill = (ref: { current: HTMLInputElement | null }) => {
  const { value, setFalse, setTrue } = useBoolean(false);

  React.useEffect(() => {
    // onChange is not triggered in Chrome with autofill
    // to fix this we use animation on -webkit-autofill and listen to this animation here
    const input = ref.current;

    const disableAutofillOnChange = () => {
      if (input) {
        input.removeEventListener('transitionend', setTrue);
      }
      setFalse();
    };
    if (input) {
      input.addEventListener('transitionend', setTrue); // listen to animation on autofilled input
      input.addEventListener('change', disableAutofillOnChange); // disable detetecting autofill on input change
      input.addEventListener('focus', disableAutofillOnChange); // disable detetecting autofill on focus
    }

    return () => {
      if (input) {
        input.removeEventListener('transitionend', setTrue);
        input.removeEventListener('change', disableAutofillOnChange);
        input.removeEventListener('focus', disableAutofillOnChange);
      }
    };
  }, [ref, setFalse, setTrue]);

  return value;
};

export const useOnClickOutside = (
  ref: { current: ?HTMLElement },
  handler: Function
) => {
  React.useEffect(() => {
    const eventListener = (event: MouseEvent | TouchEvent) => {
      if (
        ref.current &&
        event.target instanceof Node &&
        !ref.current.contains(event.target)
      ) {
        handler(event);
      }
    };
    document.addEventListener('mouseup', eventListener);
    document.addEventListener('touchstart', eventListener);

    return () => {
      document.removeEventListener('mouseup', eventListener);
      document.removeEventListener('touchstart', eventListener);
    };
  }, [ref, handler]);
};

export const useBoolean = (defaultValue: boolean | (() => boolean)) => {
  const [value, setValue] = React.useState<boolean>(defaultValue);
  return {
    value,
    setValue,
    setTrue: React.useCallback(() => setValue(true), []),
    setToggle: React.useCallback(() => setValue((v) => !v), []),
    setFalse: React.useCallback(() => setValue(false), []),
  };
};

export const useBoundingClientRect = () => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [rect, setRect] = React.useState({});
  React.useLayoutEffect(() => {
    if (ref.current) {
      setRect(ref.current.getBoundingClientRect());
    }
  }, []);

  return { ref, rect };
};

export const useIsPhoenix = () => {
  return __OSG_CONFIG__.isPhoenixOrigin;
};
