// @flow
import * as React from 'react';

export const Loading = ({ customClass }: { customClass: string }) => (
  <div className={customClass}>
    <div className="osg-loading">Loading...</div>
  </div>
);

Loading.defaultProps = {
  customClass: '',
};
export class DebouncedLoading extends React.PureComponent<
  { delay: number },
  { pastDelay: boolean }
> {
  static defaultProps = {
    delay: 200,
  };

  timer: TimeoutID;

  state = {
    pastDelay: false,
  };

  componentDidMount() {
    this.timer = setTimeout(() => {
      this.setState({
        pastDelay: true,
      });
    }, this.props.delay);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    return this.state.pastDelay && <Loading {...this.props} />;
  }
}
