// @flow
import { type $DeepShape, type JSSThemeVariablesType } from './JSSThemeDefault';
import { jssHelpers, applyOnlyDesktop } from './jss-helpers';

export const JSSThemeDefaultAuthForm = (themeVars: JSSThemeVariablesType) => {
  const {
    margins,
    fontSizes,
    borderRadius,
    colors: {
      colorTypes: { white, background5, error },
    },
  } = themeVars;

  return {
    MobileAuthForm: {
      wrapper: {
        padding: [margins.xm],
        fontSize: fontSizes.md,
      },
      iconBack: {
        fontSize: fontSizes.lg,
        marginRight: margins.md,
        ...jssHelpers.fa,
        ...jssHelpers.faIconChevronLeft,
      },
      iconMailWrap: {
        fontSize: 50,
        textAlign: 'center',
        opacity: 0.4,
        padding: margins.md,
      },
      iconMail: {
        fontSize: fontSizes.lg,
        marginRight: margins.xs,
        ...jssHelpers.fa,
        ...jssHelpers.faIconMail,
      },
      passwordResetSuccess: {
        fontSize: fontSizes.lg,
      },
      link: {
        display: 'inline-block',
        fontSize: fontSizes.lg,
        padding: [margins.xm, 0],
        ...applyOnlyDesktop({
          cursor: 'pointer',
        }),
      },
      label: {
        display: 'inline-block',
        fontSize: fontSizes.lg,
        padding: [margins.xm, 0],
      },
      item: {
        marginBottom: margins.xm,
      },
      errors: {
        color: error,
        padding: [margins.xs, 0],
      },
    },
    LoginPage: {
      heading: {
        fontSize: fontSizes.xxl,
        padding: [margins.md, 0],
      },
      wrapper: {
        margin: [margins.xl, 'auto'],
        width: 350,
      },
      wrapperMobile: {
        padding: margins.xm,
      },
    },
    DepositButton: {
      link: {
        display: 'flex',
        alignItems: 'center',
        color: white,
        '&:hover': {
          color: white,
          opacity: 0.7,
        },
      },
      iconWrap: {
        fontSize: fontSizes.lg,
        marginRight: margins.md,
      },
    },
    AuthFormDesktop: {
      depositWrap: {
        fontSize: fontSizes.sm,
        padding: [margins.md, 0],
        marginRight: margins.lg,
      },
    },
    LoginFormDesktop: {
      inputWrapper: {
        margin: [margins.md, 0],
      },
      rootWrapper: {
        position: 'relative',
      },
      loginButton: {
        marginRight: margins.sm,
      },
      iconWrap: {
        marginRight: margins.md,
      },
      forgotText: {
        textAlign: 'center',
        cursor: 'pointer',
        marginBottom: margins.md,
      },
      error: {
        color: error,
      },
      popUpWrapper: {
        position: 'absolute',
        right: 0,
        background: background5,
        padding: margins.md,
        marginTop: margins.md,
        transition: 'opacity 0.3s',
        opacity: 0,
        width: 250,
        borderRadius: borderRadius.md,
        boxShadow: '0 50px 200px rgba(0, 0, 0, 0.8)',
        '&:before': {
          content: '""',
          position: 'absolute',
          width: 10,
          top: 1,
          right: 20,
          height: 10,
          transform: 'rotate(-45deg)',
          transformOrigin: 'top left',
          background: background5,
        },
        '&.opened': {
          opacity: 1,
          transition: 'opacity 0.3s',
          zIndex: 999,
        },
      },
    },

    PasswordResetWithEmail: {
      resetPassword: {
        display: 'flex',
        fontSize: fontSizes.md,
        fontWeight: 'bold',
        marginBottom: margins.md,
      },
      closeIconWrap: {
        display: 'inline-block',
        cursor: 'pointer',
      },
      backIconWrap: {
        marginRight: margins.xs,
        cursor: 'pointer',
      },
      resetPasswordDescription: {
        marginBottom: margins.md,
      },
      inputWrap: {
        marginBottom: margins.md,
      },
      contactUsWrap: {
        marginTop: margins.xm,
        marginBottom: margins.xs,
        textAlign: 'center',
        fontSize: fontSizes.sm,
        opacity: 0.7,
        '&:hover': {
          opacity: 1,
        },
      },
      contactUsLink: {},
      error: {
        color: error,
        margin: [margins.md, 0],
        textAlign: 'center',
      },
      passwordResetSuccess: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: fontSizes.sm,
        justifyContent: 'center',
      },
      iconMailWrap: {
        textAlign: 'center',
        fontSize: fontSizes.xxxl,
      },
      passwordResetDebounce: {
        color: white,
        fontSize: fontSizes.md,
        paddingTop: '10px',
        '@media (pointer: coarse)': {
          fontSize: fontSizes.lg,
        },
      },
    },
  };
};

export type JSSThemeDefaultAuthFormType = (JSSThemeVariablesType) => $DeepShape<
  $Call<typeof JSSThemeDefaultAuthForm, JSSThemeVariablesType>
>;
