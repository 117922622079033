// @flow
import { isEmpty } from 'ramda';
import { diffusionMessageTypes } from '../message-descriptor';
import type { Message, Config } from '../types';
import { TEST_LOCALE } from '../../constants';

const shouldHandle = (key, val) => key && val && !isEmpty(val);

const makeGetItlDeltaData = ({
  keys,
  handlers,
  childrenHandlers,
  config,
}: {
  keys: {
    [number]: string,
  },
  handlers: {
    [string]: (string, ?Config) => *,
  },
  childrenHandlers: {
    [string]: (string, Array<string>) => *,
  },
  config: Config,
}) => valueList =>
  valueList.reduce((obj, val, index) => {
    const key = keys[index];

    if (!shouldHandle(key, val)) return obj;

    let data = { [key]: val };

    const handler = handlers && handlers[key];
    if (handler) {
      data[key] = handler(val, config);
    }

    const childrenHandler = childrenHandlers && childrenHandlers[key];
    if (childrenHandler) {
      data = { ...childrenHandler(data[key], valueList) };
    }

    Object.assign(obj, data);
    return obj;
  }, {});

export const handleMessages = (
  config: Config,
  message: Message,
  settings: {
    getKey: Message => string,
    getItlData: (Message, ?Config) => *,
    keys: Object,
    handlers: {
      [string]: (string, ?Config) => *,
    },
    childrenHandlers: {
      [string]: (string, Array<string>) => *,
    },
  }
) => {
  if (!settings) return message;

  const { fields } = message;
  const { getKey, getItlData } = settings;
  const getItlDeltaData = makeGetItlDeltaData({ ...settings, config });

  const actionPayload: {
    id: string,
    type: string,
    topicType: string,
    data?: {
      [key: string]: string,
    },
  } = {
    id: getKey(message),
    type: message.type,
    topicType: message.topic.type,
  };

  switch (message.type) {
    case diffusionMessageTypes.initial: {
      const itlData = getItlData && getItlData(message, config);
      actionPayload.data = {
        ...getItlDeltaData(fields),
        ...itlData,
      };
      return actionPayload;
    }
    case diffusionMessageTypes.delta:
      actionPayload.data = {
        ...getItlDeltaData(fields),
      };
      return actionPayload;
    case diffusionMessageTypes.removal:
      return actionPayload;
    default:
      return message;
  }
};

export const getSportId = (message: Message) => {
  return message.topic.parts[2];
};

export const getEventId = (message: Message) => {
  return message.topic.parts[3];
};

export const getMarketId = (message: Message) => {
  return message.topic.parts[5];
};

export const getOutcomeId = (message: Message) => {
  const marketId = getMarketId(message);
  const parts = `${message.topic.parts[7]}-${message.topic.parts[8]}`;
  return `${marketId}-${parts}`;
};

export const getMessageId = (message: Message) => {
  return `${getEventId(message)}-${message.topic.parts[6]}`;
};

export const parseJSONSafely = (
  json: string,
  { locale }: { locale: string }
) => {
  const loc = locale === TEST_LOCALE ? 'en_GB' : locale.replace(/-/, '_');
  try {
    const jsonParse = JSON.parse(json);
    return jsonParse[loc] || jsonParse;
  } catch (e) {
    return json;
  }
};

export const getSafetyEventField = (
  field: string | Object,
  propertyName: string
): string => {
  if (typeof field === 'string') {
    return field;
  }
  // eslint-disable-next-line no-console
  console.error(`Event field type error: ${propertyName} is not a string`);

  if (typeof field === 'object') {
    const engFieldTranslation = field['en_GB'];
    if (typeof engFieldTranslation === 'string') {
      return engFieldTranslation;
    }
  }
  return '-';
};
