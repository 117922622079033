// @flow
import { type JSSThemeDefaultAffiliatesType } from '../JSSThemeDefaultAffiliates';

export const JSSThemeAffiliates60700: JSSThemeDefaultAffiliatesType = themeVars => {
  const {
    margins,
    colors: {
      colorTypes: { text3, text4, neutral3, accent },
    },
  } = themeVars;

  return {
    text: {
      margin: margins.md,
    },
    affiliatesHeading: {
      background: text3,
      margin: '0 auto',
      padding: '100px 20px 50px',
    },
    affiliatesHome: {
      topBanner: {
        background: text3,
      },
      affiliateRoot: {
        background: text3,
      },
      productsRoot: {
        background: text3,
      },
      productsHeading: {
        fontWeight: '800 !important',
      },
      topBannerButton: {
        '&:hover': {
          color: '#fff',
        },
      },
      productsButton: {
        '&:hover': {
          color: '#fff',
        },
      },
      topBannerRoot: {
        background: text3,
      },
    },
    affiliatesProducts: {
      productsButton: {
        '&:hover': {
          color: '#fff',
        },
      },
    },
    affiliatesContacts: {
      contactsMail: {
        '&:hover': {
          color: neutral3,
        },
      },
    },
    affiliateHeader: {
      headerRoot: {
        background: text3,
      },
      burgerOverlay: {
        background: text3,
      },
      topMenuItem: {
        color: '#fff !important',
        '&:hover': {
          color: `${neutral3} !important`,
        },
      },
      topMenuItemActive: {
        color: `${neutral3} !important`,
      },
      loginBarRoot: {
        background: text4,
        '@media (pointer: coarse)': {
          background: text3,
        },
      },
      SignupRoot: {
        color: '#fff',
        '&:hover': {
          color: '#fff',
          opacity: '.6',
        },
      },
      reasonsIcon: {
        fill: accent,
      },
    },
    affiliatesFooter: {
      productsTerms: {
        color: '#fff',
        '&:hover': {
          color: neutral3,
        },
      },
      footer: {
        background: text3,
        opacity: 1,
      },
      footerRoot: {
        background: text3,
      },
    },
    affiliatesSignup: {
      signupRoot: {
        '& h1': {
          textAlign: 'center',
        },
      },
    }
  };
};
