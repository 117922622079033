// @flow
import type { JSSThemeVariablesType } from './JSSThemeDefault';
import { getButtonColors } from './jss-helpers';

export const JSSThemeDefaultResets = (themeVars: JSSThemeVariablesType) => {
  const {
    fontSizes,
    margins,
    fonts,
    borderRadius,
    colors: {
      colorCombinations: { text1Bg2 },
    },
  } = themeVars;
  return {
    button: {
      borderRadius: borderRadius.md,
      fontFamily: fonts.default.fontFamily,
      display: 'inline-block',
      transition: '0.3s ease-in',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      fontStyle: 'normal',
      border: 'none',
      fontSize: fontSizes.md,
      padding: [margins.xs, margins.md * 1.5],
      lineHeight: 'normal',
      cursor: 'pointer',
      textDecoration: 'none',
      outline: 0,
      width: 'auto',
      ...getButtonColors(text1Bg2),
    },
    '@global': {
      a: {
        textDecoration: 'none',
      },
    },
  };
};

export type JSSThemeDefaultResetsType = JSSThemeVariablesType => {
  [$Keys<$Call<typeof JSSThemeDefaultResets, JSSThemeVariablesType>>]: any,
};
