// @flow
import { type $DeepShape, type JSSThemeVariablesType } from './JSSThemeDefault';

export const JSSThemeDefaultLive = (themeVars: JSSThemeVariablesType) => {
  const {
    margins,
    fontSizes,
    borderRadius,
    colors: {
      colorTypes: { white, primary, accent, background4 },
    },
  } = themeVars;

  return {
    LiveEventView: {
      liveEventViewRoot: {},
    },
    BannerSectionComponent: {
      bannerTitle: {},
      bannerBadge: {},
    },
    ScoreNumber: {
      wonScore: {},
    },
    LiveCouponMarket: {
      favoriteMarketIcon: {},
      favoriteMarketIconWatched: {},
    },
    LiveSportComponent: {
      panelHeading: {},
    },
    LiveMenuFavoritesComponent: {
      favoritesPanelGroup: {},
      panelHeading: {},
      favoritesMessage: {},
    },
    LiveMenuFavoriteIcon: {
      liveMenuFavoriteIcon: {},
    },
    LiveLeague: {
      liveLeagueRoot: {},
      liveLeaguePanelHeading: {},
      panelTitleLeagueName: {},
      liveLeagueCollapseIcon: {},
    },
    LiveMenuEventHeading: {
      eventHeading: {},
      eventHeadingTeams: {},
      eventHeadingSuspended: {
        marginRight: '5px',
      },
      LiveIcon: {
        fill: '#f52f21',
        margin: '-1px 5px 0 -0.5px',
        order: 1,
        '@media (pointer: coarse)': {
          order: 'unset',
        },
      },
    },
    LiveMenuEvent: {
      LiveEventRankedDesktop: {
        flexBasis: 'calc(50% - 10px)',
        marginRight: '5px !important',
        borderRadius: 3,
      },
      LiveIcon: {
        fill: '#f52f21',
        width: '22px',
        margin: [0, margins.md, 0, 0],
      },
      Clock: {
        padding: [0, margins.xs],
        color: primary,
      },
      Teams: {
        position: 'relative',
        cursor: 'pointer',
        padding: 0,
        maxWidth: '100%',
        display: 'block',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        width: '83.33333333%',
        '&:hover': {
          color: '',
        },
      },
      LiveEventRanked: {
        display: 'flex',
        alignItems: 'center',
        animation: 'liveNowAppearance 0.1s linear 1 normal forwards',
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        fontSize: fontSizes.sm,
        color: '#fefefe',
        background: background4,
        padding: margins.md,
        margin: [0, 0, margins.md],
      },

      TeamsText: {},
    },
    LiveMenuRegularEvent: {
      liveMenuEventRoot: {},
      liveMenuEventInfo: {},
      liveMenuEventInfoChild: {},
      liveMenuEventLink: {
        color: 'inherit !important',
        transition: 'none !important',
      },
    },
    LiveMenuRankedEvent: {
      liveMenuRankedEventRoot: {},
      liveMenuRankedEventWrapper: {},
      liveMenuRankedEventTeams: {},
      LiveIcon: {
        fill: '#f52f21',
        marginRight: margins.xs,
        order: 1,
      },
    },
    ClockScoreComponent: {
      clockScorePeriod: {},
      clockScoreResult: {},
    },
    Badge: {
      root: {},
    },
    LiveStreamingMessage: {
      loginButton: {
        padding: [margins.md, margins.xm * 2],
        fontWeight: 'bold',
        fontSize: fontSizes.lg,
        borderRadius: borderRadius.xl,
        display: 'inline-block',
        transition: '0.3s ease-in',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        fontStyle: 'normal',
        border: 'none',
        lineHeight: 'normal',
        cursor: 'pointer',
        textDecoration: 'none',
        outline: 0,
        width: 'auto',
        background: primary,
        color: white,
        '@media not all and (pointer: coarse)': {
          '&:hover': {
            background: accent,
            color: white,
          },
        },
      },
      LiveStreamingMessage: {
        textAlign: 'center',
        color: '#fff',
        fontSize: fontSizes.lg,
        fontWeight: 'bold',
        padding: [margins.xm, 0, margins.xm * 2],
        background: 'rgba(0, 0, 0, 0.3)',
      },
      LiveStreamingMessageText: {
        padding: 0,
      },
    },
  };
};

export type JSSThemeDefaultLiveType = (JSSThemeVariablesType) => $DeepShape<
  $Call<typeof JSSThemeDefaultLive, JSSThemeVariablesType>
>;
