// @flow
import { type JSSThemeVariablesType, type $DeepShape } from './JSSThemeDefault';

export const JSSThemeDefaultWidgets = (themeVars: JSSThemeVariablesType) => {
  return {
    LiveNowNotRankedEvent: {
      eventRoot: {},
      eventHeader: {},
      totalScore: {},
    },
    ClockTime: {
      clockTimePrematch: {},
      clockTimeLive: {},
    },
  };
};

export type JSSThemeDefaultWidgetsType = JSSThemeVariablesType => $DeepShape<
  $Call<typeof JSSThemeDefaultWidgets, JSSThemeVariablesType>
>;
