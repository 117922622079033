// @flow
import { LIVE_PERFORM_SET_ERROR } from '../../actions/live/event-actions';
import type { ReduxAction, LivePerform } from '../../../types';

export default (state: LivePerform = {}, action: ReduxAction) => {
  switch (action.type) {
    case LIVE_PERFORM_SET_ERROR: {
      const { eventId } = action.payload;
      return {
        ...state,
        [eventId]: { isError: true },
      };
    }
    default:
      return state;
  }
};
