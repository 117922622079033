import React from 'react';
import Loadable from 'react-loadable';
import { withErrorBoundary } from './ErrorBoundary';
import { Loading as ComponentLoading } from './Loading';
import { checkKoltronVersion } from '../../checkKoltronVersion';

const Loading = ({ error, pastDelay }) => {
  const [actualError, setActualError] = React.useState(null);

  React.useLayoutEffect(
    () => {
      if (error) {
        checkKoltronVersion().then(isValid => {
          if (isValid) {
            setActualError(error);
          }
        });
      }
    },
    [error]
  );

  if (actualError) {
    throw actualError;
  }

  if (pastDelay) {
    return <ComponentLoading />;
  }

  return null;
};

export const LoadableComponentWrapper = options =>
  withErrorBoundary(
    Loadable({
      loading: Loading,
      ...options,
    })
  );
