// @flow
import * as React from 'react';
import { createPortal } from 'react-dom';

type Props = {
  children: React.Node,
  className: string,
};

export class Modal extends React.Component<Props> {
  componentDidMount() {
    this.el.className = `osg-modal ${this.props.className}`;
    if (document.body) {
      document.body.appendChild(this.el);
    }
  }

  componentWillUnmount() {
    if (document.body) {
      document.body.removeChild(this.el);
    }
  }

  el = document.createElement('div');

  render() {
    return createPortal(this.props.children, this.el);
  }
}
