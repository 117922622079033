// @flow
import { type JSSThemeVariablesType, type $DeepShape } from './JSSThemeDefault';
import { jssHelpers } from './jss-helpers';

export const JSSThemeDefaultHeaderMenu = (themeVars: JSSThemeVariablesType) => {
  const {
    margins,
    fontSizes,
    colors: {
      colorTypes: { text2, border2, white, primary, text5 },
      colorCombinations: { blackWhite },
    },
  } = themeVars;
  return {
    HeaderMenuDesktop: {
      menuWrapper: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        zIndex: 1,
        fontSize: fontSizes.md,
        ...blackWhite,
      },
      menu: {
        display: 'flex',
        flexDirection: 'row',
        ...jssHelpers.ulReset,
      },
      menuItemWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        borderRight: `1px solid ${border2}`,
        '&:last-child': {
          borderRight: 'none',
        },
      },
      menuItem: {
        padding: [0, margins.lg],
        position: 'relative',
        textDecoration: 'none',
        display: 'inline-block',
        lineHeight: `${fontSizes.xxl}px`,
        color: 'inherit',
        '&:hover, &:focus, &:active': {
          color: text2,
        },
        '@media screen and (max-width: 1200px)': {
          padding: [0, margins.xs],
        },
      },
      menuItemActive: {
        color: text2,
      },
    },
    HeaderMenuMobile: {
      root: {
        padding: [margins.xm, 0],
      },
      mobileMenu: {
        listStyleType: 'none',
      },
      iconSportsbook: {
        marginRight: margins.md,
      },
      iconWrap: {
        fontSize: fontSizes.xxl,
      },
      menuTitle: {
        lineHeight: 1,
      },
      menuItem: {
        ...jssHelpers.aReset,
        padding: [margins.xs, margins.xm],
        fontSize: fontSizes.xxl,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        display: 'flex',
        alignItems: 'center',
        lineHeight: `calc(${fontSizes.xxl}px + 5px)`,
        color: white,
        '&:hover,&:focus': {
          color: white,
        },
        '&.active': {
          color: primary,
        },
      },
    },
    HeaderSubmenuDesktop: {
      root: {
        padding: [margins.md, margins.md, margins.md, 300],
        background: text5,
      },
      link: {
        fontSize: fontSizes.sm,
        padding: [0, margins.xm],
        color: white,
      },
      icon: {
        width: '14px',
        height: '14px',
        marginRight: margins.xs,
      },
    },
    HeaderSubmenuMobile: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        padding: [margins.xm, margins.xm, 0, margins.xm],
      },
      link: {
        fontSize: fontSizes.xxl,
        paddingTop: margins.xs,
        color: white,
      },
      icon: {
        width: '14px',
        height: '14px',
        marginRight: margins.xs,
      },
    },
  };
};

export type JSSThemeDefaultHeaderMenuType =
  (JSSThemeVariablesType) => $DeepShape<
    $Call<typeof JSSThemeDefaultHeaderMenu, JSSThemeVariablesType>
  >;
