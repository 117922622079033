// @flow
import {
  CASINO_PATH,
  IPOOLS_PATH,
  LIVE_CASINO_PATH,
  LIVE_PATH,
  PREMATCH_PATH,
  PROMOTIONS_PATH,
  SEASONAL_PROMOTION_PATH,
  VIRTUALS_PATH,
  POST_REGISTRATION_PATH as POST_REGISTRATION_PATH_COMMON,
} from '../common/constants';
import {
  HEADER_CASINO_BTN_ID,
  HEADER_CASINO_LIVE_BTN_ID,
  HEADER_ESPORTS_BTN_ID,
  HEADER_IPOOLS_BTN_ID,
  HEADER_LIVE_BTN_ID,
  HEADER_PROMOTIONS_BTN_ID,
  HEADER_SEASONAL_PROMOTION_BTN_ID,
  HEADER_SPORT_BTN_ID,
  HEADER_VIRTUALS_BTN_ID,
} from '../common/elementIDs';

export const MENU_ITEM_PREMATCH = {
  title: 'prematch-page',
  to: PREMATCH_PATH,
  id: HEADER_SPORT_BTN_ID,
  target: '_self',
};

export const MENU_ITEM_LIVE = {
  title: 'live-page',
  to: LIVE_PATH,
  id: HEADER_LIVE_BTN_ID,
  target: '_self',
};

export const MENU_ITEM_CASINO = {
  title: 'casino-page',
  to: CASINO_PATH,
  id: HEADER_CASINO_BTN_ID,
  target: '_self',
};

export const MENU_ITEM_PROMOTIONS = {
  title: 'promo-page',
  to: PROMOTIONS_PATH,
  id: HEADER_PROMOTIONS_BTN_ID,
  target: '_self',
};

export const MENU_ITEM_VIRTUALS = {
  title: 'virtuals',
  to: VIRTUALS_PATH,
  id: HEADER_VIRTUALS_BTN_ID,
  target: '_self',
};

export const MENU_ITEM_IPOOLS = {
  title: 'ipools',
  to: IPOOLS_PATH,
  id: HEADER_IPOOLS_BTN_ID,
  target: '_self',
};

export const MENU_ITEM_LIVE_CASINO = {
  title: 'casino-live',
  to: LIVE_CASINO_PATH,
  id: HEADER_CASINO_LIVE_BTN_ID,
  target: '_self',
};

export const MENU_ITEM_SEASONAL_PROMOTION = {
  title: 'seasonal-promo-page',
  to: SEASONAL_PROMOTION_PATH,
  id: HEADER_SEASONAL_PROMOTION_BTN_ID,
  target: '_self',
};

export const MENU_ITEM_ESPORTS = {
  title: 'e-sports',
  to: '/sportsbook/6000',
  id: HEADER_ESPORTS_BTN_ID,
  target: '_self',
};

export const MAIN_MENU = [
  MENU_ITEM_PREMATCH,
  MENU_ITEM_LIVE,
  MENU_ITEM_CASINO,
  MENU_ITEM_PROMOTIONS,
];

export const ADDITIONAL_SITEMAP_ITEMS = [];

if (__OSG_CONFIG__.showVirtuals) {
  MAIN_MENU.push(MENU_ITEM_VIRTUALS);
}

if (window.__OSG_RUNTIME_CONFIG__.showIpools) {
  MAIN_MENU.push(MENU_ITEM_IPOOLS);
}

export const getMenuTitle = (title: string) => {
  switch (title) {
    case 'prematch-page':
      return _t('prematch-page');
    case 'live-page':
      return _t('live-page');
    case 'casino-page':
      return _t('casino-page');
    case 'promo-page':
      return _t('promo-page');
    case 'virtuals':
      return _t('virtuals');
    case 'ipools':
      return _t('ipools');
    case 'blog':
      return _t('blog');
    case 'sitemap-title':
      return _t('sitemap-title');
    case 'casino-live':
      return _t('casino-live');
    case 'tutorials':
      return _t('tutorials');
    case 'tragamonedas':
      return _t('tragamonedas');
    case 'seasonal-promo-page':
      return _t('seasonal-promo-page');
    case 'contacts':
      return _t('contacts');
    case 'main':
      return _t('main-page');
    case 'e-sports':
      return _t('e-sports');
    case 'sports-predictions':
      return _t('sports-predictions');
    case 'home':
      return _t('home');
    default:
      return 'noMenuTitleKey';
  }
};

export const COMMA_THOUSANDS_SEPARATOR = ',';

/* FIXME: If user close the casino tab (all tabs) and then return in a few time
  this timer doesn't update
*/
export const LOGIN_TIMESTAMP_STORAGE_LEY = 'login_timestamp';

export const COOKIE_BANNER_STORAGE_KEY_WAS_SHOWN = 'click-date-cookie-banner';

export const OPM_UNLOCKED_STORAGE_KEY = 'OSG_OPM_unlocked';

export const KEY_IDENTITY_CARD_NUMBER = 'identityCardNumber';

export const TEST_LOCALE = 'tst-TST';

export const LOCAL_STORAGE_KEY_INK_BANNER_HIDE = 'ink_hide_banner';

export const POST_REGISTRATION_PATH =
  __OSG_CONFIG__.customPostregUrl.enabled && __OSG_CONFIG__.customPostregUrl.URL
    ? __OSG_CONFIG__.customPostregUrl.URL
    : POST_REGISTRATION_PATH_COMMON;

export const BG_PLAYER_URL =
  'https://genius-live-player-production.betstream.betgenius.com';

export const TRUSTLY_REGISTRATION_TYPE = 'trustly';
export const SIMPLE_REGISTRATION_TYPE = 'simple';
export const MATI_REGISTRATION_TYPE = 'mati';

export const DIFFUSION_EMPTY_VALUE = '@@';
