import { SportsmenuActionTypes } from '../actions/sportsmenu-actions';

const defaultState = {
  leagues: {},
  regions: {},
  sports: {},
  isLoading: true,
  isError: false,
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case SportsmenuActionTypes.fetch:
      if (payload.sports.length === 0)
        return {
          ...state,
          isLoading: true,
        };
      return {
        ...state,
        ...payload,
        isLoading: false,
        isError: false,
      };
    case SportsmenuActionTypes.error:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    default:
      return state;
  }
};
