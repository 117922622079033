// @flow
import { getSafetyEventField, getSportId, parseJSONSafely } from './utils';

const keys = {
  '0': 'name',
};

const handlers = {
  name: parseJSONSafely,
};

const childrenHandlers = {
  name: (name: string | Object): { name: string } => {
    return {
      name: getSafetyEventField(name, 'name'),
    };
  },
};

export const sportHandlerSettings = {
  keys,
  getKey: getSportId,
  handlers,
  childrenHandlers,
};
