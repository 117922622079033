// @flow
import { KOLTRON_SEO_UPDATE } from '../actions/action-types';
import { type SeoType, type ReduxAction } from '../../types';

const defaultState = {
  title: '',
  description: '',
  h1: '',
  textData: null,
  ogDescription: '',
  ogTitle: '',
  ogImage: '',
  postIDs: [],
  alias: '',
};

export const seoReducer = (
  state: SeoType = defaultState,
  action: ReduxAction
): SeoType => {
  switch (action.type) {
    case KOLTRON_SEO_UPDATE:
      return {
        title: action.payload.title,
        description: action.payload.description,
        h1: action.payload.h1,
        textData: action.payload.textData,
        ogDescription: action.payload.ogDescription,
        ogTitle: action.payload.ogTitle,
        ogImage: action.payload.ogImage,
        postIDs: action.payload.postIDs,
        alias: action.payload.alias,
      };
    default:
      return state;
  }
};
