// @flow
import * as React from 'react';
import { useCurrentTime, useIsOffline } from '../redux-hooks';
import { useJSSStyles } from '../jss-hooks';
import { type JSSThemeType } from '../origins/JSSThemeDefault';

const JSSPath = (theme: JSSThemeType) => theme.components.OfflineMessage;

export const OfflineMessage = () => {
  useCurrentTime();
  const isOffline = useIsOffline();
  const classes = useJSSStyles(JSSPath);

  return isOffline ? (
    <div className={classes.popup}>{_t('offline-message')}</div>
  ) : null;
};
