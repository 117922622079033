// @flow
import { type JSSThemeVariablesType, type $DeepShape } from './JSSThemeDefault';

export const JSSThemeDefaultForm = (themeVars: JSSThemeVariablesType) => {
  const {
    fontSizes,
    margins,
    colors: {
      colorCombinations: { text3Neutral2 },
      colorTypes: { error, background3, border2, success, white, text2 },
    },
    borderRadius,
    buttons: { secondary },
  } = themeVars;
  return {
    Input: {
      root: {
        width: '100%',
        fontSize: fontSizes.md,
        border: `1px solid ${border2}`,
        borderRadius: borderRadius.md,
        height: '32px',
        lineHeight: '32px',
        padding: [0, margins.md],
        display: 'inline-block',
        transition: 'all 0.1s ease',
        outline: 'none',
        ...text3Neutral2,
        '&::placeholder': {
          color: background3,
        },
        '&:read-only': {
          opacity: 0.5,
        },
        '&:-webkit-autofill': {
          '-webkit-box-shadow': `0 0 0 30px ${white} inset !important`,
          '-webkit-text-fill-color': `${text2} !important`,
        },
        '@media (pointer: coarse)': {
          fontSize: fontSizes.lg,
          '&:[type="color"], &:[type="date"], &:[type="datetime"], &:[type="datetime-local"], &:[type="email"], &:[type="month"], &:[type="number"], &:[type="password"], &:[type="search"], &:[type="tel"], &:[type="text"], &:[type="time"], &:[type="url"], &:[type="week"], &:select:focus, &:textarea':
            {
              fontSize: fontSizes.lg,
            },
        },
      },
      error: {
        color: error,
      },
      hasIcon: {
        textIndent: margins.lg,
      },
      betslipInput: {
        border: `1px solid ${white}`,
        borderRadius: '0 0 5px 5px',
        fontSize: fontSizes.sm,
        padding: [margins.sm, margins.xs],
        ...text3Neutral2,
      },
      hasError: {
        borderColor: error,
        color: error,
      },
      activeDone: {
        borderColor: success,
        transition: 'all 0.1s ease',
      },
      inputFieldWrap: {
        marginBottom: margins.xm,
      },
      iconShowPassword: {
        position: 'absolute',
        fontSize: fontSizes.sm,
        top: 0,
        right: 0,
        height: '100%',
        opacity: 0.6,
        padding: [0, margins.sm],
        display: 'flex',
        alignItems: 'center',
        color: text2,
      },
    },
    FileInput: {
      input: {
        display: 'none',
      },
      fileList: {
        padding: [margins.xs, 0],
        margin: [-margins.xs, 0],
      },
      file: {
        margin: [margins.xs, 0],
      },
      icon: {
        marginRight: margins.md,
      },
      iconRemove: {
        cursor: 'pointer',
        marginLeft: margins.md,
      },
      buttonIcon: {
        marginRight: margins.md,
      },
      description: {
        opacity: 0.5,
        padding: [margins.xm, 0, margins.md],
      },
      error: {
        color: error,
      },
      uploadButton: secondary,
    },
    Message: {
      messageHelp: {
        position: 'relative',
      },
      messageError: {
        fontSize: fontSizes.sm,
        color: error,
        padding: [margins.xs, 0],
        position: 'absolute',
      },
    },
    Field: {
      root: {
        width: '100%',
        position: 'relative',
        display: 'inline-block',
        textAlign: 'left',
        marginBottom: margins.lg + margins.xs,
      },
      fieldLabel: {
        fontSize: fontSizes.md,
        display: 'block',
        padding: [margins.md, 0],
      },
      fieldLabelError: {
        color: error,
      },
      iconForField: {
        cursor: 'default',
        fontSize: fontSizes.lg,
        lineHeight: '32px',
        paddingLeft: margins.lg / 2,
        position: 'absolute',
      },
      halfSize: {
        flexBasis: '49%',
      },
      checkboxField: {
        margin: [margins.lg, 0, 0],
      },
    },
    InputField: {
      errorMessage: {
        padding: [margins.xs, 0],
        color: error,
      },
    },
    PasswordField: {
      inputField: {
        position: 'relative',
      },
      iconShowPassword: {
        position: 'absolute',
        fontSize: fontSizes.sm,
        top: 0,
        right: 0,
        opacity: 0.6,
        padding: [margins.md, margins.sm, 0],
        display: 'flex',
        alignItems: 'center',
        color: text2,
        cursor: 'pointer',
      },
    },
    Select: {
      selectRoot: {},
    },
    SubmitSection: {
      submitSectionRoot: {},
      submitSectionError: {},
    },
    TelInputWithCode: {
      root: {
        '& > input': {
          width: '100% !important',
        },
      },
      readOnly: {
        opacity: 0.5,
        '& > input': {
          cursor: 'default !important',
        },
      },
    },
  };
};

export type JSSThemeDefaultFormType = (JSSThemeVariablesType) => $DeepShape<
  $Call<typeof JSSThemeDefaultForm, JSSThemeVariablesType>
>;
