// @flow
import { merge, never, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { getAccountNumber, getIsAuthorised } from '../selectors/auth';
import { type ReduxAction, type ReduxState } from '../../types';
import { type ActionsObservable, type StateObservable } from 'redux-observable';
import {
  KOLTRON_AUTHORIZE,
  KOLTRON_UNAUTHORIZE,
  KOLTRON_UNAUTHORIZE_WITH_ERROR,
  USER_REGISTER,
} from '../actions/action-types';
import {
  XTREMEPUSH_UPDATED_CUSTOMER_ID,
  XTREMEPUSH_UPDATED_CUSTOMER_ID_WITH_ERROR,
} from '../actions/xtremepush';

/**
 * Function binds user account ID with the unique ID issued by xtremepush
 * xtremepush - a global function declared in an external loadable script
 * (view get-sdk-script-tag.js and html.js)
 * @param state$
 * @returns {{type: "xtremepush:customer_id:updated_with_error"}|{type: "xtremepush:customer_id:updated"}}
 */
const registerCustomerId = (state$: StateObservable<ReduxState>) => {
  const xtremepush = window.xtremepush;
  if (typeof xtremepush === 'function') {
    const accountNumber = getAccountNumber(state$.value);
    xtremepush('set', 'user_id', accountNumber);
    return {
      type: XTREMEPUSH_UPDATED_CUSTOMER_ID,
    };
  } else {
    return {
      type: XTREMEPUSH_UPDATED_CUSTOMER_ID_WITH_ERROR,
    };
  }
};

/**
 * Function removes the link between the user account ID and the unique ID issued by xtremepush
 * xtremepush - a global function declared in an external loadable script
 * (view get-sdk-script-tag.js and html.js)
 * @returns {{type: "xtremepush:customer_id:updated_with_error"}|{type: "xtremepush:customer_id:updated"}}
 */
const unregisterCustomerId = () => {
  const xtremepush = window.xtremepush;
  if (typeof xtremepush === 'function') {
    xtremepush('set', 'user_id', null);
    return {
      type: XTREMEPUSH_UPDATED_CUSTOMER_ID,
    };
  } else {
    return {
      type: XTREMEPUSH_UPDATED_CUSTOMER_ID_WITH_ERROR,
    };
  }
};

/**
 * Function creates an epic that triggers on user login, logout and registration
 * and binds xtremepush ID with user account ID or removes it
 * @param action$
 * @param state$
 * @returns {rxjs$Observable<T>}
 */
export const xtremepushAuthEpic = (
  action$: ActionsObservable<ReduxAction>,
  state$: StateObservable<ReduxState>
) => {
  if (window.__OSG_RUNTIME_CONFIG__.xtremepushToken) {
    return merge(
      action$
        .ofType(KOLTRON_AUTHORIZE, USER_REGISTER)
        .pipe(map(() => registerCustomerId(state$))),
      action$
        .ofType(KOLTRON_UNAUTHORIZE, KOLTRON_UNAUTHORIZE_WITH_ERROR)
        .pipe(map(unregisterCustomerId))
    );
  } else {
    return never();
  }
};

/**
 * Function binds xtremepush ID with user account ID or removes it
 * 15 seconds after the user visits the site
 * @param action$
 * @param state$
 * @returns {rxjs$Observable<T>}
 */
export const xtremepushTimerTokenUpdateEpic = (
  action$: ActionsObservable<ReduxAction>,
  state$: StateObservable<ReduxState>
) => {
  if (window.__OSG_RUNTIME_CONFIG__.xtremepushToken) {
    const tokenDelayTime = 15 * 1000;
    return timer(tokenDelayTime).pipe(
      map(() =>
        getIsAuthorised(state$.value)
          ? registerCustomerId(state$)
          : unregisterCustomerId()
      )
    );
  } else {
    return never();
  }
};
